import React, {Component} from 'react';
import {connect} from "react-redux";
import CloseVector from "../../../assets/images/CloseVector.png";
import SaveButton from "../../../components/common/Buttons/SaveButton";
import CancelButton from "../../../components/common/Buttons/CancelButton";
import {ChangeEmailErr, ChangeMerchantEmail,} from "../../../modules/merchantes/merchantesAction";
import {CloseAddAdminPage, CloseAdminInfo, OpenBlockAdmin} from "../../../modules/admins/adminAction";
import {AddAdmin} from "../../../modules/admins/AddAdmins/AddAdminsAction";
import ErrMessage from "../../../components/common/ErrMessage";
import TextFieldS from "../../../components/common/Inputs/TextField";
import {Translation} from "react-i18next";
import MerchantReg from "../../MerchantReg";
import * as moment from "moment/moment";


const styles = {
    Container: {
        background: 'rgba(0, 0, 0, 0.4)',
        width: '100%',
        minHeight: '100vh',
        zIndex: '100',
        position: 'fixed',
        right: 0,
    },
    hideDiv: {
        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
        boxShadow: '-2px 0px 4px rgba(0, 0, 0, 0.2), -1px 0px 10px rgba(0, 0, 0, 0.12), -4px 0px 5px rgba(0, 0, 0, 0.14)',
        width: '370px',
        height: '100%',
        position: 'absolute',
        minHeight: '100vh',
        zIndex: '20',
        right: 0,
        boxSizing: 'border-box',
        padding: '20px',
        overflowY: 'scroll'
    },
    ChangeDivCloseButton: {
        background: 'none',
        border: 'none',
    },
    hideDivCloseButton: {
        background: 'none',
        border: 'none',
        marginLeft: '220px',
        marginTop: '20px'
    },
    hideDivID: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        color: 'rgba(255, 255, 255, 0.6)'
    },
    hideDivIDcontent: {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: 'rgba(255, 255, 255, 0.87)'
    },
    hideDivIDcontentID: {
        marginLeft: '20px',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: 'rgba(255, 255, 255, 0.87)'
    },
    showList: {
        marginTop: '10px',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '10px',
        lineHeight: '16px',
        letterSpacing: '1.5px',
        textTransform: 'uppercase',
        color: 'rgba(255, 255, 255, 0.6)',
    },
    showListInfo: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        // lineHeight: '20px',
        letterSpacing: '0.25px',
        color: 'rgba(255, 255, 255, 0.87)',
    },
    hideDivIds: {
        display: 'flex',
        flexDirection: 'column',
    },
    blockUserButton: {
        background: 'none',
        border: '1px solid #EB5B47',
        color: '#EB5B47',
        borderRadius: '8px',
        height: '40px',
        width: '170px',
        marginTop: '25px',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '1.25px',
        textTransform: 'uppercase',
    },
    ActiveUserButton: {
        background: 'rgba(101, 227, 42, 0.87)',
        border: '1px solid green',
        color: 'white',
        borderRadius: '8px',
        height: '40px',
        width: '170px',
        marginTop: '25px',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '1.25px',
        textTransform: 'uppercase',
    },
}

class AddAdmins extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showInfo: true,
            HistoryColor: 'white',
            InfoColor: '#5F94E2',
            BlockPopup: false,
            blockButton: false,
            activeButton: false,
            errMassage: false,
            NewEmail: ''
        }
    }

    handleChange = (ev) => {
        const {name, value} = ev.target;
        const newState = {};
        newState[name] = value;
        this.setState(newState);
        this.setState({errMassage: false})
    };

    addMerchant = () => {
        const {login, password} = this.state
        if (login && password) {
            this.props.AddAdmin(login, password)
            this.props.CloseAddAdminPage()
        } else {
            this.setState({errMassage: true})
        }
    }


    CloseSection = () => {
        this.props.CloseMerchantInfo()
        this.setState({InfoColor: '#5F94E2', showInfo: true, HistoryColor: 'white'})

    }
    SetColor = () => {
        this.setState({InfoColor: '#5F94E2', showInfo: true, HistoryColor: 'white'})
    }
    // SetSecondColor = (phoneNumber) => {
    // this.props.setRentHistoryItem(phoneNumber)
    // this.setState({InfoColor: 'white', showInfo: false, HistoryColor: '#5F94E2'})
    // }
    openBlocKPopup = (openAdminInfo) => {
        this.props.OpenBlockAdmin(openAdminInfo)
        this.props.CloseAdminInfo()
    }
    handleChangeNewEmail = (ev) => {
        this.setState({NewEmail: ev.target.value})
        this.setState({errMassage: false})
    }
    ChangeAdmin = () => {
        const oldEmail = this.props.OpenAdminsAddPage[1]
        const newEmail = this.state.NewEmail

        if (this.state.NewEmail.length > 0) {
            this.props.ChangeMerchantEmail([oldEmail, newEmail])
            if (this.props.MerchantsEmailErr !== 'EMAIL_EXIST') {

            } else {
                this.props.CloseAddAdminPage()
            }
            this.setState({NewEmail: ''})
        } else {
            this.setState({errMassage: true})
        }
    }
    handleClose = () => {
        this.props.CloseAddAdminPage()
        this.setState({errMassage: false})
        this.props.ChangeEmailErr('')
    }

    render() {
        const MerchantsInfo = Object.values(this.props.openInfo)
        const date =  MerchantsInfo.created ?  moment.tz(MerchantsInfo.created,'YYYY-MM-DD/HH:mm','Europe/Moscow').local().format(' DD.MM.YYYY/HH:mm') : '...'
        const {showInfo} = this.state
        const open = this.props.OpenAdminsAddPage
        const openAdminInfo = Object.values(this.props.openInfo)
        const Role = localStorage.getItem('role')

        return (
            <>
                <Translation>
                    {(t) =>
                        <>
                            {open[0] === 'edit' ?
                                <div style={styles.Container}>
                                    <div onClick={() => {
                                        this.handleClose()
                                    }} className={'CloseSectionClass'}/>
                                    <div style={styles.hideDiv}>

                                        <div style={{display: 'flex', alignItems: 'center',}}>
                                            <button onClick={() => {
                                                this.handleClose()
                                            }} style={styles.ChangeDivCloseButton}>
                                                <img src={CloseVector} alt={CloseVector}/></button>
                                            <span style={{color: 'white', marginLeft: '15px'}}>{t('Edit Admin')}</span>
                                        </div>
                                        <div>
                                            <TextFieldS onChange={this.handleChangeNewEmail} name='login' type='email'
                                                        label={t('E-mail')}/>
                                        </div>
                                        {this.state.errMassage === true ? <ErrMessage/> : ''}
                                        {this.props.MerchantsEmailErr === 'EMAIL_EXIST' ?
                                            <div style={{marginTop: '20px'}}>
                <span style={{color: '#EB5B47', fontSize: '16px', lineHeight: '24px', letterSpacing: '0.5px',}}>
                 {t('New email matches with old email')}
                </span>
                                            </div>
                                            : ""}
                                        <div style={{marginTop: '20px',}}>
                                            <CancelButton
                                                onClick={() => this.handleClose()}>{t('CANCEL')}</CancelButton>
                                            <SaveButton onClick={this.ChangeAdmin}>{t('Change')}</SaveButton>
                                        </div>
                                    </div>
                                </div>

                                : ""}
                            {open === 'open'
//                            && this.props.MerchantsInfo
                                ?
                                <div style={styles.Container}>
                                    <div onClick={() => {
                                        this.props.CloseAddAdminPage() && this.setState({errMassage: false})
                                    }} className={'CloseSectionClass'}/>

                                    <div style={styles.hideDiv}>

                                        <div style={{display: 'flex', alignItems: 'center',}}>
                                            <button onClick={() => {
                                                this.props.CloseAddAdminPage() && this.setState({errMassage: false})
                                            }} style={styles.ChangeDivCloseButton}>
                                                <img src={CloseVector} alt={CloseVector}/></button>
                                            <span style={{color: 'white', marginLeft: '15px'}}>{t('New Admin')}</span>
                                        </div>
                                        <div>
                                            <MerchantReg isPartner={false} onClose={() => this.props.CloseAddAdminPage()}/>
                                        </div>
                                        {this.state.errMassage === true ? <ErrMessage/> : ''}
                                    </div>
                                </div>
                                : ''}
                            {openAdminInfo.length
                            && this.props.MerchantsInfo
                                ?
                                <div style={{
                                    background: 'rgba(0, 0, 0, 0.4)',
                                    width: '100%',
                                    minHeight: '100vh',
                                    zIndex: '100',
                                    position: 'fixed',
                                    right: 0,
                                }}>
                                    <div onClick={() => {
                                        this.props.CloseAdminInfo()
                                    }} className={'CloseSectionClass'}/>
                                    <div style={styles.hideDiv}>
                                        <button onClick={() => {
                                            this.props.CloseAdminInfo()
                                        }} style={styles.hideDivCloseButton}><img src={CloseVector} alt={CloseVector}/>
                                        </button>
                                        <div style={styles.hideDivIds}>
                                            <span style={styles.hideDivID}>{t("ID")}</span>
                                            <span style={styles.hideDivIDcontent}>{openAdminInfo[0]}</span>
                                        </div>
                                        <div style={{display: 'flex', marginTop: '20px'}}>
                                            <button onClick={this.SetColor}
                                                    style={{
                                                        width: '100%',
                                                        border: 'none',
                                                        borderBottom: `1px solid ${this.state.InfoColor}`,
                                                        background: 'none',
                                                        padding: '15px',
                                                        outline: 'none'
                                                    }}>
                                                <span style={{color: this.state.InfoColor}}>{t("INFO")}</span></button>
                                        </div>
                                        {showInfo ?
                                            <div key={MerchantsInfo.id}
                                                 style={{display: 'flex', flexDirection: 'column', marginLeft: '20px'}}>
                                                <span style={styles.showList}>{t("NAME")}</span>
                                                <span
                                                    style={styles.showListInfo}>{this.props.MerchantsInfo.name ? this.props.MerchantsInfo.name : '...'}</span>
                                                <span style={styles.showList}>{t("Surname")}</span>
                                                <span
                                                    style={styles.showListInfo}>{this.props.MerchantsInfo.surname ? this.props.MerchantsInfo.surname : '...'}</span>
                                                <span style={styles.showList}>{t("Middle Name")}</span>
                                                <span
                                                    style={styles.showListInfo}>{this.props.MerchantsInfo.middleName ? this.props.MerchantsInfo.middleName : '...'}</span>
                                                <span style={styles.showList}>{t("Phone Number")}</span>
                                                <span
                                                    style={styles.showListInfo}>{this.props.MerchantsInfo.phoneNumber ? this.props.MerchantsInfo.phoneNumber : '...'}</span>
                                                <span style={styles.showList}>{t("E-mail")}</span>
                                                <span
                                                    style={styles.showListInfo}>{this.props.MerchantsInfo.email ? this.props.MerchantsInfo.email : '...'}</span>
                                                <span style={styles.showList}>{t("Account Number")}</span>
                                                <span
                                                    style={styles.showListInfo}>{this.props.MerchantsInfo.accountNumber ? this.props.MerchantsInfo.accountNumber : '...'}</span>
                                                <span style={styles.showList}>{t("Position")}</span>
                                                <span
                                                    style={styles.showListInfo}>{this.props.MerchantsInfo.position ? this.props.MerchantsInfo.position : '...'}</span>
                                                <span style={styles.showList}>{t("Legal Entinty")}</span>
                                                <span
                                                    style={styles.showListInfo}>{this.props.MerchantsInfo.nameOfLegalEntity ? this.props.MerchantsInfo.nameOfLegalEntity : '...'}</span>
                                                <span style={styles.showList}>{t("INN")}</span>
                                                <span style={styles.showListInfo}>{this.props.MerchantsInfo.inn ? this.props.MerchantsInfo.inn : '...'}</span>
                                                <span style={styles.showList}>{t("Legal Adress")}</span>
                                                <span style={styles.showListInfo}>{this.props.MerchantsInfo.legalAddress ? this.props.MerchantsInfo.legalAddress : '...'}</span>
                                                <span style={styles.showList}>{t("Bank Name")}</span>
                                                <span style={styles.showListInfo}>{this.props.MerchantsInfo.bankName ? this.props.MerchantsInfo.bankName : '...'}</span>
                                                <span style={styles.showList}>{t("Bic")}</span>
                                                <span style={styles.showListInfo}>{this.props.MerchantsInfo.bic ? this.props.MerchantsInfo.bic : '...'}</span>

                                                <span style={styles.showList}>{t("Shop id")}</span>
                                                <span style={styles.showListInfo}>{this.props.MerchantsInfo.shopId ? this.props.MerchantsInfo.shopId : '...'}</span>

                                                <span style={styles.showList}>{t("Online Kass")}</span>
                                                <span style={styles.showListInfo}>{this.props.MerchantsInfo.onlineCheckout  ? this.props.MerchantsInfo.onlineCheckout === true ? t('There is') : t('There is none')  : '...'}</span>
                                                <span style={styles.showList}>{t("Create Date")}</span>
                                                <span style={styles.showListInfo}>{date}</span>
                                                {this.state.acceptErrMessage === true ?
                                                    <ErrMessage/> : ''}
                                            </div>
                                            :
                                            <div/>
                                        }
                                    </div>
                                </div>
                                : ''}
                        </>}</Translation>
            </>
        );
    }
}

const mapStateToProps = state => ({
    OpenAdminsAddPage: state.adminReducer.OpenAdminsAddPage,
    openInfo: state.MerchantesReducer.MerchantsInfo2,
    MerchantsEmailErr: state.MerchantesReducer.MerchantsEmailErr
})

export default connect(mapStateToProps, {
    ChangeEmailErr,
    ChangeMerchantEmail,
    OpenBlockAdmin,
    CloseAdminInfo,
    CloseAddAdminPage,
    AddAdmin
})(AddAdmins);
