import { put, call, takeLatest } from "@redux-saga/core/effects";
import * as api from "../api";
import { BANKS_ACTIONS } from "./powerbanksReducer";
import axios from "axios";
import { getPowerBanksList } from "./powerbanksAction";
import {
  blockPowerBankService,
  downloadPowerbanksListService,
  photoControlService,
  searchPowerBankByParams,
  searchPowerBankByStationParams,
  searchPowerBankCollectionByParams,
} from "./powerbanksServices";

function* handleBanksSaga() {
  yield takeLatest(BANKS_ACTIONS.GET_LIST_REQUEST, BanksSaga);
}

function* BanksSaga(action) {
  try {
    // const {data} = yield call(api.GetPowerBanks,action.payload.page)
    const { data } = yield call(api.GetAllPowerbanks, action.payload.page);
    yield put({
      type: BANKS_ACTIONS.GET_LIST_SUCCESS,
      payload: { data: data.powerBankList },
    });
    yield put({
      type: BANKS_ACTIONS.GET_PAGE_LIST,
      payload: {
        data: {
          pageCount: data.pageCount,
          powerBankCount: data.powerBankCount,
        },
      },
    });
  } catch (e) {
    if (e.message === "Request failed with status code 401") {
      localStorage.removeItem("token");
      window.location.reload();
    }

    yield put({
      type: BANKS_ACTIONS.GET_LIST_FAIL,
      message: e.message,
    });
  }
}

export function* getPowerbanksByStatusWatcher() {
  yield takeLatest(
    BANKS_ACTIONS.GET_LIST_BY_STATUS_REQUEST,
    getPowerbanksByStatusWorker
  );
}

function* getPowerbanksByStatusWorker(action) {
  try {
    const { data } = yield call(
      api.GetPowerbanksByStatus,
      action.payload.page,
      action.payload.status,
      action.payload.checkingStatus
    );
    yield put({
      type: BANKS_ACTIONS.SET_LIST_BY_STATUS,
      payload: { data: data },
    });
  } catch (e) {
    console.log(e);
  }
}

// export function* getPowerbanksByConditionWatcher() {
//   yield takeLatest(
//     BANKS_ACTIONS.GET_LIST_BY_CONDITION_REQUEST,
//     getPowerbanksByConditionWorker
//   );
// }

// function* getPowerbanksByConditionWorker(action) {
//   try {
//     const { data } = yield call(
//       api.GetPowerbanksByCondition,
//       action.payload.condition
//     );
//     yield put({
//       type: BANKS_ACTIONS.SET_LIST_BY_CONDITION,
//       payload: { data: data },
//     });
//   } catch (e) {
//     console.log(e);
//   }
// }

function* attemptDelPowerBank({ payload }) {
  const powerBankId = payload.data;
  const page = payload.page;
  try {
    const { data } = yield axios.get(`/api/private/admin/deletePowerBank`, {
      params: {
        powerBankId,
      },
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    yield put(getPowerBanksList(page));
  } catch (e) {
    console.log(e);
  }
}

export function* DelBakSaga() {
  yield takeLatest(BANKS_ACTIONS.DEL_POWERBANK_REQUEST, attemptDelPowerBank);
}

function* handleFindPowerBank(action) {
  try {
    const { data } = yield call(api.FindPowerBank, action.payload.powerBankId);

    if (data.length === 0) {
      yield put({
        type: BANKS_ACTIONS.FINDING_SUCCESS_NOT_FOUND,
        payload: data.length === 0 ? "not found" : { data },
      });
    } else {
      yield put({
        type: BANKS_ACTIONS.FINDING_SUCCESS,
        payload: { data },
      });
    }
  } catch (e) {
    yield put({
      type: BANKS_ACTIONS.FINDING_FAIL,
    });
    console.log(e);
  }
}

export function* handleFindPowerBankById() {
  yield takeLatest(BANKS_ACTIONS.FINDING_REQUEST, handleFindPowerBank);
}

export function* downloadPowerbanksListWatcher() {
  yield takeLatest(
    BANKS_ACTIONS.DOWNLOAD_POWERBANKS_LIST_ASYNC,
    downloadPowerbanksListWorker
  );
}

export function* downloadPowerbanksListWorker({ payload }) {
  try {
    const { data } = yield call(downloadPowerbanksListService, payload);
    yield window.open(data, "_blank");
  } catch (e) {
    console.log(e);
  }
}

export function* photoControlWatcher() {
  yield takeLatest(BANKS_ACTIONS.PHOTO_CONTROL_ASYNC, photoControlWorker);
}

export function* photoControlWorker({ payload }) {
  try {
    const { data } = yield call(photoControlService, payload);
    yield put({
      type: BANKS_ACTIONS.PHOTO_CONTROL,
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
}

export function* blockPowerBankWatcher() {
  yield takeLatest(BANKS_ACTIONS.BLOCK_POWERBANK_ASYNC, blockPowerBankWorker);
}

export function* blockPowerBankWorker({ payload }) {
  try {
    const { id, block, comment } = payload;
    const { data } = yield call(blockPowerBankService, id, block, comment);
    yield put({
      type: BANKS_ACTIONS.BLOCK_POWERBANK,
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
}

export function* searchPowerbankWatcher() {
  yield takeLatest(BANKS_ACTIONS.SEARCH_POWERBANK_ASYNC, searchPowerbankWorker);
}

export function* searchPowerbankWorker({ payload }) {
  try {
    const { data } = yield call(searchPowerBankByParams, payload);
    const response = Array.isArray(data) ? data : [data];
    yield put({
      type: BANKS_ACTIONS.SET_LIST_BY_SEARCH,
      payload: { data: response },
    });
  } catch (e) {
    console.log(e);
  }
}

export function* searchPowerbankByStationWatcher() {
  yield takeLatest(
    BANKS_ACTIONS.SEARCH_POWERBANK_BY_STATION_ASYNC,
    searchPowerbankByStationWorker
  );
}

export function* searchPowerbankByStationWorker({ payload }) {
  try {
    const { data } = yield call(searchPowerBankByStationParams, payload);
    const response = Array.isArray(data) ? data : [data];
    yield put({
      type: BANKS_ACTIONS.SET_LIST_BY_SEARCH,
      payload: { data: response },
    });
  } catch (e) {
    console.log(e);
  }
}

export function* searchPowerbankCollectionWatcher() {
  yield takeLatest(
    BANKS_ACTIONS.SEARCH_POWERBANK_COLLECTION_ASYNC,
    searchPowerbankCollectionWorker
  );
}

export function* searchPowerbankCollectionWorker({ payload }) {
  try {
    const { data } = yield call(searchPowerBankCollectionByParams, payload);
    yield put({
      type: BANKS_ACTIONS.SET_LIST_BY_STATUS,
      payload: { data: data },
    });
  } catch (e) {
    console.log(e);
  }
}

export default handleBanksSaga;
