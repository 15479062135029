import { DASHBOARD_ACTIONS } from "./dashboardReducer";

export const getPowerbanksDashboardInfo = () => {
    return {
      type: DASHBOARD_ACTIONS.GET_POWERBANKS_DASHBOARD_INFO_REQUEST,
    };
};

export const getMerchantsDashboardInfo = () => {
    return {
      type: DASHBOARD_ACTIONS.GET_MERCHANTS_DASHBOARD_INFO_REQUEST,
    };
};

export const getLostPowerbanksInfo = (startDate, endDate, merchant, station) => {
    return {
      type: DASHBOARD_ACTIONS.GET_LOST_POWERBANKS_INFO_REQUEST,
      payload: {
        startDate: startDate,
        endDate: endDate,
        merchant: merchant,
        station: station
      }
    };
};

export const sortPowerbanksConditionsDashboardInfoByMerchant = (merchantEmail) => {
    return {
        type: DASHBOARD_ACTIONS.SORT_POWERBANKS_CONDITIONS_DASHBOARD_INFO_BY_MERCHANT_REQUEST,
        payload: {merchantEmail}
    };
};

export const clearMerchantsPowerbanksConditionsDashboardSortList = () => {
    return {
        type: DASHBOARD_ACTIONS.CLEAR_MERCHANTS_POWERBANKS_CONDITIONS_DASHBOARD_LIST
    };
};

export const sortPowerbanksStatusesDashboardInfoByMerchant = (merchantEmail) => {
    return {
      type: DASHBOARD_ACTIONS.SORT_POWERBANKS_STATUSES_DASHBOARD_INFO_BY_MERCHANT_REQUEST,
      payload: {merchantEmail}
    };
};

export const clearMerchantsPowerbanksStatusesDashboardSortList = () => {
    return {
      type: DASHBOARD_ACTIONS.CLEAR_MERCHANTS_POWERBANKS_STATUSES_DASHBOARD_LIST,
    };
};
  

export const changeMonthOnLostPowerbanksChart = (month) => {
  return {
    type: DASHBOARD_ACTIONS.CHANGE_MONTH_ON_LOST_POWERBANKS_CHART,
    payload: month
  }
}

export const getAllBanksOnMap = () => {
  return {
    type: DASHBOARD_ACTIONS.GET_ALL_STATIONS_ON_MAP
  }
}

export const getStationInfoOnMap = (stationId) => {
  return {
    type: DASHBOARD_ACTIONS.GET_STATION_INFO_ON_MAP,
    payload: stationId
  }
}
export const clearStationInfoOnMap = () => {
  return {
    type: DASHBOARD_ACTIONS.CLEAR_STATION_INFO_ON_MAP
  }
}