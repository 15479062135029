import {call, put, takeLatest} from "@redux-saga/core/effects";
import * as api from "../api";
import {MERCHANTS_ACTION} from "./MerchantesReducer";
import axios from 'axios';
import {BlockOneMerchant, ChangeEmailErr, CloseAddMerchantPage, GetMerchants} from "./merchantesAction";
import {BlockOneMerchantByAdmin} from "./GetMerchant/GetMerchantAction";
import {CloseAddAdminPage, GetAdmins} from "../admins/adminAction";

// axios.defaults.baseURL = 'https://dev.chargex.am/'
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL

function* handleSaga(action) {
  try {
    const {data} = yield call(api.getMerchants, action.payload.all ? action.payload.all : false)
    yield put({
      type: MERCHANTS_ACTION.GET_MERCHANTS_SUCCESS,
      payload: {data}
    })
  } catch (e) {

    if(e.message === 'Request failed with status code 401'){
      localStorage.removeItem('token')
      window.location.reload()
    }

    yield put({
      type: MERCHANTS_ACTION.GET_MERCHANTS_FAIL,
      message: e.message
    })
  }
}

function* attemptBlockMerchantsSaga ({payload}) {
  const email = payload.email
  const lock  = payload.lock
  try {
    const {data} = yield axios.get(`/api/private/admin/lockMerchant?${'email=' + email}`, {
      params: {
         'lock' :lock
      },
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('token'),
      }

    });
    yield put(BlockOneMerchant(payload.email,payload.lock))
    yield put(BlockOneMerchantByAdmin(payload.email,payload.lock))

  }catch(e) {
    console.log(e)
  }
}

function* attemptChangeMerchantsSaga ({payload}) {
  const oldEmail=payload.oldEmail[0]
  const newEmail=payload.oldEmail[1]

  try {
    const {data} = yield axios.post(`/api/private/admin/changeEmail?${'oldEmail='+ oldEmail}&&${'newEmail='+ newEmail}`, {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('token'),
      }
    });
    yield put(GetAdmins())
    yield put(GetMerchants())
    yield put(CloseAddAdminPage())
    yield put(CloseAddMerchantPage())

  }catch(e) {
    yield put(ChangeEmailErr(e.response.data))
  }
}

function* attemptRegSaga(actions) {
  try {
    const {data} = yield call(api.getMerchantsRequest)
    yield put({
      type: MERCHANTS_ACTION.GET_MERCHANTS_REG_SUCCESS,
      payload: {data}
    })
  } catch (e) {

    if(e.message === 'Request failed with status code 401'){
      localStorage.removeItem('token')
      window.location.reload()
    }

    yield put({
      type:MERCHANTS_ACTION.GET_MERCHANTS_REG_FAIL,
      message: e.message
    })
  }
}

function* attemptRegInfoSaga(action) {
  try {
    const {data} = yield call(api.getMerchantsRequestFullInfo,action.payload.email)
    yield put({
      type: MERCHANTS_ACTION.GET_MERCHANTS_REG_FULLINFO_SUCCESS,
      payload: {data}
    })
  } catch (e) {
    yield put({
      type:MERCHANTS_ACTION.GET_MERCHANTS_REG_FULLINFO_FAIL,
      message: e.message
    })
  }
}

function* SendMerchantReport(action) {
  try {
    const {data} = yield call(api.SentRepo,action.payload.data)
    yield put({
      type: MERCHANTS_ACTION.SENT_REPORT_SUCCESS,
      payload: {data}
    })
  } catch (e) {
  console.log(e)
  }
}

export function* SendMerchantReportSaga() {
  yield takeLatest(MERCHANTS_ACTION.SENT_REPORT_REQUEST, SendMerchantReport)
}

export function* merchantsREGSFullInfo() {
  yield takeLatest(MERCHANTS_ACTION.GET_MERCHANTS_REG_FULLINFO_REQUEST, attemptRegInfoSaga)
}

export function* merchantsREGSaga() {
  yield takeLatest(MERCHANTS_ACTION.GET_MERCHANTS_REG_REQUEST, attemptRegSaga)
}

export function* attemptBlockMerchant() {
  yield takeLatest(MERCHANTS_ACTION.BLOCK_MERCHANT_REQUEST, attemptBlockMerchantsSaga);
}

export function* attemptChangeMerchantEmail() {
  yield takeLatest(MERCHANTS_ACTION.CHANGE_MERCHANT_EMAIL, attemptChangeMerchantsSaga);
}

export function* merchantsSaga2() {
  yield takeLatest(MERCHANTS_ACTION.GET_MERCHANTS_REQUEST_ALL, handleSaga)
}

function* merchantsSaga() {
  yield takeLatest(MERCHANTS_ACTION.GET_MERCHANTS_REQUEST, handleSaga)
}

export default merchantsSaga
