import {GET_QUESTION} from "./GetPopularQuestionReducer";

export const GetQuestions=(location)=>{
  return{
    type:GET_QUESTION.GET_POPULAR_QUESTION_REQUEST,
    payload:{location}
  }
}

export const EditQuestionsList = (data)=>{
  return{
    type:GET_QUESTION.EDIT_POPULAR_QUESTION_REQUEST,
    payload:{data}
  }
}

export const CloseQuestionsList = ()=>{
  return{
    type:GET_QUESTION.CLOSE_EDIT_POPULAR_QUESTION_REQUEST,
  }
}

export const DelQuestions =(data)=>{
  return{
    type:GET_QUESTION.DEL_POPULAR_QA,
    payload:{data}
  }
}
export const ClearDelQuestions=()=>{
  return{
    type:GET_QUESTION.CLEA_DEL_POPULAR_QA
  }
}
