import TextField from "@material-ui/core/TextField";
import React from "react";
import {Translation} from "react-i18next";

const BigTextFieldS = ({value,type,onChange,name,label,onClick, style={}, children,inputProps, ...props}) => {
  return (
    <Translation>{(t) => <>
    <div style={{ marginTop: '20px'}}>
      <TextField
        id="outlined-select-currency-native"
        value={value}
        type={type}
        label={t(label)}
        name={name}
        InputLabelProps={{style:{fontSize: '14px', lineHeight: '16px', letterSpacing: '0.4px',color:'rgba(255, 255, 255, 0.6)',background:'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',padding:'0 10px 0 0 '},}}
        inputProps={{ style: {outline:'none',width:'450px',fontFamily: 'Arial', color: 'white',border:'1px solid white',borderRadius: '5px',}}}
        onChange={onChange}
        SelectProps={{native: true,}}
        variant="outlined"
      />
    </div>
    </>}</Translation>
  )
};

export default BigTextFieldS