import React, {Component} from 'react';
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {addQuestionsPage} from "../../modules/Questions/AddQuestion/AddQuestionAction";
import {DelQuestions, EditQuestionsList, GetQuestions} from "../../modules/Questions/GetPopularQuestionAction";
import TableBody from "@material-ui/core/TableBody";
import another from "../../assets/images/another.png";
import Title from "../../components/common/TabbleText/AllTitle";
import NewButton from "../../components/common/Buttons/ForNewButton";
import {TableCEll} from "../../components/common/TableCEll";
import {Card} from "../../components/common/Card";
import {Translation} from "react-i18next";


const useStyles = {
  BodyTableCell: {
    color: 'white',
  },
  BodyTableRow: {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14)',
    borderBottom: '5px solid black',
    borderRadius: '10px',
    width: '100%',
    height: '56px',
  },
  openInfoButton: {
    border: 'none',
    background: 'none',
    marginTop: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    letterSpacing: '0.1px',
    color: 'rgba(255, 255, 255, 0.87)'
  },
  activeIndexStyle: {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #121212',
    boxShadow: '0px 11px 15px rgba(0, 0, 0, 0.2), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 24px 38px rgba(0, 0, 0, 0.14)',
    borderRadius: '5px',
    width: '100px',
    height: '90px',
    position: 'absolute',
    right: '150px',
    zIndex: '50',
  },
  activeIndexButtonsStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  deleteQuestion: {
    color: '#EB5B47',
    background: 'none',
    border: 'none',
    marginTop: '10px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
  },
  BodyTableCellCheck: {
    borderBottomLeftRadius: '5px',
    borderTopLeftRadius: '5px',
  },
  BodyTableCellRight: {
    borderBottomRightRadius: '5px',
    borderTopRightRadius: '5px',
    color: 'white',
  },
  createButton: {
    background: '#5F94E2',
    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14)',
    color: 'white',
    borderRadius: '28px',
    marginRight:'10px',
  },
  createButtonPassive: {
    marginRight:'10px',
    background:  'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #121212',
    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14)',
    color: 'white',
    borderRadius: '28px',
  },
};
const Location =localStorage.getItem('Lan') === 'ru' ? "RU" : "ARM"
class QA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: '',
      ActiveButton: false,
      location: Location,
      QuestionsList:false
    }
  }

  componentDidMount() {
    this.props.GetQuestions(Location)
  }

  handleClick = (i) => {
    this.setState({activeIndex: i})
  }
  openInfo = (data) => {
    sessionStorage.setItem("QuestionLocal",this.state.location)
    this.props.EditQuestionsList(data)
  }
  ClosePopup = () => {
    this.setState({activeIndex: ''})
  }

  openQuestionsList =()=>{
    this.setState({QuestionsList:true})
  }

  render() {
    const PopularQuestion = this.props.PopularQuestion
    return (
      <>
        {/*<AddQuestions bool={this.state.QuestionsList }/>*/}
        {/*<QuestionsList*/}
        {/*  bool={this.state.QuestionsList }*/}
        {/*  onCLick={()=>{this.setState({QuestionsList:false})}}/>*/}
   <Card>
     <Translation>
       {(t) =>
         <>
            <Title Text={'Q&A'}/>
            <Button
                style={this.state.location === "RU" ? useStyles.createButton : useStyles.createButtonPassive}
                onClick={()=>this.props.GetQuestions("RU") && this.setState({location:"RU"})}>
                RU</Button>
              <Button
                style={this.state.location !== "RU" ? useStyles.createButton : useStyles.createButtonPassive}
                        onClick={()=>this.props.GetQuestions("ARM") && this.setState({location:"ARM"})} >
                ENG</Button>
              <Table size="small">
                <TableHead style={{borderBottom: '1px solid grey',}}>
                  <TableCEll text={['','N','Question','Answer','',''] }/>
                </TableHead>
                <TableCell style={{border: 'none', padding: '1px'}}>.</TableCell>
                {PopularQuestion.length > 0 ?
                  PopularQuestion.map((row, i) => (
                    <TableBody key={i}>

                      {this.state.activeIndex === i ?
                        <div onMouseLeave={() => {
                          this.ClosePopup('')
                        }} style={useStyles.activeIndexStyle}>
                          <div style={useStyles.activeIndexButtonsStyle}>
                            <button onClick={() => {this.openInfo(row)}} style={useStyles.openInfoButton}>{t("Edit")}</button>
                            <button onClick={() => {
                                  if(row.id.map((chunk, o) => i === o )){
                                   row.id.map((chunk, o) => i === o ?
                                     this.props.DelQuestions([chunk,this.state.ActiveButton])
                                     : '')}}} style={useStyles.deleteQuestion}
                                    onMouseDown={()=>  sessionStorage.setItem("QuestionLocal",this.state.location)}>
                              {t("Delete")}
                            </button>
                          </div>
                        </div> : ''}
                      <TableRow style={useStyles.BodyTableRow} key={row.id}>
                        <TableCell style={useStyles.BodyTableCellCheck}/>
                        <TableCell onClick={this.openQuestionsList} style={useStyles.BodyTableCell}>{i}</TableCell>
                        <TableCell onClick={this.openQuestionsList} style={useStyles.BodyTableCell}>{row.heading.slice(0.30) + '...'}</TableCell>
                        <TableCell onClick={this.openQuestionsList} style={useStyles.BodyTableCell}>
                          {row.questions.map((chunk, i) =>
                            <span key={i}>
                     {chunk.slice(0, 35) + '...'}
                   </span>)}
                        </TableCell>
                        <TableCell style={useStyles.BodyTableCellRight}>
                          <Button onClick={() => this.handleClick(i)}><img src={another} alt={another}/></Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )) : ''}
              </Table>
              <NewButton  onClick={() => this.props.addQuestionsPage(true)} ButtonText={'+ NEW QUESTION'}/>
         </>}</Translation>
   </Card>
        </>
    );
  }
}

const mapStateToProps = state => ({
  PopularQuestion: state.GetPopularQuestionReducer.PopularQuestion
})

export default connect(mapStateToProps, {DelQuestions,addQuestionsPage, GetQuestions, EditQuestionsList})(QA);
