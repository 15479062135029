import {GET_MERCHANT_BY_EMAIL} from "./GetMerchantReducer";


export const GetMerchantByAdmin =(adminEmail)=>{
  return{
    type:GET_MERCHANT_BY_EMAIL.GET_MERCHANT_REQUEST,
    payload:{adminEmail}
  }
}

export const BlockOneMerchantByAdmin=(email,lock)=>{
  return{
    type:GET_MERCHANT_BY_EMAIL.BLOCK_ONE_MERCHANT_BY_ADMIN,
    payload:{email,lock}
  }
}