import {STATION_INFO} from "./stationInfoReducer";

export const sendStationInfo =(data,page)=>{
  return{
    type:STATION_INFO.ALL_STATION_INFO,
    payload:{data,page}
  }
}

export const EditStationInfo =(data,page)=>{
  return{
    type:STATION_INFO.EDIT_STATION_INFO,
    payload:{data,page}
  }
}
export const ClearEditInfo = ()=>{
  return{
    type:STATION_INFO.CLEAR_EDIT_STATION_INFO,
  }
}

export const clearStationInfo = ()=>{
  return{
    type:STATION_INFO.CLEAR_STATION_INFO,
  }
}

export const createStation = (data)=>{
  return{
    type:STATION_INFO.CREATE_STATION_INFO,
    payload:{data}
  }
}

export const clearNewStation = ()=>{
  return{
    type:STATION_INFO.CLEAR_NEW_STATION_INFO,
  }
}
