import {put,call, takeLatest} from "@redux-saga/core/effects";
import {TAKE_BANK} from "./TakeBankReducer";
import * as api from "../../api";



function* handleSaga() {
  yield takeLatest(TAKE_BANK.TAKE_BANK_REQUEST,StationSaga)
}

function* StationSaga(action) {
  try {
    const {data} = yield call(api.TakePowerBank,action.payload.stationId,action.payload.slotNumber)
    yield put({
      type:TAKE_BANK.TAKE_BANK_SUCCESS,
      payload:{data}
    })
  }
  catch (e) {
    yield put({
      type:TAKE_BANK.TAKE_BANK_FAIL,
      message:e.message
    })
  }

}

export default handleSaga