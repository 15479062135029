import React, {Component} from 'react';
import {connect} from "react-redux";
import {deleteUserInfo} from "../../../modules/users/usersInfo/usersInfoAction";
import {LockUsers} from "../../../modules/users/lockUsers/lockUsersAction";
import {DeleteUsers} from "../../../modules/users/DeleteUsers/DeleteUsersAction";
import {ClearDeleteUserPopup, clearForBlockUser} from "../../../modules/users/usersActon";
import {Translation} from "react-i18next";


const useStyles = {
  CanclePopup: {
    margin: 'auto',
    width: '320px',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #121212',
    boxShadow: '0px 11px 15px rgba(0, 0, 0, 0.2), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 24px 38px rgba(0, 0, 0, 0.14)',
    borderRadius: '8px',
    padding: '15px'
  },
  CanclePopupButtons: {
    color: '#5F94E2',
    border: 'none',
    background: 'none'
  },
  headDiv: {
    background: 'rgba(0, 0, 0, 0.4)',
    width: '100%',
    minHeight: '100vh',
    zIndex: '100',
    position: 'fixed',
    right: 0,
  }

}

class UserBlockPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }


  CancelBlockUserPopup = () => {
    this.props.clearForBlockUser()
    this.props.deleteUserInfo()
    this.props.ClearDeleteUserPopup()
  }
  blockUserPopup = (phoneNumber, lock) => {
    if (lock === true) {
      this.props.LockUsers(phoneNumber, false,this.props.blockingPage)
    } else {
      this.props.LockUsers(phoneNumber, true,this.props.blockingPage)
    }
    this.props.clearForBlockUser()
    this.props.deleteUserInfo()
  }

  render() {
    const ForBlockingUser = Object.values(this.props.ForBlockingUser)
    const popupInfo =ForBlockingUser[7] === false ? 'Block' : 'Unlock'

    return (
      <>
        <Translation>
          {(t) =>
            <>
        {ForBlockingUser.length > 0 ?
          <div className='headDiv'>
            <div  className={'ModalPosition'}>
              <div className='ModalStyle'>
                <span>{`${t('Are you sure you want')} ${t(popupInfo)} ${t('User')}: ${ForBlockingUser[0]}`}</span>
                <div style={{marginLeft: '130px',marginTop:'10px'}}>
                  <button style={useStyles.CanclePopupButtons} onClick={this.CancelBlockUserPopup}>{t("Cancel")}</button>
                  <button style={useStyles.CanclePopupButtons} onClick={() => {
                    this.blockUserPopup(ForBlockingUser[2], ForBlockingUser[7])
                  }}>{t(popupInfo)}</button>
                </div>
              </div>
            </div>
          </div>
          :
          ''
        }
            </>}</Translation>
      </>
    );
  }
}


const mapStateToProps = state => ({
  ForBlockingUser: state.usersReducer.ForBlockingUser,
  blockingPage: state.usersReducer.blockingPage,
  popupInfo: state.usersReducer.popupInfo,
})

export default connect(mapStateToProps, {
  ClearDeleteUserPopup,
  DeleteUsers,
  LockUsers,
  clearForBlockUser,
  deleteUserInfo
})(UserBlockPopup);
