import {RENTS_ACTIONS} from "../rentsReducer";

export const STOP_RENT = {
  REQUEST: 'STOP_RENT.REQUEST',
  SUCCESS: 'STOP_RENT.SUCCESS',
  FAIL: 'STOP_RENT.FAIL'
}

const initialState = {
  Stop:[]
}


export default (state=initialState,action)=> {
  switch (action.type) {
    case STOP_RENT.REQUEST: {
      return {...state, Stop: initialState.Stop}
    }
    case STOP_RENT.SUCCESS: {
      return {...state, Stop: action.payload.data}
    }
    case STOP_RENT.FAIL: {
      return {...state, Stop: initialState.Stop}
    }

    default: {
      return state
    }
  }
}

