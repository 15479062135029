export const STATES={
  STATE_INFO_REQUEST:'STATES.STATE_INFO_REQUEST',
  STATE_INFO_SUCCESS:'STATES.STATE_INFO_SUCCESS',
  STATE_INFO_FAIL:'STATES.STATE_INFO_FAIL',
  FILTER_ACTIVE_STATIONS:'STATES.FILTER_ACTIVE_STATIONS',
  FILTER_BLOCKED_STATIONS:'STATES.FILTER_BLOCKED_STATIONS',
  FINDING_STATION_REQUEST: 'STATES.FINDING_STATION_REQUEST',
  FINDING_STATION_SUCCESS: 'STATES.FINDING_STATION_SUCCESS',
  FINDING_STATION_FAIL: 'STATES.FINDING_STATION_FAIL',
  STATE_PAGES_INFO:'STATES.STATE_PAGES_INFO',
  CLEAR_FINDING_STATE_INFO: 'STATES.CLEAR_FINDING_STATE_INFO',


  DOWNLOAD_STATION_LIST_REQUEST: 'STATES.DOWNLOAD_STATION_LIST_REQUEST',
  DOWNLOAD_STATION_LIST_SUCCESS: 'STATES.DOWNLOAD_STATION_LIST_SUCCESS',


  RELOAD_STATION_REQUEST:'STATES.RELOAD_STATION_REQUEST',
  RELOAD_STATION_SUCCESS:'STATES.RELOAD_STATION_SUCCESS',



  // CECH_STATION_REQUEST:'STATES.CECH_STATION_REQUEST',
  // CECH_STATION_SUCCESS:'STATES.CECH_STATION_SUCCESS',
  // CECH_STATION_FAIL:'STATES.CECH_STATION_FAIL'
}

const initialState={
  AllStations:[],
  AllStationsFullInfo:[],
  PageInfo:[],
  DownloadList:[],


  CechStationAnswer:[]
}

export default (state=initialState,action)=>{

  switch (action.type) {




    case STATES.STATE_INFO_REQUEST:{
      return{...state,AllStations:initialState.AllStations}
    }
    case STATES.STATE_INFO_SUCCESS:{
      return{...state,AllStations:action.payload.data,AllStationsFullInfo:action.payload.data }
    }
    case STATES.CLEAR_FINDING_STATE_INFO:{
      return{...state,AllStations:[] }
    }
    case STATES.STATE_PAGES_INFO:{
      return{...state,PageInfo:action.payload.data}
    }
    case STATES.DOWNLOAD_STATION_LIST_SUCCESS:{
      return{...state,DownloadList:action.payload.data}
    }
    case STATES.STATE_INFO_FAIL:{
      return{...state,AllStations:initialState.AllStations}
    }
    case STATES.FILTER_ACTIVE_STATIONS:
      const ActiveStationList=state.AllStationsFullInfo.map(item=> {
        if (item.availability === true) {
          return item
        }
      })
      return {...state,AllStations:ActiveStationList}

    case STATES.FILTER_BLOCKED_STATIONS:
      const BlockedStationList=state.AllStationsFullInfo.map(item=> {
        if (item.availability === false) {
          return item
        }
      })
      return {...state,AllStations:BlockedStationList}

    case STATES.FINDING_STATION_REQUEST :{
      return {...state,AllStations:initialState.AllStations}
    }
    case STATES.FINDING_STATION_SUCCESS:{
      return {...state,AllStations:[action.payload.data]}
    }
    case STATES.FINDING_STATION_FAIL:{
      return {...state,AllStations:'not found'}
    }


    default:{
      return state
    }

  }
}
