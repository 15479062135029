import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import Charts from '../views/Dashboard/components/Charts';
import Deposits from '../views/Dashboard/components/Deposits';
import Orders from '../views/UsersComponents';
import SignIn from "../views/SignIn";
import Stations from "../views/StationComponents";
import Rents from "../views/RentsComponents";
import RentHistoryItem from "../views/Components/RentHistory/RentHistoryItem";
import RentHistory from "../views/Components/RentHistory/RentHistory";
import Tariffs from "../views/TariffsComponents";
import QA from "../views/Q&A";
import Documents from "../views/Document";
import Support from "../views/Support";
import Finance from "../views/Finance";
import Merchants from "../views/Merchants";
import Admins from "../views/Admins";
import licens from "../views/Licens";
import PowerBank from "../views/PowerBanks/PowerBank";
import PartnerReg from "../views/PartnerReg";

class Routers extends Component {
  render() {
    const { authToken, user } = this.props;
    const Role = localStorage.getItem('role')
    return (
      <div style={{ margin: '0 auto', height: '100%', }}>
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', background: 'black' }}>
          <HashRouter>
            {Role === 'ADMIN' ?
              <Switch>
                <Route path='/' exact component={Charts} />
                {/* <Route path='/charts'  component={Charts}/> */}
                <Route path='/deposites' component={Deposits} />
                <Route path='/login' component={SignIn} />
                <Route path='/station' component={Stations} />
                <Route path='/rents' component={Rents} />
                <Route path='/powerBank' component={PowerBank} />
                <Route path='/finance' component={Finance} />
                <Route path='/merchants' component={Admins} />
                <Route path='/license_agreement' component={licens} />
              </Switch>
              :
              Role === 'MERCHANT' ?
                <Switch>
                  <Route path='/' exact component={Charts} />
                  {/* <Route path='/charts'  component={Charts}/> */}
                  <Route path='/deposites' component={Deposits} />
                  <Route path='/login' component={SignIn} />
                  <Route path='/station' exact component={Stations} />
                  <Route path='/rents' component={Rents} />
                  <Route path='/powerBank' component={PowerBank} />
                  <Route path='/finance' component={Finance} />
                  <Route path='/license_agreement' component={licens} />
                </Switch>
                :
                <Switch>
                  <Route path='/' exact component={Charts} />
                  <Route path='/charts' component={Charts} />
                  <Route path='/deposites' component={Deposits} />
                  <Route path='/login' component={SignIn} />
                  <Route path='/users' component={Orders} />
                  <Route path='/station' component={Stations} />
                  <Route path='/tariff' component={Tariffs} />
                  <Route path='/Q&A' component={QA} />
                  <Route path='/documents' component={Documents} />
                  <Route path='/support' component={Support} />
                  <Route path='/rents' component={Rents} />
                  <Route path='/renthistory' component={RentHistory} />
                  <Route path='/renthistoryitem' component={RentHistoryItem} />
                  <Route path='/finance' component={Finance} />
                  <Route path='/merchants' component={Admins} />
                  <Route path='/partners' component={Merchants} />
                  <Route path='/new-partner' component={PartnerReg} />
                  <Route path='/license_agreement' component={licens} />
                  <Route path='/license-agreement' component={licens} />
                  <Route path='/powerBank' component={PowerBank} />
                  <Redirect to='/login' />

                </Switch>
            }
          </HashRouter>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { user, authToken } = state.authReducer;
  return {
    user,
    authToken,
  }
}

export default Routers
