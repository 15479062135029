export const GET_TARIFFS={
  ADD_TARIFF_TEXT_REQUEST:'GET_TARIFFS.ADD_TARIFF_TEXT_REQUEST',
  ADD_TARIFF_TEXT_SUCCESS:'GET_TARIFFS.ADD_TARIFF_TEXT_SUCCESS',
  ADD_TARIFF_TEXT_FAIL:'GET_TARIFFS.ADD_TARIFF_TEXT_FAIL',
  OPEN_TARIFFS_POPUP:'GET_TARIFFS.OPEN_TARIFFS_POPUP',
  CLOSE_TARIFFS_POPUP:'GET_TARIFFS.CLOSE_TARIFFS_POPUP',
}

const initialState={
  TariffText:[],
  OpenPopup:[]
}

export default (state=initialState,action)=>{
  switch (action.type) {
    case GET_TARIFFS.ADD_TARIFF_TEXT_REQUEST:{
      return {...state,TariffText:initialState.TariffText}
    }
    case GET_TARIFFS.ADD_TARIFF_TEXT_SUCCESS:{
      return {...state,TariffText:action.payload.data}
    }
    case GET_TARIFFS.ADD_TARIFF_TEXT_FAIL:{
      return {...state,TariffText:initialState.TariffText}
    }
    case GET_TARIFFS.OPEN_TARIFFS_POPUP:{
      return {...state,OpenPopup:action.payload.data}
    }
    case GET_TARIFFS.CLOSE_TARIFFS_POPUP:{
      return {...state,OpenPopup:''}
    }
    default:{
      return state
    }

  }
}