import {put, takeLatest,call} from "@redux-saga/core/effects";
import * as api from "../api";
import {GET_QUESTION} from "./GetPopularQuestionReducer";
import {GetQuestions} from "./GetPopularQuestionAction";


function* handleQuestionSaga() {
  yield takeLatest(GET_QUESTION.GET_POPULAR_QUESTION_REQUEST,handleSaga)
}

function* handleSaga(action) {
  try {
    const {data}=yield call(api.getQuestion,action.payload.location)
    let newData = [];
    let newDataId = [];
    for(let item of data ) {
      newData = [...newData, item.popularQuestion,]
      newDataId = [...newDataId, item.id,]
    }

    let anotherNewData = [];
    for(let item of newData ) {
      let keys = Object.keys(item);
      let values = Object.values(item);
      let ids =newDataId.map(row=>( row ))
      const newItem = {};
      newItem.heading = keys[0];
      newItem.questions = values[0];
      newItem.id = ids;
      anotherNewData = [...anotherNewData, newItem]
    }
    yield put({
      type:GET_QUESTION.GET_POPULAR_QUESTION_SUCCESS,
      payload: {data: anotherNewData},
    });
  }
  catch (e) {
    yield put({
      type:GET_QUESTION.GET_POPULAR_QUESTION_FAIL,
      message:e.message
    })
  }
}
export default handleQuestionSaga





// getQuestion