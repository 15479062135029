export const ADD_ADMIN_ACTION = {
  ADD_ADMIN_REQUEST:'ADD_ADMIN_ACTION.ADD_ADMIN_REQUEST',
  ADD_ADMIN_SUCCESS:'ADD_ADMIN_ACTION.ADD_ADMIN_SUCCESS',
  ADD_ADMIN_FAIL:'ADD_ADMIN_ACTION.ADD_ADMIN_FAIL',
}


const initialState={
  addAdmin:[]
}

export default (state=initialState,action)=>{
  switch (action.type) {

    case ADD_ADMIN_ACTION.ADD_ADMIN_REQUEST:{
      return{...state,addAdmin:initialState.addAdmin}
    }
    case ADD_ADMIN_ACTION.ADD_ADMIN_SUCCESS: {
      return {...state, addAdmin: action.payload.data}
    }
    case ADD_ADMIN_ACTION.ADD_MERCHANTS_FAIL:{
      return{...state,addAdmin:initialState.addAdmin}
    }

    default:{
      return state
    }

  }

}