import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { connect } from "react-redux";
import Container from "@material-ui/core/Container";
import usb from '../../assets/images/Group 23 (1).png'
import logo from '../../assets/images/logo.png'
import { ClearAuthorization, getSignInParams } from "../../modules/signIn/signInAction";
import Dashboard from "../Dashboard";
import MerchantReg from "../MerchantReg";
import RegTextField from "../../components/common/Inputs/RegTextField";
import SaveButton from "../../components/common/Buttons/SaveButton";
import {
  merchantReg,
  RegIndividualMerchant,
  RemoveStatus
} from "../../modules/merchantes/AddMerchants/AddMerchantsAction";
import { HashRouter, Route, Switch } from "react-router-dom";
import licens from "../Licens";
import { Translation } from "react-i18next";
import PartnerReg from "../PartnerReg";


const styles = {
  paper: {
    background: '#121212',
    width: '100%',
    minHeight: '100vh',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  form: {
    color: 'gray',
    width: '100%',
  },
  submit: {
    width: '100%',
    maxWidth: '65px',
    background: '#5F94E2',
    padding: '5px',
    color: 'white',
    borderRadius: '5px',
    border: '1px solid #5F94E2'
  },
  LogInStyle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '20px',
    lineHeight: '36px',
    color: 'rgba(255, 255, 255, 0.87)',
    marginTop: '30px'
  },
  CustomInput: {
    width: '300px', padding: '15px', background: '#121212',
    border: '1px solid rgba(255, 255, 255, 0.87)',
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: 'white',
    marginTop: '20px'
  },
  forgotText: {
    marginTop: '30px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    color: 'rgba(255, 255, 255, 0.87)',
  },
  ContactWidthAdmin: {
    background: '#5F94E2',
    borderRadius: '5px',
    border: '2px solid #5F94E2',
    padding: '8px',
    color: 'white',
    textDecoration: 'none'
  },
  LogIn: {
    border: 'none',
    background: 'none',
    color: '#5F94E2',
    marginLeft: '10px',
  },
  LogInButton: {
    color: 'white',
    background: '#5F94E2',
    border: '1px solid #5F94E2',
    borderRadius: '5px',
    padding: '5px',
    alignSelf: 'center',
  },
  head: {
    overflow: 'auto',
    width: '100%',
    height: '100vh',
    left: 0,
    top: 0,
    background: '#121212',
    position: 'fixed',
    zIndex: 9999
  },
  logoStyle: {
    textAlign: 'center',
    marginTop: '20px'
  },
  buttons: {
    textAlign: 'center',
    marginTop: '20px'
  },
  LogInButtonS: {
    color: 'white',
    background: '#5F94E2',
    border: '1px solid #5F94E2',
    borderRadius: '5px',
    padding: '5px',
    alignSelf: 'center',
    marginLeft: '10px',
  },
  LogInButtonPassive: {
    marginLeft: '10px',
    border: '1px solid #5F94E2',
    padding: '5px',
    borderRadius: '5px',
    color: '#5F94E2'
  },
  textStyle: {
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '20px',
    lineHeight: '36px',
    color: 'rgba(255, 255, 255, 0.87)',
    marginTop: '20px'
  },
}


class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: '',
      password: '',
      forgot: false,
      Legal: true,
      ERRmessage: true
    }
  }
  componentDidMount() {

  }
  handleLogin = (ev) => {
    this.setState({ login: ev.target.value, ERRmessage: true })
    this.props.ClearAuthorization()
  }
  handlePassword = (ev) => {
    this.setState({ password: ev.target.value, ERRmessage: true })
    this.props.ClearAuthorization()
  }
  handleSupport = () => {
    this.setState((prevState) => ({ forgot: !prevState.forgot }))
  }
  sendData = (event) => {
    if (this.state.login && this.state.password) {
      const { password, login, } = this.state;
      this.props.getSignInParams(login, password);
      event.preventDefault()
    }
    else { this.setState({ ERRmessage: false }) }
  }





  render() {
    const rol = this.props.signInData.user
    if (rol) {
      const Position = rol ? rol.roles[0].role : ''
      localStorage.setItem('role', Position)
    }
    if (rol) {
      const Email = rol ? rol.email : ''
      localStorage.setItem('Email', Email)
    }

    const { forgot } = this.state
    const AuthorizationData = this.props.AuthorizationData
    const { activButton } = this.state
    return (
      <>
        {localStorage.getItem('token') ?
          <Dashboard />
          :
          window.location.hash === '#/new-partner' ?
            <>
              <HashRouter>
                <Switch>
                  <Route path='/new-partner' component={PartnerReg} />
                  <Route path='/license_agreement' component={licens} />
                  <MerchantReg isPartner={true} />
                </Switch>
              </HashRouter>

            </>
            :

            <Translation>
              {(t) =>
                <>
                  <div style={{ display: 'flex', overflow: 'hidden' }}>
                    <div style={{ background: '#121212', }}>
                      <img style={{ width: '100%', maxWidth: '1400px', minHeight: '99.1vh', height: 'auto' }} src={usb} alt={usb} />
                    </div>
                    <div style={styles.paper}>
                      <Container style={{ marginLeft: '0px' }} component="main" maxWidth="xs">
                        <CssBaseline />
                        <img style={{ width: '100px' }} src={logo} alt={logo} />

                        {forgot ?
                          <div>
                            <Typography style={styles.LogInStyle} component="h1" variant="h5">
                              {t("Forgot password")}
                            </Typography>
                            <span style={styles.forgotText}>
                              {t('If you have forgotten the password, contact the service administrator to restore it.')}
                            </span>
                            <div style={{ marginTop: '20px' }}>
                              <a style={styles.ContactWidthAdmin} href="mailto:admin@chargex.am">{t('CONTACT WIDTH ADMINISTRATOR')}</a>
                              <button onClick={this.handleSupport} style={styles.LogIn}><span>{t('LOG IN')}</span></button>
                            </div>
                          </div>
                          :
                          <div className={'LoginInputs'}>
                            <Typography style={styles.LogInStyle} component="h1" variant="h5">
                              {t('Log in')}
                            </Typography>
                            <form style={styles.form} noValidate>
                              <input
                                style={styles.CustomInput}
                                placeholder={t('E-mail')}
                                required
                                id="email"
                                name="username"
                                type="text"
                                autoComplete="email"
                                autoFocus
                                onChange={this.handleLogin} />
                              <input
                                style={styles.CustomInput}
                                placeholder={t('Password')}
                                required
                                name="password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={this.handlePassword} />
                              {AuthorizationData !== 'default' ?
                                <h4 style={{ color: 'red' }}>{t('EMAIL OR PASSWORD IS INVALID')}</h4>
                                : ''
                              }
                              {this.state.ERRmessage === false ?
                                <h4 style={{ color: 'red' }}>{t("Input not field")}</h4>
                                : ''
                              }

                              <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                                <Button style={styles.LogInButton} onClick={this.sendData} type="submit">
                                  {t("LOG IN")}
                                </Button>
                                <div style={{
                                  marginLeft: '10px',
                                  border: '1px solid #5F94E2',
                                  padding: '7px',
                                  borderRadius: '5px',
                                }}>
                                  <Link
                                    style={{ color: ' #5F94E2' }}
                                    onClick={this.handleSupport} href="#" variant="body2">
                                    {t('FORGOT PASSWORD?')}
                                  </Link>
                                </div>
                              </div>
                            </form>
                          </div>
                        }

                      </Container>
                    </div>
                  </div>
                </>}
            </Translation>
        }
      </>
    );
  }
}


const mapStateToProps = state => ({
  signInData: state.signInReducer.signInData,
  AuthorizationData: state.signInReducer.AuthorizationData,
  status: state.AddMerchantsReducer.status,
})

export default connect(mapStateToProps, { getSignInParams, ClearAuthorization, RemoveStatus, RegIndividualMerchant, merchantReg })(SignIn);


