import {GET_TARIFF} from "./tariffsReducer";

export const GetTariffs = ()=>{
  return{
    type:GET_TARIFF.GET_TARIFFS_REQUEST,
  }
}

export const GetAllGroupTariffs = ()=>{
  return{
    type:GET_TARIFF.GET_ALL_GROUP_REQUEST
  }
}

export const TariffsListInfo =(data)=>{
  return{
    type:GET_TARIFF.TARIFFS_LIST,
    payload:{data}
  }
}
export const CloseTariffsPopup =()=>{
  return{
    type:GET_TARIFF.CLOSE_TARIFFS_LIST,
  }
}


export const DeleteTariffAction =(data)=>{
  return{
    type:GET_TARIFF.DELETE_TARIFF_REQUEST,
    payload:{data}
  }
}
