import React, {Component} from 'react';
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import {
  ClearFindTransactionByRentId,
  filterTransactions,
  FindTransactionById,
  FindTransactionByRentId,
  GetTransactions
} from "../../modules/transactions/GetTransactionAction";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import another from "../../assets/images/another.png";
import Table from "@material-ui/core/Table";
import {openTar, OpenTransactions} from "../../modules/transactions/AddTransactions/AddTransactionsAction";
import AddTransaction from "./components/addTransaction";
import {CLearFindingRentInfo, FindRentsByNumber, getRents} from "../../modules/rents/rentsAction";
import NewButton from "../../components/common/Buttons/ForNewButton";
import moment from 'moment';
import {TableCEll} from "../../components/common/TableCEll";
import {Card} from "../../components/common/Card";
import {Page} from "../../components/common/Pages";
import {Translation} from "react-i18next";
import {sendUserRentInfo} from "../../modules/rents/rentUsersInfo/rentUsersInfoAction";
import RetIfoModal from "../RentsComponents/components/RetIfoModal";
import {Loader} from "../../components/common/Asyncs/Loader";
import {NotFound} from "../../components/common/Asyncs/NotFound";

class Finance extends Component {
  constructor(props) {
    super(props);
    this.state={
        transactionId:'',
        // email:'',
        page:0,
        closePage:true,
        financeList:''
     }
  }

  componentDidMount() {
      this.props.GetTransactions(0)
  }
  ButtonAll=()=>{
    this.setState({transactionId:''})
    this.props.GetTransactions(0)
    this.props.ClearFindTransactionByRentId()
  }
  handleClick = (i) => {
    this.setState({activeIndex: i})
  }
  openInfo = (row) => {
    this.props.openTar(row)
    this.setState({activeIndex: ''})
  }
  FindeHandleChange = (ev) => {
    // if (ev.keyCode === 8) {
    //   this.props.ClearFindTransaction()
    // }
    // if(ev.target.value.length < 24){
    //   this.setState({email:ev.target.value})
    // }
    // else {
      this.setState({transactionId: ev.target.value})
    // }
    if(ev.keyCode === 8 ){
      if(ev.target.value.length === 1 || ev.target.value.length === 24) {
        this.props.GetTransactions(this.state.page)
        this.props.ClearFindTransactionByRentId()
        this.setState({closePage:true})
      }
    }
  }

  sendFindData=()=>{
    if(this.state.transactionId.length === 24) {
      this.props.FindTransactionById(this.state.transactionId)
      this.props.FindTransactionByRentId(this.state.transactionId)
      this.setState({closePage:false})
    }
    else{
      this.props.filterTransactions(this.state.transactionId)
    }
  }
  ClosePopup = ()=>{
    this.setState({activeIndex: ''})
  }
  handleChange =(event,value)=>{
    this.props.GetTransactions(value - 1)
    this.setState({page:value - 1,closePage:true,financeList:''})
  }
  openRentInfo =(row)=>{
    sessionStorage.setItem('InfoForClose','CloseStation')
    this.props.FindRentsByNumber(row.rentId)
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
   if(this.props.AllRents ? this.props.AllRents.length : false) {
     this.props.sendUserRentInfo(this.props.AllRents[0])
    }
  }
  handleRevert =()=>{
    this.setState({financeList: this.props.AllTransactions.data.reverse()})
  }
  render() {
    const Role = localStorage.getItem('role')
    const DataList =
      this.props.AllTransactionsByRentID.length ? this.props.AllTransactionsByRentID :
      this.state.financeList ? this.state.financeList : Object.values(this.props.AllTransactions.data ? this.props.AllTransactions.data.length === 1 ? this.props.AllTransactions.data :this.props.AllTransactions.data : '')
    const {pageCount,transactionCount} = this.props.PageInfo
    const info = this.props.AllRents !== undefined ? this.props.AllRents[0] :''
   let notFound = !this.props.AllTransactionsByRentID.length &&
    this.props.AllTransactions && this.props.AllTransactions.data && this.props.AllTransactions.data[0]
    return (
      <Translation>{(t) => <>
        <AddTransaction/>
        {info && <RetIfoModal  page={this.state.page} HandleClose={() =>this.setState({infoForModal:''})} info={info}/>}

        <Card
          TitleName={'Finance'}
          value={this.state.transactionId}
          Count={transactionCount}
          search={true}
          FilterBoolean={true}
          clearSearch={this.state.transactionId.length && this.ButtonAll}
          place={'Search transactions'}
          onClick={this.sendFindData}
          onChange={this.FindeHandleChange}
          onKeyDown={this.FindeHandleChange}
          filter={'open'}
          revert={this.handleRevert}>
      <div>
        <Table size="small" >
        <TableHead onClick={() => this.handleClick('')} style={{borderBottom: '1px solid grey',}}>
          <TableCEll text={['ID','Description','Rent ID','Date and time','Money','Type',''] }/>

        </TableHead>
        <TableCell style={{border: 'none',padding:'1px'}}>.</TableCell>
          {notFound === "" ?  <NotFound/>  :
            this.props.AllTransactions === 'not found' ?  <NotFound/>  :
              DataList!== 'not found' &&  DataList.length ?
            DataList.map((row, i) => (
              <TableBody key={i}>
                {this.state.activeIndex === i ?
                    <div onMouseLeave={() => {this.ClosePopup('')}} className={'activeIndexStyleForSmallModal'}>
                    <div  className={'activeIndexButtonsStyle'}>
                      <button   onClick={() => this.props.OpenTransactions(['open','edit',[row]])}  className={'EditInfoButton'}>{t("Change Info")}</button>
                    </div>
                  </div>
                  : ''}
                <TableRow className={'BodyTableRow'} key={row.id}>
                  <TableCell  onClick={() => {this.openInfo(row)}}  className={'BodyTableCellCheck'}>{row.user ? row.id.slice(0,4) +'...' : null}</TableCell>
                  <TableCell  onClick={() => {this.openInfo(row)}}  className={'BodyTableCell'}>{row.description ?row.description.slice(0,17  )+'...' : null}</TableCell>
                  <TableCell  onClick={() => {this.openRentInfo(row)}} className={'LinkBodyTableCell'}>{row.rentId ? '...' + row.rentId.slice(-4) : ''}</TableCell>
                  <TableCell  onClick={() => {this.openInfo(row)}} className={'BodyTableCell'}>
                    {row.createdDate ?
                    `${moment.tz(row.createdDate,'YYYY-MM-DD/HH:mm','Europe/Moscow').local().format('DD.MM.YYYY/HH:mm')}`
                    :''}
                  </TableCell>
                  <TableCell  onClick={() => {this.openInfo(row)}} className={'BodyTableCell'}>{row.price}</TableCell>
                  <TableCell  onClick={() => {this.openInfo(row)}}  className={'BodyTableCell'}>{row.transactionStatus}</TableCell>
                  <TableCell className={'BodyTableCellRight'}>
                    <Button style={{marginTop: '5px'}} onClick={Role === 'SUPER_ADMIN' ? () => this.handleClick(i) :''}>
                      <img src={another} alt={another}/></Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            )) : <Loader/>}
          {this.state.closePage && <Page page={this.state.page + 1} handleChange={this.handleChange} PageCount={pageCount}/>}
        </Table>
        {Role === 'SUPER_ADMIN' ?
        <NewButton  onClick={() => this.props.OpenTransactions(['open'])} ButtonText={'+ NEW TRANSACTION'}/>
    :''}
      </div>

      </Card>
      </>}</Translation>
    );
  }
}

const mapStateToProps = state => ({
  openTRANSACTIONS:state.AddTransactionsReducer.openTRANSACTIONS,
  AllTransactions:state.GetTransactionReducer.AllTransactions,
  AllTransactionsByRentID:state.GetTransactionReducer.AllTransactionsByRentID,
  PageInfo:state.GetTransactionReducer.PageInfo,
  AllRents: state.rentsReducer.AllRents,
})
export default connect(mapStateToProps, {ClearFindTransactionByRentId,FindTransactionByRentId,CLearFindingRentInfo,sendUserRentInfo,FindRentsByNumber,FindTransactionById,filterTransactions,getRents,openTar,GetTransactions,OpenTransactions})(Finance);
