import {GET_QUESTION} from "../GetPopularQuestionReducer";

export const DELETE_USER ={
  REQUEST:'DELETE_USER.REQUEST',
  SUCCESS:'DELETE_USER.SUCCESS',
  FAIL:'DELETE_USER.FAIL',
}


const initialState={
  DeletedUser:[]
}

export default (state=initialState,action)=>{
  switch (action.type) {
    case DELETE_USER.REQUEST:{
      return{...state,DeletedUser:initialState.DeletedUser}
    }
    case DELETE_USER.SUCCESS:{
      return {...state,DeletedUser:action.payload.data}
    }
    case DELETE_USER.FAIL:{
      return{...state,DeletedUser:initialState.DeletedUser}
    }
    default:{
      return state
    }
  }

}