export const COMMENTS ={
  // GET_COMMENTS_REQUEST:'COMMENTS.GET_COMMENTS_REQUEST',
  // GET_COMMENTS_SUCCESS:'COMMENTS.GET_COMMENTS_SUCCESS',
  SET_COMMENTS_REQUEST:'COMMENTS.SET_COMMENTS_REQUEST',
  SET_COMMENTS_SUCCESS:'COMMENTS.SET_COMMENTS_SUCCESS',

}

const initialState={
  // Comments:[],
  SendComment:[]

}

export default (state=initialState,action)=>{
  switch (action.type) {
    // case COMMENTS.GET_COMMENTS_SUCCESS:{
    //   return {...state,Comments:action.payload.data}
    // }
    case COMMENTS.SET_COMMENTS_SUCCESS:{
      return {...state,SendComment:action.payload.data}
    }


    default:{
      return state
    }
  }

}