import React, {Component} from 'react';
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import CloseVector from "../../../assets/images/CloseVector.png";
import {
  AddTransactions,
  CloseInfoPopup,
  CloseTransactions, EditTaransaction
} from "../../../modules/transactions/AddTransactions/AddTransactionsAction";
import ListText from "../../../components/common/TabbleText/ListText";
import TextField from "@material-ui/core/TextField";
import TextFieldS from "../../../components/common/Inputs/TextField";
import ErrMessage from "../../../components/common/ErrMessage";
import {Translation} from "react-i18next";
import moment from "moment";

const styles = {
  ChangeDivCloseButton: {
    background: 'none',
    border: 'none',
    cursor:'pointer'
  },
  CancelButton:{
    color: 'white',
    background: '#EB5B47',
    borderRadius: '5px',
    border: '1px solid #EB5B47',
    padding: '6px'
  },
  CreateButton:{
    color: 'white',
    background: '#5F94E2',
    borderRadius: '5px',
    border: '1px solid #5F94E2',
    padding: '6px',
    marginLeft: '5px'
  },
  headDiv: {
    background: 'rgba(0,0,0,0.2)',
    width:'100%',
    minHeight: '100vh',
    zIndex: '9900',
    position: 'fixed',
    right: '0',
    bottom: '0',
    color:'white'
  },
  hideDiv: {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
    boxShadow: '-2px 0px 4px rgba(0, 0, 0, 0.2), -1px 0px 10px rgba(0, 0, 0, 0.12), -4px 0px 5px rgba(0, 0, 0, 0.14)',
    width: '300px',
    height: '100%',
    position: 'absolute',
    minHeight: '100vh',
    zIndex: '20',
    right: 0,
    boxSizing: 'border-box',
    padding:'20px'
  },
  showList: {
    marginTop: '30px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    color: 'rgba(255, 255, 255, 0.6)',
  },
  showListMoney:{
    marginTop: '30px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    color: 'rgba(255, 255, 255, 0.6)',
  },
  showListID:{
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    color: 'rgba(255, 255, 255, 0.6)',
  },
  showListRentID:{
    marginTop:'25px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    color: 'rgba(255, 255, 255, 0.6)',
  },
  showListInfo: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: 'rgba(255, 255, 255, 0.87)',
  },

}

class AddTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errMassage:false,
      description:'',
      rentId:'',
      price:'',
    }
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.openTRANSACTIONS[2]) {
      this.setState({
        description: nextProps.openTRANSACTIONS[2][0].description,
        rentId: nextProps.openTRANSACTIONS[2][0].rentId,
        price: nextProps.openTRANSACTIONS[2][0].price,
      })
    }
  }

  handleChange = (ev) => {
    const {name, value} = ev.target;
    const newState = {};
    newState[name] = value;
    this.setState(newState);
    this.setState({errMassage:false})
  };
  Create = () => {
    if(this.props.openTRANSACTIONS[1] === 'edit'){
      const data = this.props.openTRANSACTIONS[1] === 'edit' ?   this.props.openTRANSACTIONS[2] :''
      const transactionId  =this.state.transactionId ? this.state.transactionId : data[0].id
      const description  =this.state.description ? this.state.description : data[0].description
      const price  = this.state.price ? this.state.price : data[0].price
      const rentId  = this.state.rentId ? this.state.rentId : data[0].rentId
      const transactionStatus  = this.state.transactionStatus ? this.state.transactionStatus : data[0].transactionStatus
      this.props.EditTaransaction(transactionId, description, price, rentId, transactionStatus)
      this.props.CloseTransactions()
    }
    else {
      const {phoneNumber, description, price, rentId, transactionStatus,} = this.state
      if (phoneNumber, description, price, rentId, transactionStatus) {
        this.props.AddTransactions(phoneNumber, description, price, rentId, transactionStatus)
        this.props.CloseTransactions()
      } else {
        this.setState({errMassage: true})
      }
    }
  }

  render() {
    const {openTransInfo} = this.props
    const openTransInfos = Object.values(this.props.openTransInfo)
    const data = this.props.openTRANSACTIONS[1] === 'edit' ?   this.props.openTRANSACTIONS[2] :''
    const transactionStatus  = data?  data[0].transactionStatus :''

    return (
      <>
        <Translation>
          {(t) =>
            <>
        {this.props.openTRANSACTIONS[0]  === 'open' ?
          <div style={styles.headDiv}>
            <div onClick={() => this.props.CloseTransactions() && this.setState({errMassage:true})} className='CloseSectionClass'  />
            <div style={styles.hideDiv}>
              <div style={{display: 'flex', alignItems: 'center',}}>
                <button onClick={() => this.props.CloseTransactions() && this.setState({errMassage:true})} style={styles.ChangeDivCloseButton}>
                  <img src={CloseVector} alt={CloseVector}/></button>
                <span style={{color: 'white',marginLeft:'15px'}}>{!data ?  t('New Transaction') : t('Edit Transaction')}</span>
              </div>
              <div style={{ marginTop: '20px',width: '260px'}}>

                {!data && <TextFieldS onChange={this.handleChange} name='phoneNumber' label={t('Transaction Id')}/> }
                <TextFieldS onChange={this.handleChange}  value={this.state.description} name='description' label={t('Description')} />
                <TextFieldS onChange={this.handleChange}  value={this.state.price}  name='price'  type={'number'} label={t('Price')} />
               <div style={{marginTop:'10px'}}>
                <TextField
                  value={this.state.transactionStatus ? this.state.transactionStatus : transactionStatus}
                  id="outlined-select-currency-native"
                  select
                  label={t("Type")}
                  name={'transactionStatus'}
                  InputLabelProps={{style:{fontSize: '14px', lineHeight: '16px', letterSpacing: '0.4px',color:'rgba(255, 255, 255, 0.6)',background:'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',padding:'0 10px 0 0 '},}}
                  inputProps={{ style: {outline:'none',width:'211px',fontFamily: 'Arial', color: 'white',border:'1px solid white',borderRadius: '5px'}}}
                  onChange={this.handleChange}
                  SelectProps={{native: true,}}
                  variant="outlined">
                  <option style={{color: 'black'}} value={''}>{}</option>
                  <option style={{color: 'black'}} value={'DEBIT'}>DEBIT</option>
                  <option style={{color: 'black'}} value={'CREDIT'}>CREDIT</option>
                  <option style={{color: 'black'}} value={'REFUND'}>REFUND</option>
                </TextField>
               </div>
                {this.state.errMassage === true ? <ErrMessage/> :''}
                <div style={{marginTop: '20px'}}>
                  <Button onClick={() => this.props.CloseTransactions() &&   this.setState({errMassage:false})} style={styles.CancelButton}>{t("CANCEL")}</Button>
                  <Button onClick={() =>this.Create()} style={styles.CreateButton}>{data ? t('EDIT') : t('CREATE')}</Button>
                </div>
              </div>
            </div>
          </div>
          : ''}
        {openTransInfos.length > 0 ?
          <div style={styles.headDiv}>
          <div onClick={() => this.props.CloseInfoPopup()} className={'CloseSectionClass'}  />
          <div style={styles.hideDiv}>
            <div style={{display: 'flex', marginLeft: '220px', marginTop: '10px'}}>
              <button onClick={() => this.props.CloseInfoPopup()} style={styles.ChangeDivCloseButton}>
                <img src={CloseVector} alt={CloseVector}/></button>
            </div>
            <div style={{display:'flex',flexDirection:'column'}}>
              <ListText style={styles.showListID} Text={'ID'} />
              <ListText Text={openTransInfo.user ? openTransInfo.id : null} />
              <ListText style={styles.showListRentID} Text={'Rent ID'}/>
              <ListText Text={openTransInfo.rentId ? openTransInfo.rentId : null}/>
              <ListText style={styles.showListMoney} Text={'Description'}/>
              <ListText Text={openTransInfo.description ? openTransInfo.description: null}/>
              <ListText style={styles.showListMoney} Text={'Money'} />
              <ListText Text={openTransInfo.price}/>
              <ListText style={styles.showList} Text ={'Type'}/>
              <ListText Text={openTransInfo.transactionStatus}/>
              <ListText style={styles.showList} Text ={'Date and time'}/>
              <span>{  openTransInfo.createdDate ?
                  `${moment.tz(openTransInfo.createdDate,'YYYY-MM-DD/HH:mm','Europe/Moscow').local().format('DD.MM.YYYY/HH:mm')}` :''}
              </span>
              <hr style={{width: '100%',marginTop:'30px'}}/>
            </div>
          </div>
        </div>
          :''}
            </>}</Translation>
      </>
    );
  }
}
const mapStateToProps = state => ({
  openTRANSACTIONS:state.AddTransactionsReducer.openTRANSACTIONS,
  openTransInfo:state.AddTransactionsReducer.openTransInfo
})

export default connect(mapStateToProps, {
  CloseTransactions,
  AddTransactions,
  CloseInfoPopup,
  EditTaransaction,
})(AddTransaction);

