import {put, takeLatest,call} from "@redux-saga/core/effects";
import {USER_ACTIONS} from "./usersReducer";
import * as api from "../api";


function* handleSaga() {
  yield takeLatest(USER_ACTIONS.USER_LIST_REQUEST, usersSaga)
}

function* usersSaga(action) {
  try {
    const {data} = yield call(api.getUsersList,action.payload.page)
    yield put({
      type: USER_ACTIONS.USER_LIST_SUCCESS,
      payload: {data : data.users}
    })
    yield put({
      type: USER_ACTIONS.USER_PAGE_LIST,
      payload:{data : { pageCount:data.pageCount, userCount:data.userCount} }
    })
  } catch (e) {
    yield put({
      type: USER_ACTIONS.USER_LIST_FAIL,
      message: e.message
    })
  }
}


function* handleFindUser(action) {
  try {
    const {data} = yield call(api.FindUsers,action.payload.phoneNumber)
    yield put({
      type:USER_ACTIONS.FINDING_SUCCESS,
      payload:{data}
    })
  }catch (e) {
    yield put({
      type:USER_ACTIONS.FINDING_FAIL,
    })
 console.log(e)
  }
}
export function* FindUser (){
  yield takeLatest(USER_ACTIONS.FINDING_REQUEST,handleFindUser)
}


function* handleGivePromo(action) {
  try {
    const {data} = yield call(api.GivePromoApi,action.payload.phoneNumber)
    yield put({
      type:USER_ACTIONS.CECH_PROMO_STATUS_SUCCESS,
      payload:{data}
    })
  }catch (e) {
 console.log(e)
  }
}
export function* GivePromoSaga (){
  yield takeLatest(USER_ACTIONS.CECH_PROMO_STATUS_REQUEST,handleGivePromo)
}



function* handleGivePromoUser(action) {
  try {
    const {data} = yield call(api.GivePromoToUserApi, action.payload.data, )
    yield put({
      type:USER_ACTIONS.GIVE_PROMO_SUCCESS,
      payload:{data}
    })
  }catch (e) {
     console.log(e)
  }
}
export function* GivePromoToUserSaga (){
  yield takeLatest(USER_ACTIONS.GIVE_PROMO_REQUEST,handleGivePromoUser)
}

export function* getAllPromoTariffsWatcher (){
  yield takeLatest(USER_ACTIONS.GET_ALL_PROMO_TARIFFS,getAllPromoTariffsWorker)
}
function* getAllPromoTariffsWorker() {
  try {
    const {data} = yield call(api.GetAllPromoTariffs);
    yield put({
      type:USER_ACTIONS.SET_ALL_PROMO_TARIFFS,
      payload:{data}
    })
  }catch (e) {
     console.log(e)
  }
}





function* HandleGetCard(action) {
  try {
    const {data} = yield call(api.GetUserCardApi, action.payload.id, )
    yield put({
      type:USER_ACTIONS.GET_USER_CARD_SUCCESS,
      payload:{data}
    })
  }catch (e) {
     console.log(e)
  }
}
export function* GetUserCardSaga (){
  yield takeLatest(USER_ACTIONS.GET_USER_CARD_REQUEST,HandleGetCard)
}

export default handleSaga
