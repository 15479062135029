import React from 'react';

const styles = {
  heading: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    margin: 0,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#74717E',
    letterSpacing: '0.0025em'
  },
  value: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '20px',
    color: 'white',
    marginTop: 0,
    marginBottom: '13px',
    width: '288px',
    letterSpacing: '0.005em'
  }
};

export const HistoryItem = ({heading, value}) => (
  <div style={{marginBottom: '15px'}}>
    <p style={styles.heading}>{heading}</p>
    <p style={styles.value}>{value}</p>
  </div>
);