import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { Translation, useTranslation } from "react-i18next";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download.svg";
import { ReactComponent as ArrowUpIcon } from "../../../assets/icons/arrow-up.svg";
import { ReactComponent as ArrowDownIcon } from "../../../assets/icons/arrow-down.svg";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { translateStatuses } from "../../../utiles/translateStatuses";

export const FilterButtons = ({
  filter,
  revert,
  downloadList,
  download,
  allButtons,
  conditionsButtons,
}) => {
  const { t } = useTranslation();
  const [activeFilter, setActiveFilter] = useState("All");
  const [activeConditions, setActiveConditions] = useState("All");
  const [showFilterButtons, setShowFilterButtons] = useState(false);
  const [showConditionsButtons, setShowConditionsButtons] = useState(false);
  const [text, changeText] = useState(false);

  useEffect(() => {
    let all = sessionStorage.getItem("filterButtons");
    if (all === "all") {
      setActiveFilter(translateStatuses(t, "All"));
      sessionStorage.removeItem("filterButtons");
    }
  }, []);

  const filterFn = (name, fn) => {
    fn();
    setActiveFilter(name);
    setActiveConditions("All");
    setShowFilterButtons(false);
    changeText(false);
  };
  const filterCondition = (name, fn) => {
    fn();
    setActiveConditions(name);
    setActiveFilter("All");
    setShowConditionsButtons(false);
    changeText(false);
  };

  const HandleRevert = () => {
    revert();
    changeText(text === false);
  };

  const Role = localStorage.getItem("role");
  return (
    <div className="filterButtons">
      <Translation>
        {(t) => (
          <>
            {filter !== "open" ? (
              <>
                {allButtons && (
                  <div className="chooseFiltersBlock">
                    <Button
                      className="showAllFiltersButton"
                      onClick={() => {
                        setShowFilterButtons((pv) => !pv);
                        setShowConditionsButtons(false);
                      }}
                    >
                      {conditionsButtons
                        ? t("filters.status") +
                          ": " +
                          translateStatuses(t, activeFilter)
                        : translateStatuses(t, activeFilter)}

                      {!showFilterButtons ? <ArrowDownIcon /> : <ArrowUpIcon />}
                    </Button>
                    {showFilterButtons && (
                      <div className="filterButtonsList">
                        {allButtons.map((item, key) => (
                          <Button
                            key={key}
                            onClick={() => filterFn(item.name, item.fn)}
                          >
                            {translateStatuses(t, item.name)}
                          </Button>
                        ))}
                      </div>
                    )}
                  </div>
                )}
                {conditionsButtons && (
                  <div className="chooseFiltersBlock second">
                    <Button
                      className="showAllFiltersButton"
                      onClick={() => {
                        setShowConditionsButtons((pv) => !pv);
                        setShowFilterButtons(false);
                      }}
                    >
                      {t("filters.condition") +
                        ": " +
                        translateStatuses(t, activeConditions)}
                      {!showConditionsButtons ? (
                        <ArrowDownIcon />
                      ) : (
                        <ArrowUpIcon />
                      )}
                    </Button>
                    {showConditionsButtons && (
                      <div className="filterButtonsList">
                        {conditionsButtons.map((item, key) => (
                          <Button
                            key={key}
                            onClick={() => filterCondition(item.name, item.fn)}
                          >
                            {translateStatuses(t, item.name)}
                          </Button>
                        ))}
                      </div>
                    )}
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button onClick={HandleRevert}>
                    <span
                      className={"DefaultText"}
                      style={{
                        textTransform: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {!text ? <ArrowDownIcon /> : <ArrowUpIcon />}
                      {t("sorts.date")}
                    </span>
                  </Button>
                </div>
                {Role === "SUPER_ADMIN" && download === true && (
                  <div
                    // style={{
                    //   position: "absolute",
                    //   right: "25px",
                    // }}
                    className="downloadWrapper"
                  >
                    <button
                      style={{
                        outline: "none",
                        background: "none",
                        border: "2px solid rgba(255, 255, 255, 0.12)",
                        borderRadius: "5px",
                        cursor: "pointer",
                        height: "42.5px",
                        width: "50px",
                      }}
                      onClick={downloadList}
                    >
                      <DownloadIcon />
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className={"sortText"}>{t("Sort") + ":"}</span>
                <span onClick={HandleRevert} className="DefaultText">
                  {text === true ? t("from End to Beginning") : t("Default")}
                </span>
                <Button onClick={HandleRevert}>
                  <UnfoldMoreIcon color="disabled" />
                </Button>
              </div>
            )}
          </>
        )}
      </Translation>
    </div>
  );
};
