export const ADD_TRANSACTIONS={
  TRANSACTIONS_REQUEST:'ADD_TRANSACTIONS.TRANSACTIONS_REQUEST',
  TRANSACTIONS_SUCCESS:'ADD_TRANSACTIONS.TRANSACTIONS_SUCCESS',
  TRANSACTIONS_FAIL:'ADD_TRANSACTIONS.TRANSACTIONS_FAIL',
  OPEN_TRANSACTION:'ADD_TRANSACTIONS.OPEN_TRANSACTION',
  CLOSE_TRANSACTION:'ADD_TRANSACTIONS.CLOSE_TRANSACTION',
  OPEN_TRANS:'ADD_TRANSACTIONS.OPEN_TRANS',
  ClOSE_POPUP:'ADD_TRANSACTIONS.ClOSE_POPUP',
  EDIT_TRANSACTIONS_REQUEST:'ADD_TRANSACTIONS.EDIT_TRANSACTIONS_REQUEST'
}

const initialState={
  AllTransactions:[],
  openTRANSACTIONS:'',
  openTransInfo:[]
}

export default (state=initialState,action) => {
  switch (action.type) {
    case ADD_TRANSACTIONS.TRANSACTIONS_REQUEST:{
      return{...state,AllTransactions:initialState.AllTransactions}
    }
    case ADD_TRANSACTIONS.TRANSACTIONS_SUCCESS:{
      return {...state,AllTransactions:action.payload.data}
    }
    case ADD_TRANSACTIONS.TRANSACTIONS_FAIL:{
      return {...state,AllTransactions:initialState.AllTransactions}
    }
    case ADD_TRANSACTIONS.OPEN_TRANSACTION:{
      return {...state,openTRANSACTIONS:action.payload.data}
    }
    case ADD_TRANSACTIONS.CLOSE_TRANSACTION:{
      return {...state,openTRANSACTIONS:''}
    }
    case ADD_TRANSACTIONS.OPEN_TRANS:{
      return {...state,openTransInfo:action.payload.data}
    }
    case ADD_TRANSACTIONS.ClOSE_POPUP:{
      return {...state,openTransInfo:''}
    }
    default:{
      return state
    }
  }

}
