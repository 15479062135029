import {put,call, takeLatest} from "@redux-saga/core/effects";
import * as api from "../../../api";
import {TARIFFS} from "./AddTariffWidthGroupReducer";
import {GetAllGroupTariffs} from "../../tariffsAction";

function* handlCreateeSaga() {
  yield takeLatest(TARIFFS.ADD_TARIFF_REQUEST,CreateTariffSaga)
}

function* CreateTariffSaga(action) {
  try {
    const {data} = yield call(api.addTatiffs,action.payload.nameTariffGroup,action.payload.period,action.payload.price,action.payload.tariffName,
      action.payload.typeTime,action.payload.workingTimeInterval
      )
    yield put({
      type:TARIFFS.ADD_TARIFF_SUCCESS,
      payload:{data}
    })
    yield put(GetAllGroupTariffs())
  }
  catch (e) {
    yield put({
      type:TARIFFS.ADD_TARIFF_FAIL,
      message:e.message
    })
  }

}

export default handlCreateeSaga