export const DASHBOARD_ACTIONS = {
    GET_POWERBANKS_DASHBOARD_INFO_REQUEST: 'DASHBOARD_ACTIONS.GET_POWERBANKS_DASHBOARD_INFO_REQUEST',
    GET_MERCHANTS_DASHBOARD_INFO_REQUEST: 'DASHBOARD_ACTIONS.GET_MERCHANTS_DASHBOARD_INFO_REQUEST',
    GET_LOST_POWERBANKS_INFO_REQUEST: 'DASHBOARD_ACTIONS.GET_LOST_POWERBANKS_INFO_REQUEST',
    SET_POWERBANKS_DASHBOARD_INFO: 'DASHBOARD_ACTIONS.SET_POWERBANKS_DASHBOARD_INFO',
    SET_MERCHANTS_DASHBOARD_INFO: 'DASHBOARD_ACTIONS.SET_MERCHANTS_DASHBOARD_INFO',
    SET_LOST_POWERBANKS_INFO: 'DASHBOARD_ACTIONS.SET_LOST_POWERBANKS_INFO',

    SORT_POWERBANKS_STATUSES_DASHBOARD_INFO_BY_MERCHANT_REQUEST: 'DASHBOARD_ACTIONS.SORT_POWERBANKS_STATUSES_DASHBOARD_INFO_BY_MERCHANT_REQUEST',
    SORT_POWERBANKS_CONDITIONS_DASHBOARD_INFO_BY_MERCHANT_REQUEST: 'DASHBOARD_ACTIONS.SORT_POWERBANKS_CONDITIONS_DASHBOARD_INFO_BY_MERCHANT_REQUEST',
    SORT_POWERBANKS_STATUSES_DASHBOARD_INFO_BY_MERCHANT: 'DASHBOARD_ACTIONS.SORT_POWERBANKS_STATUSES_DASHBOARD_INFO_BY_MERCHANT',
    SORT_POWERBANKS_CONDITIONS_DASHBOARD_INFO_BY_MERCHANT: 'DASHBOARD_ACTIONS.SORT_POWERBANKS_CONDITIONS_DASHBOARD_INFO_BY_MERCHANT',
    CLEAR_MERCHANTS_POWERBANKS_STATUSES_DASHBOARD_LIST: 'DASHBOARD_ACTIONS.CLEAR_MERCHANTS_POWERBANKS_STATUSES_DASHBOARD_LIST',
    CLEAR_MERCHANTS_POWERBANKS_CONDITIONS_DASHBOARD_LIST: 'DASHBOARD_ACTIONS.CLEAR_MERCHANTS_POWERBANKS_CONDITIONS_DASHBOARD_LIST',

    CHANGE_MONTH_ON_LOST_POWERBANKS_CHART: 'DASHBOARD_ACTIONS.CHANGE_MONTH_ON_LOST_POWERBANKS_CHART',

    GET_ALL_STATIONS_ON_MAP: 'DASHBOARD_ACTIONS.GET_ALL_STATIONS_ON_MAP',
    SET_ALL_STATIONS_ON_MAP: 'DASHBOARD_ACTIONS.SET_ALL_STATIONS_ON_MAP',
    GET_STATION_INFO_ON_MAP: 'GET_STATION_INFO_ON_MAP',
    SET_STATION_INFO_ON_MAP: 'SET_STATION_INFO_ON_MAP',
    CLEAR_STATION_INFO_ON_MAP: 'CLEAR_STATION_INFO_ON_MAP',
};

const initialState = {
    dataStatuses: {},
    dataMerchants: {},
    dataLostPowerbanks: {
        lostPowerBanks: {},
        creditDebit: {series: [], categories: []},
        rents: {series: [], categories: []},
        scans: {series: [], categories: []},
        creditDebitMerchants: {series: [], categories: []},
        creditDebitStations: {series: [], categories: []},
        utilization: {series: [], categories: []},
        utilizationStations: {series: [], categories: []},
        rentScan: {series: [], categories: []},
        stations: []
    },
    MerchantsDashboadtList: {},
    PowerbanksStatusesSortedByMerchant: {},
    PowerbanksConditionsSortedByMerchant: {},
    month: "",
    allBanksOnMap: [],
    stationInfo: {}
};

export default (state = initialState, action) => {
    switch (action.type) {

        case DASHBOARD_ACTIONS.GET_POWERBANKS_DASHBOARD_INFO_REQUEST: {
            return {...state}
        }
        case DASHBOARD_ACTIONS.SET_POWERBANKS_DASHBOARD_INFO: {
            return {
                ...state,
                dataStatuses: action.payload
            }
        }
        case DASHBOARD_ACTIONS.GET_MERCHANTS_DASHBOARD_INFO_REQUEST: {
            return {...state}
        }
        case DASHBOARD_ACTIONS.SET_MERCHANTS_DASHBOARD_INFO: {
            return {
                ...state,
                dataMerchants: action.payload
            }
        }
        case DASHBOARD_ACTIONS.GET_LOST_POWERBANKS_INFO_REQUEST: {
            return {...state}
        }
        case DASHBOARD_ACTIONS.SET_LOST_POWERBANKS_INFO: {
            return {
                ...state,
                dataLostPowerbanks: action.payload
            }
        }
        case DASHBOARD_ACTIONS.SORT_POWERBANKS_STATUSES_DASHBOARD_INFO_BY_MERCHANT_REQUEST: {
            return {...state}
        }
        case DASHBOARD_ACTIONS.SORT_POWERBANKS_CONDITIONS_DASHBOARD_INFO_BY_MERCHANT_REQUEST: {
            return {...state}
        }
        case DASHBOARD_ACTIONS.SORT_POWERBANKS_STATUSES_DASHBOARD_INFO_BY_MERCHANT: {
            return {
                ...state,
                PowerbanksStatusesSortedByMerchant: action.payload
            }
        }
        case DASHBOARD_ACTIONS.SORT_POWERBANKS_CONDITIONS_DASHBOARD_INFO_BY_MERCHANT: {
            return {
                ...state,
                PowerbanksConditionsSortedByMerchant: action.payload
            }
        }
        case DASHBOARD_ACTIONS.CLEAR_MERCHANTS_POWERBANKS_STATUSES_DASHBOARD_LIST: {
            return {
                ...state,
                PowerbanksStatusesSortedByMerchant: {}
            }
        }
        case DASHBOARD_ACTIONS.CLEAR_MERCHANTS_POWERBANKS_CONDITIONS_DASHBOARD_LIST: {
            return {
                ...state,
                PowerbanksConditionsSortedByMerchant: {}
            }
        }

        case DASHBOARD_ACTIONS.CHANGE_MONTH_ON_LOST_POWERBANKS_CHART: {
            return {
                ...state,
                month: action.payload
            }
        }

        case DASHBOARD_ACTIONS.GET_ALL_STATIONS_ON_MAP: {
            return {...state}
        }
        case DASHBOARD_ACTIONS.GET_STATION_INFO_ON_MAP: {
            return {...state}
        }
        case DASHBOARD_ACTIONS.SET_ALL_STATIONS_ON_MAP: {
            return {
                ...state,
                allBanksOnMap: action.payload
            }
        }
        case DASHBOARD_ACTIONS.SET_STATION_INFO_ON_MAP: {
            return {
                ...state,
                stationInfo: action.payload
            }
        }
        case DASHBOARD_ACTIONS.CLEAR_STATION_INFO_ON_MAP: {
            return {
                ...state,
                stationInfo: {}
            }
        }

        default: {
            return state
        }
    }
}