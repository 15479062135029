import {put,call, takeLatest} from "@redux-saga/core/effects";
import * as api from "../../api";
import {GET_IMG} from "./GetStationImgReducer";



function* handleSaga() {
  yield takeLatest(GET_IMG.STATION_IMG_REQUEST,StationSaga)
}

function* StationSaga(action) {
  try {
    const {data} = yield call(api.getStationImg,action.payload.stationNumber)
    yield put({
      type:GET_IMG.STATION_IMG_SUCCESS,
      payload:{data}
    })
  }
  catch (e) {
    yield put({
      type:GET_IMG.STATION_IMG_FAIL,
      message:e.message
    })
  }

}


export function* handleImgSaga() {
  yield takeLatest(GET_IMG.STATION_ALL_IMG_REQUEST,StationImgSaga)
}

function* StationImgSaga(action) {
  try {
    const {data} = yield call(api.getStationAllImg,action.payload.stationNumber)
    yield put({
      type:GET_IMG.STATION_ALL_IMG_SUCCESS,
      payload:{data}
    })
  }
  catch (e) {

  }

}

export default handleSaga