import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RentHistoryCard} from "./components/RentHistoryCard";
import RentHistoryItem from "../RentHistoryItem";
import {setRentHistoryIteM, setRentHistoryItem} from "../../../../modules/rents/rentHistory/rentHistoryActions";
import {rentHistoriesSelector} from "../../../../modules/rents/rentHistory/rentHistorySelectors";

const styles = {
  cardStyle: {
    background: 'none',
    margin: '0 auto',
    minHeight: '100vh',
    padding: '0 20px 20px 0'
  }
}

class RentHistory extends Component {
  constructor(props) {
    super(props);
    this.state={
      open:false
    }
  }


  setHistoryItem = item => () => {
    const {history, setRentHistoryIteM} = this.props;
    setRentHistoryIteM(item);
    this.setState({open:true})
  };

  renderHistories = () => {
    const {rentHistories} = this.props;
    return rentHistories.map(item => < RentHistoryCard key={item.id} openRent={this.setHistoryItem(item)} item={item}/>)
  };

  render() {
    const {history} = this.props;
    return (
      <div style={{height: '100%',}}>
        {this.state.open === true ?
        <RentHistoryItem/>
        :
        <div style={styles.cardStyle}>
          <div style={{marginTop: '10px'}}>
            {this.renderHistories()}
          </div>
        </div>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  rentHistories: rentHistoriesSelector(state),
})

export default connect(mapStateToProps, {setRentHistoryItem, setRentHistoryIteM})(RentHistory);