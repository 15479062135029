import React, {Component} from 'react';
import {connect} from "react-redux";
import {addSupportText, CloseSupportPage} from "../../../modules/documents/SupportText/addSuportText/addSuportTextAtion";
import ErrMessage from "../../../components/common/ErrMessage";
import BigTextFieldS from "../../../components/common/Inputs/BigTextField";
import {LocationTextField} from "../../../components/common/Inputs/LocationTextField";
import {InfoModalCard} from "../../../components/common/InfoModal/InfoModal";

class SupportText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errMessage:false
    }
  }
  handleChange = (ev) => {
    const {name, value} = ev.target;
    const newState = {};
    newState[name] = value;
    this.setState(newState);
    this.setState({errMessage:false})
  };
  Create = () => {
    const supportEmail = this.state.supportEmail ? this.state.supportEmail :''
    const supportPhoneNumber = this.state.supportPhoneNumber ? this.state.supportPhoneNumber : ''
    const locationForSupport = this.state.locationForSupport ? this.state.locationForSupport:''
    if(supportEmail && supportPhoneNumber && locationForSupport) {
       this.props.addSupportText(locationForSupport, supportEmail, supportPhoneNumber)
      this.props.CloseSupportPage()
    } else{
      this.setState({errMessage:true})
    }
  }

  render() {
    const {openSupportPage} = this.props
    return (
       <>
        {openSupportPage  === true &&
          <InfoModalCard ClickClose={this.props.CloseSupportPage} ModalSize={'Big'} titleText={'New Support Text'}
                         Buttons={true} HandleCancel={() => this.props.CloseSupportPage()} HandleSave={this.Create}
                         Save={'CREATE'} Cancel={'CANCEL'}>
            <LocationTextField Back={'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212'} onChange={this.handleChange}/>
            <BigTextFieldS onChange={this.handleChange}  name='supportEmail' label={'Email'} />
            <BigTextFieldS onChange={this.handleChange}  name='supportPhoneNumber' label={'Phone Number'} />
            {this.state.errMessage === true ? <ErrMessage/> :''}
          </InfoModalCard >}
        </>
    );
  }
}
const mapStateToProps = state => ({
  openSupportPage: state.addSuportTextReducer.openSupportPage
})
export default connect(mapStateToProps, {addSupportText, CloseSupportPage})(SupportText);