export const STATION_INFO = {
  ALL_STATION_INFO:'STATION_INFO.ALL_STATION_INFO',
  CREATE_STATION_INFO:'STATION_INFO.CREATE_STATION_INFO',
  CLEAR_STATION_INFO:'STATION_INFO.CLEAR_STATION_INFO',
  CLEAR_NEW_STATION_INFO:'STATION_INFO.CLEAR_NEW_STATION_INFO',
  EDIT_STATION_INFO:'STATION_INFO.EDIT_STATION_INFO',
  CLEAR_EDIT_STATION_INFO:'STATION_INFO.CLEAR_EDIT_STATION_INFO'
}

const initialState = {
  StationAllINfo: [],
  CreateStation:[],
  EditStation:[],
  page:[]
}

export default (state = initialState, action) => {
  switch (action.type) {
    case STATION_INFO.EDIT_STATION_INFO: {
      return {...state, EditStation: action.payload.data}
    }
    case STATION_INFO.ALL_STATION_INFO: {
      return {...state, StationAllINfo: action.payload.data,page:action.payload.page}
    }
    case STATION_INFO.CREATE_STATION_INFO:{
      return {...state,CreateStation:action.payload.data}
    }
    case STATION_INFO.CLEAR_STATION_INFO: {
      return {...state, StationAllINfo:[]}
    }
    case STATION_INFO.CLEAR_NEW_STATION_INFO:{
      return {...state,CreateStation:[]}
    }
    case STATION_INFO.CLEAR_EDIT_STATION_INFO:{
      return {...state,EditStation:[]}
    }

    default: {
      return state
    }
  }
}