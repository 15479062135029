export const rentHistory = {
  RENT_HISTORY_REQUEST :'rentHistory.RENT_HISTORY_REQUEST',
  RENT_HISTORY_SUCCESS :'rentHistory.RENT_HISTORY_SUCCESS',
  RENT_HISTORY_FAIL : 'rentHistory.RENT_HISTORY_FAIL',
  SET_RENT_HISTORY_ITEM: 'rentHistory:SET_RENT_HISTORY_ITEM',
  CLEAR_RENT_HISTORY:'rentHistory.CLEAR_RENT_HISTORY'
};

const initialState={
  rentHistories :[],
  rentHistoryItem: null,
};
const SET_RENT_HISTORY_ITEM = (state, { payload }) => {
  return {...state, rentHistoryItem: payload};
};

export default  (state=initialState,action)  => {

  switch (action.type) {
    case rentHistory.RENT_HISTORY_REQUEST:{
      return {...state,rentHistories:initialState.rentHistories}
    }
    case rentHistory.RENT_HISTORY_SUCCESS:{
      return{...state,rentHistories:action.payload.data}
    }
    case rentHistory.CLEAR_RENT_HISTORY:{
      return{...state,rentHistories:[],rentHistoryItem:[]}
    }
    case rentHistory.RENT_HISTORY_FAIL:{
      return {...state,rentHistories:initialState.rentHistories}
    }
    case rentHistory.SET_RENT_HISTORY_ITEM:
      return SET_RENT_HISTORY_ITEM(state, action);
    default:{
      return state
    }
  }
}

