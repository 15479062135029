export const SET_TARIFFS={
  ADD_TARIFFGROUP_REQUEST:'SET_TARIFFS.ADD_TARIFFGROUP_REQUEST',
  ADD_TARIFFGROUP_SUCCESS:'SET_TARIFFS.ADD_TARIFFGROUP_SUCCESS',
  ADD_TARIFFGROUP_FAIL:'SET_TARIFFS.ADD_TARIFFGROUP_FAIL',
}

const initialState={
  TariffGroup:[],
}

export default (state=initialState,action)=>{
  switch (action.type) {
    case SET_TARIFFS.ADD_TARIFFGROUP_REQUEST:{
      return {...state,TariffGroup:initialState.TariffGroup}
    }
    case SET_TARIFFS.ADD_TARIFFGROUP_SUCCESS:{
      return {...state,TariffGroup:action.payload.data}
    }
    case SET_TARIFFS.ADD_TARIFFGROUP_FAIL:{
      return {...state,TariffGroup:initialState.TariffGroup}
    }
    default:{
      return state
    }

  }
}