import React, {Component, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import notFound from '../../assets/images/notFound.png'
import {connect} from "react-redux";
import {TableSortLabel} from '@material-ui/core';
import 'moment-timezone';
import {
  ClearAllForLoader,
  CLearDebitInfo,
  CLearFindingRentInfo,
  FilterRentsByActive,
  filterRentsList,
  FindRentsByNumber,
  getRents
} from "../../modules/rents/rentsAction";
import {sendUserRentInfo} from "../../modules/rents/rentUsersInfo/rentUsersInfoAction";
import {DelRentsFinding, filtreData, GetRentsUser} from "../../modules/rents/FindingRents/FindingRentsAction";
import * as moment from "moment";
import {sendUserInfo} from "../../modules/users/usersInfo/usersInfoAction";
import {ClearFindingStationData, FindStationByNumber, GetStation} from "../../modules/stations/stationsAction";
import {sendStationInfo} from "../../modules/stations/stationInfo/stationInfoAction";
import {GetStationImg} from "../../modules/stations/GetStationImg/GetStationImgAction";
import {TableCEll} from "../../components/common/TableCEll";
import {Card} from "../../components/common/Card";
import {Translation} from "react-i18next";
import {Page} from "../../components/common/Pages";
import {CechPromoStatus, ClearFindingData, FindUsersByPhoneNumber} from "../../modules/users/usersActon";
import DatePicker from "react-datepicker";
import {UsersStatus} from "../../components/common/Status/Statuss";
import {DebitCredit} from "../../components/common/Status/DebitCredit";
import "react-datepicker/dist/react-datepicker.css";
import RetIfoModal from "./components/RetIfoModal";
import DataList from "../UsersComponents/Components/DataList";
import {NotFound} from "../../components/common/Asyncs/NotFound";
import {Loader} from "../../components/common/Asyncs/Loader";

class Rents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: '',
      activeIndex: '',
      StartDate: '',
      EndDate: '',
      closePage:true,
      page:0,
      filter:'',
      RentList:'',
      allFilterButtons:[],
    }
  }

  componentDidMount() {
    this.props.getRents(this.state.page);
    this.props.ClearFindingStationData();
    this.props.ClearFindingData();
    this.setState({
      allFilterButtons: [
        {name: 'All', fn: this.handleAll},
        {name: 'Active', fn: this.handleActive},
        {name: 'Finished', fn: this.handleFinished}
      ]
    });
  }
  FindeHandleChange = (ev) => {
    this.setState({phoneNumber: ev.target.value})
    if (ev.keyCode === 8) {
      if (this.state.phoneNumber.length === 0 || this.state.phoneNumber.length >= 4 && this.state.phoneNumber.length <= 12  || this.state.phoneNumber.length === 12 || this.state.phoneNumber.length === 24) {
        setTimeout(()=> this.props.getRents(this.state.page) && this.props.CLearDebitInfo() &&
          this.setState({RentList:'',closePage:true}),
          200 )
      }
    }
  }
  sendFindData = () => {
    const {phoneNumber} = this.state
    if (phoneNumber.length >= 4) {
      sessionStorage.setItem('filterButtons','all')
      this.setState({RentList:'', StartDate: '', EndDate: '',activeUsers: false, blockedUsers: false,closePage:false})
      setTimeout(()=>this.props.FindRentsByNumber(phoneNumber), 200 )
    }
  }
  handleClick = (i) => {
    this.setState({activeIndex: i})
  }
  ClosePopup = () => {
    this.setState({activeIndex: ''})
  }
  openInfo = (row) => {
    this.setState({infoForModal:row})
  }
  filter=(date)=>{
    this.props.ClearAllForLoader()
    this.setState({RentList:''})
    if(date ===null ){
      if(this.state.StartDate) {
        this.setState({StartDate: '', EndDate: '', closePage: true})
        setTimeout(()=>this.props.getRents(this.state.page), 200 )
      }
    }
    if(date) {
      sessionStorage.setItem('filterButtons','all')
      if (date[0]) {
        this.setState({StartDate: date[0],EndDate:'',})
        if (!date[1]) {
          const Data = {
            StartDate: `${moment(date[0], 'YYYY-MM-DD').format('YYYY-MM-DD')}T00:00:00.001`,
            EndDate: `${moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD')}T23:59:59.001`,
          }
          setTimeout(()=>  this.props.filterRentsList(Data) && this.props.CLearDebitInfo(), 200 )
          this.setState({closePage:false,phoneNumber:''})
        }
      }
      if (date[1]) {
        this.setState({EndDate: date[1]})
        const Data = {
          StartDate: `${moment(this.state.StartDate, 'YYYY-MM-DD').format('YYYY-MM-DD')}T00:00:00.001`,
          EndDate: `${moment(date[1], 'YYYY-MM-DD').format('YYYY-MM-DD')}T23:59:59.001`,
        }
        setTimeout(()=>this.props.filterRentsList(Data) && this.props.CLearDebitInfo(), 200 )
        this.setState({closeCalendar: false,closePage:false,phoneNumber:''})
      }
    }
  }
  HandleChange =(event,value)=>{
    this.setState({RentList:'',page: value - 1, closePage: true})
    if(this.state.filter === 'ACTIVE'){
      const data = ['PROCESSING',value-1]
      this.props.FilterRentsByActive(data)
    }
    else if(this.state.filter === 'FINISHED'){
      const data = ['FINISHED',value-1]
      this.props.FilterRentsByActive(data)
    }
    else {
      this.props.ClearAllForLoader()
      setTimeout(() => this.props.getRents(value - 1), 200 )
    }
  }
  FindStationBuNumberA=(StationNumber)=>{
    sessionStorage.setItem('InfoForClose','CloseStation')
    this.props.FindStationByNumber(StationNumber)
    this.props.GetStationImg(StationNumber)
  }
  FindUserById=(row)=>{
    sessionStorage.setItem('InfoForClose','CloseUser')
    this.props.FindUsersByPhoneNumber(row.id)
    this.props.CechPromoStatus(row.id)
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.AllStations.length) {
      this.props.sendStationInfo(this.props.AllStations ? this.props.AllStations[0].station : '')
    }
    if(this.props.usersList.length){
      this.props.sendUserInfo(this.props.usersList ? this.props.usersList[0] : '')
    }
  }
  handleAll = () => {
    this.setState({RentList:'',StartDate: '', EndDate: '',closePage:true, page:0, phoneNumber:'',filter:''})
    setTimeout(() =>this.props.getRents(0) &&  this.props.CLearDebitInfo(), 200 )
  }
  handleActive = () => {
    this.setState({RentList:'',StartDate: '', EndDate: '',phoneNumber:'',filter:'ACTIVE',closePage:true,})
    const data = ['PROCESSING',0]
    setTimeout(() => this.props.FilterRentsByActive(data)  , 200 )
    if(this.props.DebitInfo.debit){
       this.props.ClearAllForLoader()
      setTimeout(() => this.props.CLearDebitInfo() , 200 )
      }
  }
  handleFinished = () => {
    this.setState({RentList:'',StartDate: '', EndDate: '',phoneNumber:'',filter:'FINISHED',closePage:true,})
    const data = ['FINISHED',0]
    setTimeout(() =>  this.props.FilterRentsByActive(data) , 200 )
    if(this.props.DebitInfo.debit){
      this.props.ClearAllForLoader()
      setTimeout(() => this.props.CLearDebitInfo() , 200 )
    }
  }
  componentWillUnmount() {
    this.props.CLearFindingRentInfo()
    this.props.CLearDebitInfo()
  }
  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({RentList: this.props.AllRents })
  }
  handleRevert =()=>{
   this.setState({RentList: this.props.AllRents.reverse()})
  }

  render() {
    const ExampleCustomInput = ({ onClick }) => (
      <button className= {this.state.StartDate ? "example-custom-input" :'Example-Custom'} onClick={onClick}>
        {this.state.StartDate ?
          this.state.EndDate ?
            ` ${moment(this.state.StartDate, 'YYYY-MM-DD').format('YYYY.MM.DD')} - ${moment(this.state.EndDate, 'YYYY-MM-DD').format('YYYY.MM.DD')}` :
            ` ${moment(this.state.StartDate, 'YYYY-MM-DD').format('YYYY.MM.DD')} - ${moment(new Date(), 'YYYY-MM-DD').format('YYYY.MM.DD')}`
          :
          <Translation>{(t) => <span> {t('--.--.----')}</span> }</Translation>}
      </button>
    );
    const rentsListArray = Object.values(this.state.RentList ? this.state.RentList : '')
    const Role = localStorage.getItem('role')
    const {pageCount,rentCount}=this.props.PageInfo
    const {debit, credit} = this.props.DebitInfo
    const startDate = this.state.StartDate ? this.state.StartDate : ''
    const endDate = this.state.EndDate ? this.state.EndDate : ''
    const info = this.state.infoForModal && this.state.infoForModal
    const data = [startDate,endDate]


    return (
      <Translation>{(t) => <>
        {info && <RetIfoModal
                filterData={data}
                page={this.state.page}
                HandleClose={() =>this.setState({infoForModal:''})} info={info}
                GetList={this.props.getRents}
        />}
        <Card 
          TitleName={'Rents'}
          value={this.state.phoneNumber} 
          Count={rentCount} 
          search={true}
          clearSearch={this.state.phoneNumber.length && this.handleAll }
          place={'Search rents'} 
          onClick={this.sendFindData} 
          onChange={this.FindeHandleChange} 
          onKeyDown={this.FindeHandleChange}
          FilterBoolean={true}
          revert={this.handleRevert}
          allButtons={this.state.allFilterButtons}
        >
         <DataList />
          <div style={{display:'flex',alignItems: 'center',flexDirection: 'row-reverse',marginRight:'50px', marginTop: '-43px'}}>
            <DatePicker placeholderText="Select a weekday"
                        selected={startDate} onChange={this.filter} startDate={startDate} endDate={endDate} selectsRange
                        customInput={<ExampleCustomInput />} shouldCloseOnSelect={false} showMonthDropdown isClearable
            />
               <span style={{color: 'white', marginRight: '15px'}}>{t("Filter by Date")}:</span>
              </div>
          <DebitCredit debit={debit} credit={credit} />
            <TableSortLabel/>
            <Table  style={{marginTop:'-30px'}} size="small">
              <TableHead onClick={() => this.handleClick('')} style={{borderBottom: '1px solid grey',}}>
                <TableCEll text={['ID', 'Rent period', 'User ID', 'Start station', 'End station', 'Debt','Rent price', 'Status']}/>
              </TableHead>
              <TableCell style={{border: 'none', padding: '1px'}}>.</TableCell>
              { this.props.AllRents === 'not found' || rentCount === 0 ?  <NotFound/>  :
                rentsListArray.length > 0  ?
                rentsListArray.map((row, i) => (
                  row !== undefined ?
                    <TableBody key={i}>
                      {this.state.activeIndex === i ?
                        <div onMouseLeave={() => {this.ClosePopup('')}} className={'activeIndexStyleForSmallModal'}>
                          <div className={'activeIndexButtonsStyle'}>
                            <button onClick={() => {this.openInfo(row)}} className={'openInfoButton'}>{t("Open Info")}</button>
                          </div>
                        </div> : ''}
                      <TableRow className={'BodyTableRow'}  key={row.id ? row.id : ''}>
                        <TableCell onClick={() => {this.openInfo(row)}} className={'BodyTableCellCheck'}>{row.id ? row.id.slice(-4) : ''}</TableCell>
                        <TableCell onClick={() => {this.openInfo(row)}} className='BodyTableCell'>{row.startDate ? `${moment.tz(row.startDate, 'YYYY-MM-DD/HH:mm', 'Europe/Moscow').local().format('DD.MM.YYYY/HH:mm')}` : ''}-{row.finishedDate ? `${moment.tz(row.finishedDate, 'YYYY-MM-DD/HH:mm', 'Europe/Moscow').local().format(' DD.MM.YYYY/HH:mm')}` : 'In progress'}</TableCell>
                        <TableCell onClick={Role === 'SUPER_ADMIN' ? () => {this.FindUserById(row.user)} : null} className={Role === 'SUPER_ADMIN' ? 'LinkBodyTableCell' : 'LinkBodyTableCellUsers'}>{row.user ? row.user.id.slice(-4) : ''}</TableCell>
                        <TableCell onClick={() => {this.FindStationBuNumberA(row.stationNumber)}} className='LinkBodyTableCell'>{row.startStation ? row.startStation.slice(0, 13) + '...' : ''}</TableCell>
                        <TableCell onClick={() => {this.openInfo(row)}} className='BodyTableCell'>{row.completionStation ? row.completionStation.slice(0, 13) + '...' : 'null'}</TableCell>
                        {/*<TableCell onClick={() => {this.FindStationBuNumberA(row.stationNumber)}} className={'LinkBodyTableCell'}>{row.completionStation ? row.completionStation.slice(0, 20) + '...' : 'null'}</TableCell>*/}
                        <TableCell onClick={() => {this.openInfo(row)}} className={row.debt === 0 ?'BodyTableCell' : 'DebtBodyTableCell'}>{row.debt ? `-${row.debt} ${row.groupTariffs ? row.groupTariffs.location === 'RU' ? '₽' : '֏' : ''}` : '0'}</TableCell>



                        <TableCell onClick={() => {this.openInfo(row)}} className={'BodyTableCell'}>{row.totalAmount ? `${row.totalAmount} ${row.groupTariffs ? row.groupTariffs.location === 'RU' ? '₽' : '֏' : ''}` : '0'}</TableCell>
                          <UsersStatus
                          name={row.rentStatus}
                          statusBoolean={row.rentStatus !== 'FINISHED' }
                          Status={row.notificationStatus + ' - ' + row.rentStatus}
                          openTableInfo={()=>this.openInfo(row)}
                          onClick={()=>this.handleClick(i)} row={row}
                        />
                      </TableRow>
                    </TableBody>
                    : '')) : <Loader/>
              }
            </Table>
        {this.state.closePage &&
        <Page page={this.state.page + 1} handleChange={this.HandleChange} PageCount={pageCount}/>}
      </Card>
      </>}</Translation>
    );
  }
}

const mapStateToProps = state => ({
  usersList: state.usersReducer.usersList,
  DebitInfo: state.rentsReducer.DebitInfo,
  AllRents: state.rentsReducer.AllRents,
  PageInfo: state.rentsReducer.PageInfo,
  AllStations: state.stationsReducer.AllStations,
})
export default connect(mapStateToProps, {getRents, sendStationInfo, sendUserInfo, CLearFindingRentInfo, ClearFindingStationData, ClearFindingData,
  FindUsersByPhoneNumber, FilterRentsByActive, FindStationByNumber, CLearDebitInfo, FindRentsByNumber, GetStationImg, filterRentsList,
  ClearAllForLoader,CechPromoStatus
})(Rents);