import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Clusterer, GeolocationControl, Map, Placemark, SearchControl, YMaps } from "react-yandex-maps";
import { clearStationInfoOnMap, getAllBanksOnMap, getStationInfoOnMap } from "../../../../modules/dashboard/dashboardAction";

export const MapOnDashboard = ({allBanksOnMap}) => {
    const dispatch = useDispatch();
    const {stationInfo} = useSelector(state => state.dashboardReducer);
    const [searchControl, setSearchControl] = useState(null);
    const [firstCoords, setFirstCoords] = useState([55.755864, 37.617698]);
    const [isVisibleBalloon, setIsVisibleBalloon] = useState(false);

    useEffect(() => {
        if (searchControl) {
            searchControl.events.add('resultshow', function (event) {
                const resultIndex = event.get('index');
                const result = searchControl.getResultsArray()[resultIndex];
                result.balloon.events.add('close', function () {
                  result.getParent().remove(result);
                });
            });
        }
    }, [searchControl]);

    useEffect(() => {
        !!stationInfo.stationId ? setIsVisibleBalloon(true) : setIsVisibleBalloon(false);
    }, [stationInfo]);
    
    const checkThis = (ymaps) => {
        ymaps.geolocation.get({ provider: "yandex", mapStateAutoApply: true })
            .then(result => {
                setFirstCoords(result.geoObjects.position)
                const element = document.querySelector(".ymaps-2-1-79-float-button.ymaps-2-1-79-_hidden-text");
                element.click();
            }
        );
        // download all stations on map
        dispatch(getAllBanksOnMap());
    }

    const clickOnPlacemark = (stationId) => {
        dispatch(clearStationInfoOnMap());
        dispatch(getStationInfoOnMap(stationId));
    }

    return (
        <YMaps
            query={{
                lang: "ru_RU",
                apikey: "f8a4a7fe-f442-4a37-af5a-a4dec57c863f"
            }}
        >
            <Map
                modules={[
                    "geolocation", 
                    "geocode", 
                    "control.ZoomControl", 
                    "control.GeolocationControl", 
                    "control.SearchControl"
                ]} 
                width="100%"
                height="500px"
                defaultState={{
                    center: firstCoords,
                    zoom: 10,
                    controls: ['zoomControl']
                }}
                instanceRef={ref => {
                    if (ref) {
                        ref.events.add("click", () => {
                            ref.balloon.close();
                        });
                    }
                }}
                onLoad={(ymaps) => checkThis(ymaps)}
            >
                <GeolocationControl />
                <SearchControl 
                    instanceRef={ref => setSearchControl(ref)}
                    options={{
                        noPlacemark: false,
                    }} 
                />
                <Clusterer 
                    options={{
                        preset: "islands#invertedVioletClusterIcons",
                        groupByCoordinates: false,
                        zoomMargin: 100
                    }
                    }>
                    {allBanksOnMap.map((mapPoint, key) => 
                        <Placemark
                            key={key}
                            modules={["geoObject.addon.balloon"]}
                            geometry={[mapPoint.latitude, mapPoint.longitude]}
                            onClick={() => clickOnPlacemark(mapPoint.stationId)}
                            options={{
                                iconColor: `
                                    ${
                                        mapPoint.stationState === "STATION_DISCONNECTED" ? "grey" :
                                            mapPoint.stationState === "NO_POWER_BANKS" ? "red" :
                                            "blue"
                                    }
                                `
                            }}
                            properties={{
                                balloonMaxWidth: 1000,
                                balloonContent: 
                                    `<div class="baloonMapContent">
                                        ${
                                            isVisibleBalloon ? 
                                            `   <div class="simpleRowInBaloon">
                                                    <strong>Расположение:</strong>
                                                    <p>${stationInfo.additionalAddress}</p>
                                                </div>
                                                <hr />
                                                <div class="simpleRowInBaloon">
                                                    <strong>Адрес:</strong>
                                                    <p>${stationInfo.address}</p>
                                                </div>
                                                <hr />
                                                <div class="simpleRowInBaloon">
                                                    <strong>ID:</strong>
                                                    <p>${stationInfo.stationId}</p>
                                                </div>
                                                <hr />
                                                <div class="simpleRowInBaloon">
                                                    <strong>№ станции:</strong>
                                                    <p>${stationInfo.stationNumber}</p>
                                                </div>
                                                <hr />
                                                <div class="simpleRowInBaloon">
                                                    <strong>Слотов:</strong>
                                                    <p>${stationInfo.numberSlots}</p>
                                                </div>
                                                <hr />
                                                <div class="simpleRowInBaloon">
                                                    <strong>Пауэрбанков доступно:</strong>
                                                    <p>${stationInfo.powerBanks}</p>
                                                </div>
                                            ` : ""
                                        }
                                    </div>`
                            }}
                        />
                    )}
                </Clusterer>
            </Map>
        </YMaps>
    )
}