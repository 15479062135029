export const ADD_MERCHANTS_ACTION = {
  ADD_MERCHANTS_REQUEST:'ADD_MERCHANTS_ACTION.ADD_MERCHANTS_REQUEST',
  ADD_MERCHANTS_SUCCESS:'ADD_MERCHANTS_ACTION.ADD_MERCHANTS_SUCCESS',
  ADD_MERCHANTS_FAIL:'ADD_MERCHANTS_ACTION.ADD_MERCHANTS_FAIL',
  REG_MERCHANTS_ACTION: 'ADD_MERCHANTS_ACTION.REG_MERCHANTS_ACTION',
  REG_MERCHANTS_ACTION_INVANTE : 'ADD_MERCHANTS_ACTION.REG_MERCHANTS_ACTION_INVANTE',
  REG_INDIVIDUAL_MERCHANTS_ACTION : 'ADD_MERCHANTS_ACTION.REG_INDIVIDUAL_MERCHANTS_ACTION',
  REG_MERCHANTS_ACTION_STATUS: 'ADD_MERCHANTS_ACTION.REG_MERCHANTS_ACTION_STATUS',
  REG_MERCHANTS_ACTION_REMOVE_STATUS: 'ADD_MERCHANTS_ACTION.REG_MERCHANTS_ACTION_REMOVE_STATUS',
}


const initialState={
  addMerchant:[],
  status:[]
}

export default (state=initialState,action)=>{
  switch (action.type) {

    case ADD_MERCHANTS_ACTION.ADD_MERCHANTS_REQUEST:{
      return{...state,addMerchant:initialState.addMerchant}
    }
    case ADD_MERCHANTS_ACTION.ADD_MERCHANTS_SUCCESS: {
      return {...state, addMerchant: action.payload.data}
    }
    case ADD_MERCHANTS_ACTION.REG_MERCHANTS_ACTION_STATUS: {
      return {...state, status: action.payload.data}
    }
    case ADD_MERCHANTS_ACTION.REG_MERCHANTS_ACTION_REMOVE_STATUS: {
      return {...state, status:[]}
    }
    case ADD_MERCHANTS_ACTION.ADD_MERCHANTS_FAIL:{
      return{...state,addMerchant:initialState.addMerchant}
    }

    default:{
      return state
    }

  }

}
