import React from "react";
import Button from "@material-ui/core/Button";
import {Translation} from "react-i18next";
const styles ={
  CancelButtonDefaultStyle: {
    color: 'white',
    background: '#EB5B47',
    borderRadius: '5px',
    border: '1px solid #EB5B47',
    padding: '8px'
  },
  SaveButtonDefaultStyle: {
    color: 'white',
    background: '#5F94E2',
    borderRadius: '5px',
    border: '1px solid #5F94E2',
    padding: '8px',
    marginLeft: '10px'
  }
};

export const SaveAndCancelButtons =({HandleCancel,Save,Cancel,style={},HandleSave})=>{
  return(
    <Translation>{(t) => <>
    <div style={{marginTop: '20px'}}>
      <Button onClick={HandleCancel} style={ {...styles.CancelButtonDefaultStyle, ...style,}}>
        {t(Cancel)}
      </Button>
      <Button onClick={HandleSave} style={ {...styles.SaveButtonDefaultStyle, ...style }}>
        {t(Save)}
      </Button>
    </div>
    </>}</Translation>
  )
}