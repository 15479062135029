import {put, takeLatest, call} from "@redux-saga/core/effects";
import * as api from "../api";
import {COMMENTS} from "./CommentsReducer";

// function* handleCommentsSaga() {
//   yield takeLatest(COMMENTS.GET_COMMENTS_REQUEST, handleSaga)
// }
//
// function* handleSaga(action) {
//   try {
//     const {data} = yield call(api.getCommentsApi, action.payload.name)
//
//     yield put({
//       type: COMMENTS.GET_COMMENTS_SUCCESS,
//       payload: {data},
//     });
//   } catch (e) {
//     console.log(e)
//   }
// }

 function* handleSendCommentsSaga() {
  yield takeLatest(COMMENTS.SET_COMMENTS_REQUEST, handleSendSaga)
}

function* handleSendSaga(action) {
  try {
    const {data} = yield call(api.SetCommentsApi, action.payload.text,action.payload.name,action.payload.id)

    yield put({
      type: COMMENTS.SET_COMMENTS_SUCCESS,
      payload: {data},
    });
  } catch (e) {
    console.log(e)
  }
}


export default handleSendCommentsSaga
