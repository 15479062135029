import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import TableHead from "@material-ui/core/TableHead";
import {Translation} from "react-i18next";
import {BrowserRouter} from "react-router-dom";

const styles ={
  TableCell:{
    border: 'none',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
    color: 'rgba(255, 255, 255, 0.6)',
  }
}
export const TableCEll =({text})=>{

  return(
    <>
    <Translation>
      {(t) =>
        <>
    <TableRow>
      {text.map((item,i) => (
        <TableCell key={i} style={styles.TableCell}>{t(item)}</TableCell>
      ))
      }
    {/* <TableCell style={{border: 'none'}}>.</TableCell> */}
    </TableRow>
        </>}</Translation>
</>
  )
}
