import {GET_TRANSACTIONS} from "./GetTransactionReducer";

export const GetTransactions=(page)=>{
  return{
    type:GET_TRANSACTIONS.TRANSACTIONS_REQUEST,
    payload:{page}
  }
}


export const filterTransactions=(email)=>{
  return{
    type:GET_TRANSACTIONS.FILTER_TRANSACTIONS_REQUEST,
    payload:{email}
  }
}

export const filterTransactionsList=()=>{
  return{
    type:GET_TRANSACTIONS.FILTER_TRANSACTIONS_LIST,
  }
}

export const FindTransactionById=(transactionId)=>{
  return{
    type:GET_TRANSACTIONS.FIND_TRANSACTION_BY_ID,
    payload:{transactionId}
  }
}


export const FindTransactionByRentId=(transactionId)=>{
  return{
    type:GET_TRANSACTIONS.FIND_TRANSACTION_BY_RENT_ID,
    payload:{transactionId}
  }
}
export const ClearFindTransactionByRentId=()=>{
  return{
    type:GET_TRANSACTIONS.CLEAR_FIND_TRANSACTION_BY_RENT_ID,
  }
}



export const AddTransactionByIdList=(data)=>{
  return{
    type:GET_TRANSACTIONS.TRANSACTION_LIST_BY_ID,
    payload:{data}
  }
}

export const ClearFindTransaction =()=>{
  return{
    type:GET_TRANSACTIONS.CLEAR_FIND_TRANSACTIONS
  }
}
