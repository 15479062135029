import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { Translation } from "react-i18next";

export const StationList = ({list, chooseAll, chooseOne}) => {
    const [choosingMerchants, setChoosingMerchant] = useState('All')
    const [showList, setShowList] = useState(false);

    const selectOne = (item) => {
        setChoosingMerchant(item);
        setShowList(false);
        chooseOne(item);
    }

    const selectAll = () => {
        setChoosingMerchant('All');
        setShowList(false);
        chooseAll();
    }

    return (
        <Translation>
            {(t) => (
                <div className="merchantFilterBlock">
                    <Button className="showMerchantsListButton" onClick={() => setShowList(pv => !pv)}>
                        {t('SHOW BY STATION')}: &nbsp; &nbsp;{choosingMerchants !== 'All' ? choosingMerchants : t('All')}
                    </Button>
                    {showList && 
                        <div className="merchantsFilterList">
                            <Button onClick={selectAll}>{t('All')}</Button>
                            {list &&
                                list.map((item, key) => (
                                    <Button key={key} onClick={() => selectOne(item)}>
                                        {item}
                                    </Button>
                                ))
                            }
                        </div>
                    }
                </div>
            )}
        </Translation>
    )
}