import TextField from "@material-ui/core/TextField";
import React from "react";
import {Translation} from "react-i18next";


export const LocationTextField =({onChange,Back})=>{
  return(
    <Translation>{(t) => <>
    <div style={{marginTop:'20px'}}>
    <TextField
      id="outlined-select-currency-native"
      select
      name='locationForSupport'
      label={t("Location")}
      InputLabelProps={{style:{color:'white',background:Back,padding:'0 10px 0 0 '},}}
      inputProps={{ style: {outline:'none',width:'180px',fontFamily: 'Arial', color: 'white',border:'1px solid white',borderRadius: '5px'}}}
      onChange={onChange}
      SelectProps={{native: true,}}
      variant="outlined"
    ><option style={{color:'black'}} value={''}>{}</option>
      <option style={{color:'black'}} value={'RU'}>RU</option>
      <option style={{color:'black'}} value={'EE'}>EE</option>
      <option style={{color:'black'}} value={'BY'}>BY</option>
    </TextField>
    </div>
    </>}</Translation>
  )
}