import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import another from "../../../assets/images/another.png";
import React from "react";
import {Translation} from "react-i18next";


export const UsersStatus = ({Status,onClick,openTableInfo,statusBoolean,name}) => {
  return (
    <Translation>
      {(t) =>
          <TableCell className='BodyTableCellRight'>{statusBoolean === false ?
            <div style={{display: 'flex', justifyContent: 'space-between',}}>
              <span onClick={openTableInfo} style={{marginTop: '4px', color:name === 'FINISHED' ? 'gray' : 'rgba(101, 227, 42, 0.87)'}}>{t(Status)}</span>
                <Button onClick={onClick}><img src={another} alt={another}/></Button>
            </div>
            :
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <span onClick={openTableInfo} style={{marginTop: '4px', color: name ==='PROCESSING' ?
                  'rgba(101, 227, 42, 0.87)'
                  : name === 'Station' ?  Status === 'OPEN' ? 'rgba(101, 227, 42, 0.87)' :  'gray' : '#EB5B47'}}>{t(Status)}</span>
              <Button onClick={onClick}><img src={another} alt={another}/></Button>
            </div>
          }
          </TableCell>
        }
    </Translation>
  )
}
