import { combineReducers } from 'redux';
import signInReducer from "../modules/signIn/signInReducer";
import usersReducer from "../modules/users/usersReducer";
import rentsReducer from "../modules/rents/rentsReducer";
import stationsReducer from "../modules/stations/stationsReducer";
import DeleteStationReducer from "../modules/stations/DeleteSelectedStation/DeleteStationReducer";
import DeleteUsersReducer from "../modules/users/DeleteUsers/DeleteUsersReducer";
import lockStationReducer from "../modules/stations/LockStations/lockStationReducer";
import addStationsReducer from "../modules/stations/AddStations/addStationsReducer";
import AddStationImageReducer from "../modules/stations/AddStations/AddStationImage/AddStationImageReducer";
import FindingRentsReducer from "../modules/rents/FindingRents/FindingRentsReducer";
import GetStationImgReducer from "../modules/stations/GetStationImg/GetStationImgReducer";
import lockUsersReducer from "../modules/users/lockUsers/lockUsersReducer";
import usersInfoReducer from "../modules/users/usersInfo/usersInfoReducer";
import rentHistoryReducer from "../modules/rents/rentHistory/rentHistoryReducer";
import rentUsersInfoReducer from "../modules/rents/rentUsersInfo/rentUsersInfoReducer";
import stationInfoReducer from "../modules/stations/stationInfo/stationInfoReducer";
import tariffsReducer from "../modules/tariff/tariffsReducer";
import AddTarifTextReducer from "../modules/tariff/AddTAriffText/AddTarifTextReducer";
import AddQuestionReducer from "../modules/Questions/AddQuestion/AddQuestionReducer";
import GetPopularQuestionReducer from "../modules/Questions/GetPopularQuestionReducer";
import supportTextReducer from "../modules/documents/SupportText/supportTextReducer";
import agreementReducer from "../modules/documents/UserAgreement/agreementReducer";
import addSuportTextReducer from "../modules/documents/SupportText/addSuportText/addSuportTextReducer";
import addAgreementReducer from "../modules/documents/UserAgreement/addAgreement/addAgreementReducer";
import AddTariffReducer from "../modules/tariff/AddTariff/AddTariffReducer";
import StopRentReducer from "../modules/rents/StopRent/StopRentReducer";
import DeleteQuestionReducer from "../modules/Questions/DeleteQuestion/DeleteQuestionReducer";
import AddTariffWidthGroupReducer from "../modules/tariff/AddTariff/AddTariffWidthGRoup/AddTariffWidthGroupReducer";
import GetTransactionReducer from "../modules/transactions/GetTransactionReducer";
import AddTransactionsReducer from "../modules/transactions/AddTransactions/AddTransactionsReducer";
import MerchantesReducer from "../modules/merchantes/MerchantesReducer";
import AddMerchantsReducer from "../modules/merchantes/AddMerchants/AddMerchantsReducer";
import adminReducer from "../modules/admins/adminReducer";
import AddAdminsReducer from "../modules/admins/AddAdmins/AddAdminsReducer";
import GetMerchantReducer from "../modules/merchantes/GetMerchant/GetMerchantReducer";
import TakeBankReducer from "../modules/stations/TakeBank/TakeBankReducer";
import powerbanksReducer from "../modules/powerbanks/powerbanksReducer";
// import handleCommentsSaga from "../modules/Comments/CommentsSaga";
import CommentsReducer from "../modules/Comments/CommentsReducer";
import dashboardReducer from "../modules/dashboard/dashboardReducer";

export const reducer = combineReducers({
  signInReducer,
  usersReducer,
  rentsReducer,
  lockUsersReducer,
  usersInfoReducer,
  rentHistoryReducer,
  rentUsersInfoReducer,
  stationsReducer,
  stationInfoReducer,
  DeleteStationReducer,
  DeleteUsersReducer,
  lockStationReducer,
  addStationsReducer,
  AddStationImageReducer,
  FindingRentsReducer,
  GetStationImgReducer,
  tariffsReducer,
  AddTarifTextReducer,
  AddQuestionReducer,
  GetPopularQuestionReducer,
  supportTextReducer,
  agreementReducer,
  addSuportTextReducer,
  addAgreementReducer,
  AddTariffReducer,
  StopRentReducer,
  DeleteQuestionReducer,
  AddTariffWidthGroupReducer,
  GetTransactionReducer,
  AddTransactionsReducer,
  MerchantesReducer,
  AddMerchantsReducer,
  adminReducer,
  AddAdminsReducer,
  GetMerchantReducer,
  TakeBankReducer,
  powerbanksReducer,

  dashboardReducer,

  CommentsReducer
});
