import React from "react";
import {MenuItem, Select} from "@material-ui/core";

export const SelectMonth = ({month, updateRange}) => {
    const options = React.useMemo(() => {
        const date = new Date();
        date.setDate(1);
        return Array.from({ length: 12 }).map((_, i) => {
            date.setMonth(i);
           return { label: date.toLocaleDateString('ru-RU', { month: 'long' }), value: i };
        });
    }, []);

    return (
        <Select
            label="Месяц"
            value={month}
            onChange={(e) => updateRange(e.target.value)}
        >
            {options.map(o => (
                <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>
            ))}
        </Select>
    )
}

const YEAR_START = 2015;
export const SelectYear = ({year, updateRange}) => {
    const options = React.useMemo(() => {
        return Array.from({ length: new Date().getFullYear() - YEAR_START + 1 }).map((_, i) => {
           return { label: i + YEAR_START, value: i + YEAR_START };
        });
    }, []);

    return (
        <Select

            label="Год"
            value={year}
            onChange={(e) => updateRange(e.target.value)}
        >
            {options.map(o => (
                <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>
            ))}
        </Select>
    )
}