import React from "react";
import {Translation} from "react-i18next";
import * as moment from "moment";
import Button from "@material-ui/core/Button";
import edit from "../../../../assets/images/change.png";


export const MerchantsInfoList = ({info, individual, ChangeInfo}) => {
    console.log(info, 'info')
    console.log(individual, 'individual')
    const date = info.createdDate ? moment.tz(info.createdDate, 'YYYY-MM-DD/HH:mm', 'Europe/Moscow').local().format(' DD.MM.YYYY/HH:mm') :
        info.created ? moment.tz(info.created, 'YYYY-MM-DD/HH:mm', 'Europe/Moscow').local().format(' DD.MM.YYYY/HH:mm') : '...'
    const Role = localStorage.getItem('role')
    return (
        <Translation>
            {(t) => <>
                <div className={'MerchantsInfoListStyle'}>
                    <span>{t("Surname")}</span>
                    <p>{info.surname}</p>
                    <span>{t("NAME")}</span>
                    <p>{info.name}</p>
                    <span>{t("Middle Name")}</span>
                    <p>{info.middleName}</p>
                    <span>{t("Phone Number")}</span>
                    <p>{info.phoneNumber}</p>
                    <span>{t("E-mail")}</span>
                    <p>{info.email}</p>
                    <span>{t("Account Number")}</span>
                    <p>{info.accountNumber}</p>
                    {info.isPartner ? <>
                            <span>{t("Shop id")}</span>
                            <p>{info.shopId ? info.shopId : '...'}</p>
                            <span>{t("Online Kass")}</span>
                            <p>{info.onlineCheckout === true ? t("yes") : info.onlineCheckout === false ? t('no') : '...'}</p>
                        </> :
                        <>
                        {!info.withoutRevShare ? <>
                            <span>{t('Contract Id')}</span>
                            <p>{info.contractId ? info.contractId : '...'}</p>
                            <span>{t('Contract Date')}</span>
                            <p>{info.contractDate ? info.contractDate : '...'}</p>
                            <span>{t('Rev Share')}</span>
                            <p>{info.revShare ? info.revShare : '...'}</p>
                        </> : ''}

                            <span>{t('Partner')}</span>
                            <p>{info.partnerEmail ? info.partnerEmail : '...'}</p>
                        </>}
                    {individual === false ? <>
                        <span>{t("Position")}</span>
                        <p>{info.position ? info.position : '...'}</p>
                        <span>{t("Legal Entinty")}</span>
                        <p>{info.nameOfLegalEntity ? info.nameOfLegalEntity : '...'}</p>
                        <span>{t("Legal Adress")}</span>
                        <p>{info.legalAddress ? info.legalAddress : '...'}</p>
                    </> : ''}
                    <span>{t("Bank Name")}</span>
                    <p>{info.bankName}</p>
                    <span>{t("Bic")}</span>
                    <p>{info.bic}</p>
                    <span>{t("INN")}</span>
                    <p>{info.inn}</p>
                    {individual === true ? <>
                        <span>{t("Type Individual Merchant")}</span>
                        <p>{info.typeIndividualMerchant}</p>  </> : ''}
                    <span>{t("Create Date")}</span>
                    <p>{date}</p>
                    {individual === true ?
                        <>
                            <p style={{marginTop: '20px', fontSize: '18px'}}>{t("Documnet")}</p>
                            <span>{t("Actual Address")}</span>
                            <p>{info.document.actualAddress}</p>
                            <span>{t("Address Of Registration")}</span>
                            <p>{info.document.addressOfRegistration}</p>
                            <span>{t("Date Of Issue")}</span>
                            <p>{info.document.dateOfIssue}</p>
                            <span>{t("Document Number")}</span>
                            <p>{info.document.documentNumber}</p>
                            <span>{t("Issue By")}</span>
                            <p>{info.document.issueBy}</p>
                            <span>{t("Issue Code")}</span>
                            <p>{info.document.issueCode}</p>
                            <span>{t("Series")}</span>
                            <p>{info.document.series}</p>
                        </>
                        : <>
                            <span>{t("NDS")}</span>
                            <p>{info.withNDS === true ? t("yes") : info.withNDS === false ? t('no') : '...'}</p>
                        </>}
                </div>
            </>}</Translation>
    )
}
