import React, {Component} from 'react';
import {connect} from "react-redux";
import CloseVector from "../../../assets/images/CloseVector.png";
import {ADDQuestion, closeCreateQuestionPage} from "../../../modules/Questions/AddQuestion/AddQuestionAction";
import {CloseQuestionsList} from "../../../modules/Questions/GetPopularQuestionAction";
import SaveButton from "../../../components/common/Buttons/SaveButton";
import CancelButton from "../../../components/common/Buttons/CancelButton";
import ErrMessage from "../../../components/common/ErrMessage";
import TextField from "@material-ui/core/TextField";
import {Translation} from "react-i18next";
import BigTextFieldS from "../../../components/common/Inputs/BigTextField";


const styles = {
  Container: {
    background: 'rgba(0, 0, 0, 0.4)',
    width: '100%',
    minHeight: '100vh',
    zIndex: '100',
    position: 'fixed',
    right: 0,
  },
  hideDiv: {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
    boxShadow: '-2px 0px 4px rgba(0, 0, 0, 0.2), -1px 0px 10px rgba(0, 0, 0, 0.12), -4px 0px 5px rgba(0, 0, 0, 0.14)',
    width: '550px',
    height: '100%',
    position: 'absolute',
    minHeight: '100vh',
    zIndex: '20',
    right: 0,
    boxSizing: 'border-box',
    padding:'20px'
  },
  ChangeDivCloseButton: {
    background: 'none',
    border: 'none',
  },
}
const Location = sessionStorage.getItem("QuestionLocal")

class AddQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: null,
      question: '',
      locationForTariff:'',
      errMessage:false
    }
  }
  componentWillReceiveProps(nextProps){
    this.setState({
      question : nextProps.EditStation.heading,
      answer : nextProps.EditStation.questions,
    })
  }
  handleChange = (ev) => {
    const {name, value} = ev.target;
    const newState = {};
    newState[name] = value;
    this.setState(newState);
    this.setState({errMessage:false})
  };

  handleChangeAnswer = (ev) => {
    this.setState({answer: ev.target.value})
  }
  handleChangeQuestion = (ev) => {
    this.setState({question: ev.target.value})
  }
  ChackboxHandlechange = (e) => {
    this.setState({locationForTariff: e.target.value})
  }

  handleClick = () => {
    const {locationForTariff,answer, question} = this.state
    if(locationForTariff && question && answer.length > 0 ) {
    this.props.ADDQuestion(locationForTariff, answer, question)
    this.props.closeCreateQuestionPage()
    }else {
      this.setState({errMessage:true})
    }
  }
  handleEditClick = () => {
    const answer = this.state.answer.length > 0 ? this.state.answer : this.props.EditStation.heading
    const question = this.state.question.length > 0 ? this.state.question : this.props.EditStation.questions
      this.props.ADDQuestion(Location, answer, question)
      this.props.CloseQuestionsList()
  }

  render() {
    const {createPageInfo} = this.props
    const EditStation = Object.values(this.props.EditStation)
    return (
      <>
        <Translation>
          {(t) =>
            <>
        {createPageInfo === true ?
          <div style={styles.Container}>
            <div onClick={() => {this.props.closeCreateQuestionPage() ||   this.setState({errMessage:true})}} className={'CloseSectionClassBigDiv'}  />
            <div style={styles.hideDiv}>
              <div style={{display: 'flex', alignItems: 'center', }}>
                <button onClick={() => {this.props.closeCreateQuestionPage() ||   this.setState({errMessage:true})}} style={styles.ChangeDivCloseButton}>
                  <img src={CloseVector} alt={CloseVector}/></button>
                <span style={{color: 'white',marginLeft:'15px'}}>{t("New Question")}</span>
              </div>
              <div style={{width: '500px',marginTop:'20px'}}>
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label={t("Language")}
                  InputLabelProps={{style:{color:'white',background:'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',padding:'0 10px 0 0 '},}}
                  inputProps={{ style: {outline:'none',width:'180px',fontFamily: 'Arial', color: 'white',border:'1px solid white',borderRadius: '5px'}}}
                  onChange={this.ChackboxHandlechange}
                  SelectProps={{native: true,}}
                  variant="outlined"
                >
                  <option style={{color: 'black'}} value={''}>{}</option>
                  <option style={{color: 'black'}} value={'RU'}>Russia</option>
                  <option style={{color: 'black'}} value={'ARM'}>English</option>
                </TextField>
                <BigTextFieldS style={{marginTop: '30px'}} label={t('Question')} name={'question'} onChange={this.handleChange}/>
                <BigTextFieldS style={{marginTop: '50px'}} label={t('Answer')} name={'answer'} onChange={this.handleChange}/>
              </div>
              {this.state.errMessage === true? <ErrMessage/>:''}
              <div style={{marginTop: '20px',}}>
                <CancelButton onClick={() => {this.props.closeCreateQuestionPage()   &&   this.setState({errMessage:false})}}>{t("CANCEL")}</CancelButton>
                <SaveButton onClick={this.handleClick}>{t("CREATE QUESTION")}</SaveButton>
              </div>
            </div>
          </div>
          : ''}
        {EditStation.length > 0 ?
          <div style={styles.Container}>
            <div onClick={() => {this.props.CloseQuestionsList()}} className={'CloseSectionClass'}  />
            <div style={styles.hideDiv}>
              <div style={{marginTop: '10px'}}>
                <button onClick={() => {this.props.CloseQuestionsList()}} style={styles.ChangeDivCloseButton}>
                  <img src={CloseVector} alt={CloseVector}/></button>
                <span style={{color: 'white',marginLeft:'15px'}}>{t("Change Question")}</span>
              </div>
              <div style={{ width: '500px'}}>
                <BigTextFieldS   label={t('Question')} onChange={this.handleChangeQuestion} value={this.state.question} style={{marginTop: '20px',}}/>
                <BigTextFieldS  label={t('Answer')}  onChange={this.handleChangeAnswer} value={this.state.answer} style={{marginTop: '40px',}}/>
              </div>
              <div style={{marginTop: '20px', display: 'flex'}}>
                <CancelButton onClick={() => {this.props.CloseQuestionsList()}}>{t("CANCEL")}</CancelButton>
                <SaveButton onClick={this.handleEditClick}>{t("SAVE CHANGES")}</SaveButton>
              </div>
            </div>
          </div>
          : ''}
      </>}</Translation>
      </>
    );
  }
}

const mapStateToProps = state => ({
  createPageInfo: state.AddQuestionReducer.createPageInfo,
  EditStation: state.GetPopularQuestionReducer.EditStation,
})

export default connect(mapStateToProps, {closeCreateQuestionPage, ADDQuestion, CloseQuestionsList})(AddQuestions);
