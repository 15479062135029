import {MERCHANTS_ACTION} from "../merchantes/MerchantesReducer";

export const GET_TRANSACTIONS={
  PAGE_INFO:"GET_TRANSACTIONS.PAGE_INFO",
  TRANSACTIONS_REQUEST:'GET_TRANSACTIONS.TRANSACTIONS_REQUEST',
  TRANSACTIONS_SUCCESS:'GET_TRANSACTIONS.TRANSACTIONS_SUCCESS',
  TRANSACTIONS_FAIL:'GET_TRANSACTIONS.TRANSACTIONS_FAIL',
  FILTER_TRANSACTIONS_REQUEST:'GET_TRANSACTIONS.FILTER_TRANSACTIONS_REQUEST',
  FILTER_TRANSACTIONS_FAIL:'GET_TRANSACTIONS.FILTER_TRANSACTIONS_FAIL',
  FILTER_TRANSACTIONS_LIST: 'GET_TRANSACTIONS.FILTER_TRANSACTIONS_LIST',
  FIND_TRANSACTION_BY_ID : "GET_TRANSACTIONS.FIND_TRANSACTION_BY_ID",
  FIND_TRANSACTION_BY_ID_FAIL : "GET_TRANSACTIONS.FIND_TRANSACTION_BY_ID_FAIL",

  // FIND_TRANSACTION_BY_RENT_ID_REQUEST :'GET_TRANSACTIONS.FIND_TRANSACTION_BY_RENT_ID_REQUEST',
  FIND_TRANSACTION_BY_RENT_ID :'GET_TRANSACTIONS.FIND_TRANSACTION_BY_RENT_ID',
  FIND_TRANSACTION_BY_RENT_ID_SUCCESS :'GET_TRANSACTIONS.FIND_TRANSACTION_BY_RENT_ID_SUCCESS',
  FIND_TRANSACTION_BY_RENT_ID_FAIL :'GET_TRANSACTIONS.FIND_TRANSACTION_BY_RENT_ID_FAIL',
  CLEAR_FIND_TRANSACTION_BY_RENT_ID :'GET_TRANSACTIONS.CLEAR_FIND_TRANSACTION_BY_RENT_ID',

  TRANSACTION_LIST_BY_ID: 'GET_TRANSACTIONS.TRANSACTION_LIST_BY_ID',
  CLEAR_FIND_TRANSACTIONS: 'GET_TRANSACTIONS.CLEAR_FIND_TRANSACTIONS'
}

const initialState={
   AllTransactions:[],
   PageInfo:[],
   AllTransactionsByRentID:[]
}

export default (state=initialState,action) => {
  switch (action.type) {
    case GET_TRANSACTIONS.CLEAR_FIND_TRANSACTION_BY_RENT_ID:{
      return{...state,AllTransactionsByRentID:[]}
    }
    case GET_TRANSACTIONS.TRANSACTIONS_REQUEST:{
      return{...state,AllTransactions:initialState.AllTransactions}
    }
    case GET_TRANSACTIONS.TRANSACTIONS_SUCCESS:{
      return {...state,AllTransactions:action.payload}
    }
    case GET_TRANSACTIONS.PAGE_INFO:{
      return {...state,PageInfo:action.payload.data}
    }
    case GET_TRANSACTIONS.TRANSACTION_LIST_BY_ID:{
      return {...state,AllTransactions: action.payload}
    }
    case GET_TRANSACTIONS.FIND_TRANSACTION_BY_ID_FAIL:{
      return {...state,AllTransactions:'not found'}
    }


    case GET_TRANSACTIONS.FIND_TRANSACTION_BY_RENT_ID_SUCCESS:{
      return {...state,AllTransactionsByRentID: action.payload.data}
    }
    case GET_TRANSACTIONS.FIND_TRANSACTION_BY_RENT_ID_FAIL:{
      return {...state,AllTransactionsByRentID:'not found'}
    }


    case GET_TRANSACTIONS.TRANSACTIONS_FAIL:{
      return {...state,AllTransactions:'not found',AllTransactionsByRentID:'not found'}
      // return {...state,AllTransactions:initialState.AllTransactions}
    }
    case GET_TRANSACTIONS.FILTER_TRANSACTIONS_REQUEST:{
      return {...state,AllTransactions:action.payload}
    }
    case GET_TRANSACTIONS.FILTER_TRANSACTIONS_FAIL:{
      return {...state,AllTransactions:'not found'}
    }
    case GET_TRANSACTIONS.CLEAR_FIND_TRANSACTIONS:{
      return {...state,AllTransactions:{}}
    }
    default:{
      return state
    }
  }

}
