import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ElementWithDetails from "./ElementWithDetails";
import PhotoCard from "./PhotoCard";

const PhotoGalleryWithDetails = ({ controlData }) => {
  const { t } = useTranslation();
  const [isShowDetails, setIsShowDetails] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(null);

  const handleCardClick = (index) => {
    setSelectedCardId(index);
    setIsShowDetails(true);
  };

  const cleanUp = () => {
    setSelectedCardId(null);
    setIsShowDetails(false);
  };

  const getCurrentElement = () => {
    let {
      created,
      status = t("default.empty"),
      links,
      description,
    } = controlData[selectedCardId];

    return {
      createdAt: created,
      status,
      links,
      description,
    };
  };

  return (
    <>
      {!isShowDetails && selectedCardId === null ? (
        controlData.map(({ created, status, links }, index) => {
          return (
            <PhotoCard
              createdAt={created}
              status={status}
              image={links[0]}
              onCardClick={handleCardClick.bind({}, index)}
            />
          );
        })
      ) : (
        <ElementWithDetails {...getCurrentElement()} onGoBack={cleanUp} />
      )}
    </>
  );
};

export default PhotoGalleryWithDetails;
