import React from "react";
import {Translation} from "react-i18next";

export const DebitCredit=({debit,credit})=>{
  return(
    <Translation>
      {(t) =>
        <>
    <div style={{display:'flex',alignItems: 'baseline', marginRight: '40px',marginTop:'4px',height:'30px'}}>
      <div style={{display: 'flex'}}>
        <p style={{color: 'white',fontSize:'16px',margin:0}}>{debit > 0 ? t("Debit") + ' - ' : ''}</p>
        <p style={{color: 'rgba(101, 227, 42, 0.87)',marginTop:'2px'}}>{debit >0 ? `${debit} ₽` : ''}</p>
      </div>
      <div style={{display: 'flex',marginTop:'10px',marginLeft:'15px'}}>
        <p style={{color: 'white',fontSize:'16px',margin:0}}>{credit > 0 ? t("Credit") + ' - ': ''}</p>
        <p style={{color: '#EB5B47',marginTop:'2px'}}>{credit >0 ? `${credit} ₽` : ''}</p>
      </div>
    </div>
        </>
      }
    </Translation>
  )
}