export const MERCHANTS_ACTION = {
    OPEN_INFO: 'MERCHANTS_ACTION.OPEN_INFO',
    CLOSE_INFO: 'MERCHANTS_ACTION.CLOSE_INFO',
    OPEN_INDIVIDUAL_INFO: 'MERCHANTS_ACTION.OPEN_INDIVIDUAL_INFO',
    CLOSE_INDIVIDUAL_INFO: 'MERCHANTS_ACTION.CLOSE_INDIVIDUAL_INFO',
    OPEN_PAGE: 'MERCHANTS_ACTION.OPEN_PAGE',
    CLOSE_PAGE: 'MERCHANTS_ACTION.CLOSE_PAGE',
    GET_MERCHANTS_REQUEST: 'MERCHANTS_ACTION.GET_MERCHANTS_REQUEST',
    GET_MERCHANTS_REQUEST_ALL: 'MERCHANTS_ACTION.GET_MERCHANTS_REQUEST_ALL',
    GET_MERCHANTS_SUCCESS: 'MERCHANTS_ACTION.GET_MERCHANTS_SUCCESS',
    GET_MERCHANTS_FAIL: 'MERCHANTS_ACTION.GET_MERCHANTS_FAIL',
    BLOCK_MERCHANT_REQUEST: 'MERCHANTS_ACTION.BLOCK_MERCHANT_REQUEST',
    BLOCK_ONE_MERCHANT: 'MERCHANTS_ACTION.BLOCK_ONE_MERCHANT',


    OPEN_BLOCK_MERCHANT: 'MERCHANTS_ACTION.OPEN_BLOCK_MERCHANT',
    CLOSE_BLOCK_MERCHANT: 'MERCHANTS_ACTION.CLOSE_BLOCK_MERCHANT',
    BLOCK_ADMIN: 'ADMINS_ACTION.BLOCK_ADMIN',
    CHANGE_MERCHANT_EMAIL: 'MERCHANTS_ACTION.CHANGE_MERCHANT_EMAIL',
    CHANGE_MERCHANT_EMAIL_ERR: 'MERCHANTS_ACTION.CHANGE_MERCHANT_EMAIL_ERR',

    GET_MERCHANTS_REG_REQUEST: 'MERCHANTS_ACTION.GET_MERCHANTS_REG_REQUEST',
    GET_MERCHANTS_REG_SUCCESS: 'MERCHANTS_ACTION.GET_MERCHANTS_REG_SUCCESS',
    GET_MERCHANTS_REG_FAIL: 'MERCHANTS_ACTION.GET_MERCHANTS_REG_FAIL',


    GET_MERCHANTS_REG_FULLINFO_REQUEST: 'MERCHANTS_ACTION.GET_MERCHANTS_REG_FULLINFO_REQUEST',
    GET_MERCHANTS_REG_FULLINFO_SUCCESS: 'MERCHANTS_ACTION.GET_MERCHANTS_REG_FULLINFO_SUCCESS',
    GET_MERCHANTS_REG_FULLINFO_FAIL: 'MERCHANTS_ACTION.GET_MERCHANTS_REG_FULLINFO_FAIL',
    CLEAR_MERCHANTS_REG_FULLINFO_REQUEST: ':MERCHANTS_ACTION.CLEAR_MERCHANTS_REG_FULLINFO_REQUEST',

    SENT_REPORT_REQUEST: 'MERCHANTS_ACTION.SENT_REPORT_REQUEST',
    SENT_REPORT_SUCCESS: 'MERCHANTS_ACTION.SENT_REPORT_SUCCESS',
}

const initialState = {
    Merchants: [],
    OpenMerchantsAddPage: '',
    MerchantsInfo: [],
    MerchantsInfo2: [],
    MerchantsIndividualInfo: [],
    openBlockPage: [],
    ChangeEmail: [],
    MerchantsEmailErr: [],
    regMerchant: [],
    regMerchantFullInfo: [],
    DashboardInfo: {},
    PowerbanksStatusesSortedByMerchant: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case MERCHANTS_ACTION.GET_MERCHANTS_REQUEST: {
            return {...state, Merchants: initialState.Merchants}
        }
        case MERCHANTS_ACTION.GET_MERCHANTS_REQUEST_ALL: {
            return {...state, Merchants: initialState.Merchants}
        }
        case MERCHANTS_ACTION.GET_MERCHANTS_SUCCESS: {
            return {...state, Merchants: action.payload.data.reverse()}
        }

        case MERCHANTS_ACTION.GET_MERCHANTS_REG_REQUEST: {
            return {...state, regMerchant: initialState.regMerchant}
        }
        case MERCHANTS_ACTION.GET_MERCHANTS_REG_SUCCESS: {
            return {...state, regMerchant: action.payload.data}
        }
        case MERCHANTS_ACTION.GET_MERCHANTS_REG_FAIL: {
            return {...state, regMerchant: initialState.regMerchant}
        }

        case MERCHANTS_ACTION.GET_MERCHANTS_REG_FULLINFO_REQUEST: {
            return {...state, regMerchantFullInfo: initialState.regMerchantFullInfo}
        }
        case MERCHANTS_ACTION.GET_MERCHANTS_REG_FULLINFO_SUCCESS: {
            return {...state, regMerchantFullInfo: action.payload.data}
        }
        case MERCHANTS_ACTION.GET_MERCHANTS_REG_FULLINFO_FAIL: {
            return {...state, regMerchantFullInfo: initialState.regMerchantFullInfo}
        }
        case MERCHANTS_ACTION.CLEAR_MERCHANTS_REG_FULLINFO_REQUEST: {
            return {...state, regMerchantFullInfo: ''}
        }


        case MERCHANTS_ACTION.OPEN_INFO: {
            return {...state, MerchantsInfo: action.payload.data}
        }
        case MERCHANTS_ACTION.OPEN_INDIVIDUAL_INFO: {
            return {...state, MerchantsIndividualInfo: action.payload.data}
        }
        case MERCHANTS_ACTION.OPEN_BLOCK_MERCHANT: {
            return {...state, openBlockPage: action.payload.data}
        }
        case MERCHANTS_ACTION.CLOSE_BLOCK_MERCHANT: {
            return {...state, openBlockPage: []}
        }
        case MERCHANTS_ACTION.GET_MERCHANTS_FAIL: {
            return {...state, Merchants: initialState.Merchants}
        }
        case MERCHANTS_ACTION.OPEN_PAGE: {
            return {...state, OpenMerchantsAddPage: action.payload.data}
        }
        case MERCHANTS_ACTION.CLOSE_INFO: {
            return {...state, MerchantsInfo: []}
        }
        case MERCHANTS_ACTION.CLOSE_INDIVIDUAL_INFO: {
            return {...state, MerchantsIndividualInfo: []}
        }
        case MERCHANTS_ACTION.CLOSE_PAGE: {
            return {...state, OpenMerchantsAddPage: ""}
        }
        case MERCHANTS_ACTION.CHANGE_MERCHANT_EMAIL_ERR: {
            return {...state, MerchantsEmailErr: action.payload.data}
        }
        case MERCHANTS_ACTION.BLOCK_ONE_MERCHANT:
            const newActiveUserList = state.Merchants.map(item => {
                if (item.email === action.payload.email) {
                    return {...item, blocking: action.payload.lock}
                }
                return item
            })
            return {...state, Merchants: newActiveUserList}

        default: {
            return state
        }

    }

}
