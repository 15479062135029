import {STATES} from "../stationsReducer";

export const TAKE_BANK ={
  TAKE_BANK_REQUEST:'TAKE_BANK.TAKE_BANK_REQUEST',
  TAKE_BANK_SUCCESS:'TAKE_BANK.TAKE_BANK_SUCCESS',
  TAKE_BANK_FAIL:'TAKE_BANK.TAKE_BANK_FAIL',
  CLOSE_POPUP_EXTRACT_BANK: 'TALE_BANK.CLOSE_POPUP_EXTRACT_BANK'
}

const initialState ={
  TakeBankData:[],
  failRequest:''
}


export default (state=initialState,action)=>{
  switch (action.type) {
    case TAKE_BANK.TAKE_BANK_REQUEST:{
      return{...state,TakeBankData:initialState.TakeBankData}
    }
    case TAKE_BANK.TAKE_BANK_SUCCESS:{
      return{...state,TakeBankData:action.payload.data}
    }
    case TAKE_BANK.TAKE_BANK_FAIL:{
      return{...state,TakeBankData:initialState.TakeBankData, failRequest: action.message}
    }
    case TAKE_BANK.CLOSE_POPUP_EXTRACT_BANK:{
      return{...state,failRequest: ''}
    }

    default:{
      return state
    }

  }
}