import {COMMENTS} from "./CommentsReducer";

// export const GetComments =(name)=>{
//   return{
//     type:COMMENTS.GET_COMMENTS_REQUEST,
//     payload:{name}
//   }
// }
export const sendCommentAction =(text,name,id)=>{
  return{
    type:COMMENTS.SET_COMMENTS_REQUEST,
    payload:{text,name,id}
  }
}