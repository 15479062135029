import Button from "@material-ui/core/Button";
import React from "react";

const styles ={
  buttonDefaultStyle: {
    color: 'white',
    background: '#5F94E2',
    borderRadius: '5px',
    border: '1px solid #5F94E2',
    padding: '8px',
    marginLeft: '5px'
  }
};



const SaveButton = ({onClick, style={}, children, disabled, ...props}) => {
return(
    <Button
      onClick={onClick}
      style={ {...styles.buttonDefaultStyle, ...style, opacity: disabled ? 1 : 1 }}
      {...props}
      >
        {children}
    </Button>
  )
};

export default SaveButton
