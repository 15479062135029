export const RENTS_ACTIONS = {
  RENTS_REQUEST: 'RENTS_ACTIONS.RENTS_REQUEST',
  RENTS_SUCCESS: 'RENTS_ACTIONS.RENTS_SUCCESS',
  PAGES_INFO: 'RENTS_ACTIONS.PAGES_INFO',
  RENTS_FAIL: 'RENTS_ACTIONS.RENTS_FAIL',
  // CLEAR_ACTIVE_RENTS:'RENTS_ACTIONS.CLEAR_ACTIVE_RENTS',
  // FILTER_RENTS_REQUEST:'RENTS_ACTIONS.FILTER_RENTS_REQUEST',
  // FILTER_RENTS_SUCCESS:'RENTS_ACTIONS.FILTER_RENTS_SUCCESS',
  FILTER_RENTS_LIST_REQUEST:'RENTS_ACTIONS.FILTER_RENTS_LIST_REQUEST',
  FILTER_ACTIVE_RENTS:'RENTS_ACTIONS.FILTER_ACTIVE_RENTS',
  FILTER_BLOCKED_RENTS:'RENTS_ACTIONS.FILTER_BLOCKED_RENTS',
  FINDING_RENTS_REQUEST:'RENTS_ACTIONS.FINDING_RENTS_REQUEST',
  FINDING_RENTS_SUCCESS:'RENTS_ACTIONS.FINDING_RENTS_SUCCESS',
  FINDING_RENTS_FAIL:'RENTS_ACTIONS.FINDING_RENTS_FAIL',
  DEBIT_INFO :'RENTS_ACTIONS.DEBIT_INFO',
  CLEAR_FINDING_RENT_INFO:'RENTS_ACTIONS.CLEAR_FINDING_RENT_INFO',
  CLEAR_DEBIT_INFO: 'RENTS_ACTIONS.CLEAR_DEBIT_INFO',
  FILTER_ACTIVE_RENTS_REQUEST:'RENTS_ACTIONS.FILTER_ACTIVE_RENTS_REQUEST',
  FILTER_ACTIVE_RENTS_SUCCESS:'RENTS_ACTIONS.FILTER_ACTIVE_RENTS_SUCCESS',



  CLEAR_INFO_FOR_LOADER:'RENTS_ACTIONS.CLEAR_INFO_FOR_LOADER'
}

const initialState={
  AllRents:[],
  FullRents:[],
  ActiveRents:[],
  BlockedRents:[],
  filterRents:[],
  PageInfo:[],
  DebitInfo:[]
}

export default (state=initialState,action)=>{

  switch (action.type) {
    case RENTS_ACTIONS.CLEAR_INFO_FOR_LOADER:{
      return{...state,AllRents:[],FullRents:[]}
    }


    case RENTS_ACTIONS.RENTS_REQUEST:{
      return{...state,AllRents:initialState.AllRents}
    }
    case RENTS_ACTIONS.RENTS_SUCCESS:{
      return {...state,AllRents:action.payload.data,FullRents:action.payload.data}
    }
    case RENTS_ACTIONS.FILTER_ACTIVE_RENTS_SUCCESS :{
      return {...state,AllRents:action.payload.data,FullRents:action.payload.data}
    }
    case RENTS_ACTIONS.CLEAR_FINDING_RENT_INFO:{
      return {...state,AllRents:[]}
    }
    case RENTS_ACTIONS.CLEAR_DEBIT_INFO:{
      return {...state,DebitInfo:[]}
    }
    case RENTS_ACTIONS.PAGES_INFO:{
      return {...state,PageInfo:action.payload.data}
    }
    case RENTS_ACTIONS.RENTS_FAIL:{
      return {...state,AllRents:'not found'}
      // return {...state,AllRents:initialState.AllRents}
    }
    // case RENTS_ACTIONS.CLEAR_ACTIVE_RENTS:{
    //   return {...state,ActiveRents:[]}
    // }
    // case RENTS_ACTIONS.BLOCKED:{
    //   return {...state,AllRents:action.payload.data}
    // }
    // case RENTS_ACTIONS.CLEAR_RENTS_REQUEST:{
    //   return {...state,BlockedRents:action.payload.data}
    // }
    // case RENTS_ACTIONS.FILTER_RENTS_SUCCESS:{
    //   return {...state,AllRents:action.payload.data}
    // }




    case RENTS_ACTIONS.FILTER_ACTIVE_RENTS:
      const ActiveStationList=state.FullRents.map(item=> {
        if (item.rentStatus === 'PROCESSING') {
          return item
        }
      })
      return {...state,AllRents:ActiveStationList}

    case RENTS_ACTIONS.FILTER_BLOCKED_RENTS:
      const BlockedStationList=state.FullRents.map(item=> {
        if (item.rentStatus === 'FINISHED') {
          return item
        }
      })
      return {...state,AllRents:BlockedStationList}

    // case RENTS_ACTIONS.FINDING_RENTS_REQUEST:{
    //   return {...state,AllRents:initialState.AllRents}
    // }
    case RENTS_ACTIONS.FINDING_RENTS_SUCCESS:{
       return {...state,AllRents: Array.isArray(action.payload.data) === true ?
                 action.payload.data[0].rent ?
                   action.payload.data[0].rent.reverse()  :  action.payload.data.reverse() :
           action.payload.data.rent ?
             action.payload.data.rent
             :
            action.payload.data.reverse()
       }
    }
    case RENTS_ACTIONS.FINDING_RENTS_FAIL:{
      return {...state,AllRents:'not found'}
    }

    case RENTS_ACTIONS.DEBIT_INFO:{
      return {...state,DebitInfo:action.payload.data}
    }




    default:{
      return state
    }

  }

}
