import React, { Component } from 'react';
import SignIn from "./views/SignIn";
import './assets/styles/style.css';
class  App extends Component {

  render() {
    return (
      <div>
          <SignIn/>
      </div>
    );
  }
}


export default App

