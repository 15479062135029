import React, {Component} from 'react';
import logo from "../../assets/images/logo.png";
import Button from "@material-ui/core/Button";
import RegTextField from "../../components/common/Inputs/RegTextField";
import {connect} from "react-redux";
import {
    merchantReg,
    RegIndividualMerchant,
    RemoveStatus
} from "../../modules/merchantes/AddMerchants/AddMerchantsAction";
import {Link} from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import PhoneNUmber from "../../components/common/Inputs/PhoneNUmber";
import "react-dadata-suggestions/dist/styles.css";
import {Translation} from "react-i18next";
import LanguagesDropdown from '../../components/common/languages'
import DateOfIssueTextField from "../../components/common/Inputs/DateOfIssueTextField";
import OnlyNumbers from "../../components/common/Inputs/OnlyNumbers";
import {DaDataRequestCom} from "../../components/common/Inputs/DaDataRequestCom";
import {Hinit} from "../../components/common/Hinit";
import {OnlineCass} from "../../components/common/OnlineCass";
import MerchantReg from "../MerchantReg";

const styles = {
    head: {
        overflow: 'auto',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        background: '#121212',
        position: 'fixed',
        zIndex: 9999
    },
    logoStyle: {
        textAlign: 'center',
        marginTop: '20px'
    },
    buttons: {
        textAlign: 'center',
        marginTop: '20px'
    },
    LogInButton: {
        color: 'white',
        background: '#5F94E2',
        border: '1px solid #5F94E2',
        borderRadius: '5px',
        padding: '5px',
        alignSelf: 'center',
        marginLeft: '10px',
    },
    LogInButtonPassive: {
        marginLeft: '10px',
        border: '1px solid #5F94E2',
        padding: '5px',
        borderRadius: '5px',
        color: '#5F94E2'
    },
    textStyle: {
        fontFamily: 'Arial',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '20px',
        lineHeight: '36px',
        color: 'rgba(255, 255, 255, 0.87)',
        marginTop: '20px'
    },


    CanclePopup: {
        margin: 'auto',
        width: '330px',
        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #121212',
        boxShadow: '0px 11px 15px rgba(0, 0, 0, 0.2), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 24px 38px rgba(0, 0, 0, 0.14)',
        borderRadius: '8px',
        padding: '15px'
    },
    CanclePopupButtons: {
        color: '#5F94E2',
        border: 'none',
        background: 'none'
    },
    headDiv: {
        height: '100vh', position: 'fixed', width: '100%'
    },
    licenseButton: {
        color: 'white',
    },
    SaveButton: {
        marginTop: '20px',
        marginBottom: '10px',
        color: 'white',
        background: '#5F94E2',
        borderRadius: '5px',
        border: '1px solid #5F94E2',
        padding: '8px',
        marginLeft: '5px'
    },
    SaveButtonPassive: {
        marginTop: '20px',
        marginBottom: '10px',
        color: 'white',
        background: '#dddddd',
        borderRadius: '5px',
        border: '1px solid #dddddd',
        padding: '8px',
        marginLeft: '5px'
    }

}
let token = "d395d2fe1619a04a7f414de8d730b54a1fccf9a2";

class PartnerReg extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeButton: 'Organization',
            errMessage: false,
            Agree: false,

            // emailValid:false,
            position: '',
            name: '',
            email: '',
            phoneNumber: '',
            NotCorrectEmail: false,
            inn: '',
            bankName: "",
            bic: '',
            innName: '',
            legalAddress: '',
            nameOfLegalEntity: '',
            innNameSS: '',
            accountNumber: '',
            middleName: '',
            SERIES: '',
            documentNumber: '',
            emailValid: false,
            dateOfIssue: '',
            surname: '',
            addressOfRegistration: '',
            actualAddress: '',
            issueBy: '',
            issueCode: '',
            CheckboxCheck: false,
            reserveInn: '',
            nameForChangeNameBy: '',
            shopId: '',
            someInfo: [],
            bankInfo: [],
            bicInfo: [],
            innInfo: [],
            nameOfLegalEntityInfo: [],
            legalAddressInfo: [],
            nameInfo: [],
            surnameInfo: [],
            middleNameInfo: [],
            actualAddressInfo: [],
            issueCodeInfo: [],
            onlineCheckout: false
        }
    }

    handleIndividual = (name) => {
        this.setState({
            emailValid: false,
            errMessage: false,
            Agree: false,
            onlineCheckout: false,
            accountNumber: '',
            bankName: '',
            bic: '',
            email: '',
            inn: '',
            legalAddress: '',
            middleName: '',
            name: '',
            shopId: '',
            nameOfLegalEntity: '',
            pass: '',
            phoneNumber: '',
            position: '',
            surname: '',
            innNameSS: '',
            innName: '',
            NAME: '',
            SERIES: ''
        })
        this.setState({activeButton: name === 'Organization' ? 'Organization' : 'Self'})

    }

    handleOnlyNumber = (ev) => {
        const {name} = ev.target;
        if (name === 'bic') {
            const val = ev.target.value;
            if (ev.target.validity.valid) {
                this.setState({bic: ev.target.value});
            } else if (val === '') {
                this.setState({bic: val})
            }
        }
        if (name === 'accountNumber') {
            const val = ev.target.value;
            if (ev.target.validity.valid) {
                this.setState({accountNumber: ev.target.value});
            } else if (val === '') {
                this.setState({accountNumber: val})
            }
        }
        if (name === 'SERIES') {
            const val = ev.target.value;
            if (ev.target.validity.valid) {
                this.setState({SERIES: ev.target.value});
            } else if (val === '') {
                this.setState({SERIES: val})
            }
        }
        if (name === 'documentNumber') {
            const val = ev.target.value;
            if (ev.target.validity.valid) {
                this.setState({documentNumber: ev.target.value});
            } else if (val === '') {
                this.setState({documentNumber: val})
            }
        }
        // if (name === 'documentNumber') {
        //   const val = ev.target.value;
        //   if (ev.target.validity.valid) {
        //     this.setState({documentNumber: ev.target.value});
        //   } else if (val === '') {
        //     this.setState({documentNumber: val});
        //   }
        // }
        if (name === 'shopId') {
            const val = ev.target.value;
            if (ev.target.validity.valid) {
                this.setState({shopId: ev.target.value});
            } else if (val === '') {
                this.setState({shopId: val});
            }
        }

    }


    handleChanges = (ev) => {
        const {name, value} = ev.target;

        if (name === 'position') {
            const val = ev.target.value;
            if (ev.target.validity.valid) {
                this.setState({position: ev.target.value});
            } else if (val === '') {
                this.setState({position: val});
            }
        } else {
            const newState = {};
            newState[name] = value;
            this.setState(newState);
            this.setState({errMessage: false})
        }
    };

    handleReload = () => {
        this.props.RemoveStatus()
        window.location = "/";
    }

    sentRequest = () => {
        const {
            accountNumber,
            bankName,
            bic,
            email,
            inn,
            legalAddress,
            middleName,
            name,
            nameOfLegalEntity,
            pass,
            phoneNumber,
            position,
            surname,
            shopId,
            onlineCheckout
        } = this.state
        const data = [accountNumber, bankName, bic, email, inn, legalAddress, middleName, name, nameOfLegalEntity, pass, phoneNumber, position, surname, '', '',
            shopId, onlineCheckout
        ]
        if (accountNumber && bankName && bic && email && inn && legalAddress && middleName && name && nameOfLegalEntity && pass && phoneNumber && position && surname
            && shopId
        ) {
            if (this.state.emailValid === false) {
                this.props.merchantReg(data)
            }
        } else {
            this.setState({
                errMessage: true
            })
        }
    }

    sentIndividualRequest = () => {
        const {
            name,
            surname,
            middleName,
            inn,
            email,
            phoneNumber,
            pass,
            bankName,
            bic,
            accountNumber,
            SERIES,
            documentNumber,
            dateOfIssue,
            addressOfRegistration,
            actualAddress,
            issueBy,
            issueCode
            ,
            shopId,
            onlineCheckout
        } = this.state
        const datas = [name, surname, middleName, inn, email, phoneNumber, pass, bankName, bic, accountNumber, SERIES, documentNumber, dateOfIssue, addressOfRegistration, actualAddress, issueBy, issueCode, '', '',
            shopId, onlineCheckout
        ]
        if (name && surname && middleName && inn && email && phoneNumber && pass && bankName && bic && accountNumber && SERIES && documentNumber && dateOfIssue && addressOfRegistration && actualAddress && issueBy && issueCode
            && shopId
        ) {
            if (this.state.emailValid === false) {
                this.props.RegIndividualMerchant(datas)
            }
        } else {
            this.setState({errMessage: true})
        }
    }
    handleChangesEmail = (e) => {
        this.setState({email: e.target.value})
    }
    handleChangeDateOfIssue = (e) => {
        this.setState({dateOfIssue: e.target.value})
    }
    handleChangePhoneNumber = (e) => {
        const val = e.target.value;
        if (e.target.validity.valid) {
            if (!this.state.phoneNumber.length) {
                if (val !== '+') {
                    if (val === '8') {
                        this.setState({phoneNumber: `+7`});
                    } else if (val === '9') {
                        this.setState({phoneNumber: `+79`});
                    } else {
                        this.setState({phoneNumber: `+${e.target.value}`});
                    }
                } else {
                    this.setState({phoneNumber: `+`});
                }
            } else {
                if (val.slice(1, 2) === '8') {
                    this.setState({phoneNumber: `+7`});
                } else if (val.slice(1, 2) === '9') {
                    this.setState({phoneNumber: `+79`});
                } else {
                    this.setState({phoneNumber: e.target.value});
                }
            }

        } else if (val === '') {
            // this.setState({phoneNumber: val});
        }
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.innName) {
            const info = this.state.innName
            if (!this.state.innNameSS) {
                this.setState({innNameSS: info})
            }
        }
    }

    componentDidMount() {
        this.setState({
            inn: '',
            addressOfRegistration: '',
            actualAddress: '',
            bankName: '',
            bic: '',
            nameOfLegalEntity: '',
            legalAddress: '',
            name: '',
            surname: '',
            middleName: ''
        })
        sessionStorage.clear();
    }

    handleChangeAddress = () => {

        if (this.state.CheckboxCheck === false) {
            let reserveAddressOfRegistration = sessionStorage.getItem('addressOfRegistration')
            let addressOfRegistration = this.state.addressOfRegistration ? this.state.addressOfRegistration : reserveAddressOfRegistration
            this.setState({actualAddress: addressOfRegistration, CheckboxCheck: true})
        } else {
            this.setState({actualAddress: '', CheckboxCheck: false})
        }
    }

    chechEmailValid = (e) => {
        let Value = e.target.value
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (Value.length >= 1) {
            if (re.test(Value)) {
                this.setState({emailValid: false})
            } else {
                this.setState({emailValid: true})
            }
        }
    }


    changeIssueBy = (item) => {
        this.setState({issueBy: item.unrestricted_value, issueCode: item.data.code, someInfo: ''})
    }

    changeBankBy = (item) => {
        this.setState({bankName: item.unrestricted_value, bic: item.data.bic, bankInfo: '', bicInfo: ''})
    }
    changeInnBy = (e) => {
        this.setState({
            position: e.data.management ? e.data.management.post : '',
            innNameSS: '', nameOfLegalEntity: e.value,
            innName: e.data.inn, inn: e.data.inn,
            legalAddress: e.data.address.unrestricted_value,
            innInfo: '',
            nameOfLegalEntityInfo: ''
        })
    }
    changeInnByIndi = (e) => {
        this.setState({
            innNameSS: '', nameOfLegalEntity: e.value,
            innName: e.data.inn, inn: e.data.inn,
            innInfo: '', nameOfLegalEntityInfo: ''
        })
    }
    changeLegalAddressBy = (e) => {
        this.setState({legalAddress: e.unrestricted_value, addressOfRegistration: e.unrestricted_value})
    }
    changeActualAddress = (e) => {
        this.setState({actualAddress: e.unrestricted_value})
    }
    changeMiddleName = (e) => {
        this.setState({middleName: e.unrestricted_value, middleNameInfo: ''})
    }
    changeNameBy = (e) => {
        if (e.data.name && e.data.surname && e.data.patronymic) {
            this.setState({
                name: e.data.name, surname: e.data.surname, middleName: e.data.patronymic, nameInfo: '', surnameInfo: ''
            })
        } else if (e.data.name && e.data.surname) {
            this.setState({name: e.data.name, surname: e.data.surname, nameInfo: '', surnameInfo: ''})
        } else if (e.data.name && e.data.patronymic) {
            this.setState({name: e.data.name, middleName: e.data.patronymic, nameInfo: '', surnameInfo: ''})
        } else if (e.data.name) {
            this.setState({name: e.data.name, nameInfo: ''})
        } else if (e.data.surname) {
            this.setState({surname: e.data.surname, surnameInfo: ''})
        }
    }


    NameDaDataRequest = (ev) => {
        const val = ev.target.value;
        let name = ev.target.name
        if (name === 'name') {
            if (ev.target.validity.valid) {
                this.setState({name: ev.target.value});
            }
            // else if (val === '') {
            // this.setState({name: val})
            // }
        }
        if (name === 'surname') {
            if (ev.target.validity.valid) {
                this.setState({surname: ev.target.value});
            } else if (val === '') {
                // this.setState({surname: val})
            }
            // this.setState({surname: ev.target.value})
        }
        if (name === 'middleName') {
            if (ev.target.validity.valid) {
                this.setState({middleName: ev.target.value});
            } else if (val === '') {
                // this.setState({middleName: val})
            }
            // this.setState({middleName:ev.target.value})
        }


        let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio";
        let query = ev.target.value;
        let options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + token
            },
            body: JSON.stringify({query: query})
        }
        fetch(url, options)
            .then(response => response.json())
            .then(result => this.setState(
                name === 'name' ?
                    {nameInfo: result.suggestions} :
                    name === 'surname' ?
                        {surnameInfo: result.suggestions} :
                        name === 'middleName' ?
                            {middleNameInfo: result.suggestions} : ''
            ))
            .catch(error => console.log("error", error))
    }


    legalAddressDaDataRequest = (ev) => {
        if (ev.target.name === 'actualAddress') {
            this.setState({actualAddress: ev.target.value})

        } else {
            this.setState({legalAddress: ev.target.value, addressOfRegistration: ev.target.value})
        }
        let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
        let query = ev.target.value;
        let options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + token
            },
            body: JSON.stringify({query: query})
        }
        ev.target.name === 'actualAddress' ?
            fetch(url, options)
                .then(response => response.json())
                .then(result => this.setState({actualAddressInfo: result.suggestions}))
                .catch(error => console.log("error", error))
            :
            fetch(url, options)
                .then(response => response.json())
                .then(result => this.setState({legalAddressInfo: result.suggestions}))
                .catch(error => console.log("error", error))
    }


    InnDaDataRequest = (ev) => {

        let name = ev.target.name
        if (name === 'nameOfLegalEntity') {
            this.setState({nameOfLegalEntity: ev.target.value})
        }
        if (name === 'Inn') {
            this.setState({inn: ev.target.value})
        }
        let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party";
        let query = ev.target.value;
        let options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + token
            },
            body: JSON.stringify({query: query})
        }
        ev.target.name === 'Inn' ?
            fetch(url, options)
                .then(response => response.json())
                .then(result => this.setState({innInfo: result.suggestions}))
                .catch(error => console.log("error", error))
            :
            fetch(url, options)
                .then(response => response.json())
                .then(result => this.setState({nameOfLegalEntityInfo: result.suggestions}))
                .catch(error => console.log("error", error))
    }


    bankDaDataRequest = (ev) => {
        if (ev.target.name === 'bankName') {
            this.setState({bankName: ev.target.value})
            let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/bank";
            let query = ev.target.value;
            let options = {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Token " + token
                },
                body: JSON.stringify({query: query})
            }
            fetch(url, options)
                .then(response => response.json())
                .then(result => this.setState({bankInfo: result.suggestions}))
                .catch(error => console.log("error", error))
        }

        if (ev.target.name === 'bic') {
            const val = ev.target.value;
            if (ev.target.validity.valid) {
                let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/bank";
                let query = ev.target.value;
                let options = {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Token " + token
                    },
                    body: JSON.stringify({query: query})
                }
                fetch(url, options)
                    .then(response => response.json())
                    .then(result => this.setState({bicInfo: result.suggestions}))
                    .catch(error => console.log("error", error))

                this.setState({bic: ev.target.value});
            } else if (val === '') {
                this.setState({bic: val});
            }

        }

    }


    someTry = (ev) => {
        if (ev.target.name === 'issueCode') {
            this.setState({issueCode: ev.target.value})
        } else {
            this.setState({issueBy: ev.target.value})
        }
        let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fms_unit";
        let query = ev.target.value

        let options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + token
            },
            body: JSON.stringify({query: query})
        }
        ev.target.name === 'issueCode' ?
            fetch(url, options)
                .then(response => response.json())
                .then(result => this.setState({issueCodeInfo: result.suggestions}))
                .catch(error => console.log("error", error))
            :
            fetch(url, options)
                .then(response => response.json())
                .then(result => this.setState({someInfo: result.suggestions}))
                .catch(error => console.log("error", error));
    }

    render() {
        const {activeButton} = this.state

        return (
            <div className={'MerchantReg'}>
                <Translation>
                    {(t) =>
                        <>
                            <div style={styles.head}>
                                {this.props.status === '200' ?
                                    <div style={styles.headDiv}>
                                        <div style={{
                                            textAlign: 'center',
                                            alignItems: 'center',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            height: '100%'
                                        }}>
                                            <div style={styles.CanclePopup}>
                                                <span style={{color: 'white'}}>{t("Request sent")}</span>
                                                <div style={{marginLeft: '250px'}}>
                                                    <button style={styles.CanclePopupButtons}
                                                            onClick={() => this.handleReload()}>{t("Close")}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    this.props.status === '400' ?
                                        <div style={styles.headDiv}>
                                            <div style={{
                                                textAlign: 'center',
                                                alignItems: 'center',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                height: '100%'
                                            }}>
                                                <div style={styles.CanclePopup}>
                                                    <span
                                                        style={{color: 'white'}}>{t("Request rejected,Information matches")}</span>
                                                    <div style={{marginLeft: '150px', marginTop: '10px'}}>
                                                        <button style={styles.CanclePopupButtons}
                                                                onClick={() => this.handleReload()}>{t("Close")}</button>
                                                        {/*<button style={styles.CanclePopupButtons} onClick={() => {this.props.RemoveStatus()}}>Поменять</button>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <div style={{
                                                width: '99%',
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                height: '60px',
                                                alignItems: 'center'
                                            }}>
                                                <LanguagesDropdown/>
                                            </div>
                                            <div style={styles.logoStyle}>
                                                <img style={{width: '200px'}} src={logo} alt={logo}/>
                                            </div>
                                            <MerchantReg isPartner={true}/>
                                        </>
                                }

                            </div>
                        </>}</Translation>
            </div>

        );
    }
}

const mapStateToProps = state => ({
    status: state.AddMerchantsReducer.status,
})

export default connect(mapStateToProps, {RemoveStatus, RegIndividualMerchant, merchantReg})(PartnerReg)