import React from "react";
import {Translation} from "react-i18next";


const ErrMessage= ({style={}})=>{
  return(
    <Translation>
      {(t) =>
        <>
    <div style={{marginTop:'20px'}}>
      <span className={'errMessageTextStyle'} style={{color:'#EB5B47',fontSize:'16px',lineHeight: '24px', letterSpacing: '0.5px', ...style}}>
        {t('Input not field')}</span>
    </div>
        </>}</Translation>
  )
}
export default ErrMessage
