// import React from "react";
import {connect} from "react-redux";
import {Translation} from "react-i18next";
import CloseVector from "../../../assets/images/CloseVector.png";
import TextFieldS from "../../../components/common/Inputs/TextField";
import CancelButton from "../../../components/common/Buttons/CancelButton";
import SaveButton from "../../../components/common/Buttons/SaveButton";
import {changeSuperAdminPassword} from "../../../modules/signIn/signInAction";

import React, {Component} from 'react';
import ErrMessage from "../../../components/common/ErrMessage";






class Settings extends Component {
  constructor(props) {
    super(props);
    this.state={
      password:'',
      errMessage:false
    }
  }

  changePass=(ev)=>{
    this.setState({password:ev.target.value})
    this.setState({errMessage:false})
  }
  handleChangePassword=()=>{
    if(this.state.password.length >= 5) {
      this.props.changeSuperAdminPassword(this.state.password)
      this.props.onClick()
      this.setState({password:''})
    }else {
      this.setState({errMessage: true })
    }
  }
  render() {
    const {errMessage}=this.state
return (
<div>
    <>
      <Translation>
        {(t) =>
          <>
            {this.props.bool === true ?
              <div className={'headDiv'}>
                <div onClick={this.props.onClick} className={'CloseSectionClass'}/>
                <div className={'hideDiv'}>
                  <div className={'settingsClass'}>
                    <button onClick={this.props.onClick} className={'hideDivCloseButton'}>
                      <img src={CloseVector} alt={CloseVector}/>
                    </button>
                    <h4 style={{color: 'white',}}>{t("Super Admin")}</h4>
                    <h3 style={{color: 'white', margin: 0}}>David Ohanyan</h3>
                    <hr style={{marginTop: '50px'}}/>
                    <p style={{marginTop: '40px'}}>{t("Change Password")}</p>
                    <TextFieldS
                      onChange={this.changePass}
                      name="password"
                      type="password"
                      label={t('Password')}/>
                     {errMessage === true ?
                       <><ErrMessage/>
                         <span style={{color:'#EB5B47',fontSize:'16px',lineHeight: '24px', letterSpacing: '0.5px',}}>{t("Minimum number of characters 5")} </span> </>:
                      ''}
                    <div style={{marginTop: '20px'}}>
                      <CancelButton onClick={this.props.onClick}>{t("CANCEL")}</CancelButton>
                      <SaveButton onClick={() => this.handleChangePassword()}>{t("Change")}</SaveButton>
                    </div>
                  </div>
                </div>
              </div>
              : ''}
          </>}</Translation>
    </>
  </div>
);
}
}


const mapStateToProps = state => ({})

export default connect(mapStateToProps, {changeSuperAdminPassword})(Settings);

