import {LOCK_USERS} from "./lockUsersReducer";
import {USER_ACTIONS} from "../usersReducer";

export const LockUsers = (phoneNumber,lock,page) => {
  return {
    type: LOCK_USERS.LOCK_USERS_REQUEST,
    payload: {phoneNumber,lock,page}
  }
}


// export const BlockOneUserByPhone=(phoneNumber,lock)=>{
//   return{
//     type:USER_ACTIONS.BLOCK_ONE_USER,
//     payload:{phoneNumber,lock}
//   }
// }
// export const BlockActiveUserByPhone=(phoneNumber,lock)=>{
//   return{
//     type:USER_ACTIONS.ACTIVE_USERS_ONE,
//     payload:{phoneNumber,lock}
//   }
// }
// export const BlockBlocedUserByPhone=(phoneNumber,lock)=>{
//   console.log(phoneNumber,'BlockBlocedUserByPhoneBlockBlocedUserByPhone')
//   return{
//     type:USER_ACTIONS.BLOCKED_USERS_ONE,
//     payload:{phoneNumber,lock}
//   }
// }


