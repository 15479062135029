import RegTextField from "./RegTextField";
import DadataSuggestions from "react-dadata-suggestions";
import React from "react";
import {Translation} from "react-i18next";

let styles = {
  pStyle: {
    margin: 0,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
    color: 'rgba(255, 255, 255, 0.87)',
  },
  textDivStyle: {
    // borderBottom:'1px solid gray',
    // boxSh  adow: '0px 11px 15px rgba(0, 0, 0, 0.2), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 24px 38px rgba(0, 0, 0, 0.14)',
    cursor: 'pointer',
    marginTop: '10px'
  }
}
export const DaDataRequestCom = ({shrink,onKeyDown,infoForText, onClick, info, value, label, name, onChange, Length, Pattern, onBlur, type,}) => {
  let token = 'd395d2fe1619a04a7f414de8d730b54a1fccf9a2';


  return (
    <div  className={infoForText === 'addressForStation' ? 'addressForStation' :
      infoForText ===   'addressForCreateStation' ?  'addressForCreateStation' :
      infoForText ===   'addressForEditStation' ?  'addressForEditStation' :
        ''}

          onClick={onBlur}>

      <Translation>
        {(t) =>
          <>
            <RegTextField
              Shrink={shrink}
              value={value}
              name={name}
              onChange={onChange}
              label={t(label)}
              type={type}
              pattern={Pattern}
              Length={Length}
              onKeyDown={onKeyDown}
            />
            {info.length ?
              <div style={{
                zIndex: '20', width: '100%', position: 'absolute', display: 'flex',
                flexDirection: 'column', alignItems: 'center'
              }}>
                <div className={
                  infoForText === 'addressForStation' ?
                    'addressForStation-container-div'
                    :
                    infoForText === 'addressForCreateStation' ?
                    'addressForCreateStation-container-div'
                      :
                     'suggestions-container-div'}
                >
                  {info.map((item, index) => (
                    <div key={index} style={styles.textDivStyle} onClick={() => onClick(item)}>

                      <p style={styles.pStyle}>{item.value}</p>

                      {infoForText === 'inn' ?
                        <p style={styles.pStyle}>{item.data.inn}</p>
                        :
                         infoForText === 'bank' ?
                          <p style={styles.pStyle}>{item.data.bic}</p>
                          :
                           infoForText === 'code' ?
                          <p style={styles.pStyle}>{item.data.code}</p>
                             :''
                      }
                    </div>
                  ))}
                </div>
              </div>
              : ''}
          </>
        }
      </Translation>

    </div>
  )
}