import React from 'react';
import Button from "@material-ui/core/Button";

const styles ={
  buttonDefaultStyle: {
    color: 'white',
    background: '#EB5B47',
    borderRadius: '5px',
    border: '1px solid #EB5B47',
    padding: '8px'
  }
};



const CancelButton = ({onClick, style={}, children, disabled, ...props}) => {
  return (
      <Button
        onClick={onClick}
        style={ {...styles.buttonDefaultStyle, ...style, opacity: disabled ? 1 : 1 }}
        {...props}
      >
        {children}
      </Button>
  )
};

export default CancelButton