import React, {useState} from 'react'
import Button from "@material-ui/core/Button";
import another from "../../assets/images/another.png";
import {Translation} from "react-i18next";
import {Card} from "./Card";

const styles = {
  Container: {
    marginLeft: "20px",
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
    height: '240px',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14)',
    borderRadius: '8px'
  },
  supportTitle: {
    padding: '10px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: 'rgba(255, 255, 255, 0.87)'
  },
  contextStyle: {
    marginLeft: '10px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '25px',
    letterSpacing: '0.25px',
    color: 'rgba(255, 255, 255, 0.6)'
  },
};

const TextTable =({children,Text,AllText,onClick,Location, OpenModal,...props})=>{
  return(
    <React.Fragment>
      <Translation>
        {(t) =>
          <>
      <div  style={{display: 'flex',}}>
        <div style={styles.Container}>

          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',marginTop:'8px'}}>
            <div style={{width:'35px',color:'black',background: '#ECC53C', borderRadius: '3px',marginLeft:'10px',display:'flex',alignItems:'center',justifyContent:'center'}}>
              <span style={{marginLeft: '2px'}}>{Location}</span>
            </div>
            <Button onClick={onClick} style={{width: '10px',marginLeft:'5px'}}>
              <img src={another} alt={another}/>
            </Button>
          </div>
          <span style={styles.supportTitle}>{t(Text)}</span>
          <span style={styles.contextStyle}>{AllText}</span>

        </div>

      </div>
          </>}</Translation>
    </React.Fragment>
  )
}

export default TextTable;
