export const ADD_SUPPORT ={
  SUPPORT_TEXT_REQUEST:'ADD_SUPPORT.SUPPORT_TEXT_REQUEST',
  SUPPORT_TEXT_SUCCESS:'ADD_SUPPORT.SUPPORT_TEXT_SUCCESS',
  SUPPORT_TEXT_FAIL:'ADD_SUPPORT.SUPPORT_TEXT_FAIL',
  OPEN_SUPPORT_PAGE:'ADD_SUPPORT.OPEN_SUPPORT_PAGE',
  CLOSE_SUPPORT_PAGE:'ADD_SUPPORT.CLOSE_SUPPORT_PAGE'
}

const initialState = {
  CrateSupport:[],
  openSupportPage:[]
}

export default (state=initialState,action)=>{
  switch (action.type) {
    case ADD_SUPPORT.SUPPORT_TEXT_REQUEST :{
      return {...state,CrateSupport:initialState.CrateSupport}
    }
    case ADD_SUPPORT.SUPPORT_TEXT_SUCCESS :{
      return {...state,CrateSupport:action.payload.data}
    }
    case ADD_SUPPORT.SUPPORT_TEXT_FAIL:{
      return {...state,CrateSupport:initialState.CrateSupport}
    }


    case ADD_SUPPORT.OPEN_SUPPORT_PAGE:{
      return{...state,openSupportPage:action.payload.data}
    }
    case ADD_SUPPORT.CLOSE_SUPPORT_PAGE:{
      return {...state,openSupportPage:[]}
    }
      default:{
        return state
      }
  }

}