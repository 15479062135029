import React from "react";
import {Translation} from "react-i18next";

export const InfoSwitch =({Show,Close,ShowReport,FirstText,SecondText})=>{
  return(
    <Translation>
      {(t) => <>
    <div className={'InfoSwitchDiv'}>
      <button  onClick={Show} style={{width: '100%', border: 'none', borderBottom: `1px solid ${ShowReport === false ? '#5F94E2' :'white'}`, background: 'none', padding: '15px', outline: 'none'}}>
        <span style={{marginLeft:'10px',color: ShowReport === false ? '#5F94E2' :'white'}}
        >{t(FirstText)}</span></button>
      <button onClick={Close} style={{width: '100%', border: 'none', borderBottom: `1px solid ${ShowReport === true ? '#5F94E2' :'white'}`, background: 'none', padding: '15px', outline: 'none'}}>
        <span style={{marginLeft:'30px',color: ShowReport === true ? '#5F94E2' :'white'}}
        >{t(SecondText)}</span></button>
        {/*>{t("Report")}</span></button>*/}
    </div>
      </>}</Translation>
  )
}
