export const BANKS_ACTIONS = {
  GET_LIST_REQUEST: "BANKS_ACTIONS.GET_LIST_REQUEST",
  GET_LIST_SUCCESS: "BANKS_ACTIONS.GET_LIST_SUCCESS",
  GET_LIST_FAIL: "BANKS_ACTIONS.GET_LIST_FAIL",
  GET_POWERBANKS_FULL_LIST: "BANKS_ACTIONS.GET_POWERBANKS_FULL_LIST",

  GET_LIST_BY_STATUS_REQUEST: "BANKS_ACTIONS.GET_LIST_BY_STATUS_REQUEST",
  GET_LIST_BY_CONDITION_REQUEST: "BANKS_ACTIONS.GET_LIST_BY_CONDITION_REQUEST",
  SET_LIST_BY_STATUS: "BANKS_ACTIONS.SET_LIST_BY_STATUS",
  SET_LIST_BY_SEARCH: "SET_LIST_BY_SEARCH",
  // SET_LIST_BY_CONDITION: "BANKS_ACTIONS.SET_LIST_BY_CONDITION",

  GET_PAGE_LIST: "BANKS_ACTIONS.GET_PAGE_LIST",

  OPEN_BANKS_INFO: "BANKS_ACTIONS.OPEN_BANKS_INFO",
  CLEAR_BANKS_INFO: "BANKS_ACTIONS.CLEAR_BANKS_INFO",
  DEL_POWERBANK_REQUEST: "BANKS_ACTIONS.DEL_POWERBANK_REQUEST",
  DEL_POWERBANK_SUCCESS: "BANKS_ACTIONS.DEL_POWERBANK_SUCCESS",

  FINDING_REQUEST: "BANKS_ACTIONS.FINDING_REQUEST",
  FINDING_SUCCESS: "BANKS_ACTIONS.FINDING_SUCCESS",
  FINDING_FAIL: "BANKS_ACTIONS.FINDING_FAIL",

  FINDING_SUCCESS_NOT_FOUND: "BANKS_ACTIONS.FINDING_SUCCESS_NOT_FOUND",
  DOWNLOAD_POWERBANKS_LIST_ASYNC: "DOWNLOAD_POWERBANKS_LIST_ASYNC",
  PHOTO_CONTROL_ASYNC: "PHOTO_CONTROL_ASYNC",
  PHOTO_CONTROL: "PHOTO_CONTROL",
  COMMENT_POWERBANK_ASYNC: "COMMENT_POWERBANK_ASYNC",
  BLOCK_POWERBANK_ASYNC: "BLOCK_POWERBANK_ASYNC",
  BLOCK_POWERBANK: "BLOCK_POWERBANK",
  SEARCH_POWERBANK_COLLECTION_ASYNC: "SEARCH_POWERBANK_COLLECTION_ASYNC",
  SEARCH_POWERBANK_ASYNC: "SEARCH_POWERBANK_ASYNC",
  SEARCH_POWERBANK_BY_STATION_ASYNC: "SEARCH_POWERBANK_BY_STATION_ASYNC",

  SET_CROSS_LINK_POWERBANK_ID: "SET_CROSS_LINK_POWERBANK_ID"
};

const initialState = {
  PowerBanksList: [],
  PowerBanksListByFilter: [],
  BankFillInfo: [],
  PageInfo: [],
  page: [],
  FindingList: [],
  DashboardInfo: {},
  MerchantsDashboadtList: {},
  PowerbanksConditionsSortedByMerchant: {},
  photoControl: [],
  isLoading: false,
  crossLinkPowerbankId: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BANKS_ACTIONS.GET_LIST_REQUEST: {
      return {
        ...state,
        PowerBanksList: initialState.PowerBanksList,
        isLoading: true,
      };
    }

    case BANKS_ACTIONS.GET_LIST_SUCCESS: {
      return {
        ...state,
        PowerBanksList: action.payload.data,
        PowerBanksListByFilter: action.payload.data,
        isLoading: false,
      };
    }

    case BANKS_ACTIONS.GET_PAGE_LIST: {
      return { ...state, PageInfo: action.payload.data };
    }

    case BANKS_ACTIONS.GET_LIST_FAIL: {
      return { ...state, PowerBanksList: initialState.PowerBanksList };
    }

    case BANKS_ACTIONS.OPEN_BANKS_INFO: {
      return {
        ...state,
        BankFillInfo: action.payload.data,
        page: action.payload.page,
      };
    }

    case BANKS_ACTIONS.CLEAR_BANKS_INFO: {
      return { ...state, BankFillInfo: [] };
    }

    case BANKS_ACTIONS.GET_POWERBANKS_DASHBOARD_INFO_REQUEST: {
      return { ...state };
    }

    case BANKS_ACTIONS.SET_POWERBANKS_DASHBOARD_INFO: {
      return {
        ...state,
        DashboardInfo: action.payload,
      };
    }

    case BANKS_ACTIONS.FINDING_REQUEST: {
      return { ...state, PowerBanksList: initialState.FindingList };
    }

    case BANKS_ACTIONS.FINDING_SUCCESS_NOT_FOUND: {
      return { ...state, PowerBanksList: action.payload, PageInfo: "" };
    }

    case BANKS_ACTIONS.FINDING_SUCCESS: {
      return { ...state, PowerBanksList: [action.payload.data], PageInfo: "" };
    }

    case BANKS_ACTIONS.FINDING_FAIL: {
      return { ...state, PowerBanksList: "not found", PageInfo: "" };
    }

    case BANKS_ACTIONS.GET_LIST_BY_STATUS_REQUEST: {
      return { ...state, isLoading: true };
    }

    case BANKS_ACTIONS.SET_LIST_BY_STATUS: {
      return {
        ...state,
        PowerBanksList: action.payload.data.powerBankList,
        PageInfo: action.payload.data,
        isLoading: false,
      };
    }

    case BANKS_ACTIONS.SET_LIST_BY_SEARCH: {
      return {
        ...state,
        PowerBanksList: action.payload.data,
        PageInfo: action.payload.data,
        isLoading: false,
      };
    }

    case BANKS_ACTIONS.PHOTO_CONTROL: {
      return { ...state, photoControl: action.payload };
    }

    case BANKS_ACTIONS.BLOCK_POWERBANK: {
      return {
        ...state,
        PowerBanksList: state.PowerBanksList.map((powerbank) => {
          if (powerbank.id === action.payload.id) {
            return action.payload;
          }

          return powerbank;
        }),
        BankFillInfo: action.payload,
      };
    }

    case BANKS_ACTIONS.SET_CROSS_LINK_POWERBANK_ID: {
      return {
        ...state, 
        crossLinkPowerbankId: action.payload,
        isLoading: true,
      }
    }

    default: {
      return state;
    }
  }
};
