import React, {Component} from 'react';
import {connect} from "react-redux";
import {CloseSupportPage} from "../../../modules/documents/SupportText/addSuportText/addSuportTextAtion";
import CloseVector from "../../../assets/images/backImg.png";
import {
  addAgreementText,
  CloseAgreementPage
} from "../../../modules/documents/UserAgreement/addAgreement/addAgreementAction";
import CancelButton from "../../../components/common/Buttons/CancelButton";
import SaveButton from "../../../components/common/Buttons/SaveButton";
import RichTextEditor from "react-rte";
import PropTypes from "prop-types";
import {Translation} from "react-i18next";

const styles = {
  hideDiv:{
    width: '100%',
    height: '100%',
    position: 'absolute',
    minHeight: '100vh',
    zIndex: '20',
    overflowY: 'scroll',
    right: 0,
    boxSizing: 'border-box',
  },
  ChangeDivCloseButton: {
    marginLeft:'5px',
    background: 'none',
    border: 'none',
  },
  Container: {
    background: 'black',
    boxShadow: '-2px 0px 4px rgba(0, 0, 0, 0.2), -1px 0px 10px rgba(0, 0, 0, 0.12), -4px 0px 5px rgba(0, 0, 0, 0.14)',
    width: '100%',
    minHeight: '100vh',
    zIndex: '100',
    position: 'fixed',
    right: 0,
  },
  ContainerDiv: {
    display: 'flex',
    height: '40px',
    alignItems: 'center',
    width: '100%',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
  }
}
class EditUserAgreement extends Component {
  static propTypes = {
    onChange: PropTypes.func
  };
  constructor(props) {
    super(props);
    this.state={
      editorValue: RichTextEditor.createEmptyValue(),
      locationForUserAgreement:'',
    }
  }
componentWillReceiveProps = (nextProps) => {
  const note = nextProps.openAgreementPage[0]
  let editorValue =this.state.editorValue
  if(editorValue.toString('html') !== note){
    editorValue = RichTextEditor.createValueFromString(note,'html')
  }
  this.setState({editorValue})
}

  onChange = (editorValue) => {
    this.setState({editorValue});
    this.props.openAgreementPage[0] = editorValue.toString('html')
  };

  ChackboxHandlechange=(ev)=>{
    this.setState({locationForUserAgreement:ev.target.value})
  }
  Create = () => {
    const text = this.state.text? this.state.text : this.props.openAgreementPage[0]
    this.props.addAgreementText(this.props.openAgreementPage[1] ,text)
    this.props.CloseAgreementPage()
  }


  render() {
    const {openAgreementPage} = this.props
    return (
      <>
        <Translation>
          {(t) =>
            <>
        {openAgreementPage.length >  0  ?
          <div style={styles.Container}>
            <div style={styles.ContainerDiv}>
              <button onClick={() => this.props.CloseAgreementPage()} style={styles.ChangeDivCloseButton} >
                <img  style={{width:'16px'}} src={CloseVector} alt={CloseVector}/></button>
            </div>
            <div style={styles.hideDiv}>
              <div style={{display:'flex',justifyContent:'flex-end',margin:'0 auto',width:'80%',marginTop:'20px',}}>
                <div>
                  <CancelButton onClick={() => this.props.CloseAgreementPage()}>{t("CANCEL")}</CancelButton>
                  <SaveButton onClick={this.Create}>{t("SAVE CHANGES")}</SaveButton>
                </div>
              </div>
              <div style={{color:'black',background:'black'}}>
                <RichTextEditor
                  name='body'
                  value={this.state.editorValue}
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
          : ''}
            </>}</Translation>
      </>
    );
  }
}

const mapStateToProps = state => ({
  openAgreementPage:state.addAgreementReducer.openAgreementPage
})

export default connect(mapStateToProps, {CloseSupportPage,CloseAgreementPage,addAgreementText})(EditUserAgreement);
