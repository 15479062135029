import {put, takeLatest,call} from "@redux-saga/core/effects";
import {RENTS_ACTIONS} from "./rentsReducer";
import * as api from "../api";
import axios from "axios";
import {ClearAllForLoader} from "./rentsAction";

function* rentSaga() {
  yield takeLatest(RENTS_ACTIONS.RENTS_REQUEST, handleSaga)
}

function* handleSaga(action) {
  try {
    const {data} = yield call(api.getRents,action.payload.data)

    yield put({
      type: RENTS_ACTIONS.RENTS_SUCCESS,
      payload: {data : data.rents}
    })
    yield put({
      type: RENTS_ACTIONS.PAGES_INFO,
      payload:{data : { pageCount:data.pageCount, rentCount:data.rentCount} }
    })
  } catch (e) {

    if(e.message === 'Request failed with status code 401'){
      localStorage.removeItem('token')
      window.location.reload()
    }

    yield put({
      type: RENTS_ACTIONS.RENTS_FAIL,
      message: e.message
    })
  }
}


// function* DefaultFiltrRents ({payload}) {
//   const email = payload.email
//   try {
//     const {data} = yield axios.get('/api/private/admin/rentsFilter', {
//       params: {
//         'email':email
//       },
//       headers: {
//         authorization: 'Bearer ' + localStorage.getItem('token'),
//       }
//     });
//     yield put({
//       type:RENTS_ACTIONS.FILTER_RENTS_SUCCESS,
//       payload : {data}
//     })
//   }catch(e) {
//     console.log(e)
//   }
// }


function* handleFindRents(action) {
  try {
    const {data} = yield call(api.getUserRent,action.payload.phoneNumber)
    yield put({
      type:RENTS_ACTIONS.FINDING_RENTS_SUCCESS,
      payload:{data : Array.isArray(data)  === true ? data : [data]}
    })
    yield put({
      type:RENTS_ACTIONS.DEBIT_INFO,
      payload:{data : { debit:data.debit, credit:data.credit} }
    })
    yield put({
      type: RENTS_ACTIONS.PAGES_INFO,
      payload:{data : { pageCount:0, rentCount:data.rent ? data.rent.length : data.length} }
    })
  }catch (e) {
    yield put(ClearAllForLoader())


    yield put({
      type:RENTS_ACTIONS.FINDING_RENTS_FAIL,
      // payload:{data:'not found'}
    })
    console.log(e)
  }
}


function* handleFiterByDateRentsSaga(action) {
  try {
    const {data} = yield call(api.FilterRentsByDate,action.payload)
    yield put({
      type: RENTS_ACTIONS.RENTS_SUCCESS,
      payload: {data : data}
    })
    yield put({
      type: RENTS_ACTIONS.PAGES_INFO,
      payload:{data : { pageCount:0, rentCount:data.length} }
    })
  }catch (e) {
    console.log(e)
  }
}

export function* handleFiterByDateRents (){
  yield takeLatest(RENTS_ACTIONS.FILTER_RENTS_LIST_REQUEST,handleFiterByDateRentsSaga)
}



function* handleFilterByStatus(action) {
  try {
    const {data} = yield call(api.FilterRentsByStatus,action.payload.data)
    yield put({
      type: RENTS_ACTIONS.RENTS_SUCCESS,
      payload: {data : data.rents}
    })
    yield put({
      type: RENTS_ACTIONS.PAGES_INFO,
      payload:{data : { pageCount:data.pageCount, rentCount:data.rentCount} }
    })
  }catch (e) {
    console.log(e)
  }
}

export function* handleFilterRentsByStatus (){
  yield takeLatest(RENTS_ACTIONS.FILTER_ACTIVE_RENTS_REQUEST,handleFilterByStatus)
}


export function* FindRentsBYSaga (){
  yield takeLatest(RENTS_ACTIONS.FINDING_RENTS_REQUEST,handleFindRents)
}

// export  function* handleFilterRents() {
//   yield takeLatest(RENTS_ACTIONS.FILTER_RENTS_REQUEST,DefaultFiltrRents)
// }

export default rentSaga
