import React, {Component} from 'react';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import {DeleteModal} from "../../../components/common/modalPopup/DeleteModal";
import {Translation} from "react-i18next";
import {InfoModalCard} from "../../../components/common/InfoModal/InfoModal";
import moment from "moment";
import {TotalRentTime} from "../../../utiles/TotalRentTime";
import {connect} from "react-redux";
import {RentBlock} from "../../../modules/rents/StopRent/StopRentAction";
import {deleteUserRentInfo} from "../../../modules/rents/rentUsersInfo/rentUsersInfoAction";
import {CLearFindingRentInfo, filterRentsList, getRents} from "../../../modules/rents/rentsAction";
import Comments from "../../../components/common/Comments";
import {setCrossLinkPowerbankId} from "../../../modules/powerbanks/powerbanksAction";

class RetIfoModal extends Component {
  constructor() {
    super();
    this.state={
      showModal:false
    }
  }
  StopRent=()=>{
    sessionStorage.setItem('filterButtons','all')
    this.props.HandleClose()
    if(this.props.filterData[0]){
      const Data = {
        StartDate: `${moment(this.props.filterData[0], 'YYYY-MM-DD').format('YYYY-MM-DD')}T00:00:00.001`,
        EndDate: `${moment(this.props.filterData[1], 'YYYY-MM-DD').format('YYYY-MM-DD')}T23:59:59.001`,
      }
      this.props.RentBlock(this.props.info.id,Data)
    }
else {
      this.props.RentBlock(this.props.info.id, this.props.page)
      this.setState({showModal: false})
    }
  }
  closeSectionByStorage=()=>{
    this.props.CLearFindingRentInfo()
    sessionStorage.removeItem('InfoForClose')
  }
  handleCloseComment=()=>{
    this.props.HandleClose()
    this.props.getRents(this.props.page)
  }

  showInPowerbanksList = (i) => {
    console.log('HQH')
    this.props.setCrossLinkPowerbankId(i)
    this.props.history.push('/powerBank')
  }
  render() {
    const {showModal} =this.state
    const {info}=this.props
    const finishDate = info.finishedDate ? info.finishedDate : 'In progress'
    const StartTime = `${moment.tz(info.startDate,'YYYY-MM-DD/HH:mm','Europe/Moscow').local().format('DD.MM.YYYY/HH:mm')} `
    const FinishTime = `${moment.tz(finishDate,'YYYY-MM-DD/HH:mm','Europe/Moscow').local().format('DD.MM.YYYY/HH:mm')} `
    const Role = localStorage.getItem('role')
    const CloseSection =   sessionStorage.getItem('InfoForClose')

    return (
      <Translation>{(t) => <>
        {showModal &&
        <DeleteModal boolean={showModal} text={`${t("Are you sure you want Stop Rent")} : ${info.id}`}
          Cancel={'Cancel'} Delete={t('Stop')} handleCancel={() => {this.setState({showModal: false})}}
                     handleDelete={() =>{this.StopRent()}}
        />}
      <InfoModalCard ClickClose={CloseSection ==='CloseStation' ? ()=> this.closeSectionByStorage() :  this.props.HandleClose} ModalSize={'small'} Buttons={false} position={'right'}>
     <div style={{display:'flex',flexDirection:'column',marginBottom:'20px'}}>
       <span className={'showList'}>{t("ID")}</span>
       <span className={'showListInfo'}>{info.id}</span>
       <span className={'showList'}>{t("PowerBank Id")} </span>
       <span className={'showListInfo'}>{info.powerBankID ? info.powerBankID :'...'} </span>
       <button 
        onClick={() => this.showInPowerbanksList(info.powerBankID)}
        style={{
          padding: '10px',
          fontSize: '16px',
          width: 'max-content',
          borderRadius: '5px',
          border: 'none',
          background: 'rgb(95, 148, 226)',
          color: 'white',
          cursor: 'pointer',
        }}
       >{t("crossPowerbankLink")}</button>
       <span className={'showList'}>{t("Start of rent")}</span>
       <span className={'showListInfo'}>{`${StartTime}`}</span>
       <span className={'showList'}>{t("End of rent")}</span>
       <span className={'showListInfo'}>{finishDate === 'In progress' ? finishDate : `${FinishTime}`}</span>
       <span className={'showList'}>{t('Total rent time')}</span>
       <TotalRentTime startDate={info.startDate}   finishedDate={finishDate}  />
       <span className={'showList'}>{t("Total rent cost")}</span>
       <span className={'showListInfo'}>{`${info.totalAmount} ${info.groupTariffs ? info.groupTariffs.location === 'RU' ? '₽' : '֏' : ''}`}</span>
       <span className={'showList'}>{t("Debt")}</span>
       <span className={info.debt === 0 ? 'showListInfo' : 'showDebtInfo' }>{info.debt ? `-${info.debt} ${info.groupTariffs ? info.groupTariffs.location === 'RU' ? '₽' : '֏' : ''}` : '0'}</span>

       <span className={'showList'}>{t('Tariff')}</span>
       <span className={'showListInfo'}>{info.currentTariff  ?  info.currentTariff.tariffName : ''}</span>
       <span className={'showList'}>{t('Receiving station address')}</span>
       <span className={'showListInfo'}>{info.startStation} </span>
       <span className={'showList'}>{t('Receiving station slot')}</span>
       <span className={'showListInfo'}>{info.stationSlot === null ? 'null' : info.stationSlot} </span>
       <span className={'showList'}>{t('Return station address')}</span>
       <span className={'showListInfo'}>{info.completionStation === null ? 'In progress' : info.completionStation} </span>
       <span className={'showList'}>{t('Return station slot')}</span>
       <span className={'showListInfo'}>{info.completionStation === null ? 'In progress' : (info.completionStationSlot === null ? 'null' : info.completionStationSlot)} </span>
        <hr style={{color: 'white', width: '100%',marginTop:'15px'}}/>
        <span className={'showList'}>{t('User ID')}</span>
        <span className={'showListInfo'}>{info.user ? info.user.id : 'null'} </span>
        { Role === 'SUPER_ADMIN' || Role === 'ADMIN' || Role === 'MERCHANT' ? <>
            <span className={'showList'}>{t("User Phone")}</span>
            <span className={'showListInfo'}>{info.user.phoneNumber ? info.user.phoneNumber : 'null'} </span>
            <span className={'showList'}>{t('E-mail')}</span>
            <span className={'showListInfo'}>{info.user.email ? info.user.email : t('null')} </span></>
          :'' }
        <span className={'showList'}>{t('Status')}</span>
        <span style={{color: '#EB5B47',marginBottom: '20px'}} className={'showListInfo'}>{info.rentStatus === 'FINISHED' ?
          <span style={{color: '#EB5B47'}}>{t('Finished')}</span>
          : info.rentStatus === 'FAILED' ?
                <span style={{color: '#EB5B47'}}>{t('Failed')}{info.failReason ? ' - ' + info.failReason : ''}</span>
                :
          <span style={{color: 'rgba(101, 227, 42, 0.87)'}}>{t('In progress')}</span>
        }</span>
        {info.rentStatus === 'FINISHED' || info.rentStatus === 'FAILED' ? '' :
          Role === 'SUPER_ADMIN' ?
            <button onClick={() => {this.setState({showModal:true})}} className={'StopRentButton'}>
              {t("STOP RENT")}
            </button>:''
        }

       {Role === 'SUPER_ADMIN' &&
       <Comments
                 handleClose={() =>this.handleCloseComment()}
                 text={info.comment}
                 id={info.id}
                 name={'FOR_RENT'}
       />
       }

     </div>
      </InfoModalCard>
      </>}</Translation>
    );
  }
}
const mapStateToProps = state => ({
})
export default compose(
  withRouter,
  connect(mapStateToProps, {getRents,filterRentsList,deleteUserRentInfo, RentBlock,CLearFindingRentInfo, setCrossLinkPowerbankId})
)(RetIfoModal);
