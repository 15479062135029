export const FINDING = {
  RENTS_REQUEST:'FINDING.RENTS_REQUEST',
  RENTS_SUCCESS: 'FINDING.RENTS_SUCCESS',
  RENTS_FAIL:'FINDING.RENTS_FAIL',
  RENTS_FILTR:'FINDING.RENTS_FILTR',
  CLEAR_RENTS_REQUEST :'FINDING.CLEAR_RENTS_REQUEST'
}

const initialState = {
  FindingRents:[]
}


export default (state=initialState,action)=>{
  switch (action.type) {
    case FINDING.RENTS_REQUEST:{
      return{...state,FindingRents:initialState.FindingRents}
    }
    case FINDING.RENTS_SUCCESS:{
      return {...state,FindingRents:action.payload.data}
    }
    case FINDING.RENTS_FAIL:{
      return {...state,FindingRents:initialState.FindingRents}
    }
    case FINDING.CLEAR_RENTS_REQUEST:{
      return {...state,FindingRents:[]}
    }
    default:{
      return state
    }

  }

}
