import {put,call, takeLatest} from "@redux-saga/core/effects";
import * as api from "../../api";
import {LOCK_STATION} from "./lockStationReducer";
import {GetStation} from "../stationsAction";


function* handleLockSaga() {
  yield takeLatest(LOCK_STATION.LOCK_REQUEST,StationLockSaga)
}

function* StationLockSaga(action) {
  try {
    const {data} = yield call(api.lockStation,action.payload.stationNumber,action.payload.lock)
    yield put({
      type:LOCK_STATION.LOCK_SUCCESS,
      payload:{data}
    })
    yield put(GetStation(0))
  }
  catch (e) {
    yield put({
      type:LOCK_STATION.LOCK_FAIL,
      message:e.message
    })
  }

}

export default handleLockSaga
