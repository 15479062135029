import moment from "moment";
import React from "react";
import {Translation} from "react-i18next";


export const TotalRentTime =({startDate,finishedDate})=>{
  let d1 = new Date(startDate ? startDate[0] : '',
    startDate ? startDate[1] - 1 : '',
    startDate ? startDate[2] : '',
    startDate ? startDate[3] : '',
    startDate ? startDate[4] : '');
  let d2 = new Date(finishedDate ? finishedDate[0] : '',
    finishedDate ? finishedDate[1] - 1 : '',
    finishedDate ? finishedDate[2] : '',
    finishedDate ? finishedDate[3] : '',
    finishedDate ? finishedDate[4] : '');

  let date = new Date(d2-d1);
  let hours = date.getUTCHours();
  let min = date.getUTCMinutes();
  let sec = date.getUTCSeconds();
  let days = date.getUTCDate() - 1;
  return(
    <Translation>{(t) => <>
     {finishedDate === 'In progress' ?
      <span className={'activeRentInInfoModal'}>{t('In progress')}</span>
      :
      <span className={'showListInfo'}>
        { `${days > 0 ? `${days}${t('d')} ` : ''} ${hours > 0 ? `${hours}${t('h')} ` : ''}   ${min > 0 ? `${min}${t('m')}` : ''}  ${sec > 0 ? `${sec}${t('s')}` : ''} `}
      </span>
    }
    </>}</Translation>
  )

}