import {takeLatest, call, put} from "@redux-saga/core/effects";
import {ADD_QUESTION} from "./AddQuestionReducer";
import * as api from "../../api";
import {GetQuestions} from "../GetPopularQuestionAction";


function* handleSaga() {
  yield takeLatest(ADD_QUESTION.REQUEST,AddHandleSaga)
}

function* AddHandleSaga(action) {
  try {
    const {data} = yield call(api.AddQuestion,action.payload.locationForPopularQuestion,action.payload.answer,action.payload.question)
    yield put({
      type:ADD_QUESTION.SUCCESS,
      payload:{data}
    })
    const location =action.payload.locationForPopularQuestion
    yield put(GetQuestions(location))
  }catch (e) {
   yield put({
     type:ADD_QUESTION.FAIL,
     message:e.message
   })
  }
}
export default handleSaga
