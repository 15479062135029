import React from "react";
import {Translation} from "react-i18next";


const ErrMessageText = ({style = {}, text}) => {
    return (
        <div>{text &&
        <Translation>
            {(t) =>
                <>
                    <div style={{marginTop: '20px'}}>
      <span className={'errMessageTextStyle'}
            style={{
                color: '#EB5B47',
                fontSize: '16px',
                lineHeight: '24px',
                letterSpacing: '0.5px', ...style
            }}>
        {t(text)}
      </span>
                    </div>
                </>}</Translation>
        }
        </div>
    )
}
export default ErrMessageText