import React, { useEffect, useState } from 'react';
import { MerchantsList } from '../MerchantsList';
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from 'react-redux';
import { clearMerchantsPowerbanksStatusesDashboardSortList, sortPowerbanksStatusesDashboardInfoByMerchant } from "../../../../modules/dashboard/dashboardAction";

export const PowerbanksStatusesChart = ({dataPowerbanks, merchantsList}) => {
    const dispatch = useDispatch();
    const Language = localStorage.getItem("Lan");
    const role = localStorage.getItem("role");
    const sortedDataByMerchantEmail = useSelector(state => state.dashboardReducer.PowerbanksStatusesSortedByMerchant);
    const [datas, setDatas] = useState({
        options: {
            labels: Language === 'RU' ? 
                ['В терминале', 'В аренде', 'У партнеров', 'У мерчантов', 'У суперадминов', 'Утерян']
                :
                ['In station', 'Rented', 'At partners', 'At merchants', 'At superadmins', 'Losted'],
            colors: ['#5f94e2', '#008000', '#c9c900', '#ffa500', '#ba66ff', '#ff0000'],
            plotOptions: {
                pie: {
                donut: {
                    labels: {
                    show: true,
                    value: {
                        show: true,
                        color: '#fff',
                        fontSize: '20px',
                        label: 'Total'
                    },
                    total: {
                        show: true,
                        color: '#fff',
                        fontSize: '15px',
                        label: 'Total'
                    }
                    }
                }
                }
            },
            legend: {
                fontSize: '16px',
                labels: {
                colors: '#fff'
                },
                markers: {
                offsetX: '-5'
                },
                position: window.innerWidth > 768 ? "right" : "bottom",
            },
        },
        statuses: {
            inStation: 0,
            rented: 0,
            atAdmin: 0,
            atMerchant: 0,
            atSuperAdmin: 0,
            losted: 0
        },
        merchants:[]
    });

    useEffect(() => {
        window.addEventListener("resize", () => {
            window.innerWidth > 768 ? 
            setDatas(pv => ({
                ...pv,
                options: {
                    ...pv.options,
                    legend: {
                        ...pv.options.legend,
                        position: "right"
                    }
                }
            })) 
            : 
            setDatas(pv => ({
                ...pv,
                options: {
                    ...pv.options,
                    legend: {
                        ...pv.options.legend,
                        position: "bottom"
                    }
                }
            }))
        });

        return () => window.removeEventListener("resize", () => {
            window.innerWidth > 768 ? 
            setDatas(pv => ({
                ...pv,
                options: {
                    ...pv.options,
                    legend: {
                        ...pv.options.legend,
                        position: "right"
                    }
                }
            })) 
            : 
            setDatas(pv => ({
                ...pv,
                options: {
                    ...pv.options,
                    legend: {
                        ...pv.options.legend,
                        position: "bottom"
                    }
                }
            }))
        });
    }, [])

    useEffect(() => {
        if(sortedDataByMerchantEmail.allPowerBanks !== undefined){
            setDatas({
                ...datas,
                statuses: {
                    inStation: sortedDataByMerchantEmail.inStation,
                    rented: sortedDataByMerchantEmail.inRent,
                    atAdmin: sortedDataByMerchantEmail.atAdmin,
                    atMerchant: sortedDataByMerchantEmail.atMerchant,
                    atSuperAdmin: sortedDataByMerchantEmail.atSuperAdmin,
                    losted: sortedDataByMerchantEmail.lost
                }
            })
        } else {
            setDatas({
                ...datas,
                statuses: {
                    inStation: dataPowerbanks.inStation,
                    rented: dataPowerbanks.inRent,
                    atAdmin: dataPowerbanks.atAdmin,
                    atMerchant: dataPowerbanks.atMerchant,
                    atSuperAdmin: dataPowerbanks.atSuperAdmin,
                    losted: dataPowerbanks.lost
                }
            })
        }
    }, [sortedDataByMerchantEmail]);

    useEffect(() => {
        setDatas({
            ...datas,
            statuses: {
                inStation: dataPowerbanks.inStation,
                rented: dataPowerbanks.inRent,
                atAdmin: dataPowerbanks.atAdmin,
                atMerchant: dataPowerbanks.atMerchant,
                atSuperAdmin: dataPowerbanks.atSuperAdmin,
                losted: dataPowerbanks.lost
            }
        })
    }, [dataPowerbanks])

    useEffect(() => {
        setDatas({
            ...datas,
            merchants: merchantsList
        })
    }, [merchantsList])

    const sortFn = (item) => {
        dispatch(sortPowerbanksStatusesDashboardInfoByMerchant(item))
    }

    const chooseAllMerchants = () => {
        dispatch(clearMerchantsPowerbanksStatusesDashboardSortList());
    }

    return (
        <>
            <Chart
                options={datas.options}
                series={
                    [
                        datas.statuses.inStation,
                        datas.statuses.rented,
                        datas.statuses.atAdmin,
                        datas.statuses.atMerchant,
                        datas.statuses.atSuperAdmin,
                        datas.statuses.losted
                    ]
                }
                type="donut"
                width={window.innerWidth > 768 ? "500" : "320" } 
            />
            {
                role === 'SUPER_ADMIN' || role === 'ADMIN' ? 
                <MerchantsList 
                    chooseAllMerchants={chooseAllMerchants} 
                    sortFn={sortFn} 
                    merchantsList={datas.merchants}
                />
                : 
                null
            }
        </>
    )
}