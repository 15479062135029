export const USER_INFO = {
  SEND_USER_INFO: "USER_INFO.SEND_USER_INFO",
  CLEAR_USER_INFO: "USER_INFO.CLEAR_USER_INFO",
  BLOCK_ONE_USER: 'USER_INFO.BLOCK_ONE_USER',
}

const initialState = {
  UsersInfo: [],
  page:[]
}

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_INFO.SEND_USER_INFO: {
      return {...state, UsersInfo: action.payload.data, page: action.payload.page}
    }
    case USER_INFO.CLEAR_USER_INFO: {
      return {...state, UsersInfo:[]}
    }

    default: {
      return state
    }
  }
}
