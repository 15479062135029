import {ADD_MERCHANTS_ACTION} from "./AddMerchantsReducer";


export const AddMerchant = (adminEmail,login,password)=>{
  return{
    type:ADD_MERCHANTS_ACTION.ADD_MERCHANTS_REQUEST,
    payload:{adminEmail,login,password}
  }
}

export const merchantReg =(data)=>{
  return{
    type:ADD_MERCHANTS_ACTION.REG_MERCHANTS_ACTION,
    payload:{data}
  }
}
export const RegIndividualMerchant =(data)=>{
  return{
    type:ADD_MERCHANTS_ACTION.REG_INDIVIDUAL_MERCHANTS_ACTION,
    payload:{data}
  }
}



export const acceptInvante =(data)=>{
  return{
    type:ADD_MERCHANTS_ACTION.REG_MERCHANTS_ACTION_INVANTE,
    payload:{data}
  }
}


export const setStatus =(data)=>{
  return{
    type:ADD_MERCHANTS_ACTION.REG_MERCHANTS_ACTION_STATUS,
    payload:{data}
  }
}
export const RemoveStatus =()=>{
  return{
    type:ADD_MERCHANTS_ACTION.REG_MERCHANTS_ACTION_REMOVE_STATUS,
  }
}
