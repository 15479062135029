import React from "react";
import CloseVector from "../../../assets/images/CloseVector.png";
import { Translation } from "react-i18next";
import Button from "@material-ui/core/Button";
import change from "../../../assets/images/change.png";
import { SaveAndCancelButtons } from "../Buttons/SaveAndCancelButtons";
import { ClearBankInfo } from "../../../modules/powerbanks/powerbanksAction";
import { useDispatch } from "react-redux";

export const InfoModalCard = ({
  info,
  position = "right",
  Buttons,
  ModalSize = "Small",
  ClickClose,
  titleText,
  Change,
  Edit,
  children,
  HandleSave,
  HandleCancel,
  Save,
  Cancel,
}) => {
  const dispatch = useDispatch();
  const closeAction = () => {
    if (ClickClose) return ClickClose();
    return dispatch(ClearBankInfo());
  };

  return (
    <Translation>
      {(t) => (
        <>
          <div
            className={
              info === "Card" ? "backDropWidtoutBackground" : "backDrop"
            }
          >
            <div
              onClick={closeAction}
              className={
                ModalSize === "Big"
                  ? "CloseBigSectionClass"
                  : "CloseSmallSectionClass"
              }
            />
            <div className={ModalSize === "Big" ? "BigHideDiv" : "hideDiv"}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "30px",
                }}
              >
                <div
                  style={
                    position === "right"
                      ? { right: "10px", position: "absolute" }
                      : {
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "20px",
                        }
                  }
                >
                  <button
                    onClick={closeAction}
                    className={"ChangeDivCloseButton"}
                  >
                    <img src={CloseVector} alt={CloseVector} />
                  </button>
                  {Edit === true && (
                    <Button onClick={() => Change()}>
                      <img src={change} alt={change} />
                    </Button>
                  )}
                </div>
                <span style={{ color: "white", marginLeft: "15px" }}>
                  {t(titleText)}
                </span>
              </div>
              <div style={{ marginLeft: "20px" }}>
                {children}
                {Buttons === true && (
                  <SaveAndCancelButtons
                    HandleCancel={HandleCancel}
                    HandleSave={HandleSave}
                    Save={Save}
                    Cancel={Cancel}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </Translation>
  );
};
