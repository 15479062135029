import TextField from "@material-ui/core/TextField";
import React from "react";
import {Translation} from "react-i18next";

const SelectInput = ({InputWidth,defaultData,pattern,disabled,value,type,onChange,name,label,onClick, style={}, children,inputProps, ...props}) => {
  return (

    <div style={{ marginTop: '10px'}}>
      <Translation>
        {(t) =>
          <TextField
            id="outlined-select-currency-native"
            select
            name={'numberSlots'}
            label={t("Number Slots")}
            InputLabelProps={{  style: {border:'none',fontSize: '14px', lineHeight: '16px', letterSpacing: '0.4px',color:'rgba(255, 255, 255, 0.6)',background:'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',padding:'0 10px 0 0 '},}}
            inputProps={{style: {color:'gray',outline:'none',width:InputWidth,fontFamily: 'Arial',border:'1px solid white',borderRadius: '5px',}}}
            onChange={onChange}
            SelectProps={{native: true,}}
            variant="outlined"

          >
            <option value={defaultData ? defaultData : ''}>{defaultData ? defaultData : ''}</option>
            <option value={3}>{3}</option>
            <option value={4}>{4}</option>
            <option value={6}>{6}</option>
            <option value={8}>{8}</option>
            <option value={9}>{9}</option>
            <option value={24}>{24}</option>
            <option value={48}>{48}</option>
          </TextField>
        }</Translation>
    </div>
  )
};

export default SelectInput
