import React from "react";
import "./styles/index.css";

export const OutlinedButton = ({
  children,
  onClick,
  color = "white",
  disabled,
}) => {
  const throwCorrectClass = () => {
    switch (color) {
      case "green":
        return "greenButton";
      case "red":
        return "redButton";
      case "disabled":
        return "disabledButton";
      default:
        return "commonButton";
    }
  };

  const handleClick = () => {
    if (onClick) return onClick();
  };

  return (
    <button
      className={throwCorrectClass()}
      onClick={handleClick}
      disabled={!!disabled}
    >
      {children}
    </button>
  );
};
