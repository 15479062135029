import CircularProgress from "@material-ui/core/CircularProgress";
import TextFieldAddStation from "./Inputs/TextFieldAddStation";
import React from "react";
import {Translation} from "react-i18next";

const styles = {
    inputStyle: {
        border: '1px solid rgba(255, 255, 255, 0.87)',
        boxSizing: 'border-box',
        borderRadius: '5px',
        padding: '6px',
        background: 'none',
        width: '260px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.5px',
        color: 'gray',
        marginTop: '8px'
    },
}

export const CheckIdAndNumber = ({showLoaderForCech, onChange, name, value, type, label, CechAnswer, ErrText,Leng,wid}) => {

    return (
        <>

            {showLoaderForCech === true &&
            <div style={{position: 'absolute', marginTop: '25px', right: '70px'}}>
                <CircularProgress size={30} disableShrink/>
            </div>
            }

            <TextFieldAddStation onChange={onChange}
                                 name={name}
                                 value={value}
                                 type={type}
                                 style={styles.inputStyle}
                                 label={label}
                                 Length={Leng}
                                 width={wid}
            />
            {CechAnswer === true &&
            <div style={{marginTop: '10px'}}>
                <span className={'errMessageTextStyle'}>{ErrText}</span>
            </div>
            }


        </>
    )
}