export const ADD_STATION_IMAGE = {
  ADD_IMAGE_REQUEST:'ADD_STATION_IMAGE.ADD_IMAGE_REQUEST',
  ADD_IMAGE_SUCCESS:'ADD_STATION_IMAGE.ADD_IMAGE_SUCCESS',
  ADD_IMAGE_FAIL:'ADD_STATION_IMAGE.ADD_IMAGE_FAIL'

}

const initialState ={
  ADDPhoto:[]
}

export default (state=initialState,action)=>{
  switch (action.type) {
    case ADD_STATION_IMAGE.ADD_IMAGE_REQUEST:{
      return{...state,ADDPhoto:initialState.ADDPhoto}
    }
    case ADD_STATION_IMAGE.ADD_IMAGE_SUCCESS:{
      return {...state,ADDPhoto:action.payload.data}
    }
    case ADD_STATION_IMAGE.ADD_IMAGE_FAIL:{
      return{...state,ADDPhoto:initialState.ADDPhoto}
    }
    default:{
      return state
    }
  }
}