import {USER_INFO} from "./usersInfoReducer";

export const sendUserInfo =(data,page)=>{
  return{
    type:USER_INFO.SEND_USER_INFO,
    payload:{data,page}
  }
}
export const deleteUserInfo = ()=>{
  return{
    type:USER_INFO.CLEAR_USER_INFO
  }
}
