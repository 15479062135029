import {USERS_DEL} from "./DeleteUsersReducer";


export const DeleteUsers = (phoneNumber,page) =>{
  return{
    type:USERS_DEL.DELETE_USERS_REQUEST,
    payload:{phoneNumber,page}
  }
}

