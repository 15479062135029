import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";


export const DeleteIconButton =({onClick,key}) =>{
    return(
        <button key={key} className={'DeleteIcon'} onClick={onClick}>
            <DeleteIcon color={'secondary'}/>
        </button>
    )
}