import { axiosInstance } from "../api";

const POWERBANK_ENDPOINT = "/api/private/admin/powerbank";

export const downloadPowerbanksListService = (payload) => {
  return axiosInstance.get(`${POWERBANK_ENDPOINT}/download`, {
    params: { ...payload },
  });
};

export const photoControlService = (powerBankId) => {
  return axiosInstance.get(`${POWERBANK_ENDPOINT}/${powerBankId}/photo`);
};

export const blockPowerBankService = (powerBankId, block, comment = "") => {
  const body = comment.trim() ? { text: comment } : {};

  return axiosInstance.post(
    `${POWERBANK_ENDPOINT}/${powerBankId}/block/${block}`,
    body
  );
};

export const searchPowerBankCollectionByParams = (payload) => {
  return axiosInstance.get(`${POWERBANK_ENDPOINT}`, {
    params: { ...payload, page: 0 },
  });
};

export const searchPowerBankByParams = ({ value }) => {
  return axiosInstance.get(`${POWERBANK_ENDPOINT}/${value}`);
};

export const searchPowerBankByStationParams = ({ value }) => {
  return axiosInstance.get(`${POWERBANK_ENDPOINT}/station/${value}`);
};
