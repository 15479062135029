import {put, takeLatest,call} from "@redux-saga/core/effects";
import {GET_MERCHANT_BY_EMAIL} from "./GetMerchantReducer";
import * as api from "../../api";
import {BlockOneMerchantByAdmin} from "./GetMerchantAction";


function* merchantsSaga() {
  yield takeLatest(GET_MERCHANT_BY_EMAIL.GET_MERCHANT_REQUEST, handleSaga)
}

function* handleSaga(action) {
  try {
    const {data} = yield call(api.getMerchantsBy,action.payload.adminEmail)
    yield put({
      type: GET_MERCHANT_BY_EMAIL.GET_MERCHANT_SUCCESS,
      payload: {data}
    })
   } catch (e) {
    yield put({
      type: GET_MERCHANT_BY_EMAIL.GET_MERCHANT_FAIL,
      message: e.message
    })
  }
}

export default merchantsSaga
