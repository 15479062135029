import React, {Component} from 'react';
import {Translation} from "react-i18next";
import CloseVector from "../../../assets/images/CloseVector.png";
import RegTextField from "../../../components/common/Inputs/RegTextField";
import CancelButton from "../../../components/common/Buttons/CancelButton";
import SaveButton from "../../../components/common/Buttons/SaveButton";
import PhoneNUmber from "../../../components/common/Inputs/PhoneNUmber";
import {connect} from "react-redux";
import {
  merchantReg,
  RegIndividualMerchant,
  RemoveStatus
} from "../../../modules/merchantes/AddMerchants/AddMerchantsAction";
import {DaDataRequestCom} from "../../../components/common/Inputs/DaDataRequestCom";
import OnlyNumbers from "../../../components/common/Inputs/OnlyNumbers";
import DateOfIssueTextField from "../../../components/common/Inputs/DateOfIssueTextField";
import Checkbox from "@material-ui/core/Checkbox";
import {OnlineCass} from "../../../components/common/OnlineCass";
import TextField from "@material-ui/core/TextField";
import {WithoutRevShare} from "../../../components/common/WithoutRevShare";


let token = "d395d2fe1619a04a7f414de8d730b54a1fccf9a2";

class ChangeIndividualMerchant extends Component {
  constructor(props) {
    super(props);
    this.state={
      inn:'',
      bankName:'',
      phoneNumber:'',
      email:'',
      name:'',
      surname:'',
      pass:'',
      accountNumber:'',
      middleName:'',
      bic:'',
      series:'',
      actualAddress:'',
      addressOfRegistration:'',
      dateOfIssue:'',
      documentNumber:'',
      issueBy:'',
      issueCode:'',
      innNameSS:'',
      CheckboxCheck:false,
      emailValid:false,
      legalAddress:'',
      onlineCheckout:'',
      contractId: '',
      revShare: '',
      contractDate: '',
      withoutRevShare: false,
      partnerEmail: '',



      shopId:'',
      someInfo:[],
      bankInfo:[],
      bicInfo:[],
      innInfo:[],
      nameOfLegalEntityInfo:[],
      legalAddressInfo:[],
      nameInfo:[],
      surnameInfo:[],
      middleNameInfo:[],
      actualAddressInfo:[],
      issueCodeInfo:[]
    }
  }

componentDidMount() {
    this.setState({
      email:this.props.info.email,
      phoneNumber:this.props.info.phoneNumber,
      bankName:this.props.info.bankName,
      inn:this.props.info.inn,

      shopId:this.props.info.shopId,
      name:this.props.info.name,
      surname:this.props.info.surname,
      pass:this.props.info.password,
      accountNumber:this.props.info.accountNumber,
      middleName:this.props.info.middleName,
      bic:this.props.info.bic,
      series:this.props.info.document.series,
      actualAddress:this.props.info.document.actualAddress,
      addressOfRegistration:this.props.info.document.addressOfRegistration,
      dateOfIssue:this.props.info.document.dateOfIssue,
      documentNumber:this.props.info.document.documentNumber,
      issueBy:this.props.info.document.issueBy,
      issueCode:this.props.info.document.issueCode,
      onlineCheckout:this.props.info.onlineCheckout,
      contractId:this.props.info.contractId,
      contractDate:this.props.info.contractDate,
      revShare:this.props.info.revShare,
      withoutRevShare:this.props.info.withoutRevShare,
      partnerEmail:this.props.info.partnerEmail,
    })
}

  ChackboxHandlechange = (e) => {
    this.setState({partnerEmail: e.target.value})
  }

  handleChangeContractDate = (e) => {
    this.setState({contractDate: e.target.value})
  }

  handleIndividual = (name) => {
    this.setState({
      emailValid:false, errMessage:false,onlineCheckout:'',
      accountNumber: '', bankName: '', bic: '', email: '', inn: '', addressOfRegistration: '', middleName: '', name: '',
      nameOfLegalEntity: '', pass: '', phoneNumber: '' , surname: '', innNameSS: '', innName: '', NAME: '', series: ''
    })
    this.setState ({activeButton: name ==='Organization' ? 'Organization' : 'Self' })

  }

  handleOnlyNumber = (ev) => {
    const {name} = ev.target;
    if (name === 'bic') {
      const val = ev.target.value;
      if (ev.target.validity.valid) {
        this.setState({bic: ev.target.value});
      } else if (val === '') {
        this.setState({bic: val})
      }
    }
    if (name === 'accountNumber') {
      const val = ev.target.value;
      if (ev.target.validity.valid) {
        this.setState({accountNumber: ev.target.value});
      } else if (val === '') {
        this.setState({accountNumber: val})
      }
    }
    if (name === 'series') {
      const val = ev.target.value;
      if (ev.target.validity.valid) {
        this.setState({series: ev.target.value});
      } else if (val === '') {
        this.setState({series: val})
      }
    }
    if (name === 'documentNumber') {
      const val = ev.target.value;
      if (ev.target.validity.valid) {
        this.setState({documentNumber: ev.target.value});
      } else if (val === '') {
        this.setState({documentNumber: val})
      }
    }
    if (name === 'shopId') {
      const val = ev.target.value;
      if (ev.target.validity.valid) {
        this.setState({shopId: ev.target.value});
      } else if (val === '') {
        this.setState({shopId: val});
      }
    }
    if (name === 'revShare') {
      const val = ev.target.value;
      if (ev.target.validity.valid) {
        this.setState({revShare: ev.target.value});
      } else if (val === '') {
        this.setState({revShare: val});
      }
    }

  }


  handleChanges = (ev) => {
      const {name, value} = ev.target;
      const newState = {};
      newState[name] = value;
      this.setState(newState);
      this.setState({errMessage: false})
  };

  handleChangesEmail = (e) => {
    this.setState({email: e.target.value})
  }
  handleChangeDateOfIssue = (e) => {
    this.setState({dateOfIssue: e.target.value})
  }
  handleChangePhoneNumber = (e) => {
    const val = e.target.value;
    if (e.target.validity.valid) {
      if (!this.state.phoneNumber.length) {
        if (val !== '+') {
          if (val === '8') {
            this.setState({phoneNumber: `+7`});
          } else if (val === '9') {
            this.setState({phoneNumber: `+79`});
          } else {
            this.setState({phoneNumber: `+${e.target.value}`});
          }
        } else {
          this.setState({phoneNumber: `+`});
        }
      } else {
        if (val.slice(1, 2) === '8') {
          this.setState({phoneNumber: `+7`});
        } else if (val.slice(1, 2) === '9') {
          this.setState({phoneNumber: `+79`});
        } else {
          this.setState({phoneNumber: e.target.value});
        }
      }

    } else if (val === '') {
      // this.setState({phoneNumber: val});
    }
  };


  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.innName) {
      const info = this.state.innName
      if (!this.state.innNameSS) {
        this.setState({innNameSS: info})
      }
    }
  }


  handleChangeAddress =()=>{

    if(this.state.CheckboxCheck === false) {
        let addressOfRegistration = this.state.addressOfRegistration
      this.setState({actualAddress: addressOfRegistration,CheckboxCheck:true})
    }else{
      this.setState({actualAddress: '',CheckboxCheck:false})
    }
  }

  chechEmailValid=(e)=>{
    let Value = e.target.value
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(Value.length >= 1) {
      if (re.test(Value)) {
        this.setState({emailValid: false})
      } else {
        this.setState({emailValid: true})
      }
    }
  }


  changeIssueBy=(item)=>{
    this.setState({issueBy:item.unrestricted_value, issueCode:item.data.code,someInfo:''})
  }

  changeBankBy=(item)=>{
    this.setState({bankName: item.unrestricted_value, bic: item.data.bic,bankInfo:'',bicInfo:''})
  }
  changeInnBy= (e)=>{
    this.setState({
      // position :e.data.management ? e.data.management.post : '',
      innNameSS: '', nameOfLegalEntity: e.value,
      innName: e.data.inn, inn: e.data.inn,
      // legalAddress: e.data.address.unrestricted_value,
      innInfo:'',
      nameOfLegalEntityInfo:''
    })
  }
  changeInnByIndi= (e)=>{
    this.setState({
      innNameSS: '', nameOfLegalEntity: e.value,
      innName: e.data.inn, inn: e.data.inn,
      innInfo:'', nameOfLegalEntityInfo:''
    })
  }
  changeLegalAddressBy = (e)=>{
    this.setState({addressOfRegistration:e.unrestricted_value})
  }
  changeActualAddress =(e)=>{
    this.setState({actualAddress: e.unrestricted_value})
  }
  changeMiddleName =(e) =>{
    this.setState({middleName:e.unrestricted_value,middleNameInfo:''})
  }
  changeNameBy =(e)=>{
    if (e.data.name && e.data.surname && e.data.patronymic) {
      this.setState({
        name: e.data.name, surname: e.data.surname, middleName: e.data.patronymic, nameInfo: '', surnameInfo: ''
      })
    } else if (e.data.name && e.data.surname) {
      this.setState({name: e.data.name, surname: e.data.surname, nameInfo: '', surnameInfo: ''})
    } else if (e.data.name && e.data.patronymic) {
      this.setState({name: e.data.name,middleName: e.data.patronymic, nameInfo: '', surnameInfo: ''})
    } else if (e.data.name) {
      this.setState({name: e.data.name, nameInfo: ''})
    } else if (e.data.surname) {
      this.setState({surname: e.data.surname, surnameInfo: ''})
    }
  }


  NameDaDataRequest=(ev)=>{
    const val = ev.target.value;
    let name = ev.target.name
    if(name ==='name'){
      if (ev.target.validity.valid) {
        this.setState({name: ev.target.value});
      }
      // else if (val === '') {
      // this.setState({name: val})
      // }
    }
    if(name ==='surname'){
      if (ev.target.validity.valid) {
        this.setState({surname: ev.target.value});
      } else if (val === '') {
        // this.setState({surname: val})
      }
      // this.setState({surname: ev.target.value})
    }
    if(name === 'middleName'){
      if (ev.target.validity.valid) {
        this.setState({middleName: ev.target.value});
      } else if (val === '') {
        // this.setState({middleName: val})
      }
      // this.setState({middleName:ev.target.value})
    }


    let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio";
    let query = ev.target.value;
    let options = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Token " + token
      },
      body: JSON.stringify({query: query})
    }
    fetch(url, options)
      .then(response => response.json())
      .then(result => this.setState(
        name === 'name' ?
          {nameInfo:result.suggestions} :
          name ==='surname' ?
            {surnameInfo:result.suggestions} :
            name=== 'middleName' ?
              {middleNameInfo:result.suggestions}:''
      ))
      .catch(error => console.log("error", error))
  }



  legalAddressDaDataRequest=(ev)=>{
    if(ev.target.name ==='actualAddress'){
      this.setState({actualAddress:ev.target.value})

    }else {
      this.setState({addressOfRegistration: ev.target.value})
    }
    let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
    let query = ev.target.value;
    let options = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Token " + token
      },
      body: JSON.stringify({query: query})
    }
    ev.target.name==='actualAddress' ?
      fetch(url, options)
        .then(response => response.json())
        .then(result => this.setState({actualAddressInfo:result.suggestions}))
        .catch(error => console.log("error", error))
      :
      fetch(url, options)
        .then(response => response.json())
        .then(result => this.setState({legalAddressInfo:result.suggestions}))
        .catch(error => console.log("error", error))
  }


  InnDaDataRequest=(ev)=>{

    let name = ev.target.name
    if(name ==='nameOfLegalEntity'){
      this.setState({nameOfLegalEntity:ev.target.value})
    }
    if(name==='Inn') {
      this.setState({inn: ev.target.value})
    }
    let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party";
    let query = ev.target.value;
    let options = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Token " + token
      },
      body: JSON.stringify({query: query})
    }
    ev.target.name === 'Inn' ?
      fetch(url, options)
        .then(response => response.json())
        .then(result => this.setState({innInfo:result.suggestions}))
        .catch(error => console.log("error", error))
      :
      fetch(url, options)
        .then(response => response.json())
        .then(result =>  this.setState({nameOfLegalEntityInfo:result.suggestions}))
        .catch(error => console.log("error", error))
  }



  bankDaDataRequest =(ev)=>{
    if(ev.target.name === 'bankName') {
      this.setState({bankName: ev.target.value})
      let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/bank";
      let query = ev.target.value;
      let options = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Token " + token
        },
        body: JSON.stringify({query: query})
      }
      fetch(url, options)
        .then(response => response.json())
        .then(result => this.setState({bankInfo:result.suggestions}))
        .catch(error => console.log("error", error))
    }

    if(ev.target.name === 'bic') {
      const val = ev.target.value;
      if (ev.target.validity.valid) {
        let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/bank";
        let query = ev.target.value;
        let options = {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Token " + token
          },
          body: JSON.stringify({query: query})
        }
        fetch(url, options)
          .then(response => response.json())
          .then(result =>  this.setState({bicInfo:result.suggestions}))
          .catch(error => console.log("error", error))

        this.setState({bic: ev.target.value});
      } else if (val === '') {
        this.setState({bic: val});
      }

    }

  }


  someTry =(ev)=>{
    if(ev.target.name === 'issueCode'){
      this.setState({issueCode: ev.target.value})
    }else {
      this.setState({issueBy: ev.target.value})
    }
    let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fms_unit";
    let query = ev.target.value

    let options = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Token " + token
      },
      body: JSON.stringify({query: query})
    }
    ev.target.name === 'issueCode' ?
      fetch(url, options)
        .then(response => response.json())
        .then(result => this.setState({issueCodeInfo:result.suggestions}))
        .catch(error => console.log("error", error))
      :
      fetch(url, options)
        .then(response => response.json())
        .then(result => this.setState({someInfo:result.suggestions}))
        .catch(error => console.log("error", error));
  }


  sentRequest=()=> {
    const inn = this.state.inn ? this.state.inn : this.props.info.inn
    const name = this.state.name ? this.state.name : this.props.info.name
    const surname = this.state.surname ? this.state.surname : this.props.info.surname
    const pass = this.state.pass ? this.state.pass : this.props.info.password
    const accountNumber = this.state.accountNumber ? this.state.accountNumber : this.props.info.accountNumber
    const middleName = this.state.middleName ? this.state.middleName : this.props.info.middleName
    const email = this.state.email ? this.state.email : this.props.info.email
    const phoneNumber = this.state.phoneNumber ? this.state.phoneNumber : this.props.info.phoneNumber
    const bankName = this.state.bankName ? this.state.bankName : this.props.info.bankName
    const bic = this.state.bic ? this.state.bic : this.props.info.bic
    const series = this.state.series ? this.state.series : this.props.info.document.series
    const actualAddress = this.state.actualAddress ? this.state.actualAddress : this.props.info.document.actualAddress
    const addressOfRegistration = this.state.addressOfRegistration ? this.state.addressOfRegistration : this.props.info.document.addressOfRegistration
    const dateOfIssue = this.state.dateOfIssue ? this.state.dateOfIssue : this.props.info.document.dateOfIssue
    const documentNumber = this.state.documentNumber ? this.state.documentNumber : this.props.info.document.documentNumber
    const issueBy = this.state.issueBy ? this.state.issueBy : this.props.info.document.issueBy
    const issueCode = this.state.issueCode ? this.state.issueCode : this.props.info.document.issueCode
    const shopId = this.state.shopId ? this.state.shopId : this.props.info.shopId
    const onlineCheckout = this.state.onlineCheckout ? this.state.onlineCheckout : this.props.info.onlineCheckout
    const contractId = this.state.contractId ? this.state.contractId : this.props.info.contractId
    const contractDate = this.state.contractDate ? this.state.contractDate : this.props.info.contractDate
    const partnerEmail = this.state.partnerEmail ? this.state.partnerEmail : this.props.info.partnerEmail
    const revShare = this.state.revShare ? this.state.revShare : this.props.info.revShare
    const withoutRevShare = this.state.withoutRevShare

    const datas =[name, surname, middleName, inn, email, phoneNumber, pass,
      bankName, bic, accountNumber, series, documentNumber, dateOfIssue, addressOfRegistration,
      actualAddress, issueBy, issueCode,'Edit',this.props.info.id,
      shopId,onlineCheckout, contractId, contractDate, revShare, withoutRevShare, partnerEmail
    ]
    if(name && surname && middleName && inn && email && phoneNumber && pass && bankName &&
      bic && bic && accountNumber && series && documentNumber && dateOfIssue && addressOfRegistration &&
      actualAddress && issueBy && issueCode
    ){

      if(this.state.emailValid === false) {
        this.props.RegIndividualMerchant(datas)
        this.props.handleCLose()
        this.props.close()
      }
    }else{
      this.setState({errMessage:true})
    }
  }
  render() {
    const Role = localStorage.getItem('role');
    const token = 'd395d2fe1619a04a7f414de8d730b54a1fccf9a2';
    const admins = this.props.Merchants;
    const {

    phoneNumber,bankName,inn,email,
      name, surname, pass, accountNumber, middleName, bic, series, actualAddress, addressOfRegistration, dateOfIssue, documentNumber, issueBy, issueCode
      ,shopId
    } = this.state

    console.log(this.state,'this.state')
    return (
      <>
        <Translation>
          {(t) =>
            <>
              <div className={'headDiv'}>
                <div onClick={this.props.handleCLose} className={'CloseSectionClass'}/>
                <div className={'MerchantsHideDiv'}
                     onClick={()=>this.setState({innInfo:'',  someInfo:'', bankInfo:'', bicInfo:'', nameOfLegalEntityInfo:'',
                       legalAddressInfo:'', nameInfo:'', surnameInfo:'', middleNameInfo:'', actualAddressInfo:'',})}
                >
                  <div className={'MerchantClass'}>

                    <div style={{display:'flex',alignItems:'center'}}>
                    <button onClick={this.props.handleCLose}
                            style={{background: 'none', border: 'none', marginTop: '20px', marginLeft: '10px',cursor:'pointer'}}>
                    <img src={CloseVector} alt={CloseVector}/>
                      </button>
                      <span style={{marginLeft:'20px',marginTop:'18px'}}>{t('Change Individual')}</span>
                    </div>


                    <div style={{textAlign: 'center',color:'black'}}>

                      <DaDataRequestCom
                        onBlur={() => this.setState({surnameInfo:''})}
                        // Pattern={'^[a-zA-Z]*$'}
                        name={'surname'} label={t('Surname')} type={'tel'}
                        value={surname} onChange={this.NameDaDataRequest}
                        info={this.state.surnameInfo} onClick={this.changeNameBy}
                      />

                       <DaDataRequestCom
                        onBlur={() => this.setState({nameInfo:''})}
                        // Pattern={'^[a-zA-Z]*$'}
                        name={'name'} label={t('Name')} type={'tel'}
                        value={name} onChange={this.NameDaDataRequest}
                        info={this.state.nameInfo} onClick={this.changeNameBy}
                      />

                      <DaDataRequestCom
                        onBlur={() => this.setState({middleNameInfo:'',nameForChangeNameBy:''})}
                        // Pattern={'^[a-zA-Z]*$'}
                        name={'middleName'} label={t('Middle Name')} type={'tel'}
                        value={middleName} onChange={this.NameDaDataRequest}
                        info={this.state.middleNameInfo} onClick={this.changeMiddleName}
                      />

                      <DaDataRequestCom
                        infoForText={'inn'}
                        onBlur={() => this.setState({innInfo:''})}
                        name={'Inn'} label={t('INN ')} type={'tel'}
                        value={inn} onChange={this.InnDaDataRequest}
                        info={this.state.innInfo} onClick={ this.changeInnBy}
                        Length={13}
                      />
                      <RegTextField
                        onBlur={(e)=>this.chechEmailValid(e)}
                        onFocus={(e)=>this.setState({emailValid:false})}
                        typeError={this.state.emailValid === true ? 'Error' : ''}
                        value={email} name={'email'} onChange={this.handleChangesEmail}
                        label={t('Email')}
                      />
                      <PhoneNUmber value={phoneNumber} onChange={this.handleChangePhoneNumber} label={t('Phone')}/>
                      <DaDataRequestCom
                        infoForText={'bank'}
                        onBlur={() => this.setState({bicInfo:''})}
                        name={'bic'} label={t('Bic')} type={'tel'}
                        value={bic} onChange={this.bankDaDataRequest}
                        info={this.state.bicInfo} onClick={ this.changeBankBy}
                        Pattern={'^[0-9]\\d*'}
                        Length={9}
                      />
                      <DaDataRequestCom
                        infoForText={'bank'}
                        onBlur={() => this.setState({bankInfo:''})}
                        name={'bankName'} label={t('Bank')} type={'tel'}
                        value={bankName} onChange={this.bankDaDataRequest}
                        info={this.state.bankInfo} onClick={ this.changeBankBy}
                      />
                      <OnlyNumbers Length={20} value={accountNumber} name={'accountNumber'}
                                   onChange={this.handleOnlyNumber} label={t('Account number')}/>

                      <p style={{color:'white'}}>{t("Passport data")}</p>

                      <OnlyNumbers Length={4} name={'series'} value={series} onChange={this.handleOnlyNumber} label={t('Series')}/>


                      <OnlyNumbers Length={6} name={'documentNumber'} value={documentNumber} onChange={this.handleOnlyNumber} label={t('Document Number')}/>
                      <DateOfIssueTextField Length={11} value={dateOfIssue} onChange={(e) => this.handleChangeDateOfIssue(e)} label={t('Date Of Issue')}/>


                      <DaDataRequestCom
                        infoForText={'code'}
                        onBlur={() => this.setState({someInfo:''})}
                        name={'issueBy'} label={t('Issue By')} type={'tel'}
                        value={issueBy} onChange={this.someTry}
                        info={this.state.someInfo} onClick={ this.changeIssueBy}
                      />


                      <DaDataRequestCom
                        infoForText={'code'} Length={9}
                        onBlur={() => this.setState({issueCodeInfo:''})}
                        name={'issueCode'} label={t('Issue Code')} type={'tel'}
                        value={this.state.issueCode} onChange={this.someTry}
                        info={this.state.issueCodeInfo} onClick={ this.changeIssueBy}
                      />

                      {/*<RegTextField*/}
                      {/*  value={issueCode}  Length={9}*/}
                      {/*  name={'issueCode'} onChange={this.handleChanges} label={t('Issue Code')}/>*/}

                      <DaDataRequestCom
                        onBlur={() => this.setState({legalAddressInfo:''})}
                        name={'addressOfRegistration'} label={t('Address Of Registration')} type={'tel'}
                        value={addressOfRegistration} onChange={this.legalAddressDaDataRequest}
                        info={this.state.legalAddressInfo} onClick={ this.changeLegalAddressBy}
                      />
                      <div style={{marginTop:'10px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <span style={{color:'white'}}>{t('Matches the registration address')}</span>
                        <Checkbox
                          disabled={!this.state.addressOfRegistration.length}
                          onClick={this.handleChangeAddress}
                          style={{color:this.state.addressOfRegistration.length ? 'white' : 'gray', marginLeft: '30px'}}
                          value="secondary"
                          inputProps={{'aria-label': 'secondary checkbox'}}
                        />
                      </div>

                      <DaDataRequestCom
                        onBlur={() => this.setState({actualAddressInfo:''})}
                        name={'actualAddress'} label={t('Actual Address')} type={'tel'}
                        value={actualAddress} onChange={this.legalAddressDaDataRequest}
                        info={this.state.actualAddressInfo} onClick={ this.changeActualAddress}
                      />
                      {this.props.info.isPartner ?
                          <>
                            <OnlineCass
                                checked={this.state.onlineCheckout}
                                handleClick={this.handleChangeChech }/>

                            <OnlyNumbers Length={6} value={shopId} name={'shopId'}
                                         onChange={this.handleOnlyNumber} label={t('Shop Id')}/>
                          </> :
                          <>
                          <WithoutRevShare checked={this.state.withoutRevShare} handleClick={() => this.setState({withoutRevShare: !this.state.withoutRevShare})}/>
                          {this.state.withoutRevShare === false ?
                              <>
                            <OnlyNumbers type={'tel'} Length={20}
                                         value={this.state.revShare}
                                         name={'revShare'} onChange={this.handleOnlyNumber}
                                         label={t('Rev Share')}/>
                            <RegTextField
                                // type={'tel'}
                                value={this.state.contractId}
                                name={'contractId'} onChange={this.handleChanges}
                                // pattern={'^[a-zA-Z]*$'}
                                label={t('Contract Id')}/>
                            <DateOfIssueTextField Length={11} value={this.state.contractDate}
                                                  onChange={(e) => this.handleChangeContractDate(e)}
                                                  label={t('Date Of Contract')}/>
                                </>:''}
                            {Role === 'SUPER_ADMIN' || Role === 'ADMIN' ?
                                <div style={{marginTop: '20px'}}>
                                  <TextField
                                      id="outlined-select-currency-native"
                                      select
                                      label={t("Admin")}
                                      InputLabelProps={{
                                        style: {
                                          width: '270px',
                                          color: 'gray',
                                          background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
                                          padding: '0 10px 0 0 '
                                        },
                                      }}
                                      inputProps={{
                                        style: {
                                          width: '270px',
                                          color: 'gray',
                                          fontFamily: 'Arial',
                                          border: '1px solid white',
                                          borderRadius: '5px',
                                        }
                                      }}
                                      onChange={this.ChackboxHandlechange}
                                      SelectProps={{native: true,}}
                                      variant="outlined"
                                      value={this.state.partnerEmail}
                                  >
                                    {admins.map((row, i) => (
                                        <option key={i} value={row.email}>{row.email}</option>
                                    ))
                                    }
                                  </TextField>
                                </div> : ''}
                          </>
                      }
                    </div>
                    <div style={{marginTop: '20px', marginLeft: '14px',marginBottom:'20px'}}>
                      <CancelButton onClick={this.props.handleCLose}>{t("CANCEL")}</CancelButton>
                      <SaveButton onClick={this.sentRequest}>{t("Change")}</SaveButton>
                    </div>
                  </div>
                </div>
              </div>
            </> }</Translation>
      </>
    );
  }
}
const mapStateToProps = state => ({

  status: state.AddMerchantsReducer.status,
  Merchants: state.MerchantesReducer.Merchants,
})

export default connect(mapStateToProps, {RegIndividualMerchant,merchantReg,RemoveStatus})(ChangeIndividualMerchant)
