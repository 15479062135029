import {takeLatest, call, put} from "@redux-saga/core/effects";
import {ADD_STATION} from "./addStationsReducer";
import * as api from "../../api";
import {GetStation} from "../stationsAction";


function* handleSaga() {
  yield takeLatest(ADD_STATION.ADD_REQUEST, AddHandleSaga)
}

function* AddHandleSaga(action) {
  try {
    const {data} = yield call(api.addStation,action.payload.tariffPlan, action.payload.address,action.payload.latitude,action.payload.longitude,action.payload.numberSlots,action.payload.owner,action.payload.stationID,action.payload.stationNumber,action.payload.workTime,action.payload.additionalAddress)
    yield put({
      type: ADD_STATION.ADD_SUCCESS,
      payload: {data}
    })
    const Page =action.payload.page >=0 ?action.payload.page : 0
    yield put(GetStation(Page))
  } catch (e) {
    yield put({
      type: ADD_STATION.ADD_FAIL,
      message: e.message
    })
  }
}

export default handleSaga
