import React, { useEffect, useState } from 'react';
import { MerchantsList } from '../MerchantsList';
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from 'react-redux';
import { clearMerchantsPowerbanksConditionsDashboardSortList, sortPowerbanksConditionsDashboardInfoByMerchant } from "../../../../modules/dashboard/dashboardAction";

export const PowerbanksConditionsChart = ({dataPowerbanks, merchantsList}) => {
    const dispatch = useDispatch();
    const Language = localStorage.getItem("Lan");
    const role = localStorage.getItem("role");
    const sortedDataByMerchantEmail = useSelector(state => state.dashboardReducer.PowerbanksConditionsSortedByMerchant);
    const [datas, setDatas] = useState({
        options: {
            labels: Language === 'RU' ? 
                ['Требуется замена', 'Необходима проверка', 'Работает']
                :
                ['Replacement required', 'Verification required', 'Working'],
            colors: ['#ff0000', '#ffa500', '#008000'],
            plotOptions: {
                pie: {
                donut: {
                    labels: {
                    show: true,
                    value: {
                        show: true,
                        color: '#fff',
                        fontSize: '20px',
                        label: 'Total'
                    },
                    total: {
                        show: true,
                        color: '#fff',
                        fontSize: '15px',
                        label: 'Total'
                    }
                    }
                }
                }
            },
            legend: {
                fontSize: '16px',
                labels: {
                colors: '#fff'
                },
                markers: {
                offsetX: '-5'
                },
                position: window.innerWidth > 768 ? "right" : "bottom",
            },
        },
        statuses: {
            replacementRequired: 0,
            requiredToCheck: 0,
            working: 0
        },
        merchants:[]
    });

    useEffect(() => {
        window.addEventListener("resize", () => {
            window.innerWidth > 768 ? 
            setDatas(pv => ({
                ...pv,
                options: {
                    ...pv.options,
                    legend: {
                        ...pv.options.legend,
                        position: "right"
                    }
                }
            })) 
            : 
            setDatas(pv => ({
                ...pv,
                options: {
                    ...pv.options,
                    legend: {
                        ...pv.options.legend,
                        position: "bottom"
                    }
                }
            }))
        });

        return () => window.removeEventListener("resize", () => {
            window.innerWidth > 768 ? 
            setDatas(pv => ({
                ...pv,
                options: {
                    ...pv.options,
                    legend: {
                        ...pv.options.legend,
                        position: "right"
                    }
                }
            })) 
            : 
            setDatas(pv => ({
                ...pv,
                options: {
                    ...pv.options,
                    legend: {
                        ...pv.options.legend,
                        position: "bottom"
                    }
                }
            }))
        });
    }, [])
    
    useEffect(() => {
        if(sortedDataByMerchantEmail.allPowerBanks !== undefined){
            setDatas({
                ...datas,
                statuses: {
                    replacementRequired: sortedDataByMerchantEmail.replacementRequired,
                    requiredToCheck: sortedDataByMerchantEmail.requiredToCheck,
                    working: sortedDataByMerchantEmail.working
                }
            })
        } else {
            setDatas({
                ...datas,
                statuses: {
                    replacementRequired: dataPowerbanks.replacementRequired,
                    requiredToCheck: dataPowerbanks.requiredToCheck,
                    working: dataPowerbanks.working
                }
            })
        }
    }, [sortedDataByMerchantEmail]);

    useEffect(() => {
        setDatas({
            ...datas,
            statuses: {
                replacementRequired: dataPowerbanks.replacementRequired,
                requiredToCheck: dataPowerbanks.requiredToCheck,
                working: dataPowerbanks.working
            }
        })
    }, [dataPowerbanks])

    useEffect(() => {
        setDatas({
            ...datas,
            merchants: merchantsList
        })
    }, [merchantsList])

    const sortFn = (item) => {
        dispatch(sortPowerbanksConditionsDashboardInfoByMerchant(item))
    }

    const chooseAllMerchants = () => {
        dispatch(clearMerchantsPowerbanksConditionsDashboardSortList());
    }

    return (
        <>
            <Chart
                options={datas.options}
                series={
                    [
                        datas.statuses.replacementRequired,
                        datas.statuses.requiredToCheck,
                        datas.statuses.working
                    ]
                }
                type="donut"
                width= {window.innerWidth > 768 ? "550" : "320" }
            />
            {
                role === 'SUPER_ADMIN' || role === 'ADMIN' ? 
                <MerchantsList 
                    chooseAllMerchants={chooseAllMerchants}
                    sortFn={sortFn} 
                    merchantsList={datas.merchants}
                />
                :
                null
            }
        </>
    )
}