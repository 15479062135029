export const ADMINS_ACTION = {
  OPEN_INFO: 'ADMINS_ACTION.OPEN_INFO',
  CLOSE_INFO: 'ADMINS_ACTION.CLOSE_INFO',
  OPEN_PAGE: 'ADMINS_ACTION.OPEN_PAGE',
  CLOSE_PAGE: 'ADMINS_ACTION.CLOSE_PAGE',
  GET_ADMINS_REQUEST:'ADMINS_ACTION.GET_ADMINS_REQUEST',
  GET_ADMINS_SUCCESS:'ADMINS_ACTION.GET_ADMINS_SUCCESS',
  GET_ADMINS_FAIL:'ADMINS_ACTION.GET_ADMINS_FAIL',
  OPEN_BLOCK_PAGE: 'ADMINS_ACTION.OPEN_BLOCK_PAGE',
  CLOSE_BLOCK_PAGE: 'ADMINS_ACTION.CLOSE_BLOCK_PAGE',
  BLOCK_ONE_ADMIN :'ADMINS_ACTION.BLOCK_ONE_ADMIN',
  LOCK_ADMIN_BLOCKIN:'ADMINS_ACTION.LOCK_ADMIN_BLOCKIN'
}

const initialState={
  Admins :[],
  OpenAdminsAddPage:'',
  openInfo:[],
  openAdminBlockPage:[]
}

export default (state=initialState,action)=>{
  switch (action.type) {
    case ADMINS_ACTION.GET_ADMINS_REQUEST:{
      return{...state,Admins:initialState.Admins}
    }
    case ADMINS_ACTION.GET_ADMINS_SUCCESS:{
      return{...state,Admins:action.payload.data}
    }
    case ADMINS_ACTION.OPEN_BLOCK_PAGE:{
      return{...state,openAdminBlockPage:action.payload.data}
    }
    case ADMINS_ACTION.CLOSE_BLOCK_PAGE:{
      return{...state,openAdminBlockPage:[]}
    }
    case ADMINS_ACTION.OPEN_INFO:{
      return{...state,openInfo:action.payload.data}
    }
    case ADMINS_ACTION.CLOSE_INFO:{
      return{...state,openInfo:[]}
    }
    case ADMINS_ACTION.GET_ADMINS_FAIL:{
      return{...state,Admins:initialState.Admins}
    }

    case ADMINS_ACTION.OPEN_PAGE:{
      return{...state,OpenAdminsAddPage:action.payload.data}
    }
    case ADMINS_ACTION.CLOSE_PAGE:{
      return{...state,OpenAdminsAddPage:""}
    }
    case  ADMINS_ACTION.BLOCK_ONE_ADMIN:
      const newActiveUserList=state.Admins.map(item=> {
        if (item.email === action.payload.email) {
          return {...item, blocking: action.payload.lock}
        }
        return item
      })
      return {...state,Admins:newActiveUserList}
    default:{
      return state
    }

  }

}
