import React, {Component} from 'react';
import {connect} from "react-redux";
import {GetAllGroupTariffs,} from "../../../modules/tariff/tariffsAction";
import {AddTariffText, ClosePopup,} from "../../../modules/tariff/AddTAriffText/AddTarifTextAction";
import Button from "@material-ui/core/Button";
import CloseVector from "../../../assets/images/CloseVector.png";
import TextField from "@material-ui/core/TextField";
import {addTariffGroupName} from "../../../modules/tariff/AddTariff/AddTariffAction";
import {addTariff} from "../../../modules/tariff/AddTariff/AddTariffWidthGRoup/AddTariffWidthGroupAction";
import TextFieldS from "../../../components/common/Inputs/TextField";
import {Translation} from "react-i18next";

const styles = {
  hideDiv: {
    overflowY: 'scroll',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
    boxShadow: '-2px 0px 4px rgba(0, 0, 0, 0.2), -1px 0px 10px rgba(0, 0, 0, 0.12), -4px 0px 5px rgba(0, 0, 0, 0.14)',
    width: '300px',
    height: '100%',
    position: 'absolute',
    minHeight: '100vh',
    zIndex: '20',
    right: 0,
    boxSizing: 'border-box',
  },
  ChangeDivCloseButton: {
    background: 'none',
    border: 'none',
  },
  SaveButton: {
    color: 'white',
    background: '#5F94E2',
    borderRadius: '5px',
    border: '1px solid #5F94E2',
    padding: '6px',
    marginLeft: '5px'
  },
  CancelButton: {
    color: 'white',
    background: '#EB5B47',
    borderRadius: '5px',
    border: '1px solid #EB5B47',
    padding: '6px'
  },
  addPeriodButton: {
    marginTop: '10px',
    background: 'none',
    color: '#5F94E2',
    border: 'none',
    outline: 'none',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  FormControlStyle:{
    width: '100px',
    background: 'white',
    borderRadius: '12px',
    marginTop: '5px',
  },
  textInput:{
    padding: '10px',
    borderRadius:'5px',
    width:'260px',
    marginTop: '10px',
    border: '1px solid white',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14)'
  },
  backStyle:{
    background: 'rgba(0, 0, 0, 0.4)',
    width: '100%',
    minHeight: '100vh',
    zIndex: '100',
    position: 'fixed',
    right: 0,
  },
  InputStyle:{
    marginBottom:'10px',
    color: 'white',
    border: '1px solid white',
    padding: '10px',
    width: '260px',
    borderRadius: '12px'
  },
}

class AddTariffsInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AddPeriod:false,
      showPeriods: false,
      showSecondPeriods:false,
      periodList: [],
      TariffGroupList: [],
      nameTariffGroup: '',
      location:'',
      period: 0,
      price: 0,
      tariffName: 'PROM',
      typeTime: '',
      workingTimeInterval: 0,
      activeIndex: '',
      TariffType:''
    }
  }


  NamehandleChange = (ev) => {
    this.setState({showPeriods:''})
    this.setState({nameTariffGroup: ev.target.value})
  }

  componentDidMount() {
    this.props.GetAllGroupTariffs()
  }
  ChangeLocation = (e) => {
    this.setState({location: e.target.value})
  }
  ClosePopup = () => {
    this.setState({})
    this.setState({AddPeriod:false,showSecondPeriods:false,nameTariffGroup :'', period :'',price :'', tariffName :'', typeTime :'', workingTimeInterval:''})
    this.setState({periodList:[],showPeriods: false,price:0, tariffName:'', typeTime : '', workingTimeInterval : '',nameTariffGroup:''})
    this.props.ClosePopup()
  }

  CreateTariff = () => {
    const {nameTariffGroup, period, price, tariffName, typeTime, workingTimeInterval,} = this.state
    if(nameTariffGroup && period && price && tariffName && typeTime && workingTimeInterval){
      this.props.addTariff(nameTariffGroup, period, price, tariffName, typeTime, workingTimeInterval)
      this.ClosePopup()
    }else{
      this.setState({showSecondPeriods:'Input Not Filed'})
    }

  }


  openPeriods = () => {
    const {nameTariffGroup,location,TariffType} = this.state
    if(nameTariffGroup && location && TariffType) {
      this.props.addTariffGroupName(nameTariffGroup,location,TariffType)
      this.setState({period: 1})
      this.setState({periodList: [1]})
      this.setState({AddPeriod:true})
    }else{
     this.setState({showPeriods:'Input Not Filed'})
    }
  }

  savePeriod = () => {
    const {nameTariffGroup, period, price, tariffName, typeTime, workingTimeInterval,} = this.state
    if(nameTariffGroup && period && price && tariffName && typeTime && workingTimeInterval){
    this.props.addTariff(nameTariffGroup, period, price, tariffName, typeTime, workingTimeInterval)
    this.setState({period: this.state.period + 1})
    this.state.periodList.push([1])
      this.setState({price:0, tariffName:'', typeTime : '', workingTimeInterval : ''})
    }else{
      this.setState({showSecondPeriods:'Input Not Filed'})
    }
  }
  deletePeriod = (i) => {
    this.setState({showPeriods:''})
    this.state.periodList.splice(i, 1);
  }


  TariffName = (ev) => {
    this.setState({showSecondPeriods:''})
    this.setState({tariffName: ev.target.value})
  }
  changeHour = (e) => {
    this.setState({showSecondPeriods:''})
    this.setState({typeTime: e.target.value})
  }
  ChangePrice = (e) => {
    this.setState({showSecondPeriods:''})
    this.setState({price: e.target.value})
  }
  ChangeWorkingTimeInterval = (e) => {
    this.setState({showSecondPeriods:''})
    this.setState({workingTimeInterval: e.target.value})
  }


  handleTariffTipe=(ev)=>{
    this.setState({TariffType:ev.target.value})
  }
  render() {
    const {OpenPopup} = this.props
    const {periodList} = this.state

    return (
      <>
        <Translation>
          {(t) =>
            <>
        {OpenPopup.length > 0 ?
          <div style={styles.backStyle}>
            <div onClick={this.ClosePopup} className={'CloseSectionClass'}  />
            <div style={styles.hideDiv}>
              <div style={{display: 'flex', alignItems: 'center', marginTop: '10px'}}>
                <Button onClick={this.ClosePopup} style={styles.ChangeDivCloseButton}>
                  <img src={CloseVector} alt={CloseVector}/></Button>
                <span style={{color: 'white'}}>{t("New Tariff")}</span>
              </div>

              <div style={{marginLeft: '15px', marginTop: '10px'}}>
                <TextFieldS onChange={this.NamehandleChange}  name='Name' label={t('Name')} />

                <div style={{marginTop:'10px'}}>
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    label={t("Tariff Type")}
                    InputLabelProps={{style:{color:'white',background:'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',padding:'0 10px 0 0 '},}}
                    inputProps={{ style: {outline:'none',width:'209px',fontFamily: 'Arial', color: 'white',border:'1px solid white',borderRadius: '5px'}}}
                    onChange={this.handleTariffTipe}
                    SelectProps={{native: true,}}
                    variant="outlined"
                  >
                    <option style={{color: 'black'}} value={''}>{}</option>
                    <option style={{color: 'black'}} value={'SPECIAL'}>SPECIAL</option>
                    <option style={{color: 'black'}} value={'STANDARD'}>STANDARD</option>
                  </TextField>
                </div>


<div style={{marginTop:'10px'}}>
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label={t("Location")}
                  InputLabelProps={{style:{color:'white',background:'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',padding:'0 10px 0 0 '},}}
                  inputProps={{ style: {outline:'none',width:'209px',fontFamily: 'Arial', color: 'white',border:'1px solid white',borderRadius: '5px'}}}
                  onChange={this.ChangeLocation}
                  SelectProps={{native: true,}}
                  variant="outlined"
                >
                  <option style={{color: 'black'}} value={''}>{}</option>
                  <option style={{color: 'black'}} value={'RU'}>RU</option>
                  <option style={{color: 'black'}} value={'BY'}>BY</option>
                  <option style={{color: 'black'}} value={'EE'}>EE</option>
                </TextField>
</div>
<div>
                {this.state.AddPeriod === false ?
                <Button onClick={this.openPeriods} style={styles.addPeriodButton}>{t("+ Add period")}</Button>
                  :
               ''
               }
                {this.state.showPeriods === 'Input Not Filed'?
              <div>
                <span style={{color:'red'}}>{t('Input not field')}</span>
              </div>:''}
</div>
                {periodList[0] > 0 ?
                  periodList.map((row, i) => (

                    <div key={i} style={{marginTop: '20px'}}>
                      <span>{`${i + 1} ${t("Tariff Period")}`}</span>
                      <div>
                        <TextFieldS  onChange={this.TariffName} label={'Tariff Name'}/>
                      </div>
                      <div style={{display: 'flex', marginTop: '10px'}}>
                        <TextField
                          id="outlined-select-currency-native"
                          label={t('Working Time')}
                          InputLabelProps={{style:{fontSize: '14px', lineHeight: '16px', letterSpacing: '0.4px',color:'rgba(255, 255, 255, 0.6)',background:'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',padding:'0 10px 0 0 '},}}
                          inputProps={{ style: {width:'90px',color:'gray',outline:'none',fontFamily: 'Arial',border:'1px solid white',borderRadius: '5px',}}}
                          onChange={this.ChangeWorkingTimeInterval}
                          SelectProps={{native: true,}}
                          variant="outlined"
                        />
                        <div style={{marginLeft:'10px'}}>
                        <TextField
                          id="outlined-select-currency-native"
                          select
                          label={t("Time")}
                          InputLabelProps={{  style: {color: 'gray', background:'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212', padding: '0 10px 0 0 '},}}
                          inputProps={{style: {width:'75px',color:'gray',fontFamily: 'Arial', border: '1px solid white', borderRadius: '5px',}}}
                          onChange={this.changeHour}
                          SelectProps={{native: true,}}
                          variant="outlined"
                        >
                          <option value={''}>{}</option>
                          <option value={'MINUTE'}>{t("Minute")}</option>
                          <option value={'HOUR'}>{t("Hourly")}</option>
                          <option value={'DAY'}>{t("Daily")}</option>
                        </TextField>
                        </div>
                      </div>
                      <div style={{display: 'flex', marginTop: '10px'}}>
                        <div style={{display: 'flex', marginTop: '10px'}}>
                          <TextField
                            id="outlined-select-currency-native"
                            label={t('Price')}
                            InputLabelProps={{style:{fontSize: '14px', lineHeight: '16px', letterSpacing: '0.4px',color:'rgba(255, 255, 255, 0.6)',background:'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',padding:'0 10px 0 0 '},}}
                            inputProps={{ style: {width:'90px',color:'gray',outline:'none',fontFamily: 'Arial',border:'1px solid white',borderRadius: '5px',}}}
                            onChange={this.ChangePrice}
                            SelectProps={{native: true,}}
                            variant="outlined"
                          />
                          <div style={{marginLeft:'10px'}}>
                            <TextField
                              id="outlined-select-currency-native"
                              select
                              label={t("Time")}
                              InputLabelProps={{  style: {color: 'gray', background:'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212', padding: '0 10px 0 0 '},}}
                              inputProps={{style: {width:'75px',color:'gray',fontFamily: 'Arial', border: '1px solid white', borderRadius: '5px',}}}
                              onChange={this.changeHour}
                              SelectProps={{native: true,}}
                              variant="outlined"
                            >
                              <option value={''}>{}</option>
                              <option value={'MINUTE'}>{t("Minute")}</option>
                              <option value={'HOUR'}>{t('Hourly')}</option>
                              <option value={'DAY'}>{t('Daily')}</option>
                            </TextField>
                          </div>
                        </div>
                      </div>
                      <Button onClick={() => {
                        this.deletePeriod(i)
                      }} style={{border: '1px solid #EB5B47', color: '#EB5B47', marginTop: '5px'}}>{t('DELETE PERIOD')}</Button>
                    </div>
                  ))
                  : ''
                }
                {this.state.AddPeriod === true?
                <Button onClick={this.savePeriod} style={styles.addPeriodButton}>{t('+ Add period')}</Button>
                  : ''}
                {this.state.showSecondPeriods === 'Input Not Filed'?
                  <div>
                    <span style={{color:'red'}}>{t('Input not field')}</span>
                  </div>
                  :''}
                <div style={{marginTop: '10px'}}>
                  <Button onClick={this.ClosePopup}  style={styles.CancelButton}>{t("CANCEL")}</Button>
                  <Button onClick={this.CreateTariff} style={styles.SaveButton}>{t("CREATE")}</Button>
                </div>
              </div>
            </div>
          </div>
          : ''}
            </>}</Translation>
      </>
    );
  }
}


const mapStateToProps = state => ({
  CreateTariffTexts: state.AddTarifTextReducer.CreateTariffTexts,
  OpenPopup: state.AddTarifTextReducer.OpenPopup,
  AllGroup: state.tariffsReducer.AllGroup
})

export default connect(mapStateToProps, {
  AddTariffText,
  ClosePopup,
  addTariffGroupName,
  addTariff,
  GetAllGroupTariffs
})(AddTariffsInfo);
