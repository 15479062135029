import {put,call, takeLatest} from "@redux-saga/core/effects";
import * as api from "../../api";
import {USERS_DEL} from "./DeleteUsersReducer";
import {getUserList} from "../usersActon";


function* handleDelUserSaga() {
  yield takeLatest(USERS_DEL.DELETE_USERS_REQUEST,UserDelSaga)
}

function* UserDelSaga(action) {
  try {
    const {data} = yield call(api.DeleteUsersApi,action.payload.phoneNumber)
    yield put({
      type:USERS_DEL.DELETE_USERS_SUCCESS,
      payload:{data}
    })
    yield put(getUserList(action.payload.page))
  }
  catch (e) {
    yield put({
      type:USERS_DEL.DELETE_USERS_FAIL,
      message:e.message
    })
  }

}

export default handleDelUserSaga
