import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";


export const Loader =({})=>{
  return(
    <div className={'loader-div'}>
      <CircularProgress  size={50}  disableShrink />
    </div>
  )
}