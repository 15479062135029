import axios from "axios";

export const axiosInstance = axios.create({
  // baseURL: "https://dev.chargex.am/",
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 30000,
  headers: { authorization: "Bearer " + localStorage.getItem("token") },
});

// axios.defaults.baseURL = "https://dev.chargex.am/";
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["authorization"] =
        "Bearer " + localStorage.getItem("token");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export function signIn(login, password) {
  return axios.post("/api/public/admin/login", {
    login,
    password,
  });
}

export function getUsersList(page) {
  return axios.get("/api/private/admin/allUsers", {
    params: {
      page,
    },
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function FindUsers(phoneNumber) {
  const Filter = Object.values(phoneNumber);
  if (Filter.length <= 12 || phoneNumber.startsWith("+")) {
    return axios.get(`/api/private/admin/findUserByPhoneNumber${phoneNumber}`, {
      params: {
        phoneNumber,
      },
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  } else if (Filter.length === 24) {
    let userId = phoneNumber;
    return axios.get(`/api/private/admin/findUserById`, {
      params: {
        userId,
      },
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  } else {
    let email = phoneNumber;
    return axios.get(`/api/private/admin/findUserByEmail`, {
      params: {
        email,
      },
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  }
}

export function getRents(page) {
  return axios.get("/api/private/admin/allRents", {
    params: {
      page,
    },
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function lockUser(phoneNumber, lock) {
  return axios.get("/api/private/admin/lockUser", {
    params: {
      phoneNumber,
      lock: lock,
    },
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function getUserRent(phoneNumber) {
  const Filter = Object.values(phoneNumber);
  if (Filter.length === 4) {
    return axios.get(
      `/api/private/admin/findRentByStationNumber?${
        "stationNumber=" + phoneNumber
      }`,
      {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
  } else if (Filter.length <= 14) {
    return axios.get(`/api/private/admin/userRents${phoneNumber}`, {
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  } else {
    return axios.get(
      `/api/private/admin/findRentById?${"rentId=" + phoneNumber}`,
      {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
  }
}

export function GetStations(page) {
  return axios.get("/api/private/admin/allStations", {
    params: {
      page,
    },
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function DeleteStation(stationNumber) {
  return axios.get("/api/private/admin/deleteStationByNumber", {
    params: {
      stationNumber,
    },
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function DeleteUsersApi(phoneNumber) {
  return axios.get("/api/private/admin/deleteUser", {
    params: {
      phoneNumber,
    },
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function lockStation(stationNumber, lock) {
  return axios.get("/api/private/admin/lockStation", {
    params: {
      stationNumber,
      lock: lock,
    },
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function addStation(
  tariffPlan,
  address,
  latitude,
  longitude,
  numberSlots,
  owner,
  stationID,
  stationNumber,
  workTime,
  additionalAddress
) {
  let time = new Date().toTimeString().slice(9);
  let timezone = time.slice(0, 8);
  return axios.post("/api/private/admin/addOrUpdateStation", {
    address,
    latitude,
    longitude,
    numberSlots,
    owner,
    stationID,
    stationNumber,
    workTime,
    tariffPlan,
    timezone,
    additionalAddress,
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function AddImage(image, stationNumber) {
  let formData = new FormData();
  image.map((i, item) => formData.append("multipartFiles", image[item]));
  return axios.post("/api/private/admin/addStationImage", formData, {
    params: {
      stationNumber,
    },
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  });
}

export function findStations(stationNumber) {
  const Filter = Object.values(stationNumber);
  if (Filter.length === 4) {
    return axios.get("/api/private/admin/stationByNumber", {
      params: {
        stationNumber,
      },
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  } else {
    let stationID = stationNumber;
    return axios.get("/api/private/admin/FindStationByID", {
      params: {
        stationID,
      },
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  }
}

//
// export function ChechStations(stationNumber) {
//     return axios.get('/api/private/admin/stationByNumber', {
//       params: {
//         stationNumber,
//       },
//       headers: {
//         authorization: 'Bearer ' + localStorage.getItem('token'),
//       }
//     })
// }

export function getStationImg(stationNumber) {
  return axios.get(
    `/api/private/admin/stationByNumber?${"stationNumber=" + stationNumber}`,
    {
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
}

export function getStationAllImg(stationNumber) {
  return axios.get(
    `/api/private/admin/getAdditionalImages?${
      "stationNumber=" + stationNumber
    }`,
    {
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
}

export function getSupport() {
  return axios.get("/api/private/admin/getSupportText", {
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function getAgreement() {
  return axios.get(`/api/private/admin/getUserAgreements`, {});
}

export function GetAllGroup() {
  return axios.get("/api/private/admin/getAllGroupTariffs", {
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function addSupport(
  locationForSupport,
  supportEmail,
  supportPhoneNumber
) {
  return axios.post("/api/private/admin/addAndUpdateSupportText", {
    locationForSupport,
    supportEmail,
    supportPhoneNumber,
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function addAgreement(locationForUserAgreement, text) {
  return axios.post("/api/private/admin/addAndUpdateUserAgreementText", {
    locationForUserAgreement,
    text,
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function getQuestion(location) {
  return axios.post(
    `/api/public/v1/getPopularQuestions?${"location=" + location}`
  );
}

export function AddQuestion(locationForPopularQuestion, answer, question) {
  return axios.post("/api/private/admin/addAndUpdatePopularQuestions", {
    locationForPopularQuestion,
    answer: [answer],
    question,
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function GetTariff() {
  return axios.get("/api/private/admin/getTariffText");
}

export function AddTarifText(locationForTariff, text) {
  return axios.post("/api/private/admin/addAndUpdateTariffText", {
    locationForTariff,
    text,
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function addTatiffs(
  nameTariffGroup,
  period,
  price,
  tariffName,
  typeTime,
  workingTimeInterval
) {
  return axios.post(
    `/api/private/admin/addTariff?${"nameTariffGroup=" + nameTariffGroup}`,
    {
      period,
      price,
      tariffName,
      typeTime,
      workingTimeInterval,
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
}

export function addTariffGroup(nameGroupTariffs, location, TariffType) {
  return axios.get(
    `/api/private/admin/addGroupTariffs?${
      "nameGroupTariffs=" + nameGroupTariffs
    }&${"location=" + location}&${"type=" + TariffType}`,
    {
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
}

export function BlockRent(rentId) {
  return axios.get("/api/private/admin/finishRent", {
    params: {
      rentId,
    },
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function DeleteQuestion(i) {
  const id = i[0];
  return axios.get("/api/private/admin/deletePopularQuestion", {
    params: {
      id,
    },
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

const Role = localStorage.getItem("role");

export function Transactions(page) {
  if (Role === "SUPER_ADMIN") {
    return axios.get("/api/private/admin/allTransactions", {
      params: {
        page,
      },
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  } else {
    return axios.get("/api/private/admin/getTransactions", {
      params: {
        page,
      },
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  }
}

export function addTransActions(
  phoneNumber,
  description,
  price,
  rentId,
  transactionStatus
) {
  return axios.post(`/api/private/admin/addTransaction${phoneNumber}`, {
    description,
    price,
    rentId,
    transactionStatus,
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function getMerchants(all) {
  return axios.get(`/api/private/admin/getMerchants?all=${all}`, {
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}
export function getMerchantsRequest() {
  return axios.get("/api/private/admin/getMerchantsRequest", {
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}
export function getMerchantsRequestFullInfo(email) {
  return axios.get(
    `/api/private/admin/getMerchantFullInfo?${"email=" + email}`,
    {
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
}

export function getAdmins() {
  return axios.get("/api/private/admin/getAdmins", {
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function addMerchants(adminEmail, login, password) {
  return axios.post("/api/private/admin/regMerchant", {
    adminEmail,
    login,
    password,
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function addAdmins(login, password) {
  return axios.post("/api/private/admin/regAdmin", {
    login,
    password,
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function getMerchantsBy(adminEmail) {
  return axios.get(
    `/api/private/admin/getMerchantsByAdmin?${"adminEmail=" + adminEmail}`,
    {
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
}

export function TakePowerBank(stationId, slotNumber) {
  return axios.post(
    // `/api/private/admin/getPowerBank?${"stationId=" + stationId}&${
    //   "slotNumber=" + slotNumber
    // }`,
    `/api/private/admin/powerbank/extract/${stationId}/${slotNumber}`
    // {
    //   headers: {
    //     authorization: "Bearer " + localStorage.getItem("token"),
    //   },
    // }
  );
}

export function FilterRentsByDate(data) {
  const startDate = data.StartDate;
  const endDate = data.EndDate;
  return axios.get(`/api/private/admin/findRentByDate`, {
    params: {
      endDate,
      startDate,
    },
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function GetAllPowerbanks(page) {
  return axios.get("/api/private/admin/powerbank/", {
    params: {
      page,
    },
  });
}

export function GetPowerbanksByStatus(page, status, checkingStatus) {
  return axios.get("/api/private/admin/powerbank/", {
    params: {
      page,
      status,
      checkingStatus,
    },
  });
}

export function GetPowerbanksDashboardInfo() {
  return axios.get("/api/private/admin/powerbank/dashboard");
}

export function GetPowerbanksDashboardInfoSortByMerchant(merchantEmail) {
  return axios.get("/api/private/admin/powerbank/dashboard", {
    params: {
      merchantEmail,
    },
  });
}

export function GetMerchantsDashboardInfo() {
  return axios.get("/api/private/admin/merchant/email");
}

export function GetLostPowerbanksInfo(startDate, endDate, merchant, station) {
  return axios.get("/api/private/admin/dashboard/powerbank/lost", {
    params: {
      startDate,
      endDate,
      merchant,
      station
    },
  });
}

export function DeleteTariffApi(idGroupTariffs) {
  return axios.get("/api/private/admin/deleteGroupTariffs", {
    params: {
      idGroupTariffs,
    },
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function changePassword(password) {
  return axios.post("/api/private/admin/changePassword", {
    password,
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function SentRepo(data) {
  const merchantEmail = data.email;
  const endDate = data.EndDate;
  const startedDate = data.StartDate;
  return axios.get(`/api/private/admin/sendReport`, {
    params: {
      merchantEmail,
      startedDate,
      endDate,
    },
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function FilterRentsByStatus(data) {
  return axios.get(`/api/private/admin/allRentsByStatus`, {
    params: {
      page: data[1],
      status: data[0],
    },
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function GivePromoApi(data) {
  return axios.get(`/api/private/admin/specialTariffStatus`, {
    params: {
      userId: data,
    },
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function GetAllPromoTariffs() {
  return axios.get(`/api/private/admin/special-tariffs`, {
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function GivePromoToUserApi(data) {
  return axios.post(`/api/private/admin/special-tariff`, null, {
    params: {
      userId: data[0],
      b: data[1],
      groupTariffId: data[2],
    },
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    }
  });
}

export function DownloadStationList() {
  return axios.get(`/api/private/admin/allStationsDownload`, {
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function FindTransactionByRentId(transactionId) {
  return axios.get("/api/private/admin/findTransactionByRentId", {
    params: {
      rentId: transactionId,
    },
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

// export function getCommentsApi(name){
// return axios.get('/api/private/admin/findTransactionByRentId',{
//   params: {
//     'rentId':transactionId
//   },
//   headers: {
//     authorization: 'Bearer ' + localStorage.getItem('token'),
//   }
// })
// }

export function SetCommentsApi(text, name, id) {
  return axios.get("/api/private/admin/addComment", {
    params: {
      text: text,
      commentType: name,
      id: id,
    },
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function ReloadStation(stationID) {
  return axios.get("/api/private/admin/restartStation", {
    params: {
      stationID: stationID,
    },
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function FindPowerBank(powerBankId) {
  return axios.get(`/api/private/admin/getPowerBankById`, {
    params: {
      powerBankId,
    },
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export function GetUserCardApi(userId) {
  return axios.get(`/api/private/admin/userCards`, {
    params: {
      userId,
    },
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

// DASHBOARD MAP ====================================================

export function GetStationsOnMap() {
  return axios.get('/api/public/v2/map/stations');
}

export function GetStationInfoOnMap(stationId) {
  return axios.get(`/api/public/v2/map/station/${stationId}/full`);
}
// export function GetStationsOnMap(authorization, latitude, longitude, radius) {
//   return axios.get(`/api/public/v1/allStationsFullInfo`, {
//     params: {
//       authorization,
//       latitude, 
//       longitude, 
//       radius,
//     }
//   });
// }
