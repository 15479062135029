import { fork } from "redux-saga/effects";
import signInSaga, { changePassSaga } from "../modules/signIn/signInSaga";
import usersSaga, {
  FindUser,
  GetUserCardSaga,
  GivePromoSaga,
  GivePromoToUserSaga,
  getAllPromoTariffsWatcher
} from "../modules/users/usersSaga";
import rentsSaga, {
  FindRentsBYSaga,
  handleFilterRentsByStatus,
  handleFiterByDateRents,
} from "../modules/rents/rentsSaga";
import stationsSaga, {
  downloadStationList,
  FindStationBYSaga,
  ReloadStationSaga,
} from "../modules/stations/stationsSaga";
import handleDelSaga from "../modules/stations/DeleteSelectedStation/DeleteStationSaga";
import DeleteUsersSaga from "../modules/users/DeleteUsers/DeleteUsersSaga";
import lockStationSaga from "../modules/stations/LockStations/lockStationSaga";
import addStationsSaga from "../modules/stations/AddStations/addStationsSaga";
import AddStationImageSaga from "../modules/stations/AddStations/AddStationImage/AddStationImageSaga";
import FindingRentsSaga from "../modules/rents/FindingRents/FindingRentsSaga";
import GetStationImgSaga, {
  handleImgSaga,
} from "../modules/stations/GetStationImg/GetStationImgSaga";
import lockUsersSaga from "../modules/users/lockUsers/lockUsersSaga";
import rentHistorySaga from "../modules/rents/rentHistory/rentHistorySaga";
import tariffsSaga, {
  DelTariff,
  GetAllGroup,
} from "../modules/tariff/tariffsSaga";
import AddTarifTextSaga from "../modules/tariff/AddTAriffText/AddTarifTextSaga";
import AddQuestionSaga from "../modules/Questions/AddQuestion/AddQuestionSaga";
import GetPopularQuestionSaga from "../modules/Questions/GetPopularQuestionSaga";
import agreementSaga from "../modules/documents/UserAgreement/agreementSaga";
import supportTextSaga from "../modules/documents/SupportText/supportTextSaga";
import addSuportTextSaga from "../modules/documents/SupportText/addSuportText/addSuportTextSaga";
import addAgreementSaga from "../modules/documents/UserAgreement/addAgreement/addAgreementSaga";
import AddTariffSaga from "../modules/tariff/AddTariff/AddTariffSaga";
import StopRentSaga from "../modules/rents/StopRent/StopRentSaga";
import DeleteQuestionSaga from "../modules/Questions/DeleteQuestion/DeleteQuestionSaga";
import AddTariffWidthGroupSaga from "../modules/tariff/AddTariff/AddTariffWidthGRoup/AddTariffWidthGroupSaga";
import handleTransaction, {
  FindTransactionByID,
  FindTransactionByRENTID,
  handleFilterTransaction,
} from "../modules/transactions/GetTransactionSaga";
import AddTransactionsSaga, {
  EditTransactionSaga,
} from "../modules/transactions/AddTransactions/AddTransactionsSaga";
import merchantsSaga, {
  attemptChangeMerchantEmail,
  merchantsREGSaga,
  merchantsREGSFullInfo, merchantsSaga2,
  SendMerchantReportSaga,
} from "../modules/merchantes/merchantesSaga";
import { attemptBlockMerchant } from "../modules/merchantes/merchantesSaga";
import AddMerchantsSaga, {
  RegHandleInvite,
  REGIndividualMerchant,
  REGMerchant,
} from "../modules/merchantes/AddMerchants/AddMerchantsSaga";
import adminSaga, { attemptBlockEdAdmin } from "../modules/admins/adminSaga";
import AddAdminsSaga from "../modules/admins/AddAdmins/AddAdminsSaga";
import GetMerchantSaga from "../modules/merchantes/GetMerchant/GetMerchantSaga";
import TakeBankSaga from "../modules/stations/TakeBank/TakeBankSaga";
import handleBanksSaga, {
  blockPowerBankWatcher,
  DelBakSaga,
  downloadPowerbanksListWatcher,
  // getPowerbanksByConditionWatcher,
  getPowerbanksByStatusWatcher,
  handleFindPowerBankById,
  photoControlWatcher,
  searchPowerbankByStationWatcher,
  searchPowerbankCollectionWatcher,
  searchPowerbankWatcher,
} from "../modules/powerbanks/powerbanksSaga";
import {
  getPowerbanksDashboardInfoWatcher,
  getMerchantsDashboardInfoWatcher,
  getLostPowerbanksInfoWatcher,
  sortPowerbanksStatusesDashboardInfoByMerchantWatcher,
  sortPowerbanksConditionsDashboardInfoByMerchantWatcher,
  getAllStationsOnMapWatcher,
  getStationInfoOnMapWatcher,
} from "../modules/dashboard/dashboardSaga";
import handleCommentsSaga from "../modules/Comments/CommentsSaga";

export default function* watchers() {
  return [
    yield fork(signInSaga),
    yield fork(usersSaga),
    yield fork(rentsSaga),
    yield fork(lockUsersSaga),
    yield fork(rentHistorySaga),
    yield fork(stationsSaga),
    yield fork(handleDelSaga),
    yield fork(DeleteUsersSaga),
    yield fork(lockStationSaga),
    yield fork(addStationsSaga),
    yield fork(AddStationImageSaga),
    // yield fork(FindStationSaga),
    yield fork(FindingRentsSaga),
    yield fork(GetStationImgSaga),
    yield fork(tariffsSaga),
    yield fork(AddTarifTextSaga),
    yield fork(AddQuestionSaga),
    yield fork(GetPopularQuestionSaga),
    yield fork(agreementSaga),
    yield fork(supportTextSaga),
    yield fork(addSuportTextSaga),
    yield fork(addAgreementSaga),
    yield fork(AddTariffSaga),
    yield fork(StopRentSaga),
    yield fork(DeleteQuestionSaga),
    yield fork(AddTariffWidthGroupSaga),
    yield fork(GetAllGroup),
    yield fork(handleTransaction),
    yield fork(AddTransactionsSaga),
    yield fork(AddMerchantsSaga),
    yield fork(adminSaga),
    yield fork(AddAdminsSaga),
    yield fork(GetMerchantSaga),
    yield fork(TakeBankSaga),
    yield fork(attemptBlockMerchant),
    yield fork(merchantsSaga),
    yield fork(merchantsSaga2),
    yield fork(attemptBlockEdAdmin),
    yield fork(handleFilterTransaction),
    // yield fork(handleFilterRents),
    yield fork(FindTransactionByID),
    yield fork(attemptChangeMerchantEmail),
    yield fork(EditTransactionSaga),
    yield fork(REGMerchant),
    yield fork(RegHandleInvite),
    yield fork(merchantsREGSaga),
    yield fork(merchantsREGSFullInfo),
    yield fork(REGIndividualMerchant),
    yield fork(FindUser),
    yield fork(FindStationBYSaga),
    yield fork(FindRentsBYSaga),
    yield fork(handleFiterByDateRents),
    yield fork(handleBanksSaga),
    // dashboard sagas start
    yield fork(getPowerbanksByStatusWatcher),
    // yield fork(getPowerbanksByConditionWatcher),
    yield fork(getPowerbanksDashboardInfoWatcher),
    yield fork(getMerchantsDashboardInfoWatcher),
    yield fork(getLostPowerbanksInfoWatcher),
    yield fork(sortPowerbanksStatusesDashboardInfoByMerchantWatcher),
    yield fork(sortPowerbanksConditionsDashboardInfoByMerchantWatcher),
    yield fork(getAllStationsOnMapWatcher),
    yield fork(getStationInfoOnMapWatcher),
    // dashboard sagas finish
    yield fork(DelBakSaga),
    yield fork(DelTariff),
    yield fork(changePassSaga),
    yield fork(SendMerchantReportSaga),
    yield fork(handleFilterRentsByStatus),
    yield fork(GivePromoSaga),
    yield fork(downloadStationList),
    yield fork(GivePromoToUserSaga),
    yield fork(getAllPromoTariffsWatcher),

    yield fork(FindTransactionByRENTID),
    yield fork(handleCommentsSaga),
    // yield fork(handleSendCommentsSaga),
    yield fork(ReloadStationSaga),
    // yield fork(CechStationBYSaga),
    yield fork(handleFindPowerBankById),
    yield fork(GetUserCardSaga),
    yield fork(handleImgSaga),
    yield fork(downloadPowerbanksListWatcher),
    yield fork(photoControlWatcher),
    yield fork(blockPowerBankWatcher),
    yield fork(searchPowerbankCollectionWatcher),
    yield fork(searchPowerbankWatcher),
    yield fork(searchPowerbankByStationWatcher),
  ];
}
