export const STATES_DEL={
  DELETE_STATION_REQUEST:'STATES_DEL.DELETE_STATION_REQUEST',
  DELETE_STATION_SUCCESS:'STATES_DEL.DELETE_STATION_SUCCESS',
  DELETE_STATION_FAIL:'STATES_DEL.DELETE_STATION_FAIL',
  SEND_STATION_NUMBER:'STATES_DEL.SEND_STATION_NUMBER',
  CLEAR_SENDED_STATION_NUMBER:'STATES_DEL.CLEAR_SENDED_STATION_NUMBER',
  LOCK_UNLOCK_STATION:'STATES_DEL.LOCK_UNLOCK_STATION',
  CLEAR_LOCK_UNLOCK_STATION_INFO:'STATES_DEL.CLEAR_LOCK_UNLOCK_STATION_INFO',
}

const initialState={
  DeleteStation:[],
  StationNumber:[],
  LockUnlockStationInfo:[]
}

export default (state=initialState,action)=>{
  switch (action.type) {
    case STATES_DEL.DELETE_STATION_REQUEST:{
      return {...state,DeleteStation:initialState.DeleteStation}
    }
    case STATES_DEL.DELETE_STATION_SUCCESS:{
      return {...state,DeleteStation:action.payload.data}
    }
    case STATES_DEL.SEND_STATION_NUMBER:{
      return {...state,StationNumber:action.payload.data}
    }
    case STATES_DEL.LOCK_UNLOCK_STATION:{
      return {...state,LockUnlockStationInfo:action.payload.data}
    }
    case STATES_DEL.CLEAR_LOCK_UNLOCK_STATION_INFO:{
      return {...state,LockUnlockStationInfo:[]}
    }
    case STATES_DEL.CLEAR_SENDED_STATION_NUMBER:{
      return {...state,StationNumber:[]}
    }
    case STATES_DEL.DELETE_STATION_FAIL:{
      return {...state,DeleteStation:initialState.DeleteStation}
    }
    default:{
      return state
    }

  }
}
