import React, {Component} from "react";
import {Translation} from "react-i18next";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ErrMessage from "./ErrMessage";
import {connect} from "react-redux";
import {GetComments, sendCommentAction} from "../../modules/Comments/CommentsAction";

const styles= {
  CreateButton: {
    color: 'white',
    background: '#5F94E2',
    borderRadius: '5px',
    border: '1px solid #5F94E2',
    padding: '6px',
    display: 'inline'
  },
}
class Comments extends Component {
  constructor(props) {
    super(props);
    this.state={
      comment:'',
      boolForErrorMessage:false
    }

  }

  handleChangeComment =(e)=>{
    this.setState({comment:e.target.value,boolForErrorMessage:false})
  }
  sendComment=()=>{
    let {comment}= this.state
    if(comment){
      this.props.sendCommentAction(comment,this.props.name,this.props.id)
      setTimeout(() =>  this.props.handleClose(),500)
    }else{
      this.setState({boolForErrorMessage:true})
    }
  }


  render() {

    let {boolForErrorMessage} = this.state
    let {comment,Comments,text} = this.props

    return (
        <Translation>
          {(t) =>
            <>
              <hr style={{marginLeft:'0',width: '90%', marginTop: '25px', color: 'rgba(255, 255, 255, 0.6)', height: '1px'}}/>

              <div style={{marginTop:'15px'}}>
                {text &&
                <>
                  <span style={{color:'rgba(255, 255, 255, 0.6)',fontSize:'16px',marginLeft:'90px'}}>{t("Comment")}</span>
                  <p style={{color:'white',marginTop:'10px',fontSize:'14px'}}>{text}</p>
                </>
                }
                <div style={{ marginTop: '15px'}}>
                  <TextField
                    id="outlined-select-currency-native"
                    value={comment}
                    // multiline
                    type={'tel'}
                    label={'Comment'}
                    name={'Comment'}
                    InputLabelProps={{style:{fontSize: '14px', lineHeight: '16px', letterSpacing: '0.4px',color:'rgba(255, 255, 255, 0.6)',background:'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',padding:'0 10px 0 0 '},}}
                    inputProps={{
                      style: {border:'1px solid white',color:'gray',outline:'none',width:'227px',fontFamily: 'Arial',borderRadius: '5px',}}}
                    onChange={this.handleChangeComment}
                    SelectProps={{native: true,}}
                    variant="outlined"
                  />
                </div>
                <div style={{marginTop:'15px'}} >
                  <Button style={styles.CreateButton}
                          onClick={this.sendComment}
                  ><span style={{color:'white',fontSize:'14px'}}>{t("Add Comment")}</span></Button>
                </div>
                {boolForErrorMessage === true &&
                <ErrMessage/>
                }
              </div>
            </>}</Translation>
    );
  }
}
const mapStateToProps = state => ({
  Comments: state.CommentsReducer.Comments,
})
export default connect(mapStateToProps, {sendCommentAction})(Comments);
