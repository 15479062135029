import {RENTS_ACTIONS} from "./rentsReducer";

export const getRents = (data) => {
  return{
    type:RENTS_ACTIONS.RENTS_REQUEST,
    payload:{data}
  }
}
// export const ClearActiveRents =()=>{
//   return{
//     type:RENTS_ACTIONS.CLEAR_ACTIVE_RENTS
  // }
// }
//
// export const filterRents=(email)=>{
//   return{
//     type:RENTS_ACTIONS.FILTER_RENTS_REQUEST,
    // payload:{email}
  // }
// }


export const filterRentsList=(data)=>{
  return{
    type:RENTS_ACTIONS.FILTER_RENTS_LIST_REQUEST,
    payload: data
  }
}
export const FilterRentsByActive =(data)=>{
  return{
    type:RENTS_ACTIONS.FILTER_ACTIVE_RENTS_REQUEST,
    payload:{data}
  }
}

export const FindRentsByNumber =(phoneNumber)=>{
  return {
    type:RENTS_ACTIONS.FINDING_RENTS_REQUEST,
    payload: {phoneNumber}
  }
}


export const CLearFindingRentInfo=()=>{
  return{
    type:RENTS_ACTIONS.CLEAR_FINDING_RENT_INFO
  }
}
export const CLearDebitInfo=()=>{
  return{
    type:RENTS_ACTIONS.CLEAR_DEBIT_INFO
  }
}


export const ClearAllForLoader =()=>{
  return{
    type:RENTS_ACTIONS.CLEAR_INFO_FOR_LOADER
  }
}