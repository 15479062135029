import React from 'react';
import CloseIcon from '../../../assets/images/CloswX.png'
import SearchIcon from '../../../assets/images/Search.png'
// import '../../assets/styles/style.css';
import {Translation} from "react-i18next";
// import SwipeUp from "../../components/common/Swipe/Swiper";
import {withRouter} from "react-router-dom";
import TextFieldAddStation from "../../../components/common/Inputs/TextFieldAddStation";
// import Draggable from "react-draggable";

const styles ={
  inputStyle: {
    border: '1px solid rgba(255, 255, 255, 0.87)',
    boxSizing: 'border-box',
    borderRadius: '5px',
    padding: '6px',
    background: 'none',
    width: '260px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    color: 'gray',
    marginTop: '8px'
  },
}
class YandexMapForm extends React.Component {
  constructor(props) {
    super(props);
    this.inputMB = React.createRef();
    this.inputDT = React.createRef();
    this.state = {
      border: "",
      containerCls: 'YandexSearchingSWIPE',
      is_visible: false,
      dragNumber:0,
      showHide:true
    }
  }
  componentDidMount() {
    // let scrollComponent = this;
    // document.addEventListener("scroll", function(e) {
    //   scrollComponent.toggleVisibility();
    // });
  }
  intervalTIME
  timeInterval
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.searchValue !== this.props.searchValue) {
      this.props.searchValue.length > 0 ? this.setState({border: "On"})
        : this.setState({border: "Off"})
    }
    if(this.state.showHide === true){
      if (this.inputMB.current && this.props.searchShown) {
        // this.inputMB.current.focus()
        // this.scrollToTop()
      }

      if (this.state.containerCls === 'YandexSearchingSWIPEClose') {
        this.timeInterval = setInterval(() => this.handleCli(), 400);
      }
      // clearInterval(this.intervalTIME)
    }
    // this.intervalTIME = setInterval(this.handleChange, 600)
  }
  handleCli = () => {
    clearInterval(this.timeInterval)
    this.setState({containerCls: "YandexSearchingSWIPE"})
    this.props.searchToggle()
  }
  //
  //
  // handleChange=()=>{
  //   if (this.inputMB.current && this.props.searchShown){
  //     this.inputMB.current.focus()
  //     this.scrollToTop()
  //     clearInterval(this.intervalTIME)
  //   }
  // }

  // toggleVisibility() {
  //   if (window.pageYOffset > 300) {
  //     this.setState({
  //       is_visible: true
  //     });
  //   } else {
  //     this.setState({
  //       is_visible: false
  //     });
  //   }
  // }
  // scrollToTop() {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "auto"
  //   });
  // }

  // intervalTIME

  // componentDidMount() {
  // this.intervalTIME = setInterval(this.handleChange, 600)
  // }

  // handleChange = () => {
  //   if (this.inputMB.current && this.props.searchShown) this.inputMB.current.focus()
  //   clearInterval(this.intervalTIME)
  // }

  // componentWillUnmount() {
  //   clearInterval(this.intervalTIME)
  // }
  // handleDragClose =()=>{
  //   this.setState({showHide:false})
  //   if(this.state.dragNumber > 20) {
  //     this.props.searchToggle()
  //     this.setState({showHide:true})
  //     this.setState({dragNumber:0})
  //   }else {
  //     this.setState({dragNumber:0})
  //   }
  // }





  render() {
    const {searchShown, errorMessage, searchResult,searchValue} = this.props;
    return (
      <>
        <div className={"DT"}>
          <div className={"YandexSearchingDT"}
          >
            <div className={"YandexSearchingBlockDT"}>
              <div style={{position: "relative"}}>

                {/*<img src={SearchIcon} alt="SearchIcon"*/}
                {/*     className={"YandexSearchFieldImg"}*/}
                {/*     style={{*/}
                {/*       position: "absolute",*/}
                {/*       display: "grid",*/}
                {/*       justifyContent: "space-between",*/}
                {/*       margin: "13.67px"*/}
                {/*     }}*/}
                {/*/>*/}

                <img className={"YandexSearchFieldImg"}
                     onClick={() => {
                       this.props.emptySearchResult()
                     }}
                     src={CloseIcon} alt="CloseIcon"
                     style={{
                       position: "absolute",
                       right: 0,
                       margin: "17.16px",
                       cursor: "pointer",
                     }}
                />

                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    searchResult[0] &&
                    this.props.ymapsSelect(`${searchResult[0].GeoObject.name} ${searchResult[0].GeoObject.description}`)
                  }}>
                  <>
                    <Translation>{(t) =>
                      <input className="YandexSearchField"
                             onChange={(e) => {this.props.searchHandler(e)}}
                             ref={this.inputDT}
                             value={searchValue}
                             placeholder={t('Search with address')}
                             autoComplete={"off"}
                      />
                    }</Translation></>
                </form>
                {errorMessage && <p>{errorMessage}</p>}
                <div className={"YandexSearchAutoFill "}
                     style={{overflow: "auto",}}>
                  {searchResult &&  searchResult.map((s, i) => {
                    return (
                      <div key={s.GeoObject.name + i}
                           onClick={() => {
                             this.props.ymapsSelect(`${s.GeoObject.name} ${s.GeoObject.description}`)
                           }}
                           style={{
                             cursor: "pointer",
                             marginTop: "15px"
                           }}>
                        <p style={{
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "16px",
                          lineHeight: "20px",
                          letterSpacing: "0.005em",
                          color: "rgba(255, 255, 255, 0.87)",
                          margin: 0,
                        }}
                        >{s.GeoObject.name}</p>
                        <span style={{
                          fontSize: "14px",
                          letterSpacing: "0.005em",
                          color: "silver",
                        }}>{s.GeoObject.description}</span>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

    );
  }
}


export default withRouter(YandexMapForm)
