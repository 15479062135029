import { store } from "../../root/store";
import {
  downloadPowerbanksList,
  searchPowerbank,
  searchPowerbankByStation,
  searchPowerbankCollection,
} from "../../modules/powerbanks/powerbanksAction";

export const powerbankService = ({ type, value, options }) => {
  if (type === "stationNumber" || type === "stationId") {
    return store.dispatch(searchPowerbankByStation(value));
  }

  if (type === "collection") {
    return store.dispatch(
      searchPowerbankCollection({ merchantEmail: value, ...options })
    );
  }

  if (type === "download") {
    return store.dispatch(downloadPowerbanksList(options));
  }

  return store.dispatch(searchPowerbank(value));
};
