import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {ADD_AGREEMENT} from "./addAgreementReducer";
import * as api from "../../../api";
import {getAgreementText} from "../agreementAction";


function* agreementtSagas() {
  yield takeLatest(ADD_AGREEMENT.AGREEMENT_TEXT_REQUEST, handleSaga);
}


function* handleSaga(action) {
  try {
    const { data } = yield call(api.addAgreement,action.payload.locationForUserAgreement , action.payload.text,);
    const location = action.payload.locationForUserAgreement
    yield put({
      type:ADD_AGREEMENT.AGREEMENT_TEXT_SUCCESS,
      payload: { data }
    });
    yield put(getAgreementText(location))
  } catch (e) {
    yield put({
      type:ADD_AGREEMENT.AGREEMENT_TEXT_FAIL,
      message: e.message,
    });
  }
}

export default agreementtSagas;