
export const LOCK_STATION = {
  LOCK_REQUEST:'LOCK_STATION.LOCK_REQUEST',
  LOCK_SUCCESS:'LOCK_STATION.LOCK_SUCCESS',
  LOCK_FAIL:'LOCK_STATION.LOCK_FAIL',

}

const initialState={
  LockStation:[]
}

export default (state=initialState,action)=>{
  switch (action.type) {
    case LOCK_STATION.LOCK_REQUEST:{
      return {...state,LockStation:initialState.LockStation}
    }
    case LOCK_STATION.LOCK_SUCCESS:{
      return {...state,LockStation:action.payload.data}
    }
    case LOCK_STATION.LOCK_FAIL:{
      return {...state,LockStation:initialState.LockStation}
    }
    default:{
      return state
    }

  }
}
