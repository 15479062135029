export const ADD_QUESTION = {
  OPEN_PAGE:'ADD_QUESTION.OPEN_PAGE',
  CLOSE_PAGE:'ADD_QUESTION.CLOSE_PAGE',
  REQUEST:'ADD_QUESTION.REQUEST',
  SUCCESS:'ADD_QUESTION.SUCCESS',
  FAIL:'ADD_QUESTION.FAIL'
}

const initialState={
  createPageInfo:[],
  addStation:[]
}

export default (state=initialState,action)=>{
  switch (action.type) {
    case ADD_QUESTION.OPEN_PAGE:{
      return{...state,createPageInfo:action.payload.data}
    }
    case ADD_QUESTION.CLOSE_PAGE:{
      return {...state,createPageInfo:[]}
    }
    case ADD_QUESTION.REQUEST:{
      return {...state,addStation:initialState.addStation}
    }
    case ADD_QUESTION.SUCCESS:{
      return {...state,addStation:action.payload.data}
    }
    case ADD_QUESTION.FAIL:{
      return {...state,addStation:initialState.addStation}
    }
    default:{
      return state
    }

  }

}