export const SUPPORT_REQUESTS = {
  SUPPORT_REQUEST_REQUEST:"SUPPORT_REQUESTS:SUPPORT_REQUEST_REQUEST",
  SUPPORT_REQUEST_SUCCEED:"SUPPORT_REQUESTS:SUPPORT_REQUEST_SUCCEED",
  SUPPORT_REQUEST_FAIL:"SUPPORT_REQUESTS:SUPPORT_REQUEST_FAIL",
}

const initalState = {
  SupportText:[]
}

export default (state=initalState,action) =>{
  switch (action.type) {
    case SUPPORT_REQUESTS.SUPPORT_REQUEST_REQUEST:{
      return{...state,SupportText:initalState.SupportText}
    }
    case SUPPORT_REQUESTS.SUPPORT_REQUEST_SUCCEED:{
      return{...state,SupportText:action.payload.data}
    }
    case SUPPORT_REQUESTS.SUPPORT_REQUEST_FAIL:{
      return{...state,SupportText:initalState.SupportText}
    }
    default:{
      return state
    }
  }
}