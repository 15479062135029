export const TARIFFS={
  ADD_TARIFF_REQUEST:'TARIFFS.ADD_TARIFF_REQUEST',
  ADD_TARIFF_SUCCESS:'TARIFFS.ADD_TARIFF_SUCCESS',
  ADD_TARIFF_FAIL:'TARIFFS.ADD_TARIFF_FAIL',
}

const initialState={
  Tariff:[],
}

export default (state=initialState,action)=>{
  switch (action.type) {
    case TARIFFS.ADD_TARIFF_REQUEST:{
      return {...state,Tariff:initialState.Tariff}
    }
    case TARIFFS.ADD_TARIFF_SUCCESS:{
      return {...state,Tariff:action.payload.data}
    }
    case TARIFFS.ADD_TARIFF_FAIL:{
      return {...state,Tariff:initialState.Tariff}
    }
    default:{
      return state
    }

  }
}