import {ADD_QUESTION} from "./AddQuestionReducer";


export const addQuestionsPage=(data)=>{
  return{
    type:ADD_QUESTION.OPEN_PAGE,
    payload:{data}
  }
}
export const closeCreateQuestionPage=()=>{
  return{
    type:ADD_QUESTION.CLOSE_PAGE
  }
}

export const ADDQuestion = (locationForPopularQuestion,answer,question)=>{
  return{
    type:ADD_QUESTION.REQUEST,
    payload:{locationForPopularQuestion,answer,question}
  }
}
