import TextField from "@material-ui/core/TextField";
import React from "react";

const OnlyNumbers = ({pattern,Length,disabled,value,type,onChange,name,label,onClick, style={}, children,inputProps, ...props}) => {
  return (

    <div style={{ marginTop: '10px'}}>
      <TextField
        id="outlined-select-currency-native"
        value={value}
        maxLength={Length}
        disabled={disabled}
        type={type}
        label={label}
        name={name}
        InputLabelProps={{style: {border: 'none', fontSize: '14px', lineHeight: '16px', letterSpacing: '0.4px', color: 'rgba(255, 255, 255, 0.6)', background: '#121212', padding: '0 10px 0 0 '},}}
        inputProps={{autoComplete: "new-password", maxLength: Length, pattern: '^[0-9]\\d*',
          style: {
          // color:  typeError === 'Error' ? 'red' : 'gray',
            color:'gray',
            outline: 'none', width: '280px', fontFamily: 'Arial', border: '1px solid white', borderRadius: '5px',}}}


        // InputLabelProps={{style:{border:'none',fontSize: '14px', lineHeight: '16px', letterSpacing: '0.4px',color:'rgba(255, 255, 255, 0.6)',background:'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',padding:'0 10px 0 0 '},}}
        // inputProps={{
        //   maxLength :Length, pattern:"^[0-9]\\d*",
        //   style: {color:'gray',outline:'none',width:'227px',fontFamily: 'Arial',border:'1px solid white',borderRadius: '5px',}}}
        onChange={onChange}
        SelectProps={{native: true,}}
        variant="outlined"
      />
    </div>
  )
};

export default OnlyNumbers
