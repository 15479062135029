import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { I18N_DEFAULT_LANG } from "./constants/default";
import en_default from "./locales/en/default";
import ru_default from "./locales/ru/default.json";

i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: I18N_DEFAULT_LANG,
  interpolation: {
    escapeValue: false,
  },
  lng: localStorage.getItem("Lan"),
  resources: {
    EN: {
      translation: en_default,
    },
    RU: {
      translation: ru_default,
    },
  },
});

export default i18n;
