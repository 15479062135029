export const translateStatuses = (t, status) => {
  const tmp = status.replaceAll("_", " ").toUpperCase();

  switch (tmp) {
    case "ALL":
      return t("powerbanks.statuses.all");
    case "RENTED":
      return t("powerbanks.statuses.rented");
    case "IN STATION":
      return t("powerbanks.statuses.inStation");
    case "LOST":
      return t("powerbanks.statuses.lost");
    case "AT SUPER ADMIN":
      return t("powerbanks.statuses.atSuperAdmin");
    case "AT ADMIN":
      return t("powerbanks.statuses.atAdmin");
    case "AT MERCHANT":
      return t("powerbanks.statuses.atMerchant");
    case "REQUIRED TO CHECK":
      return t("powerbanks.statuses.requiredCheck");
    case "WORKING":
      return t("powerbanks.statuses.working");
    case "REPLACEMENT REQUIRED":
      return t("powerbanks.statuses.replacementRequired");
    default:
      return status ? status : "...";
  }
};
