import React, {Component} from 'react';
import {connect} from "react-redux";
import {ClearLockUnlockStationInfo, ClearSendedStationNumber, DeleteStations} from "../../../modules/stations/DeleteSelectedStation/DeleteStationAction";
import {LockStations} from "../../../modules/stations/LockStations/lockStationAction";
import {clearStationInfo} from "../../../modules/stations/stationInfo/stationInfoAction";
import {DeletePopularQuestion} from "../../../modules/Questions/DeleteQuestion/DeleteQuestionAction";
import {ClearDelQuestions} from "../../../modules/Questions/GetPopularQuestionAction";
import {Translation} from "react-i18next";
import {Card} from "../Card";



const useStyles = {
  CanclePopup:{
    margin: 'auto',
    width: '330px',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #121212',
    boxShadow: '0px 11px 15px rgba(0, 0, 0, 0.2), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 24px 38px rgba(0, 0, 0, 0.14)',
    borderRadius: '8px',
    padding: '15px'
  },
  CanclePopupButtons:{
    color: '#5F94E2',
    border: 'none',
    background: 'none'
  },
  headDiv:{
    background: 'rgba(0, 0, 0, 0.4)',
    width: '100%',
    minHeight: '100vh',
    zIndex: '100',
    position: 'fixed',
    right: 0,
  }

}
class DeleteStation extends Component {
  constructor(props) {
    super(props);
    this.state={

    }
  }


  blockStation = () => {
  const {stationNumber}=  this.props.LockUnlockStationInfo
  const {lock}=  this.props.LockUnlockStationInfo
    if (lock === true) {
      this.props.LockStations(stationNumber, false)
    } else {
      this.props.LockStations(stationNumber, true)
    }
    this.props.ClearLockUnlockStationInfo()
  }

  CancelBlockUserPopup = () => {
    this.props.ClearSendedStationNumber()
    this.props.ClearLockUnlockStationInfo()
  }

  DelStations = (row) => {
    this.props.DeleteStations(row)
    this.props.ClearSendedStationNumber()
  }

  render() {

    const {StationNumber} = this.props
    const LockUnlockStationInfo = Object.values(this.props.LockUnlockStationInfo)
    const popupInfo = LockUnlockStationInfo[1] === true ? 'Unlock' : 'Block'
    const DeleteQuestion  =Object.values(this.props.DeleteQuestion)
     return (
      <>
        <Translation>
          {(t) =>
            <>
        {StationNumber.length > 0 ?
          <div style={useStyles.headDiv}>
            <div style={{marginTop: '250px'}}>
              <div style={useStyles.CanclePopup}>
                <span>{ t("Are you sure you want delete station number") + StationNumber }</span>
                <div className='ModalButtons'>
                  <button  onClick={this.CancelBlockUserPopup}>Cancel</button>
                  <button  onClick={() => {this.DelStations(StationNumber)}}>{'Delete'}</button>
                </div>
              </div>
            </div>
          </div>
          :
          ''
        }
        {LockUnlockStationInfo.length > 0 ?
          <div style={useStyles.headDiv}>
            <div style={{marginTop: '250px'}}>
              <div style={useStyles.CanclePopup}>
                <span>{`${t('Are you sure you want')} ${t(popupInfo)} ${t('Station')} : ${LockUnlockStationInfo[0]}`}</span>
                <div style={{marginLeft: '140px',marginTop:'10px'}}>
                  <button style={useStyles.CanclePopupButtons} onClick={this.CancelBlockUserPopup}>{t('Cancel')}</button>
                  <button style={useStyles.CanclePopupButtons} onClick={() => {this.blockStation()}}>{t(popupInfo)}</button>
                </div>
              </div>
            </div>
          </div>
          :
          ''
        }
        {/*{LockUnlockStationInfo.length > 0 ?*/}
        {/*  <div style={useStyles.headDiv}>*/}
        {/*    <div style={{marginTop: '250px'}}>*/}
        {/*      <div style={useStyles.CanclePopup}>*/}
        {/*        <span>{`Are you sure you want ${popupInfo} station number:${LockUnlockStationInfo[0]}`}</span>*/}
        {/*        <div style={{marginLeft: '180px'}}>*/}
        {/*          <button style={useStyles.CanclePopupButtons} onClick={this.CancelBlockUserPopup}>Cancel</button>*/}
        {/*          <button style={useStyles.CanclePopupButtons} onClick={() => {this.blockStation()}}>{popupInfo}</button>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  :*/}
        {/*  ''*/}
        {/*}*/}



        {DeleteQuestion.length > 0 ?
          <div style={useStyles.headDiv}>
            <div style={{marginTop: '250px'}}>
              <div style={useStyles.CanclePopup}>
                <span>{t(`Are you sure you want delete Question`)}</span>
                <div style={{marginLeft: '160px',marginTop:'10px'}}>
                  <button style={useStyles.CanclePopupButtons} onClick={() => this.props.ClearDelQuestions()}>{t("Cancel")}</button>
                  <button style={useStyles.CanclePopupButtons} onClick={() => this.props.DeletePopularQuestion(this.props.DeleteQuestion) && this.props.ClearDelQuestions()}>{t("Delete")}</button>
                </div>
              </div>
            </div>
          </div>
          :
          ''
        }
            </>}</Translation>
      </>
    );
  }
}


const mapStateToProps = state => ({
  ForBlockingUser: state.usersReducer.ForBlockingUser,
  StationNumber: state.DeleteStationReducer.StationNumber,
  LockUnlockStationInfo: state.DeleteStationReducer.LockUnlockStationInfo,
  DeleteQuestion: state.GetPopularQuestionReducer.DeleteQuestion
})

export default connect(mapStateToProps, {ClearDelQuestions,DeletePopularQuestion,DeleteStations,ClearSendedStationNumber,LockStations,clearStationInfo,ClearLockUnlockStationInfo})(DeleteStation);
