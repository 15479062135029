import {put, takeLatest,call} from "@redux-saga/core/effects";
import {ADD_MERCHANTS_ACTION} from "./AddMerchantsReducer";
import * as api from "../../api";
import {GetMerchants, GetRegMerchants} from "../merchantesAction";
import {GetMerchantByAdmin} from "../GetMerchant/GetMerchantAction";
import axios from "axios";
import {setStatus} from "./AddMerchantsAction";



function* merchantsSaga() {
  yield takeLatest(ADD_MERCHANTS_ACTION.ADD_MERCHANTS_REQUEST, handleSaga)
}

function* handleSaga(action) {
  try {
    const {data} = yield call(api.addMerchants, action.payload.adminEmail,action.payload.login,action.payload.password)
    yield put({
      type: ADD_MERCHANTS_ACTION.ADD_MERCHANTS_SUCCESS,
      payload: {data}
    })
    yield put(GetMerchants())
    yield put(GetMerchantByAdmin(action.payload.adminEmail))
  } catch (e) {
    yield put({
      type: ADD_MERCHANTS_ACTION.ADD_MERCHANTS_FAIL,
      message: e.message
    })
  }
}

function* handleReg(action) {
  const accountNumber =   action.payload.data[0]
  const bankName =  action.payload.data[1]
  const bic =  action.payload.data[2]
  const email =action.payload.data[3]
  const inn =action.payload.data[4]
  const legalAddress = action.payload.data[5]
  const middleName = action.payload.data[6]
  const name =action.payload.data[7]
  const nameOfLegalEntity =action.payload.data[8]
  const password =action.payload.data[9]
  const phoneNumber =action.payload.data[10]
  const position =   action.payload.data[11]
  const surname =  action.payload.data[12]
  const Edit =  action.payload.data[13]
  const Id =  action.payload.data[14]
  const shopId = action.payload.data[15]
  const onlineCheckout = action.payload.data[16]
  const contractId = action.payload.data[17]
  const contractDate = action.payload.data[18]
  const revShare = action.payload.data[19]
  const withNDS = action.payload.data[20]
  const withoutRevShare = action.payload.data[21]
  const partnerEmail = action.payload.data[22]
  const isPartner = action.payload.data[23]


  try {
    if(Edit !== 'Edit' ){
    const {data} = yield axios.post( '/api/public/requestRegMerchantLegalEntity', {
      accountNumber,
      bankName, bic,
      email, inn, legalAddress,
      middleName, name, nameOfLegalEntity,
      password, phoneNumber, position, surname,
      shopId, onlineCheckout, isPartner, contractId, contractDate, revShare, withNDS, withoutRevShare, partnerEmail,
      headers: {
          authorization: 'Bearer ' + localStorage.getItem('token'),
        }
    })
    yield put(setStatus('200'))
    }else{
      const {data} = yield axios.post( `/api/private/admin/updateLegalMerchant?${"Id=" + Id}`, {
        accountNumber, bankName, bic, email, inn, legalAddress, middleName, name, nameOfLegalEntity, password, phoneNumber, position, surname,
        shopId, onlineCheckout, contractId, contractDate, revShare, withNDS, withoutRevShare, partnerEmail,
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('token'),
        }
      })
      yield put(setStatus('200'))
      yield put(GetMerchants())
      yield put(GetRegMerchants())
    }
  }catch (e) {
    yield put(setStatus('400'))
  }
}


export function* REGIndividualMerchant() {
  yield takeLatest(ADD_MERCHANTS_ACTION.REG_INDIVIDUAL_MERCHANTS_ACTION, HandlendividualMerchant)
}
function* HandlendividualMerchant(action) {
  const name =   action.payload.data[0]
  const surname =  action.payload.data[1]
  const middleName =  action.payload.data[2]
  const inn =action.payload.data[3]
  const email =action.payload.data[4]
  const phoneNumber = action.payload.data[5]
  const password = action.payload.data[6]
  const bankName =action.payload.data[7]
  const bic =action.payload.data[8]
  const accountNumber =action.payload.data[9]
  const series =action.payload.data[10]
  const documentNumber =   action.payload.data[11]
  const dateOfIssue =  action.payload.data[12]
  const addressOfRegistration =  action.payload.data[13]
  const actualAddress =  action.payload.data[14]
  const issueBy =  action.payload.data[15]
  const issueCode =  action.payload.data[16]
  const Edit =  action.payload.data[17]
  const Id =  action.payload.data[18]
  const shopId =  action.payload.data[19]
  const onlineCheckout =  action.payload.data[20]
  const typeIndividualMerchant ="SELF_EMPLOYED"
  const contractId = action.payload.data[21]
  const contractDate = action.payload.data[22]
  const revShare = action.payload.data[23]
  const withoutRevShare = action.payload.data[24]
  const partnerEmail = action.payload.data[25]
  const isPartner = action.payload.data[26]
  try {
    if (Edit === 'Edit') {
      const {data} = yield axios.post(`/api/private/admin/updateIndividualMerchant?${"Id=" + Id}`, {
        name, surname, middleName, inn, email, phoneNumber, password, bankName, bic, accountNumber, series, documentNumber, dateOfIssue, addressOfRegistration, actualAddress, issueBy, issueCode, typeIndividualMerchant,
        shopId,onlineCheckout,contractId, contractDate, revShare, withoutRevShare, partnerEmail,
         headers: {
          authorization: 'Bearer ' + localStorage.getItem('token'),
        }
      })
      yield put(setStatus('200'))
      yield put(GetMerchants())
      yield put(GetRegMerchants())
    } else {
      const {data} = yield axios.post('/api/public/requestRegMerchantIndividual', {
        name, surname, middleName, inn, email, phoneNumber, password, bankName, bic, accountNumber, series, documentNumber, dateOfIssue, addressOfRegistration, actualAddress, issueBy, issueCode,
        shopId,onlineCheckout, contractId, contractDate, revShare, isPartner, withoutRevShare, partnerEmail,
        typeIndividualMerchant: "SELF_EMPLOYED",
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('token'),
        }
      })
      yield put(setStatus('200'))
    }
  }catch (e) {
    yield put(setStatus('400'))
  }
}


function* handleInviteReg(action) {
  const id =action.payload.data[0]
  const merchantType =action.payload.data[1]
  const approve =action.payload.data[2]
  const adminEmail =action.payload.data[3]

  try {
    const {data} = yield axios.get( `/api/private/admin/approveOrCancelMerchants?${'id='+ id}&${'merchantType='+ merchantType}&${'approve='+ approve}&${'adminEmail='+ adminEmail}`, {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('token'),
      }
    })

    yield put(GetMerchants())
    yield put(GetRegMerchants())

  }catch (e) {
    console.log(e)
  }

}
export function* RegHandleInvite() {
  yield takeLatest(ADD_MERCHANTS_ACTION.REG_MERCHANTS_ACTION_INVANTE, handleInviteReg)
}



export function* REGMerchant() {
  yield takeLatest(ADD_MERCHANTS_ACTION.REG_MERCHANTS_ACTION, handleReg)
}
export default merchantsSaga
