import {GET_IMG} from "./GetStationImgReducer";

export const GetStationImg=(stationNumber)=>{
  return{
    type:GET_IMG.STATION_IMG_REQUEST,
    payload:{stationNumber}
  }
}

export const GetStationManyImg =( stationNumber ) =>{
  return{
    type:GET_IMG.STATION_ALL_IMG_REQUEST,
    payload:{stationNumber}
  }
}