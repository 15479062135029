import React from 'react';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import Routers from '../../root/router';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SearchIcon from '@material-ui/icons/Search';
import List from "./List";
import DataList from "../UsersComponents/Components/DataList";
import StationInfoList from "../StationComponents/components/StationInfoList";
import AddTariffsInfo from "../TariffsComponents/components/AddTariffsInfo";
import AddQuestions from "../Q&A/Components/AddQuestions";
import SupportText from "../Support/components/SupportText";
import AddUserAgreement from "../Document/UserAgreementComponents/addUserAgreements";
import EditSupportText from "../Support/components/EditSupportText";
import EditUserAgreement from "../Document/UserAgreementComponents/EditUserAgreement";
import UserBlockPopup from "../../components/common/modalPopup/BlockPopup";
import DeleteStation from "../../components/common/modalPopup/DeleteStation";
import TariffsList from "../TariffsComponents/components/TariffsList";
import AddTransaction from "../Finance/components/addTransaction";
import AddMerchants from "../Merchants/components/AddMerchants";
import AddAdmins from "../Admins/components/AddAdmins";
import BlockMerchant from "../../components/common/modalPopup/BlockMerchant";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from "../../assets/images/Avatar.png";
import ListItemText from "@material-ui/core/ListItemText";
import settings from "../../assets/images/settings.png";
import MerchantReg from "../MerchantReg";
import PowerBanksInfo from "../PowerBanks/Components/PowerBanksInfo";

import Engl from "../../assets/images/Rus (2).png";
import Russian from "../../assets/images/Rus (1).png";
import { Translation } from "react-i18next";
import Button from "@material-ui/core/Button";
import Settings from "./components/Settings";
import LanguagesDropdown from "../../components/common/languages";
import { QuestionsList } from "../../components/common/QuestionsList";
// import ReactFlagsSelect from 'react-flags-select';

//import css module
// import 'react-flags-select/css/react-flags-select.css';

//OR import sass module
// import 'react-flags-select/scss/react-flags-select.scss';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)), #121212',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14)',
    paddingRight: 24, // keep right padding when drawer closed
    minHeight: '40px'
  },
  toolbarIcon: {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)), #121212',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14)',
    display: 'flex',
    color: 'white',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {

    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)), #121212',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14)',
    color: 'white',
    minWidth: '40px',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)), #121212',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14)',
    color: 'white',
    minWidth: '40px',
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    minHeight: '100vh',
    overflow: 'auto',
    marginTop: '47px'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [Show, setShow] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };



  const OpenPopup = () => {
    setShow(true);
  };
  const ClosePopup = () => {
    setShow(false);
  };


  const changeLanguageRu = () => {
    localStorage.setItem('Lan', 'ru')
    window.location.reload()
  }
  const changeLanguageEn = () => {
    localStorage.setItem('Lan', 'en')
    window.location.reload()
  }

  const Role = localStorage.getItem('role')
  const email = localStorage.getItem('Email')
  const Language = localStorage.getItem('Lan')


  return (
    <BrowserRouter>

      <Settings onClick={ClosePopup} bool={Show} />



      <Translation>
        {(t) =>
          <>
            <HashRouter>
              <div className={classes.root}>

                <CssBaseline />
                <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                  <Toolbar className={classes.toolbar}>
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                      <MenuIcon />
                    </IconButton>

                    <IconButton style={{ marginLeft: 'auto' }} color="inherit">
                      <Badge style={{ marginLeft: '10px' }} color="secondary">
                        <LanguagesDropdown />
                      </Badge>
                    </IconButton>

                  </Toolbar>
                  <UserBlockPopup />
                  <BlockMerchant />
                  <DeleteStation />
                  <AddTariffsInfo />
                  <SupportText />
                  <AddUserAgreement />
                  <EditUserAgreement />
                  <EditSupportText />
                  <AddQuestions />
                  {/*<DataList/>*/}
                  <StationInfoList />
                  <TariffsList />
                  <AddTransaction />
                  <AddMerchants />
                  <AddAdmins />

                  {/*<QuestionsList/>*/}


                </AppBar>
                <Drawer

                  variant="permanent"
                  classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                  }}
                  open={open}
                >
                  <IconButton color="inherit" style={{ marginRight: 'auto' }} onClick={handleDrawerClose}>
                    <MenuIcon />
                  </IconButton>
                  <Divider />
                  
                  <div style={{ alignItems: 'flex-start', textAlign: 'flex-start' }}>
                    <ListItem button>
                      <ListItemIcon>
                        <img style={{ height: '35px', marginTop: '15px' }} src={Avatar} alt={Avatar} />
                      </ListItemIcon>
                    </ListItem>
                    <ListItem style={{ borderBottom: '0.5px solid gray' }}>
                      <div style={{ marginBottom: '10px' }}>
                        {open === true ?
                          Role === 'SUPER_ADMIN' ?
                            <>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <ListItemText primary="David Ohanyan" />
                                {Role === 'SUPER_ADMIN' ?
                                  <Button style={{ marginLeft: '40px' }} onClick={OpenPopup}>
                                    <img style={{ height: '18px', }} src={settings} alt={settings} />
                                  </Button> : ''}
                              </div>
                              <span style={{ color: 'rgba(255, 255, 255, 0.6)' }}>{t('Super Admin')}</span>
                            </>
                            :
                            Role === 'ADMIN' ?
                              <>
                                <ListItemText primary={email} />
                                <span style={{ color: 'rgba(255, 255, 255, 0.6)' }}>{t('Admin')}</span>
                              </>
                              :
                              <>
                                <ListItemText primary={email} />
                                <span style={{ color: 'rgba(255, 255, 255, 0.6)' }}>{t('Merchant')}</span>
                              </>
                          :
                          <ListItem button>
                            <ListItemIcon onClick={Role === 'SUPER_ADMIN' ? OpenPopup : ''}>
                              <img style={{ height: '18px', marginTop: '15px', marginLeft: '-9px' }} src={settings} alt={settings} />
                            </ListItemIcon>
                          </ListItem>
                        }
                      </div>
                    </ListItem>
                  </div>
                  <List />
                  {/*<MerchantReg/>*/}
                </Drawer>
                <main className={classes.content}>
                  <Routers />
                </main>
              </div>
            </HashRouter>
          </>}
      </Translation>
    </BrowserRouter>
  );
}
