import {put,call, takeLatest} from "@redux-saga/core/effects";
import { GET_TARIFFS} from "./AddTarifTextReducer";
import * as api from "../../api";
import {GetTariffs} from "../tariffsAction";


function* handleSaga() {
  yield takeLatest(GET_TARIFFS.ADD_TARIFF_TEXT_REQUEST,tariffSaga)
}

function* tariffSaga(action) {
  try {
    const {data} = yield call(api.AddTarifText,action.payload.locationForTariff,action.payload.text)
    yield put({
      type:GET_TARIFFS.ADD_TARIFF_TEXT_SUCCESS,
      payload:{data}
    })
    yield put(GetTariffs(action.payload.locationForTariff))
  }
  catch (e) {
    yield put({
      type:GET_TARIFFS.ADD_TARIFF_TEXT_FAIL,
      message:e.message
    })
  }

}

export default handleSaga