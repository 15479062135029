import {call,put, takeLatest} from "@redux-saga/core/effects";
import {ADD_TRANSACTIONS} from "./AddTransactionsReducer";
import * as api from "../../api";
import axios from "axios";
import {BlockOneMerchant} from "../../merchantes/merchantesAction";
import {BlockOneMerchantByAdmin} from "../../merchantes/GetMerchant/GetMerchantAction";
import {GetTransactions} from "../GetTransactionAction";


function* handleTransaction() {
  yield takeLatest(ADD_TRANSACTIONS.TRANSACTIONS_REQUEST,DefaultTransaction)
}

function* DefaultTransaction(action) {
  try {
    const {data} = yield call(api.addTransActions,  action.payload.phoneNumber,action.payload.description,action.payload.price,action.payload.rentId, action.payload.transactionStatus)
    yield put({
      type:ADD_TRANSACTIONS.TRANSACTIONS_SUCCESS,
      payload:{data}

    })
  }catch (e) {
    yield put({
      type: ADD_TRANSACTIONS.TRANSACTIONS_FAIL,
      message: e.message
    })
  }
}


function* EditTransaction(action) {
  const transactionId = action.payload.transactionId
  const description  = action.payload.description
  const price  = action.payload.price
  const rentId  =action.payload.rentId
  const transactionStatus  = action.payload.transactionStatus

  try {
    const {data} = yield axios.post(`/api/private/admin/editTransaction?${'transactionId=' + transactionId}`,
      {
      // params: {
           description,
           price,
           rentId,
           transactionStatus,
    // },
      headers: {
          authorization: 'Bearer ' + localStorage.getItem('token'),
        }
      })
      yield put(GetTransactions())

  }catch(e) {
    yield put(GetTransactions())
    console.log(e)
  }
}

export function* EditTransactionSaga() {
  yield takeLatest(ADD_TRANSACTIONS.EDIT_TRANSACTIONS_REQUEST,EditTransaction)
}

export default handleTransaction
