import {InfoModalCard} from "../../../../components/common/InfoModal/InfoModal";
import React from "react";
import {NotFound} from "../../../../components/common/Asyncs/NotFound";


export const AdditionalImagesComponent =({onClick,imgList,NoImages})=>{
    return(
        <InfoModalCard
            titleText={'Additional images'}
            ClickClose={onClick}
            ModalSize={'Small'}
            position={'right'}
        >{imgList.length ?
            imgList.map((i,item) =>(
                <div className={'additionalImages'} key={item}>
                    <img src={i} alt="somePhoto"/>
                </div>
            ))
            :
            <NotFound/>
        }
        </InfoModalCard>
    )
}