import {put,call, takeLatest} from "@redux-saga/core/effects";
import {SET_TARIFFS} from "./AddTariffReducer";
import * as api from "../../api";


function* handleSaga() {
  yield takeLatest(SET_TARIFFS.ADD_TARIFFGROUP_REQUEST,tariffSaga)
}

function* tariffSaga(action) {
  try {
    const {data} = yield call(api.addTariffGroup,action.payload.nameTariffGroup,action.payload.location,action.payload.TariffType)
    yield put({
      type:SET_TARIFFS.ADD_TARIFFGROUP_SUCCESS,
      payload:{data}
    })
  }
  catch (e) {
    yield put({
      type:SET_TARIFFS.ADD_TARIFFGROUP_FAIL,
      message:e.message
    })
  }

}

export default handleSaga