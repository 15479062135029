import {USER_ACTIONS} from "../usersReducer";

export const LOCK_USERS = {
  LOCK_USERS_REQUEST: 'LOCK_USERS.LOCK_USERS_REQUEST',
  LOCK_USERS_SUCCESS: 'LOCK_USERS.LOCK_USERS_SUCCESS',
  LOCK_USERS_FAIL: 'LOCK_USERS.LOCK_USERS_FAIL',
  BLOCK_ONE_USER:'USER_ACTIONS.BLOCK_ONE_USER'
}

const initialState = {
  userLockInfo: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOCK_USERS.LOCK_USERS_REQUEST: {
      return {...state, userLockInfo: initialState.userLockInfo}
    }
    case LOCK_USERS.LOCK_USERS_SUCCESS: {
      return {...state, userLockInfo: action.payload.data}
    }
    case LOCK_USERS.LOCK_USERS_FAIL: {
      return {...state, userLockInfo: initialState.userLockInfo}
    }
    default: {
      return state
    }
  }

}

