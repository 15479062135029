import React from "react";
import notFound from "../../../assets/images/notFound.png";
import { Translation } from "react-i18next";

export const NotFound = () => {
  return (
    <Translation>
      {(t) => (
        <>
          <div className="loader-div">
            <img src={notFound} alt="notFound.png" />
            <h1
              style={{ marginTop: "40px", marginLeft: "20px", color: "white" }}
            >
              {t("Not Found")}
            </h1>
          </div>
        </>
      )}
    </Translation>
  );
};
