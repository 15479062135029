import React, {Component} from 'react';
import {connect} from "react-redux";
import {
  addSupportText,
  CloseSupportPage
} from "../../../modules/documents/SupportText/addSuportText/addSuportTextAtion";
import BigTextFieldS from "../../../components/common/Inputs/BigTextField";
import {InfoModalCard} from "../../../components/common/InfoModal/InfoModal";
import ErrMessage from "../../../components/common/ErrMessage";

class EditSupportText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supportEmail: '',
      supportPhoneNumber: '',
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      supportEmail: nextProps.openSupportPage.supportEmail,
      supportPhoneNumber: nextProps.openSupportPage.supportPhoneNumber,
    })
  }

  handleChange = (ev) => {
    const {name, value} = ev.target;
    const newState = {};
    newState[name] = value;
    this.setState(newState);
    this.setState({errMessage: false})
  };
  Create = () => {
    const locationForSupport = this.props.openSupportPage.locationForSupport
    const supportPhoneNumber = this.state.supportPhoneNumber ? this.state.supportPhoneNumber : this.props.openSupportPage.supportPhoneNumber
    const supportEmail = this.state.supportEmail ? this.state.supportEmail : this.props.openSupportPage.supportEmail
    this.props.addSupportText(locationForSupport, supportEmail, supportPhoneNumber)
    this.props.CloseSupportPage()
    this.setState({supportEmail: '', supportPhoneNumber: ''})
  }

  render() {
    const openSupportPage = Object.values(this.props.openSupportPage)
    const {supportPhoneNumber, supportEmail} = this.state
    return (
      <>{openSupportPage.length > 0 &&
      <InfoModalCard
        ClickClose={this.props.CloseSupportPage}
        ModalSize={'Big'}
        titleText={'Edit Support Text'}
        Buttons={true} HandleCancel={() => this.props.CloseSupportPage()}
        HandleSave={this.Create}
        Save={'SAVE CHANGES'}
        Cancel={'CANCEL'}
      >
        <BigTextFieldS
          onChange={this.handleChange}
          value={supportEmail}
          name='supportEmail'
          label={'Email'}/>
        <BigTextFieldS
          onChange={this.handleChange}
          value={supportPhoneNumber}
          name='supportPhoneNumber'
          label={'Phone Number'}/>
        {this.state.errMessage === true ?
          <ErrMessage/>
          : ''
        }
      </InfoModalCard>}
      </>
    );
  }
}

const mapStateToProps = state => ({
  SupportText: state.supportTextReducer.SupportText,
  openSupportPage: state.addSuportTextReducer.openSupportPage
})

export default connect(mapStateToProps, {addSupportText, CloseSupportPage})(EditSupportText);