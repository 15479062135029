import {SIGN_ACTIONS} from "./signInReducer";
import {put, takeLatest,call} from "@redux-saga/core/effects";
import * as api from "../api";
import {searchAuthorization, sendToken} from "./signInAction";

function* signInSaga() {
  yield takeLatest(SIGN_ACTIONS.SIGN_IN_REQUEST, handleSign)
}

function* handleSign(actions) {
  try {
    const res = yield call(api.signIn, actions.payload.login, actions.payload.password)
    if (res.status === 200) {
      localStorage.setItem('token', res.data.token)
    }
    yield put({
      type: SIGN_ACTIONS.SIGN_IN_SUCCESS,
      payload: {data: res.data}
    })
  } catch (e) {
    yield put({
      type: SIGN_ACTIONS.SIGN_IN_FAIL,
      message: 'not authorized'
    })
    yield put(searchAuthorization())
  }
}



function* changePass(actions) {
  try {
    const res = yield call(api.changePassword, actions.payload.password)
    yield put({
      type: SIGN_ACTIONS.CHANGE_PASSWORD_SUCCESS,
      payload: {res}
    })
  } catch (e) {
    console.log(e)
  }
}
export function* changePassSaga() {
  yield takeLatest(SIGN_ACTIONS.CHANGE_PASSWORD_REQUEST, changePass)
}

export default signInSaga
