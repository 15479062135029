import {call,put, takeLatest} from "@redux-saga/core/effects";
import {GET_TRANSACTIONS} from "./GetTransactionReducer";
import * as api from "../api";
import axios from "axios";
import {AddTransactionByIdList, filterTransactionsList} from "./GetTransactionAction";
import {RENTS_ACTIONS} from "../rents/rentsReducer";


function* handleTransaction() {
  yield takeLatest(GET_TRANSACTIONS.TRANSACTIONS_REQUEST,DefaultTransaction)
}

function* DefaultTransaction(action) {
  try {
    const {data} = yield call(api.Transactions,action.payload.page)
    yield put({
      type:GET_TRANSACTIONS.TRANSACTIONS_SUCCESS,
      payload:{data : data.transactions}
    })
    yield put({
      type:GET_TRANSACTIONS.PAGE_INFO,
      payload: {data: {pageCount: data.pageCount, transactionCount: data.transactionCount}}
    })
  }catch (e) {

    if(e.message === 'Request failed with status code 401'){
      localStorage.removeItem('token')
      window.location.reload()
    }

   yield put({
     type: GET_TRANSACTIONS.TRANSACTIONS_FAIL,
     message: e.message
   })
}
}




function* DefaultFiltrTransaction ({payload}) {
  const email = payload.email
  try {
    const {data} = yield axios.get('/api/private/admin/rentsFilter', {
      params: {
        'email':email
      },
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('token'),
      }

    });
    yield put(filterTransactionsList())
  }catch(e) {
    yield put({
      type: GET_TRANSACTIONS.FILTER_TRANSACTIONS_FAIL,
    })

    console.log(e)
  }
}

function* FindTransactionByRentIdSaga(action){
  try {
  const {data} = yield call(api.FindTransactionByRentId,action.payload.transactionId)
    // {
      // data.length ?
        yield put({
          type: GET_TRANSACTIONS.FIND_TRANSACTION_BY_RENT_ID_SUCCESS,
          payload: {data}
        })
        // :
        // yield put({
        //   type: GET_TRANSACTIONS.FIND_TRANSACTION_BY_RENT_ID_SUCCESS,
        //   payload: {data: 'not found'}
        // })
    // }
  }catch {
    yield put({
      type:GET_TRANSACTIONS.FIND_TRANSACTION_BY_RENT_ID_FAIL
    })
    console.log('e')
  }
}



function* FindTransaction ({payload}) {
  const transactionId = payload.transactionId
  try {
    const {data} = yield axios.get('/api/private/admin/findTransactionById', {

      params: {
        'transactionId':transactionId
      },
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('token'),
      }
    });
    yield put(AddTransactionByIdList([data]))

   // if(!data){
   //   const {data} = yield axios.get('/api/private/admin/findTransactionByRentId', {
   //     params: {
   //       'rentId':transactionId
   //     },
   //     headers: {
   //       authorization: 'Bearer ' + localStorage.getItem('token'),
   //     }
   //   });
   //   yield put(AddTransactionByIdList([data]))
   // }

  }catch(e) {
    console.log('catch')
  }
}





export  function* FindTransactionByID() {
  yield takeLatest(GET_TRANSACTIONS.FIND_TRANSACTION_BY_ID,FindTransaction)
}

export  function* FindTransactionByRENTID() {
  yield takeLatest(GET_TRANSACTIONS.FIND_TRANSACTION_BY_RENT_ID,FindTransactionByRentIdSaga)
}


export  function* handleFilterTransaction() {
  yield takeLatest(GET_TRANSACTIONS.FILTER_TRANSACTIONS_REQUEST,DefaultFiltrTransaction)
}


export default handleTransaction
