import {SIGN_ACTIONS} from "./signInReducer";

export const getSignInParams = (login, password) => {
  return {
    type: SIGN_ACTIONS.SIGN_IN_REQUEST,
    payload: {login, password}
  }
}

export const searchAuthorization = ()=>{
  const data ='Not Authorizated'
  return{
    type:SIGN_ACTIONS.SIGN_IN_TOKEN,
    payload: {data}
  }
}

export const ClearAuthorization=()=>{
  return{
    type:SIGN_ACTIONS.CLEAR_SIGN_IN_TOKEN,
  }
}

export const changeSuperAdminPassword=(password)=>{
  return{
    type:SIGN_ACTIONS.CHANGE_PASSWORD_REQUEST,
    payload:{password}
  }
}
