import React from "react";
import {Translation} from "react-i18next";

const styles={
  TitleStyle:{
    color: 'white',
    fontSize: '35px',
    fontStyle: 'normal',
    fontWeight: 'normal',
  }
}

const Title =({Text,style={}, })=>{
  return(
    <>
      <Translation>
        {(t) =>
          <>
    <h3 style={{...styles.TitleStyle, ...style}}>
      {t(Text)}
    </h3>
          </>}
      </Translation>
    </>
  )
}
export default Title
