import React, {Component} from 'react';
import logo from "../../assets/images/logo.png";
import Button from "@material-ui/core/Button";
import RegTextField from "../../components/common/Inputs/RegTextField";
import {connect} from "react-redux";
import {
    merchantReg,
    RegIndividualMerchant,
    RemoveStatus
} from "../../modules/merchantes/AddMerchants/AddMerchantsAction";
import {Link} from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import PhoneNUmber from "../../components/common/Inputs/PhoneNUmber";
import "react-dadata-suggestions/dist/styles.css";
import {Translation} from "react-i18next";
import LanguagesDropdown from '../../components/common/languages'
import DateOfIssueTextField from "../../components/common/Inputs/DateOfIssueTextField";
import OnlyNumbers from "../../components/common/Inputs/OnlyNumbers";
import {DaDataRequestCom} from "../../components/common/Inputs/DaDataRequestCom";
import {Hinit} from "../../components/common/Hinit";
import {OnlineCass} from "../../components/common/OnlineCass";
import {GetMerchants, GetMerchantsAll} from "../../modules/merchantes/merchantesAction";
import TextField from "@material-ui/core/TextField";
import {GetAdmins} from "../../modules/admins/adminAction";
import {NDS} from "../../components/common/NDS";
import {WithoutRevShare} from "../../components/common/WithoutRevShare";

const styles = {
    head: {
        overflow: 'auto',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        background: '#121212',
        position: 'fixed',
        zIndex: 9999
    },
    logoStyle: {
        textAlign: 'center',
        marginTop: '20px'
    },
    buttons: {
        textAlign: 'center',
        marginTop: '20px'
    },
    LogInButton: {
        color: 'white',
        background: '#5F94E2',
        border: '1px solid #5F94E2',
        borderRadius: '5px',
        padding: '5px',
        alignSelf: 'center',
        marginLeft: '10px',
    },
    LogInButtonPassive: {
        marginLeft: '10px',
        border: '1px solid #5F94E2',
        padding: '5px',
        borderRadius: '5px',
        color: '#5F94E2'
    },
    textStyle: {
        fontFamily: 'Arial',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '20px',
        lineHeight: '36px',
        color: 'rgba(255, 255, 255, 0.87)',
        marginTop: '20px'
    },


    CanclePopup: {
        margin: 'auto',
        width: '330px',
        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #121212',
        boxShadow: '0px 11px 15px rgba(0, 0, 0, 0.2), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 24px 38px rgba(0, 0, 0, 0.14)',
        borderRadius: '8px',
        padding: '15px'
    },
    CanclePopupButtons: {
        color: '#5F94E2',
        border: 'none',
        background: 'none'
    },
    headDiv: {
        height: '100vh', position: 'fixed', width: '100%'
    },
    licenseButton: {
        color: 'white',
    },
    SaveButton: {
        marginTop: '20px',
        marginBottom: '10px',
        color: 'white',
        background: '#5F94E2',
        borderRadius: '5px',
        border: '1px solid #5F94E2',
        padding: '8px',
        marginLeft: '5px'
    },
    SaveButtonPassive: {
        marginTop: '20px',
        marginBottom: '10px',
        color: 'white',
        background: '#dddddd',
        borderRadius: '5px',
        border: '1px solid #dddddd',
        padding: '8px',
        marginLeft: '5px'
    }

}
let token = "d395d2fe1619a04a7f414de8d730b54a1fccf9a2";

class MerchantReg extends Component {
    constructor(props) {
        super(props);
        this.isPartner = !!props.isPartner;
        this.state = {
            activeButton: 'Organization',
            errMessage: false,
            Agree: !this.isPartner,

            // emailValid:false,
            position: '',
            name: '',
            email: '',
            phoneNumber: '',
            NotCorrectEmail: false,
            inn: '',
            bankName: "",
            bic: '',
            innName: '',
            legalAddress: '',
            nameOfLegalEntity: '',
            innNameSS: '',
            accountNumber: '',
            middleName: '',
            SERIES: '',
            documentNumber: '',
            emailValid: false,
            dateOfIssue: '',
            surname: '',
            addressOfRegistration: '',
            actualAddress: '',
            issueBy: '',
            issueCode: '',
            CheckboxCheck: false,
            reserveInn: '',
            nameForChangeNameBy: '',
            shopId: '',
            someInfo: [],
            bankInfo: [],
            bicInfo: [],
            innInfo: [],
            nameOfLegalEntityInfo: [],
            legalAddressInfo: [],
            nameInfo: [],
            surnameInfo: [],
            middleNameInfo: [],
            actualAddressInfo: [],
            issueCodeInfo: [],
            onlineCheckout: false,
            withNDS: false,
            withoutRevShare: false,
            contractId: '',
            contractDate: '',
            revShare: '',
            partnerEmail: ''
        }
    }

    handleIndividual = (name) => {
        this.setState({
            emailValid: false,
            errMessage: false,
            Agree: !this.isPartner,
            onlineCheckout: false,
            withNDS: false,
            withoutRevShare: false,
            accountNumber: '',
            bankName: '',
            bic: '',
            email: '',
            inn: '',
            legalAddress: '',
            middleName: '',
            name: '',
            shopId: '',
            nameOfLegalEntity: '',
            pass: '',
            phoneNumber: '',
            position: '',
            surname: '',
            innNameSS: '',
            innName: '',
            NAME: '',
            SERIES: ''
        })
        this.setState({activeButton: name === 'Organization' ? 'Organization' : 'Self'})

    }

    handleOnlyNumber = (ev) => {
        const {name} = ev.target;
        if (name === 'bic') {
            const val = ev.target.value;
            if (ev.target.validity.valid) {
                this.setState({bic: ev.target.value});
            } else if (val === '') {
                this.setState({bic: val})
            }
        }
        if (name === 'accountNumber') {
            const val = ev.target.value;
            if (ev.target.validity.valid) {
                this.setState({accountNumber: ev.target.value});
            } else if (val === '') {
                this.setState({accountNumber: val})
            }
        }
        if (name === 'SERIES') {
            const val = ev.target.value;
            if (ev.target.validity.valid) {
                this.setState({SERIES: ev.target.value});
            } else if (val === '') {
                this.setState({SERIES: val})
            }
        }
        if (name === 'documentNumber') {
            const val = ev.target.value;
            if (ev.target.validity.valid) {
                this.setState({documentNumber: ev.target.value});
            } else if (val === '') {
                this.setState({documentNumber: val})
            }
        }
        // if (name === 'documentNumber') {
        //   const val = ev.target.value;
        //   if (ev.target.validity.valid) {
        //     this.setState({documentNumber: ev.target.value});
        //   } else if (val === '') {
        //     this.setState({documentNumber: val});
        //   }
        // }
        if (name === 'shopId') {
            const val = ev.target.value;
            if (ev.target.validity.valid) {
                this.setState({shopId: ev.target.value});
            } else if (val === '') {
                this.setState({shopId: val});
            }
        }
        if (name === 'revShare') {
            const val = ev.target.value;
            if (ev.target.validity.valid) {
                this.setState({revShare: ev.target.value});
            } else if (val === '') {
                this.setState({revShare: val});
            }
        }

    }


    handleChanges = (ev) => {
        const {name, value} = ev.target;

        if (name === 'position') {
            const val = ev.target.value;
            if (ev.target.validity.valid) {
                this.setState({position: ev.target.value});
            } else if (val === '') {
                this.setState({position: val});
            }
        } else {
            const newState = {};
            newState[name] = value;
            this.setState(newState);
            this.setState({errMessage: false})
        }
    };

    handleReload = () => {
        this.props.RemoveStatus()
        window.location = "/";
    }

    sentRequest = () => {
        const {
            accountNumber,
            bankName,
            bic,
            email,
            inn,
            legalAddress,
            middleName,
            name,
            nameOfLegalEntity,
            pass,
            phoneNumber,
            position,
            surname,
            shopId,
            onlineCheckout,
            contractId,
            contractDate,
            revShare,
            withNDS,
            withoutRevShare,
            partnerEmail,
        } = this.state
        const data = [accountNumber, bankName, bic, email, inn, legalAddress, middleName, name, nameOfLegalEntity, pass, phoneNumber, position, surname, '', '',
            shopId, onlineCheckout, contractId, contractDate, revShare, withNDS, withoutRevShare, partnerEmail, this.isPartner
        ]
        if (accountNumber && bankName && bic && email && inn && legalAddress && middleName && name && nameOfLegalEntity && pass && phoneNumber && position && surname
            && (!this.props.isPartner || shopId)
        ) {
            if (this.state.emailValid === false) {
                this.props.merchantReg(data)
                if (this.props.onClose) {
                    this.props.onClose();
                }
            }
        } else {
            this.setState({
                errMessage: true
            })
        }
    }

    sentIndividualRequest = () => {
        const {
            name,
            surname,
            middleName,
            inn,
            email,
            phoneNumber,
            pass,
            bankName,
            bic,
            accountNumber,
            SERIES,
            documentNumber,
            dateOfIssue,
            addressOfRegistration,
            actualAddress,
            issueBy,
            issueCode
            ,
            shopId,
            onlineCheckout,
            contractId,
            contractDate,
            revShare,
            withoutRevShare,
            partnerEmail
        } = this.state
        const datas = [name, surname, middleName, inn, email, phoneNumber, pass, bankName, bic, accountNumber, SERIES, documentNumber, dateOfIssue, addressOfRegistration, actualAddress, issueBy, issueCode, '', '',
            shopId, onlineCheckout, contractId, contractDate, revShare, withoutRevShare, partnerEmail, this.isPartner
        ]
        if (name && surname && middleName && inn && email && phoneNumber && pass && bankName && bic && accountNumber && SERIES && documentNumber && dateOfIssue && addressOfRegistration && actualAddress && issueBy && issueCode
            && (!this.props.isPartner || shopId)
        ) {
            if (this.state.emailValid === false) {
                this.props.RegIndividualMerchant(datas)
                if (this.props.onClose) {
                    this.props.onClose();
                }
            }
        } else {
            this.setState({errMessage: true})
        }
    }
    handleChangesEmail = (e) => {
        this.setState({email: e.target.value})
    }

    ChackboxHandlechange = (e) => {
        this.setState({partnerEmail: e.target.value})
    }
    handleChangeDateOfIssue = (e) => {
        this.setState({dateOfIssue: e.target.value})
    }

    handleChangeContractDate = (e) => {
        this.setState({contractDate: e.target.value})
    }
    handleChangePhoneNumber = (e) => {
        const val = e.target.value;
        if (e.target.validity.valid) {
            if (!this.state.phoneNumber.length) {
                if (val !== '+') {
                    if (val === '8') {
                        this.setState({phoneNumber: `+7`});
                    } else if (val === '9') {
                        this.setState({phoneNumber: `+79`});
                    } else {
                        this.setState({phoneNumber: `+${e.target.value}`});
                    }
                } else {
                    this.setState({phoneNumber: `+`});
                }
            } else {
                if (val.slice(1, 2) === '8') {
                    this.setState({phoneNumber: `+7`});
                } else if (val.slice(1, 2) === '9') {
                    this.setState({phoneNumber: `+79`});
                } else {
                    this.setState({phoneNumber: e.target.value});
                }
            }

        } else if (val === '') {
            // this.setState({phoneNumber: val});
        }
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.innName) {
            const info = this.state.innName
            if (!this.state.innNameSS) {
                this.setState({innNameSS: info})
            }
        }
    }

    componentDidMount() {
        this.setState({
            inn: '',
            addressOfRegistration: '',
            actualAddress: '',
            bankName: '',
            bic: '',
            nameOfLegalEntity: '',
            legalAddress: '',
            name: '',
            surname: '',
            middleName: ''
        })
        sessionStorage.clear();
        const Role = localStorage.getItem('role');
        if (Role === 'SUPER_ADMIN' || Role === 'ADMIN') {
            this.props.GetMerchants()
        }
    }

    handleChangeAddress = () => {

        if (this.state.CheckboxCheck === false) {
            let reserveAddressOfRegistration = sessionStorage.getItem('addressOfRegistration')
            let addressOfRegistration = this.state.addressOfRegistration ? this.state.addressOfRegistration : reserveAddressOfRegistration
            this.setState({actualAddress: addressOfRegistration, CheckboxCheck: true})
        } else {
            this.setState({actualAddress: '', CheckboxCheck: false})
        }
    }

    chechEmailValid = (e) => {
        let Value = e.target.value
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (Value.length >= 1) {
            if (re.test(Value)) {
                this.setState({emailValid: false})
            } else {
                this.setState({emailValid: true})
            }
        }
    }


    changeIssueBy = (item) => {
        this.setState({issueBy: item.unrestricted_value, issueCode: item.data.code, someInfo: ''})
    }

    changeBankBy = (item) => {
        this.setState({bankName: item.unrestricted_value, bic: item.data.bic, bankInfo: '', bicInfo: ''})
    }
    changeInnBy = (e) => {
        this.setState({
            position: e.data.management ? e.data.management.post : '',
            name: e.data.management ? e.data.management.name.split(' ')[1] : '',
            surname: e.data.management ? e.data.management.name.split(' ')[0] : '',
            middleName: e.data.management ? e.data.management.name.split(' ')[2] : '',
            innNameSS: '', nameOfLegalEntity: e.value,
            innName: e.data.inn, inn: e.data.inn,
            legalAddress: e.data.address.unrestricted_value,
            innInfo: '',
            nameOfLegalEntityInfo: ''
        })
    }
    changeInnByIndi = (e) => {
        this.setState({
            innNameSS: '', nameOfLegalEntity: e.value,
            innName: e.data.inn, inn: e.data.inn,
            innInfo: '', nameOfLegalEntityInfo: ''
        })
    }
    changeLegalAddressBy = (e) => {
        this.setState({legalAddress: e.unrestricted_value, addressOfRegistration: e.unrestricted_value})
    }
    changeActualAddress = (e) => {
        this.setState({actualAddress: e.unrestricted_value})
    }
    changeMiddleName = (e) => {
        this.setState({middleName: e.unrestricted_value, middleNameInfo: ''})
    }
    changeNameBy = (e) => {
        if (e.data.name && e.data.surname && e.data.patronymic) {
            this.setState({
                name: e.data.name, surname: e.data.surname, middleName: e.data.patronymic, nameInfo: '', surnameInfo: ''
            })
        } else if (e.data.name && e.data.surname) {
            this.setState({name: e.data.name, surname: e.data.surname, nameInfo: '', surnameInfo: ''})
        } else if (e.data.name && e.data.patronymic) {
            this.setState({name: e.data.name, middleName: e.data.patronymic, nameInfo: '', surnameInfo: ''})
        } else if (e.data.name) {
            this.setState({name: e.data.name, nameInfo: ''})
        } else if (e.data.surname) {
            this.setState({surname: e.data.surname, surnameInfo: ''})
        }
    }


    NameDaDataRequest = (ev) => {
        const val = ev.target.value;
        let name = ev.target.name
        if (name === 'name') {
            if (ev.target.validity.valid) {
                this.setState({name: ev.target.value});
            }
            // else if (val === '') {
            // this.setState({name: val})
            // }
        }
        if (name === 'surname') {
            if (ev.target.validity.valid) {
                this.setState({surname: ev.target.value});
            } else if (val === '') {
                // this.setState({surname: val})
            }
            // this.setState({surname: ev.target.value})
        }
        if (name === 'middleName') {
            if (ev.target.validity.valid) {
                this.setState({middleName: ev.target.value});
            } else if (val === '') {
                // this.setState({middleName: val})
            }
            // this.setState({middleName:ev.target.value})
        }


        let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio";
        let query = ev.target.value;
        let options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + token
            },
            body: JSON.stringify({query: query})
        }
        fetch(url, options)
            .then(response => response.json())
            .then(result => this.setState(
                name === 'name' ?
                    {nameInfo: result.suggestions} :
                    name === 'surname' ?
                        {surnameInfo: result.suggestions} :
                        name === 'middleName' ?
                            {middleNameInfo: result.suggestions} : ''
            ))
            .catch(error => console.log("error", error))
    }


    legalAddressDaDataRequest = (ev) => {
        if (ev.target.name === 'actualAddress') {
            this.setState({actualAddress: ev.target.value})

        } else {
            this.setState({legalAddress: ev.target.value, addressOfRegistration: ev.target.value})
        }
        let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
        let query = ev.target.value;
        let options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + token
            },
            body: JSON.stringify({query: query})
        }
        ev.target.name === 'actualAddress' ?
            fetch(url, options)
                .then(response => response.json())
                .then(result => this.setState({actualAddressInfo: result.suggestions}))
                .catch(error => console.log("error", error))
            :
            fetch(url, options)
                .then(response => response.json())
                .then(result => this.setState({legalAddressInfo: result.suggestions}))
                .catch(error => console.log("error", error))
    }


    InnDaDataRequest = (ev) => {

        let name = ev.target.name
        if (name === 'nameOfLegalEntity') {
            this.setState({nameOfLegalEntity: ev.target.value})
        }
        if (name === 'Inn') {
            this.setState({inn: ev.target.value})
        }
        let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party";
        let query = ev.target.value;
        let options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + token
            },
            body: JSON.stringify({query: query})
        }
        ev.target.name === 'Inn' ?
            fetch(url, options)
                .then(response => response.json())
                .then(result => this.setState({innInfo: result.suggestions}))
                .catch(error => console.log("error", error))
            :
            fetch(url, options)
                .then(response => response.json())
                .then(result => this.setState({nameOfLegalEntityInfo: result.suggestions}))
                .catch(error => console.log("error", error))
    }


    bankDaDataRequest = (ev) => {
        if (ev.target.name === 'bankName') {
            this.setState({bankName: ev.target.value})
            let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/bank";
            let query = ev.target.value;
            let options = {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Token " + token
                },
                body: JSON.stringify({query: query})
            }
            fetch(url, options)
                .then(response => response.json())
                .then(result => this.setState({bankInfo: result.suggestions}))
                .catch(error => console.log("error", error))
        }

        if (ev.target.name === 'bic') {
            const val = ev.target.value;
            if (ev.target.validity.valid) {
                let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/bank";
                let query = ev.target.value;
                let options = {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Token " + token
                    },
                    body: JSON.stringify({query: query})
                }
                fetch(url, options)
                    .then(response => response.json())
                    .then(result => this.setState({bicInfo: result.suggestions}))
                    .catch(error => console.log("error", error))

                this.setState({bic: ev.target.value});
            } else if (val === '') {
                this.setState({bic: val});
            }

        }

    }


    someTry = (ev) => {
        if (ev.target.name === 'issueCode') {
            this.setState({issueCode: ev.target.value})
        } else {
            this.setState({issueBy: ev.target.value})
        }
        let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fms_unit";
        let query = ev.target.value

        let options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + token
            },
            body: JSON.stringify({query: query})
        }
        ev.target.name === 'issueCode' ?
            fetch(url, options)
                .then(response => response.json())
                .then(result => this.setState({issueCodeInfo: result.suggestions}))
                .catch(error => console.log("error", error))
            :
            fetch(url, options)
                .then(response => response.json())
                .then(result => this.setState({someInfo: result.suggestions}))
                .catch(error => console.log("error", error));
    }

    render() {
        const {activeButton} = this.state
        const admins = this.props.Merchants
        const Role = localStorage.getItem('role');
        return (
            <div className={'MerchantReg'}>
                <Translation>
                    {(t) =>
                        <>
                            <div style={styles.buttons}>
                                <Button onClick={() => this.handleIndividual('Organization')}
                                        style={activeButton === 'Organization' ? styles.LogInButton : styles.LogInButtonPassive}>{t("SP/Organization")}</Button>
                                <Button onClick={() => this.handleIndividual('Self')}
                                        style={activeButton === 'Self' ? styles.LogInButton : styles.LogInButtonPassive}>{t("Self-employed")}</Button>
                            </div>

                            {activeButton === 'Organization' ?
                                <div
                                    onClick={() => this.setState({
                                        innInfo: '',
                                        someInfo: '',
                                        bankInfo: '',
                                        bicInfo: '',
                                        nameOfLegalEntityInfo: '',
                                        legalAddressInfo: '',
                                        nameInfo: '',
                                        surnameInfo: '',
                                        middleNameInfo: '',
                                        actualAddressInfo: '',
                                    })}
                                    style={{textAlign: 'center'}}>
                                    <p style={styles.textStyle}>{t("Registration of a Partner - a legal entity")}</p>


                                    <DaDataRequestCom
                                        infoForText={'inn'}
                                        onBlur={() => this.setState({innInfo: ''})}
                                        name={'Inn'} label={t("INN")}
                                        // type={'tel'}
                                        value={this.state.inn} onChange={this.InnDaDataRequest}
                                        info={this.state.innInfo} onClick={this.changeInnBy}
                                        Length={13}
                                    />

                                    <DaDataRequestCom
                                        infoForText={'inn'}
                                        onBlur={() => this.setState({nameOfLegalEntityInfo: ''})}
                                        name={'nameOfLegalEntity'} label={t('Name of the legal entity')}
                                        // type={'tel'}
                                        value={this.state.nameOfLegalEntity}
                                        onChange={this.InnDaDataRequest}
                                        info={this.state.nameOfLegalEntityInfo}
                                        onClick={this.changeInnBy}
                                    />

                                    <DaDataRequestCom
                                        onBlur={() => this.setState({legalAddressInfo: ''})}
                                        name={'legalAddress'} label={t('Legal Address')}
                                        // type={'tel'}
                                        value={this.state.legalAddress}
                                        onChange={this.legalAddressDaDataRequest}
                                        info={this.state.legalAddressInfo}
                                        onClick={this.changeLegalAddressBy}
                                    />

                                    <RegTextField
                                        // type={'tel'}
                                        value={this.state.position}
                                        name={'position'} onChange={this.handleChanges}
                                        // pattern={'^[a-zA-Z]*$'}
                                        label={t('Position')}/>


                                    <DaDataRequestCom
                                        onBlur={() => this.setState({surnameInfo: ''})}
                                        // Pattern={'^[a-zA-Z]*$'} type={'tel'}
                                        name={'surname'} label={t('Surname')}
                                        value={this.state.surname} onChange={this.NameDaDataRequest}
                                        info={this.state.surnameInfo} onClick={this.changeNameBy}
                                    />


                                    <DaDataRequestCom
                                        onBlur={() => this.setState({nameInfo: ''})}
                                        // Pattern={'^[a-zA-Z]*$'} type={'tel'}
                                        name={'name'} label={t('Firstname')}
                                        value={this.state.name} onChange={this.NameDaDataRequest}
                                        info={this.state.nameInfo} onClick={this.changeNameBy}
                                    />

                                    <DaDataRequestCom
                                        onBlur={() => this.setState({
                                            middleNameInfo: '',
                                            nameForChangeNameBy: ''
                                        })}
                                        // Pattern={'^[a-zA-Z]*$'}  type={'tel'}
                                        name={'middleName'} label={t('Middle Name')}
                                        value={this.state.middleName} onChange={this.NameDaDataRequest}
                                        info={this.state.middleNameInfo} onClick={this.changeMiddleName}
                                    />


                                    <RegTextField
                                        onBlur={(e) => this.chechEmailValid(e)}
                                        onFocus={(e) => this.setState({emailValid: false})}
                                        typeError={this.state.emailValid === true ? 'Error' : ''}
                                        value={this.state.email} name={'email'}
                                        onChange={this.handleChangesEmail}
                                        label={t('Email')}
                                    />
                                    <PhoneNUmber Length={12} onChange={this.handleChangePhoneNumber}
                                                 value={this.state.phoneNumber} label={t('Phone')}/>
                                    <RegTextField value={this.state.pass} type={'password'}
                                                  name={'pass'}
                                                  onChange={this.handleChanges} label={t('Password')}/>

                                    <DaDataRequestCom
                                        infoForText={'bank'}
                                        onBlur={() => this.setState({bicInfo: ''})}
                                        name={'bic'} label={t('Bic')}
                                        // type={'tel'}
                                        value={this.state.bic} onChange={this.bankDaDataRequest}
                                        info={this.state.bicInfo} onClick={this.changeBankBy}
                                        Pattern={'^[0-9]\\d*'}
                                        Length={9}
                                    />
                                    <DaDataRequestCom
                                        infoForText={'bank'}
                                        onBlur={() => this.setState({bankInfo: ''})}
                                        name={'bankName'} label={t('Bank')}
                                        // type={'tel'}
                                        value={this.state.bankName} onChange={this.bankDaDataRequest}
                                        info={this.state.bankInfo} onClick={this.changeBankBy}
                                    />

                                    <OnlyNumbers type={'tel'} Length={20}
                                                 value={this.state.accountNumber} name={'accountNumber'}
                                                 onChange={this.handleOnlyNumber}
                                                 label={t('Account number')}/>

                                    <NDS handleClick={() => this.setState({withNDS: !this.state.withNDS})}/>

                                    {this.props.isPartner ?
                                        <>
                                            <OnlineCass
                                                handleClick={() => this.setState({onlineCheckout: !this.state.onlineCheckout})}/>

                                            <OnlyNumbers type={'tel'} Length={6} value={this.state.shopId}
                                                         name={'shopId'}
                                                         onChange={this.handleOnlyNumber}
                                                         label={t('YouKassa Shop Id')}/>

                                            <Hinit/>

                                            <div style={{marginTop: '20px'}}>
                                                <Link style={styles.licenseButton}
                                                      to={'/license_agreement'}>{t("I AGREE WITH THE LICENSE AGREEMENT")}</Link>
                                                <Checkbox
                                                    onChange={() => this.setState({Agree: this.state.Agree === false})}
                                                    style={{color: 'white', marginLeft: '30px'}}
                                                    value="secondary"
                                                    inputProps={{'aria-label': 'secondary checkbox'}}
                                                />
                                            </div>
                                        </> :
                                        <>
                                            <WithoutRevShare checked={this.state.withoutRevShare} handleClick={() => this.setState({withoutRevShare: !this.state.withoutRevShare})}/>
                                            {this.state.withoutRevShare === false ?
                                                <>
                                            <OnlyNumbers type={'tel'} Length={20}
                                                         value={this.state.revShare}
                                                         name={'revShare'} onChange={this.handleOnlyNumber}
                                                         label={t('Rev Share')}/>
                                            <RegTextField
                                                // type={'tel'}
                                                value={this.state.contractId}
                                                name={'contractId'} onChange={this.handleChanges}
                                                // pattern={'^[a-zA-Z]*$'}
                                                label={t('Contract Id')}/>
                                            <DateOfIssueTextField Length={11} value={this.state.contractDate}
                                                                  onChange={(e) => this.handleChangeContractDate(e)}
                                                                  label={t('Date Of Contract')}/>
                                                </> : ''}
                                            {Role === 'SUPER_ADMIN' || Role === 'ADMIN' ?
                                                <div style={{marginTop: '20px'}}>
                                                    <TextField
                                                        id="outlined-select-currency-native"
                                                        select
                                                        label={t("Admin")}
                                                        InputLabelProps={{
                                                            style: {
                                                                width: '270px',
                                                                color: 'gray',
                                                                background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
                                                                padding: '0 10px 0 0 '
                                                            },
                                                        }}
                                                        inputProps={{
                                                            style: {
                                                                width: '270px',
                                                                color: 'gray',
                                                                fontFamily: 'Arial',
                                                                border: '1px solid white',
                                                                borderRadius: '5px',
                                                            }
                                                        }}
                                                        onChange={this.ChackboxHandlechange}
                                                        SelectProps={{native: true,}}
                                                        variant="outlined"
                                                    >
                                                        <option key="" value=""></option>
                                                        {admins.map((row, i) => (
                                                            <option key={i} value={row.email}>{row.email}</option>
                                                        ))
                                                        }
                                                    </TextField>
                                                </div> : ''}
                                        </>
                                    }
                                    <div style={{textAlign: 'center'}}>
                                        {this.state.errMessage && <div style={{marginTop: '20px'}}>
                              <span style={{
                                  color: '#EB5B47',
                                  fontSize: '16px',
                                  lineHeight: '24px',
                                  letterSpacing: '0.5px',
                              }}>{t("Input not field")}</span>
                                        </div>}
                                        <Button disabled={this.state.Agree === false}
                                                onClick={this.sentRequest}
                                                style={this.state.Agree === false ? styles.SaveButtonPassive : styles.SaveButton}>
                                            {t("CREATE")}
                                        </Button>
                                    </div>

                                </div>

                                :
                                <div onClick={() => this.setState({
                                    innInfo: '',
                                    someInfo: '',
                                    bankInfo: '',
                                    bicInfo: '',
                                    nameOfLegalEntityInfo: '',
                                    legalAddressInfo: '',
                                    nameInfo: '',
                                    surnameInfo: '',
                                    middleNameInfo: '',
                                    actualAddressInfo: '',
                                })}

                                     style={{textAlign: 'center'}}>
                                    <p style={styles.textStyle}>{t("Registration of a Partner - an individual")}</p>


                                    <DaDataRequestCom
                                        onBlur={() => this.setState({surnameInfo: ''})}
                                        // Pattern={'^[a-zA-Z]*$'}
                                        name={'surname'} label={t('Surname')}
                                        value={this.state.surname} onChange={this.NameDaDataRequest}
                                        info={this.state.surnameInfo} onClick={this.changeNameBy}
                                    />

                                    <DaDataRequestCom
                                        onBlur={() => this.setState({nameInfo: ''})}
                                        // Pattern={'^[a-zA-Z]*$'} type={'tel'}
                                        name={'name'} label={t('Name')}
                                        value={this.state.name} onChange={this.NameDaDataRequest}
                                        info={this.state.nameInfo} onClick={this.changeNameBy}
                                    />

                                    <DaDataRequestCom
                                        onBlur={() => this.setState({
                                            middleNameInfo: '',
                                            nameForChangeNameBy: ''
                                        })}
                                        // Pattern={'^[a-zA-Z]*$'} type={'tel'}
                                        name={'middleName'} label={t('Middle Name')}
                                        value={this.state.middleName} onChange={this.NameDaDataRequest}
                                        info={this.state.middleNameInfo} onClick={this.changeMiddleName}
                                    />


                                    <DaDataRequestCom
                                        infoForText={'inn'}
                                        onBlur={() => this.setState({innInfo: ''})}
                                        name={'Inn'} label={t("INN")}
                                        // type={'tel'}
                                        value={this.state.inn} onChange={this.InnDaDataRequest}
                                        info={this.state.innInfo} onClick={this.changeInnByIndi}
                                        Length={13}
                                    />
                                    <div className={'checkeEmailClass'}>
                                        <RegTextField
                                            onBlur={(e) => this.chechEmailValid(e)}
                                            onFocus={(e) => this.setState({emailValid: false})}
                                            typeError={this.state.emailValid === true ? 'Error' : ''}
                                            value={this.state.email} name={'email'}
                                            onChange={this.handleChangesEmail}
                                            label={t('Email')}
                                        />
                                    </div>

                                    <PhoneNUmber Length={12} onChange={this.handleChangePhoneNumber}
                                                 value={this.state.phoneNumber} label={t('Phone')}/>
                                    <RegTextField typeError={false} value={this.state.pass}
                                                  type={'password'} name={'pass'}
                                                  onChange={this.handleChanges} label={t('Password')}/>


                                    <DaDataRequestCom
                                        infoForText={'bank'}
                                        onBlur={() => this.setState({bicInfo: ''})}
                                        name={'bic'} label={t('Bic')}
                                        // type={'tel'}
                                        value={this.state.bic} onChange={this.bankDaDataRequest}
                                        info={this.state.bicInfo} onClick={this.changeBankBy}
                                        Length={9} Pattern={'^[0-9]\\d*'}
                                    />
                                    <DaDataRequestCom
                                        infoForText={'bank'}
                                        onBlur={() => this.setState({bankInfo: ''})}
                                        name={'bankName'} label={t('Bank')}
                                        // type={'tel'}
                                        value={this.state.bankName} onChange={this.bankDaDataRequest}
                                        info={this.state.bankInfo} onClick={this.changeBankBy}
                                    />

                                    <OnlyNumbers type={'tel'} Length={20}
                                                 value={this.state.accountNumber}
                                                 name={'accountNumber'} onChange={this.handleOnlyNumber}
                                                 label={t('Account number')}/>

                                    <p style={styles.textStyle}>{t("Passport data")}</p>
                                    <OnlyNumbers type={'tel'} Length={4} name={'SERIES'}
                                                 value={this.state.SERIES}
                                                 onChange={this.handleOnlyNumber} label={t('Series')}/>
                                    <OnlyNumbers type={'tel'} Length={6} name={'documentNumber'}
                                                 value={this.state.documentNumber}
                                                 onChange={this.handleOnlyNumber}
                                                 label={t('Document Number')}/>
                                    <DateOfIssueTextField Length={11} value={this.state.dateOfIssue}
                                                          onChange={(e) => this.handleChangeDateOfIssue(e)}
                                                          label={t('Date Of Issue')}/>


                                    <DaDataRequestCom
                                        infoForText={'code'}
                                        onBlur={() => this.setState({someInfo: ''})}
                                        name={'issueBy'} label={t('Issue By')}
                                        // type={'tel'}
                                        value={this.state.issueBy} onChange={this.someTry}
                                        info={this.state.someInfo} onClick={this.changeIssueBy}
                                    />


                                    <DaDataRequestCom
                                        infoForText={'code'} Length={9}
                                        onBlur={() => this.setState({issueCodeInfo: ''})}
                                        name={'issueCode'} label={t('Issue Code')}
                                        // type={'tel'}
                                        value={this.state.issueCode} onChange={this.someTry}
                                        info={this.state.issueCodeInfo} onClick={this.changeIssueBy}
                                    />

                                    {/*<RegTextField*/}
                                    {/*  Length={9}*/}
                                    {/*  value={this.state.issueCode}*/}
                                    {/*  name={'issueCode'} onChange={this.handleChanges} label={t('Issue Code')}/>*/}

                                    <DaDataRequestCom
                                        onBlur={() => this.setState({legalAddressInfo: ''})}
                                        name={'legalAddress'} label={t('Address Of Registration')}
                                        // type={'tel'}
                                        value={this.state.legalAddress}
                                        onChange={this.legalAddressDaDataRequest}
                                        info={this.state.legalAddressInfo}
                                        onClick={this.changeLegalAddressBy}
                                    />
                                    <div style={{
                                        marginTop: '10px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                                        <span
                                                            style={{color: 'white'}}>{t('Matches the registration address')}</span>
                                        <Checkbox
                                            disabled={!this.state.legalAddress.length}
                                            onClick={this.handleChangeAddress}
                                            style={{
                                                color: this.state.legalAddress.length ? 'white' : 'gray',
                                                marginLeft: '30px'
                                            }}
                                            value="secondary"
                                            inputProps={{'aria-label': 'secondary checkbox'}}
                                        />
                                    </div>

                                    <DaDataRequestCom
                                        onBlur={() => this.setState({actualAddressInfo: ''})}
                                        name={'actualAddress'} label={t('Actual Address')}
                                        // type={'tel'}
                                        value={this.state.actualAddress}
                                        onChange={this.legalAddressDaDataRequest}
                                        info={this.state.actualAddressInfo}
                                        onClick={this.changeActualAddress}
                                    />

                                    {this.props.isPartner ?
                                        <>
                                            <OnlineCass
                                                handleClick={() => this.setState({onlineCheckout: !this.state.onlineCheckout})}/>

                                            <OnlyNumbers Length={6} value={this.state.shopId} name={'shopId'}
                                                         type={'tel'}
                                                         onChange={this.handleOnlyNumber}
                                                         label={t('YouKassa Shop Id')}/>

                                            <Hinit/>
                                            <div style={{marginTop: '20px'}}>
                                                <Link style={styles.licenseButton}
                                                      to={'/license_agreement'}>{t("I AGREE WITH THE LICENSE AGREEMENT")}</Link>
                                                <Checkbox
                                                    onChange={() => this.setState({Agree: this.state.Agree === false})}
                                                    style={{color: 'white', marginLeft: '30px'}}
                                                    value="secondary"
                                                    inputProps={{'aria-label': 'secondary checkbox'}}
                                                />
                                            </div>
                                        </> :
                                        <>
                                            <WithoutRevShare checked={this.state.withoutRevShare} handleClick={() => this.setState({withoutRevShare: !this.state.withoutRevShare})}/>
                                            {this.state.withoutRevShare === false ?
                                                <>
                                            <OnlyNumbers type={'tel'} Length={20}
                                                         value={this.state.revShare}
                                                         name={'revShare'} onChange={this.handleOnlyNumber}
                                                         label={t('Rev Share')}/>
                                            <RegTextField
                                                // type={'tel'}
                                                value={this.state.contractId}
                                                name={'contractId'} onChange={this.handleChanges}
                                                // pattern={'^[a-zA-Z]*$'}
                                                label={t('Contract Id')}/>
                                            <DateOfIssueTextField Length={11} value={this.state.contractDate}
                                                                  onChange={(e) => this.handleChangeContractDate(e)}
                                                                  label={t('Date Of Contract')}/>
                                                </> : ''}
                                            {Role === 'SUPER_ADMIN' || Role === 'ADMIN' ?
                                                <div style={{marginTop: '20px'}}>
                                                    <TextField
                                                        id="outlined-select-currency-native"
                                                        select
                                                        label={t("Admin")}
                                                        InputLabelProps={{
                                                            style: {
                                                                width: '270px',
                                                                color: 'gray',
                                                                background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
                                                                padding: '0 10px 0 0 '
                                                            },
                                                        }}
                                                        inputProps={{
                                                            style: {
                                                                width: '270px',
                                                                color: 'gray',
                                                                fontFamily: 'Arial',
                                                                border: '1px solid white',
                                                                borderRadius: '5px',
                                                            }
                                                        }}
                                                        onChange={this.ChackboxHandlechange}
                                                        SelectProps={{native: true,}}
                                                        variant="outlined"
                                                    >
                                                        <option key="" value=""></option>
                                                        {admins.map((row, i) => (
                                                            <option key={i} value={row.email}>{row.email}</option>
                                                        ))
                                                        }
                                                    </TextField>
                                                </div> : ''}
                                        </>
                                    }
                                    <div style={{textAlign: 'center'}}>
                                        {this.state.errMessage && <div style={{marginTop: '20px'}}>
                              <span style={{
                                  color: '#EB5B47',
                                  fontSize: '16px',
                                  lineHeight: '24px',
                                  letterSpacing: '0.5px',
                              }}>{t("Input not field")}</span>
                                        </div>}
                                        <Button
                                            disabled={this.state.Agree === false}
                                            onClick={this.sentIndividualRequest}
                                            style={this.state.Agree === false ? styles.SaveButtonPassive : styles.SaveButton}>
                                            {t("CREATE")}
                                        </Button>
                                    </div>
                                </div>

                            }
                        </>
                    }</Translation>
            </div>

        );
    }
}

const mapStateToProps = state => ({
    status: state.AddMerchantsReducer.status,
    Merchants: state.MerchantesReducer.Merchants,
    Admins: state.adminReducer.Admins,
})

export default connect(mapStateToProps, {
    RemoveStatus,
    RegIndividualMerchant,
    merchantReg,
    GetMerchants,
    GetAdmins
})(MerchantReg)