import {put,call, takeLatest} from "@redux-saga/core/effects";
import {STATES} from "./stationsReducer";
import * as api from "../api";


function* handleSaga() {
  yield takeLatest(STATES.STATE_INFO_REQUEST,StationSaga)
}

function* StationSaga(action) {
  try {
    const {data} = yield call(api.GetStations,action.payload.page)
    yield put({
      type: STATES.STATE_INFO_SUCCESS,
      payload: {data: data.stations}
    })
    yield put({
      type: STATES.STATE_PAGES_INFO,
      payload: {data: {pageCount: data.pageCount, stationCount: data.stationCount}}
    })
  }
  catch (e) {

    if(e.message === 'Request failed with status code 401'){
      localStorage.removeItem('token')
      window.location.reload()
    }

    yield put({
      type:STATES.STATE_INFO_FAIL,
      message:e.message
    })
  }

}


function* handleFindStation(action) {
  try {
    const {data} = yield call(api.findStations,action.payload.stationNumber)
    yield put({
      type:STATES.FINDING_STATION_SUCCESS,
      payload:{data}
    })
  }catch (e) {
    yield put({
      type:STATES.FINDING_STATION_FAIL,
    })
    console.log(e)
  }
}

export function* FindStationBYSaga (){
  yield takeLatest(STATES.FINDING_STATION_REQUEST,handleFindStation)
}





// function* handleCechStation(action) {
//   try {
//     const {data} = yield call(api.ChechStations,action.payload.stationNumber)
//     sessionStorage.setItem('Info','found')
//   }catch (e) {
//     sessionStorage.setItem('Info','notFound')
//   }
// }

// export function* CechStationBYSaga (){
//   yield takeLatest(STATES.CECH_STATION_REQUEST,handleCechStation)
// }




function* downloadList(action) {
  try {
    const {data} = yield call(api.DownloadStationList)
    yield put({
      type:STATES.DOWNLOAD_STATION_LIST_SUCCESS,
      payload:{data}
    })
  }catch (e) {
    console.log(e)
  }
}

export function* downloadStationList (){
  yield takeLatest(STATES.DOWNLOAD_STATION_LIST_REQUEST,downloadList)
}




function* handleReloadStationSaga(action) {
  try {
    const {data} = yield call(api.ReloadStation,action.payload.stationID)
    yield put({
      type:STATES.RELOAD_STATION_SUCCESS,
      payload:{data}
    })
  }catch (e) {
    console.log(e)
  }
}

export function* ReloadStationSaga (){
  yield takeLatest(STATES.RELOAD_STATION_REQUEST,handleReloadStationSaga)
}



export default handleSaga
