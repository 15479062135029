
export const USER_ACTIONS = {
  USER_LIST_REQUEST: 'USER_ACTIONS.USER_LIST_REQUEST',
  USER_LIST_SUCCESS: 'USER_ACTIONS.USER_LIST_SUCCESS',
  USER_LIST_FAIL: 'USER_ACTIONS.USER_LIST_FAIL',
  USER_PAGE_LIST:'USER_ACTIONS.USER_PAGE_LIST',
  BLOCK_ONE_USER:'USER_ACTIONS.BLOCK_ONE_USER',
  ACTIVE_USERS_ONE: 'USER_ACTIONS:ACTIVE_USERS_ONE',
  FOR_BLOCKING_USERS:'USER_ACTIONS.FOR_BLOCKING_USERS',
  CLEAR_FOR_BLOCKING_USERS:'USER_ACTIONS.CLEAR_FOR_BLOCKING_USERS',
  DELETE_POPUP_INFO:'USER_ACTIONS.DELETE_POPUP_INFO',
  CLEAR_DELETE_POPUP_INFO:'USER_ACTIONS.CLEAR_DELETE_POPUP_INFO',
  FILTER_ACTIVE_USERS:'USER_ACTIONS.FILTER_ACTIVE_USERS',
  FILTER_BLOCKED_USERS:'USER_ACTIONS.FILTER_BLOCKED_USERS',
  FINDING_REQUEST:'USER_ACTIONS.FINDING_REQUEST',
  FINDING_SUCCESS:'USER_ACTIONS.FINDING_SUCCESS',
  FINDING_FAIL:'USER_ACTIONS.FINDING_FAIL',
  CLEAR_FINDING_DATA:'USER_ACTIONS.CLEAR_FINDING_DATA',
  CECH_PROMO_STATUS_REQUEST:'USER_ACTIONS.CECH_PROMO_STATUS_REQUEST',
  CECH_PROMO_STATUS_SUCCESS:'USER_ACTIONS.CECH_PROMO_STATUS_SUCCESS',

  GET_ALL_PROMO_TARIFFS: 'USER_ACTIONS.GET_ALL_PROMO_TARIFFS',
  SET_ALL_PROMO_TARIFFS: 'USER_ACTIONS.SET_ALL_PROMO_TARIFFS',
  SET_CHOSEN_PROMO_TARIFF: 'USER_ACTIONS.SET_CHOSEN_PROMO_TARIFF',


  GIVE_PROMO_REQUEST : 'USER_ACTIONS.GIVE_PROMO_REQUEST',
  GIVE_PROMO_SUCCESS: 'USER_ACTIONS.GIVE_PROMO_SUCCESS',


  GET_USER_CARD_REQUEST:'USER_ACTIONS.GET_USER_CARD_REQUEST',
  GET_USER_CARD_SUCCESS:'USER_ACTIONS.GET_USER_CARD_SUCCESS',
  CLEAR_USER_CARD_REQUEST:'USER_ACTIONS.CLEAR_USER_CARD_REQUEST',
}

const initialState = {
  usersList: [],
  AllUsers:[],

  activeUsers: [],
  ForBlockingUser:[],
  popupInfo:[],
  PageInfo:[],
  AboutPromo:null,

  blockingPage:'',
  UserCard:[],
  allPromoTariffs: [],
  chosenPromoTariff: {
    name: "", 
    id: ""
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_ACTIONS.USER_LIST_REQUEST : {
      return {...state, usersList: []}
    }
    case USER_ACTIONS.USER_LIST_SUCCESS : {
      return {...state, usersList: action.payload.data,AllUsers:action.payload.data}
    }
    case USER_ACTIONS.CLEAR_FINDING_DATA : {
      return {...state,usersList:[]}
    }
    case USER_ACTIONS.USER_PAGE_LIST : {
      return {...state, PageInfo: action.payload.data,}
    }
    case USER_ACTIONS.USER_LIST_FAIL : {
      return {...state, usersList: initialState.usersList}
    }
    case USER_ACTIONS.DELETE_POPUP_INFO : {
      return {...state, popupInfo: action.payload.data}
    }
    case USER_ACTIONS.CLEAR_DELETE_POPUP_INFO : {
      return {...state, popupInfo:[]}
    }

    // case USER_ACTIONS.ACTIVE_USERS:{
    //   return {...state,activeUsers:action.payload.data}
    // }

    case USER_ACTIONS.FOR_BLOCKING_USERS:{
      return {...state,ForBlockingUser:action.payload.data,  blockingPage : action.payload.page}
    }
    case USER_ACTIONS.CLEAR_FOR_BLOCKING_USERS:{
      return {...state,ForBlockingUser:[]}
    }


    case USER_ACTIONS.ACTIVE_USERS_ONE:
      const newActiveUserList=state.activeUsers.map(item=> {
        if (item.phoneNumber === action.payload.phoneNumber) {
          return {...item, blocking: action.payload.lock}
        }
        return item
      })
      return {...state,activeUsers:newActiveUserList}

    case USER_ACTIONS.BLOCK_ONE_USER :
      const newUserList=state.usersList.map(item=> {
        if (item.phoneNumber === action.payload.phoneNumber) {
          return {...item, blocking: action.payload.lock}
        }
        return item
      })
      return {...state,usersList:newUserList}

    case USER_ACTIONS.FILTER_ACTIVE_USERS:
      const ActiveUserList=state.AllUsers.map(item=> {
        if (item.blocking === false) {
          return item
        }
      })
      return {...state,usersList:ActiveUserList}

    case USER_ACTIONS.FILTER_BLOCKED_USERS:
      const BlockedUserList=state.AllUsers.map(item=> {
        if (item.blocking === true) {
          return item
        }
      })
      return {...state,usersList:BlockedUserList}

    case USER_ACTIONS.FINDING_REQUEST :{
      return {...state,usersList:initialState.usersList}
    }
    case USER_ACTIONS.FINDING_SUCCESS:{
      return {...state,usersList:[action.payload.data]}
    }
    case USER_ACTIONS.FINDING_FAIL:{
      return {...state,usersList:'not found'}
    }

    case USER_ACTIONS.CECH_PROMO_STATUS_SUCCESS : {
      return {...state, AboutPromo: action.payload.data}
    }
    case USER_ACTIONS.GET_USER_CARD_SUCCESS: {
      return {...state, UserCard: action.payload.data}
    }
    case USER_ACTIONS.CLEAR_USER_CARD_REQUEST: {
      return {...state, UserCard: []}
    }

    case USER_ACTIONS.SET_ALL_PROMO_TARIFFS: {
      return {...state, allPromoTariffs: action.payload.data}
    }
    case USER_ACTIONS.SET_CHOSEN_PROMO_TARIFF: {
      return {...state, chosenPromoTariff: {name: action.payload.name, id: action.payload.id}}
    }
    default: {
      return state
    }
  }

}
