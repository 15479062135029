import React, {Component} from 'react';
import {Translation} from "react-i18next";
import CancelButton from "../../../components/common/Buttons/CancelButton";
import SaveButton from "../../../components/common/Buttons/SaveButton";
import {connect} from "react-redux";
import {SentReport} from "../../../modules/merchantes/merchantesAction";
import DatePicker from "react-datepicker";
import * as moment from "moment";
import ErrMessage from "../../../components/common/ErrMessage";
import "react-datepicker/dist/react-datepicker.css";
import {DeleteModal} from "../../../components/common/modalPopup/DeleteModal";

class SendReport extends Component {
  constructor(props) {
    super(props);
    this.state={
      EndDate:'',
      StartDate:'',
      showErrMessage:false,
      showModal:false
    }
  }

  handleChange = (ev) => {
    const {name, value} = ev.target;
    const newState = {};
    newState[name] = value;
    this.setState(newState);
    this.setState({showErrMessage:false})
  };
  sendInfo=()=>{
    if(this.state.StartDate) {
      const Data = {
        email: this.props.email,
        StartDate: `${moment(this.state.StartDate, 'YYYY-MM-DD').format('YYYY-MM-DD')}T00:00:00.001`,
        EndDate: `${moment(this.state.EndDate ? this.state.EndDate : new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD')}T00:00:00.001`
      }
      this.setState({showModal :true})
      this.props.SentReport(Data)
    }else{
      this.setState({showErrMessage:true})
     }
    }

    closeSendModal =()=>{
      this.props.changeScreen()
      this.props.close()
      this.setState({showModal: false})
    }
  filter=(date)=>{
    this.setState({showErrMessage:false})
    if(date ===null ){
      if(this.state.StartDate) {
        this.setState({StartDate: '', EndDate: '',})
      }
    }
    if(date) {
      if (date[0]) {
        this.setState({StartDate: date[0],EndDate:'',})
      }
      if (date[1]) {
        this.setState({EndDate: date[1]})
      }
    }
  }
  render() {
    const ExampleCustomInput = ({ value, onClick }) => (
      <button className= {this.state.StartDate ? "example-custom-input" :'Example-Custom'} onClick={onClick}>
        {this.state.StartDate ?
          this.state.EndDate ?
            ` ${moment(this.state.StartDate, 'YYYY-MM-DD').format('YYYY.MM.DD')} - ${moment(this.state.EndDate, 'YYYY-MM-DD').format('YYYY.MM.DD')}` :
            ` ${moment(this.state.StartDate, 'YYYY-MM-DD').format('YYYY.MM.DD')} - ${moment(new Date(), 'YYYY-MM-DD').format('YYYY.MM.DD')}`
          :
          <Translation>{(t) => <span> {t('--.--.----')}</span> }</Translation>
        }
      </button>
    );
    const startDate =this.state.StartDate ? this.state.StartDate : ''
    const endDate =this.state.EndDate ? this.state.EndDate : ''
    const {showModal} =this.state
    return (
      <Translation>
        {(t) =>
          <>
            {showModal &&
            <DeleteModal
              style={{width:'240px'}}
              boolean={showModal}
              text={t("Report sent")}
              Delete={'Close'}
              handleDelete={() => {this.closeSendModal()}}
            />}

      <div style={{marginLeft:'22px'}}>
        <h4>{t("Send Report")}</h4>
        <DatePicker
          placeholderText="Select a weekday"
          selected={startDate}
          onChange={this.filter}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          customInput={<ExampleCustomInput />}
          shouldCloseOnSelect={false}
          showMonthDropdown
          isClearable
        />
        {this.state.showErrMessage ?
        <ErrMessage/>
        :''}
        <div style={{marginTop: '20px'}}>
          <CancelButton onClick={this.props.close}>{t("CANCEL")}</CancelButton>
          <SaveButton onClick={this.sendInfo}>{t("Send")}</SaveButton>
        </div>
      </div>
          </>
        }
      </Translation>
    );
  }
}
const mapStateToProps = state => ({})

export default connect(mapStateToProps, {SentReport})(SendReport);
