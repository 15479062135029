import {put, takeLatest, call} from "@redux-saga/core/effects";
import {STOP_RENT} from "./StopRentReducer";
import * as api from "../../api";
import {filterRentsList, getRents} from "../rentsAction";


function* rentBlockSaga() {
  yield takeLatest(STOP_RENT.REQUEST, handleSaga)
}

function* handleSaga(action) {
  try {
    const {data} = yield call(api.BlockRent, action.payload.rentId)
    yield put({
      type: STOP_RENT.SUCCESS,
      payload: {data}
    })
    if(action.payload.page.EndDate) {
      yield put(filterRentsList(action.payload.page))
    }else{
      yield put(getRents(action.payload.page))
    }
  } catch (e) {
    yield put({
      type: STOP_RENT.FAIL,
      message: e.message
    })
  }
}

export default rentBlockSaga
