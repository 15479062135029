import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { photoControlById } from "../../../../modules/powerbanks/powerbanksAction";
import PhotoGalleryWithDetails from "./PhotoGalleryWithDetails";
import { NotFound } from "../../../../components/common/Asyncs/NotFound";

import "./styles/index.css";

export const PhotoControl = ({ id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const photoControlData = useSelector(
    (state) => state.powerbanksReducer.photoControl
  );

  useEffect(() => {
    dispatch(photoControlById(id));
  }, []);

  return (
    <div className="photoControlContainer">
      <div className="sideHeader">
        <span>ID</span>
        <strong>{id}</strong>
      </div>
      {photoControlData.length ? (
        <PhotoGalleryWithDetails controlData={photoControlData} />
      ) : (
        <NotFound />
      )}
    </div>
  );
};
