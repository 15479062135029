import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {ADD_SUPPORT} from "./addSuportTextReducer";
import * as api from "../../../api";
import {GetSupportText} from "../supportTextAction";


function* SupportSagas() {
  yield takeLatest(ADD_SUPPORT.SUPPORT_TEXT_REQUEST, handleSaga);
}


function* handleSaga(action) {
  try {
    const { data } = yield call(api.addSupport,action.payload.locationForSupport , action.payload.supportEmail, action.payload.supportPhoneNumber);
    const location = action.payload.locationForSupport
    yield put({
      type: ADD_SUPPORT.SUPPORT_TEXT_SUCCESS,
      payload: { data }
    });
    yield put(GetSupportText(location))
  } catch (e) {
    yield put({
      type: ADD_SUPPORT.SUPPORT_TEXT_FAIL,
      message: e.message,
    });
  }
}

export default SupportSagas;