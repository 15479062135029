import {RENTS_INFO} from "./rentUsersInfoReducer";

export const sendUserRentInfo =(data,page)=>{
  return{
    type:RENTS_INFO.SEND_USERS_RENT_INFO,
    payload:{data,page}
  }
}

export const deleteUserRentInfo = ()=>{
  return{
    type:RENTS_INFO.CLEAR_USERS_RENT_INFO
  }
}