import React, { Component } from "react";
import { connect } from "react-redux";
import {
  FindPowerBankById,
  downloadPowerbanksList,
  getPowerBanksList,
  OpenBankInfo,
  FilterBanksByStatus,
  setCrossLinkPowerbankId,
} from "../../modules/powerbanks/powerbanksAction";
import { Card } from "../../components/common/Card";
import { TableSortLabel } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import { TableCEll } from "../../components/common/TableCEll";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import * as moment from "moment";
import {
  ClearFindingStationData,
  FindStationByNumber,
  GetStation,
} from "../../modules/stations/stationsAction";
import { GetStationImg } from "../../modules/stations/GetStationImg/GetStationImgAction";
import { sendStationInfo } from "../../modules/stations/stationInfo/stationInfoAction";
import { Translation } from "react-i18next";
import { Page } from "../../components/common/Pages";
import { Loader } from "../../components/common/Asyncs/Loader";
import PowerBanksInfo from "./Components/PowerBanksInfo";
import { NotFound } from "../../components/common/Asyncs/NotFound";
import { translateStatuses } from "../../utiles/translateStatuses";
import { SearchInput } from "../../components/common/UniversalTable/components/SearchInput/SearchInput";
import {
  powerBankDownloadController,
  powerBankSearchController,
} from "../../utiles/controller/powerBankSearchController";

class PowerBank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchParam: "",
      activeIndex: "",
      closePage: true,
      page: 0,
      PowerBanksList: "",
      powerBankId: "",
      condition: null,
      status: null,
      colors: {
        RENTED: "green",
        IN_STATION: "white",
        LOST: "red",
        AT_SUPER_ADMIN: "yellow",
        AT_ADMIN: "grey",
        AT_MERCHANT: "orange",
      },
      allFilterButtons: [],
      conditionsButtons: [],
      paginationFn: "",
    };
  }

  handleAll = () => {
    this.props.getPowerBanksList(this.state.page);
    this.setState({ powerBankId: "", PowerBanksList: "", closePage: true });
  };
  handleRented = () => {
    if(this.props.CrossLinkPowerbankId) {
      this.props.setCrossLinkPowerbankId('');
      this.setState({searchParam: ''});
      powerBankSearchController('', {
        status: "RENTED",
        condition: null,
      });
    } else {
      powerBankSearchController(this.state.searchParam, {
        status: "RENTED",
        condition: null,
      });
    }

    this.setState({
      PowerBanksList: "",
      closePage: true,
      paginationFn: "Rented",
      condition: null,
      status: "RENTED",
    });
  };
  handleAtAdmin = () => {
    if(this.props.CrossLinkPowerbankId) {
      this.props.setCrossLinkPowerbankId('');
      this.setState({searchParam: ''});
      powerBankSearchController('', {
        status: "AT_ADMIN",
        condition: null,
      });
    } else {
      powerBankSearchController(this.state.searchParam, {
        status: "AT_ADMIN",
        condition: null,
      });
    }

    this.setState({
      PowerBanksList: "",
      closePage: true,
      paginationFn: "At admin",
      status: "AT_ADMIN",
      condition: null,
    });
  };
  handleAtSuperAdmin = () => {
    if(this.props.CrossLinkPowerbankId) {
      this.props.setCrossLinkPowerbankId('');
      this.setState({searchParam: ''});
      powerBankSearchController('', {
        status: "AT_SUPER_ADMIN",
        condition: null,
      });
    } else {
      powerBankSearchController(this.state.searchParam, {
        status: "AT_SUPER_ADMIN",
        condition: null,
      });
    }
    this.setState({
      PowerBanksList: "",
      closePage: true,
      paginationFn: "At super admin",
      status: "AT_SUPER_ADMIN",
      condition: null,
    });
  };
  handleInStation = () => {
    if(this.props.CrossLinkPowerbankId) {
      this.props.setCrossLinkPowerbankId('');
      this.setState({searchParam: ''});
      powerBankSearchController('', {
        status: "IN_STATION",
        condition: null,
      });
    } else {
      powerBankSearchController(this.state.searchParam, {
        status: "IN_STATION",
        condition: null,
      });
    }
    this.setState({
      PowerBanksList: "",
      closePage: true,
      paginationFn: "In station",
      status: "IN_STATION",
      condition: null,
    });
  };
  handleAtMerchant = () => {
    if(this.props.CrossLinkPowerbankId) {
      this.props.setCrossLinkPowerbankId('');
      this.setState({searchParam: ''});
      powerBankSearchController('', {
        status: "AT_MERCHANT",
        condition: null,
      });
    } else {
      powerBankSearchController(this.state.searchParam, {
        status: "AT_MERCHANT",
        condition: null,
      });
    }
    this.setState({
      PowerBanksList: "",
      closePage: true,
      paginationFn: "At merchant",
      status: "AT_MERCHANT",
      condition: null,
    });
  };
  handleIsLosted = () => {
    if(this.props.CrossLinkPowerbankId) {
      this.props.setCrossLinkPowerbankId('');
      this.setState({searchParam: ''});
      powerBankSearchController('', {
        status: "LOST",
        condition: null,
      });
    } else {
      powerBankSearchController(this.state.searchParam, {
        status: "LOST",
        condition: null,
      });
    }

    this.setState({
      PowerBanksList: "",
      closePage: true,
      paginationFn: "Lost",
      status: "LOST",
      condition: null,
    });
  };

  handleConditionWorking = () => {
    if(this.props.CrossLinkPowerbankId) {
      this.props.setCrossLinkPowerbankId('');
      this.setState({searchParam: ''});
      powerBankSearchController('', {
        status: null,
        checkingStatus: "WORKING",
      });
    } else {
      powerBankSearchController(this.state.searchParam, {
        status: null,
        checkingStatus: "WORKING",
      });
    }

    this.setState({
      PowerBanksList: "",
      closePage: true,
      paginationFn: "Working",
      condition: "WORKING",
      status: null,
    });
  };
  handleConditionRequiredToCheck = () => {
    if(this.props.CrossLinkPowerbankId) {
      this.props.setCrossLinkPowerbankId('');
      this.setState({searchParam: ''});
      powerBankSearchController('', {
        status: null,
        checkingStatus: "REQUIRED_TO_CHECK",
      });
    } else {
      powerBankSearchController(this.state.searchParam, {
        status: null,
        checkingStatus: "REQUIRED_TO_CHECK",
      });
    }

    this.setState({
      PowerBanksList: "",
      closePage: true,
      paginationFn: "Required to check",
      condition: "REQUIRED_TO_CHECK",
      status: null,
    });
  };
  handleConditionReplacemantRequired = () => {
    if(this.props.CrossLinkPowerbankId) {
      this.props.setCrossLinkPowerbankId('');
      this.setState({searchParam: ''});
      powerBankSearchController('', {
        status: null,
        checkingStatus: "REPLACEMENT_REQUIRED",
      });
    } else {
      powerBankSearchController(this.state.searchParam, {
        status: null,
        checkingStatus: "REPLACEMENT_REQUIRED",
      });
    }

    this.setState({
      PowerBanksList: "",
      closePage: true,
      paginationFn: "Replacement required",
      condition: "REPLACEMENT_REQUIRED",
      status: null,
    });
  };

  componentDidMount() {
    this.setState({
      allFilterButtons: [
        { name: "All", fn: this.handleAll },
        { name: "Rented", fn: this.handleRented },
        { name: "At admin", fn: this.handleAtAdmin },
        { name: "At super admin", fn: this.handleAtSuperAdmin },
        { name: "In station", fn: this.handleInStation },
        { name: "At merchant", fn: this.handleAtMerchant },
        { name: "Lost", fn: this.handleIsLosted },
      ],
      conditionsButtons: [
        { name: "All", fn: this.handleAll },
        { name: "Working", fn: this.handleConditionWorking },
        { name: "Required to check", fn: this.handleConditionRequiredToCheck },
        {
          name: "Replacement required",
          fn: this.handleConditionReplacemantRequired,
        },
      ],
      paginationFn: "All",
    });
    if (this.props.CrossLinkPowerbankId) {
      this.setState({searchParam: this.props.CrossLinkPowerbankId})
      powerBankSearchController(this.props.CrossLinkPowerbankId, {
        status: this.state.status,
        checkingStatus: this.state.condition,
      });
    } else {
      this.props.getPowerBanksList(this.state.page);
    }
  }

  componentWillUnmount() {
    this.props.setCrossLinkPowerbankId('');
  };

  OpenBankAllInfo = (row) => {
    this.setState({ activeIndex: "" });
    this.props.OpenBankInfo(row, this.state.page);
  };
  handleClick = (i) => {
    this.setState({ activeIndex: i });
  };
  ClosePopup = () => {
    this.setState({ activeIndex: "" });
  };
  handleChange = (event, value) => {
    this.state.paginationFn === "All" &&
      this.props.getPowerBanksList(value - 1);
    this.state.paginationFn === "Rented" &&
      this.props.FilterBanksByStatus({ page: value - 1, status: "RENTED" });
    this.state.paginationFn === "At admin" &&
      this.props.FilterBanksByStatus({ page: value - 1, status: "AT_ADMIN" });
    this.state.paginationFn === "At super admin" &&
      this.props.FilterBanksByStatus({
        page: value - 1,
        status: "AT_SUPER_ADMIN",
      });
    this.state.paginationFn === "In station" &&
      this.props.FilterBanksByStatus({ page: value - 1, status: "IN_STATION" });
    this.state.paginationFn === "At merchant" &&
      this.props.FilterBanksByStatus({
        page: value - 1,
        status: "AT_MERCHANT",
      });
    this.state.paginationFn === "Lost" &&
      this.props.FilterBanksByStatus({ page: value - 1, status: "LOST" });
    this.state.paginationFn === "Working" &&
      this.props.FilterBanksByStatus({
        page: value - 1,
        checkingStatus: "WORKING",
      });
    this.state.paginationFn === "Required to check" &&
      this.props.FilterBanksByStatus({
        page: value - 1,
        checkingStatus: "REQUIRED_TO_CHECK",
      });
    this.state.paginationFn === "Replacement required" &&
      this.props.FilterBanksByStatus({
        page: value - 1,
        checkingStatus: "REPLACEMENT_REQUIRED",
      });
    this.setState({ PowerBanksList: "", page: value - 1, closePage: true });
  };
  FindStationBuNumberA = (StationNumber) => {
    sessionStorage.setItem("InfoForClose", "CloseStation");
    this.props.FindStationByNumber(StationNumber);
    this.props.GetStationImg(StationNumber);
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.AllStations.length) {
      this.props.sendStationInfo(
        this.props.AllStations ? this.props.AllStations[0].station : ""
      );
    }
  }
  handleRevert = () => {
    this.setState({ PowerBanksList: this.props.PowerBanksList.reverse() });
  };
  sendFindData = () => {
    const { powerBankId } = this.state;
    if (powerBankId.length >= 19) {
      // sessionStorage.setItem('filterButtons','all')

      this.props.FindPowerBankById(powerBankId);
      this.setState({ closePage: false });
    }
  };
  downloadPowerbanks = () => {
    return powerBankDownloadController({
      search: this.state.searchParam,
      status: this.state.status,
      checkingStatus: this.state.condition,
    });
  };
  onSearchChange = (e) => {
    if (!e.target.value && (this.state.status || this.state.condition)) {
      this.setState({ searchParam: "" });
      return powerBankSearchController(null, {
        status: this.state.status,
        checkingStatus: this.state.condition,
      });
    }

    if (!e.target.value) {
      return this.onSearchClear();
    }

    powerBankSearchController(e.target.value, {
      status: this.state.status,
      checkingStatus: this.state.condition,
    });

    this.setState({ searchParam: e.target.value });
  };
  onSearchClear = () => {
    this.setState({ searchParam: "", page: 0 });
    if (this.state.status || this.state.condition) {
      return powerBankSearchController(null, {
        status: this.state.status,
        checkingStatus: this.state.condition,
      });
    }
    this.props.getPowerBanksList(this.state.page);
  };
  FindeHandleChange = (ev) => {
    this.setState({ powerBankId: ev.target.value });
    if (ev.keyCode === 8) {
      if (
        this.state.powerBankId.length === 0 ||
        this.state.powerBankId.length === 19
      ) {
        this.props.getPowerBanksList(this.state.page);
        this.setState({ closePage: true });
      }
    }
  };

  render() {
    // const PowerBanksList = this.state.PowerBanksList
    //   ? this.state.PowerBanksList
    //   : this.props.PowerBanksList;
    const PowerBanksList = this.props.PowerBanksList;
    const { pageCount, powerBankCount } = this.props.PageInfo;
    // 5930171196289065239
    return (
      <Translation>
        {(t) => (
          <>
            <PowerBanksInfo
              getPowerBanksList={this.props.getPowerBanksList}
              page={this.state.page}
            />
            <SearchInput
              value={this.state.searchParam}
              onChange={this.onSearchChange}
              onClear={this.onSearchClear}
            />
            <Card
              TitleName={"Powerbanks"}
              value={this.state.powerBankId}
              Count={powerBankCount}
              place={"Search Powerbank"}
              search={false}
              FilterBoolean={true}
              clearSearch={this.state.powerBankId.length && this.handleAll}
              onChange={this.FindeHandleChange}
              onKeyDown={this.FindeHandleChange}
              onClick={this.sendFindData}
              revert={this.handleRevert}
              downloadList={this.downloadPowerbanks}
              download={true}
              allButtons={this.state.allFilterButtons}
              conditionsButtons={this.state.conditionsButtons}
            >
              <TableSortLabel />
              <Table size="small">
                <TableHead style={{ borderBottom: "1px solid grey" }}>
                  <TableCEll
                    text={[
                      "ID",
                      "Registration",
                      "Station",
                      "Slot",
                      "Status",
                      "Condition",
                    ]}
                  />
                </TableHead>
                <TableCell style={{ border: "none", padding: "1px" }}>
                  .
                </TableCell>
                {this.props.PowerBanksList.length === 0 &&
                !this.props.IsLoading ? (
                  <NotFound />
                ) : PowerBanksList && PowerBanksList.length > 0 ? (
                  PowerBanksList.map((row, i) =>
                    row !== undefined ? (
                      <TableBody key={i}>
                        {this.state.activeIndex === i ? (
                          <div
                            onMouseLeave={() => {
                              this.ClosePopup("");
                            }}
                            className={"activeIndexStyle"}
                          >
                            <div className={"activeIndexButtonsStyle"}>
                              <button
                                onClick={() => {
                                  this.OpenBankAllInfo(row);
                                }}
                                className={"openInfoButton"}
                              >
                                {t("Open Info")}
                              </button>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <TableRow
                          className={"BodyTableRow"}
                          key={row.id ? row.id : i}
                          onClick={() => this.OpenBankAllInfo(row, i)}
                        >
                          <TableCell className={"BodyTableCellCheck"}>
                            {row.powerBankId
                              ? `...${row.powerBankId.slice(-4)}`
                              : "..."}
                          </TableCell>

                          <TableCell className="BodyTableCell">
                            {row.created
                              ? `${moment
                                  .tz(
                                    row.created,
                                    "YYYY-MM-DD/HH:mm",
                                    "Europe/Moscow"
                                  )
                                  .local()
                                  .format("DD.MM.YYYY/HH:mm")}`
                              : "..."}
                          </TableCell>

                          <TableCell
                            onClick={() => {
                              this.OpenBankAllInfo(row, i);
                            }}
                            className={"BodyTableCell"}
                          >
                            {row.station.stationNumber
                              ? row.station.stationNumber
                              : "..."}
                          </TableCell>

                          <TableCell
                            onClick={() => {
                              this.OpenBankAllInfo(row, i);
                            }}
                            className={"BodyTableCell"}
                          >
                            {row.slot ? row.slot : "..."}
                          </TableCell>

                          <TableCell className={"BodyTableCell"}>
                            {
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span
                                  style={{
                                    marginTop: "4px",
                                    color: this.state.colors[`${row.status}`],
                                  }}
                                >
                                  {translateStatuses(
                                    t,
                                    row.status
                                  ).toUpperCase()}
                                </span>
                              </div>
                            }
                          </TableCell>

                          <TableCell
                            onClick={() => {
                              this.OpenBankAllInfo(row, i);
                            }}
                            className={"BodyTableCellRight"}
                          >
                            {translateStatuses(
                              t,
                              row.checkingStatus
                            ).toUpperCase()}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : null
                  )
                ) : (
                  <Loader />
                )}
              </Table>
              {this.state.closePage && (
                <Page
                  page={this.state.page + 1}
                  handleChange={this.handleChange}
                  PageCount={pageCount}
                />
              )}
            </Card>
          </>
        )}
      </Translation>
    );
  }
}
const mapStateToProps = (state) => ({
  FindingList: state.powerbanksReducer.FindingList,
  AllRents: state.rentsReducer.AllRents,
  PowerBanksList: state.powerbanksReducer.PowerBanksList,
  AllStations: state.stationsReducer.AllStations,
  PageInfo: state.powerbanksReducer.PageInfo,
  IsLoading: state.powerbanksReducer.isLoading,
  CrossLinkPowerbankId: state.powerbanksReducer.crossLinkPowerbankId,
});
export default connect(mapStateToProps, {
  downloadPowerbanksList,
  FindPowerBankById,
  ClearFindingStationData,
  FindStationByNumber,
  getPowerBanksList,
  GetStation,
  GetStationImg,
  sendStationInfo,
  OpenBankInfo,
  FilterBanksByStatus,
  setCrossLinkPowerbankId,
})(PowerBank);
