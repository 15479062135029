import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { Translation } from "react-i18next";

export const MerchantsList = ({merchantsList, chooseAllMerchants, sortFn}) => {
    const [choosingMerchants, setChoosingMerchant] = useState('All')
    const [showList, setShowList] = useState(false);

    const selectMerchant = (item) => {
        setChoosingMerchant(item);
        setShowList(false);
        sortFn(item);
    }

    const selectAllMerchants = () => {
        setChoosingMerchant('All');
        setShowList(false);
        chooseAllMerchants();
    }

    return (
        <Translation>
            {(t) => (
                <div className="merchantFilterBlock">
                    <Button className="showMerchantsListButton" onClick={() => setShowList(pv => !pv)}>
                        {t('SHOW BY MERCHANT')}: &nbsp; &nbsp;{choosingMerchants !== 'All' ? choosingMerchants : t('All')}
                    </Button>
                    {showList && 
                        <div className="merchantsFilterList">
                            <Button onClick={selectAllMerchants}>{t('All')}</Button>
                            {merchantsList && 
                                merchantsList.map((item, key) => (
                                    <Button key={key} onClick={() => selectMerchant(item)}>
                                        {item}
                                    </Button>
                                ))
                            }
                        </div>
                    }
                </div>
            )}
        </Translation>
    )
}