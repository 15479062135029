import React, {Component} from 'react';
import {connect} from "react-redux";
import CloseVector from "../../../assets/images/CloseVector.png";
import Button from "@material-ui/core/Button";
import addphoto from "../../../assets/images/addphoto.png";
import change from "../../../assets/images/change.png";
import {AddPhoto} from "../../../modules/stations/AddStations/AddStationImage/AddStationImageAction";
import {AddStation} from "../../../modules/stations/AddStations/addStationsAction";
import {
    ClearEditInfo,
    clearNewStation,
    clearStationInfo,
    EditStationInfo
} from "../../../modules/stations/stationInfo/stationInfoAction";
import {
    DeleteStations,
    LockUnlockStation,
    sendStationNumber
} from "../../../modules/stations/DeleteSelectedStation/DeleteStationAction";
import {LockStations} from "../../../modules/stations/LockStations/lockStationAction";
import {GetAllGroupTariffs} from "../../../modules/tariff/tariffsAction";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import {takePowerBanke, closePopupExtractBank} from "../../../modules/stations/TakeBank/TakeBankAction";
import 'react-image-crop/dist/ReactCrop.css'
import ErrMessage from "../../../components/common/ErrMessage";
import TextFieldS from "../../../components/common/Inputs/TextField";
import TextField from "@material-ui/core/TextField";
import {GetStationImg} from "../../../modules/stations/GetStationImg/GetStationImgAction";
import SignalLevel from "../../../utiles/Signals";
import DIS from '../../../assets/images/DIS.png'
import {DeleteModal} from "../../../components/common/modalPopup/DeleteModal";
import {Translation} from "react-i18next";
import {
    cechStation,
    ClearFindingStationData, FindStationByNumber,
    GetStation,
    ReloadStationAction
} from "../../../modules/stations/stationsAction";
import {GetMerchants, GetMerchantsAll} from "../../../modules/merchantes/merchantesAction";
import ReactCrop from "react-image-crop";
import TextFieldAddStation from "../../../components/common/Inputs/TextFieldAddStation";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CircularProgress from "@material-ui/core/CircularProgress";
import Comments from "../../../components/common/Comments";
import SelectInput from "../../../components/common/Inputs/SelectInput";
import {DaDataRequestCom} from "../../../components/common/Inputs/DaDataRequestCom";
import * as axios from "axios";
import {FullscreenControl, GeolocationControl, Map, Placemark, YMaps, ZoomControl} from "react-yandex-maps";
import YandexMapForm from "./YandexMapForm";
import {StationPowerBanksInfo} from "./StationPowerBanksInfo";
import {Checkbox} from "@material-ui/core";
import {CheckIdAndNumber} from "../../../components/common/CheckIdAndNumber";
import {DeleteIconButton} from "../../../components/common/Buttons/DeleteIconButton";
import {AdditionalImagesComponent} from "./common/AdditionalImages";
import ErrMessageText from "../../../components/common/ErrMessageText";
import {UniversalConfirmModal} from '../../../components/common/Modals/UniversalConfirmModal';
import {GetAdmins} from "../../../modules/admins/adminAction";
import adminReducer from "../../../modules/admins/adminReducer";

const styles = {
    PowerBanksButton: {
        outline: 'none',
        width: '0px',
        cursor: 'pointer',
        fontSize: '18px',
        margin: '10px 0 0 -7px',
        background: "none",
        border: 'none',
        color: 'rgb(95, 148, 226)',
        textDecoration: 'underline'
    },
    ChancelButtonStyle: {
        color: 'white',
        background: '#EB5B47',
        borderRadius: '5px',
        border: '1px solid #EB5B47',
        padding: '6px'
    },
    SaveButtonStyle: {
        color: 'white',
        background: '#5F94E2',
        borderRadius: '5px',
        border: '1px solid #5F94E2',
        padding: '6px',
        marginLeft: '5px'
    },
    headDiv: {
        background: 'rgba(0, 0, 0, 0.4)',
        width: '100%',
        minHeight: '100vh',
        zIndex: '100',
        position: 'fixed',
        right: 0,
    },
    hideDiv: {
        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
        boxShadow: '-2px 0px 4px rgba(0, 0, 0, 0.2), -1px 0px 10px rgba(0, 0, 0, 0.12), -4px 0px 5px rgba(0, 0, 0, 0.14)',
        width: '320px',
        height: '100%',
        position: 'absolute',
        minHeight: '100vh',
        overflowY: 'scroll',
        overflowX: 'hidden',
        zIndex: '20',
        right: 0,
        boxSizing: 'border-box',
    },
    hideDivEditStation: {
        paddingLeft: '20px',
        paddingRight: '20px',
        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
        boxShadow: '-2px 0px 4px rgba(0, 0, 0, 0.2), -1px 0px 10px rgba(0, 0, 0, 0.12), -4px 0px 5px rgba(0, 0, 0, 0.14)',
        width: '320px',
        height: '100%',
        position: 'absolute',
        minHeight: '100vh',
        overflowY: 'scroll',
        overflowX: 'hidden',
        zIndex: '20',
        right: 0,
        boxSizing: 'border-box',
    },
    hideBigDiv: {
        paddingLeft: '20px',
        paddingRight: '20px',
        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
        boxShadow: '-2px 0px 4px rgba(0, 0, 0, 0.2), -1px 0px 10px rgba(0, 0, 0, 0.12), -4px 0px 5px rgba(0, 0, 0, 0.14)',
        width: '500px',
        height: '100%',
        position: 'absolute',
        minHeight: '100vh',
        overflowY: 'scroll',
        overflowX: 'hidden',
        zIndex: '20',
        right: 0,
        boxSizing: 'border-box',
    },
    hideDivCloseButton: {
        position: 'absolute',
        background: 'none',
        border: 'none',
        right: 0,
        marginTop: '5px'
    },
    ChangeDivCloseButton: {
        background: 'none',
        border: 'none',
    },
    showList: {
        marginTop: '10px',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '10px',
        lineHeight: '16px',
        letterSpacing: '1.5px',
        textTransform: 'uppercase',
        color: 'rgba(255, 255, 255, 0.6)',
    },
    showListInfo: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        color: 'rgba(255, 255, 255, 0.87)',
    },
    showListInfoD: {
        marginTop: '10px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        color: 'rgba(255, 255, 255, 0.87)',
    },
    showListInfoDays: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        color: 'rgba(255, 255, 255, 0.87)',
    },
    changeButton: {
        background: 'none',
        border: 'none',
        outline: 'none',
        marginRight: '10px'
    },
    imgStyle: {
        width: '257px',
        marginTop: '10px'
    },
    inputStyle: {
        border: '1px solid rgba(255, 255, 255, 0.87)',
        boxSizing: 'border-box',
        borderRadius: '5px',
        padding: '6px',
        background: 'none',
        width: '260px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.5px',
        color: 'gray',
        marginTop: '8px'
    },
    ChancelButton: {
        color: 'white',
        background: '#EB5B47',
        borderRadius: '5px',
        border: '1px solid #EB5B47',
        padding: '6px'
    },
    CreateButton: {
        color: 'white',
        background: '#5F94E2',
        borderRadius: '5px',
        border: '1px solid #5F94E2',
        padding: '6px',
        marginLeft: '5px'
    },
    blockUserButton: {
        background: 'none',
        border: '1px solid #EB5B47',
        color: '#EB5B47',
        borderRadius: '5px',
        height: '40px',
        width: '260px',
        marginTop: '10px',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '1.25px',
        textTransform: 'uppercase',
    },
    ActiveUserButton: {
        background: 'none',
        border: '1px solid green',
        color: 'rgba(101, 227, 42, 0.87)',
        borderRadius: '5px',
        height: '40px',
        width: '260px',
        marginTop: '10px',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '1.25px',
        textTransform: 'uppercase',
    },
    input1: {
        height: 50
    },
    FormControlStyle: {
        width: '260px',
        background: 'white',
        borderRadius: '5px',
        marginTop: '10px',
        padding: '5px'
    },
    showListId: {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: 'rgba(255, 255, 255, 0.87)'
    },
    TerminlHoursStyle: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '18px',
        marginRight: '15px'
    }
}
const email = localStorage.getItem('Email')

let localCoords;
if (window.location.hash === '#/station') {
    navigator.geolocation.getCurrentPosition(position => {
        localCoords = position.coords
        return localCoords
    })
}

const yandexMapKey = '4e8f7bcc-1e8c-43ad-943e-f67017a61ab0'
//RESERVE API
// const yandexMapKey = 'e59b6cf0-ac13-40ca-a819-198a93172a96';

class StationInfoList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            legalAddressInfo: [],
            DisableAutocomplete: true,
            groupTariffs: null,
            tariffPlan: null,
            TariffGroupList: [],
            address: '',
            QueryAddress: '',
            latitude: "",
            longitude: "",
            numberSlots: '',
            stationID: "",
            stationNumber: '',
            owner: '',
            startHour: [],
            EndHour: [],
            day: ['monday',],

            days: [],
            workTime: {
                "monday": [],
                "tuesday": [],
                "wednesday": [],
                "thursday": [],
                "friday": [],
                "saturday": [],
                "sunday": []
            },

            reserveWorkTime: {
                "monday": [],
                "tuesday": [],
                "wednesday": [],
                "thursday": [],
                "friday": [],
                "saturday": [],
                "sunday": []
            },
            timeSlots: ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30',
                '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30',
            ],
            image: [],

            file: [],
            showException: '',
            weekdays: ['monday', 'tuesday', "wednesday", "thursday", "friday", "saturday", "sunday"],
            avil: false,
            slotNumber: '',

            AllPhotos: [],
            src: [],
            crop: {
                unit: '%',
                width: 100,
                aspect: 13 / 9,
            },

            showModal: false,
            testDey: 'monday',
            HaveDate: '',


            FROM: '',
            TO: '',
            invalidData: false,
            phoneNumber: '',
            ItionalImg: false,


            STARTTIME: '',
            ENDTIME: '',
            reloadIcon: false,


            CechAnswer: false,
            CechStationIDAnswer: false,
            showLoaderForCech: false,
            showLoaderForStationIDCech: false,

            showAddressInputTitle: false,


            additionalAddress: [],

            template: [],
            currentAddress: '',
            defaultLocation: '',
            location: null,
            searchResult: [],
            errorMessage: "",
            globalPixels: null,
            myAddress: null,
            zoom: 10,
            searchValue: "",
            myGeolocationZoom: false,
            coords: '',
            showSearch: false,


            PBInfo: ''
        }
        this.onChange = this.onChange.bind(this)
        this.map = React.createRef()


        this.createTemplateLayoutFactory = ymaps => {
            const self = this;
            const Location = this;
            this.ymaps = ymaps;

            let myGeocoder = ymaps.geocode("Moscow");
            myGeocoder.then(
                function (res) {
                    // Выведем в консоль данные, полученные в результате геокодирования объекта.
                    // console.log('Все данные геообъекта: ', res.geoObjects.get(0).properties.getAll());
                },
                function (err) {
                    // Обработка ошибки.
                }
            );


            if (ymaps) {
                ymaps.geolocation.get({
                    autoReverseGeocode: true
                }).then(function (result) {
                    const a = result.geoObjects.get(0).properties.get('metaDataProperty').GeocoderMetaData.Address.country_code
                    sessionStorage.setItem('local', a)
                    const address = result.geoObjects.get(0).geometry.getCoordinates()
                    Location.setState({defaultLocation: address})
                    self.setState({currentAddress: result.geoObjects.get(0).properties.get('metaDataProperty').GeocoderMetaData.text})
                });
            }

        };

    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            location: nextProps.CreateStation !== "open" ? [nextProps.EditStation.latitude, nextProps.EditStation.longitude] : null,
            address: nextProps.EditStation.address,
            searchValue: nextProps.EditStation.address,
            latitude: nextProps.EditStation.latitude,
            longitude: nextProps.EditStation.longitude,
            numberSlots: nextProps.EditStation.numberSlots,
            stationID: nextProps.EditStation.stationID,
            owner: nextProps.EditStation.owner,
            stationNumber: nextProps.EditStation.stationNumber,
            additionalAddress: nextProps.EditStation.additionalAddress,
            // workTime:  nextProps.CreateStation !== "open"  ?  nextProps.EditStation.workTime  : this.state.reserveWorkTime
        })

    }

    CloseSection = (sectionName) => {
        if (sectionName === 'NewStation') {
            window.location.reload()
        }
        this.ClearCropImg()
        this.props.clearStationInfo()
        this.props.clearNewStation()
        this.emptySearchResult()
        const InfoForClose = sessionStorage.getItem('InfoForClose')
        if (InfoForClose === 'CloseStation') {
            this.props.ClearFindingStationData()
            sessionStorage.removeItem('InfoForClose')
        }


        this.setState({
            src: [],
            file: '',
            AllPhotos: [],
            additionalAddress: '',
            legalAddressInfo: '',
            coords: '',
            CechAnswer: false,
            CechStationIDAnswer: false,
            testDay: 'monday',
            workTime: {
                "monday": [],
                "tuesday": [],
                "wednesday": [],
                "thursday": [],
                "friday": [],
                "saturday": [],
                "sunday": []
            },
            DisableAutocomplete: true,
            TariffGroupList: [],
            days: [],
            address: '',
            latitude: '',
            longitude: '',
            numberSlots: '',
            stationID: '',
            stationNumber: '',
            owner: '',
        })
    }

    handleCheckStationId = (ev) => {
        this.setState({showLoaderForStationIDCech: false, showException: ''})
        if (ev.target.value.length === 16) {
            this.setState({showLoaderForStationIDCech: true})
            let stationID = ev.target.value
            fetch(`https://chargex.am/api/private/admin/FindStationByID?${'stationID=' + stationID}`, {
                method: 'GET',
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    if (data === 'STATION_NOT_FOUND') {
                        this.setState({CechStationIDAnswer: false, showLoaderForStationIDCech: false})
                    } else {
                        this.setState({CechStationIDAnswer: true, showLoaderForStationIDCech: false})
                    }

                })
        }

        this.setState({stationID: ev.target.value, CechStationIDAnswer: false})
    }

    handleChanges = (ev) => {
        this.setState({showException: ''})
        if (ev.target.name === 'stationNumber') {
            if (ev.target.value.length < 5) {
                if (ev.target.value.length === 4) {
                    this.setState({showLoaderForCech: true})
                    let stationNumber = ev.target.value
                    fetch(`https://chargex.am/api/private/admin/stationByNumber?${'stationNumber=' + stationNumber}`, {
                        method: 'GET',
                        headers: {
                            authorization: 'Bearer ' + localStorage.getItem('token'),
                        },
                    })
                        .then(response => {
                            return response.json()
                        })
                        .then(data => {
                            if (data === 'STATION_NOT_FOUND') {
                                this.setState({CechAnswer: false, showLoaderForCech: false})
                            } else {
                                this.setState({CechAnswer: true, showLoaderForCech: false})
                            }

                        })
                }
                sessionStorage.removeItem('Info')
                this.setState({stationNumber: ev.target.value, CechAnswer: false})
            }
        } else if (ev.target.name === 'numberSlots') {
            if (ev.target.value.length < 3) {
                this.setState({numberSlots: ev.target.value})
            }
        } else {
            const {name, value} = ev.target;
            const newState = {};
            newState[name] = value;
            this.setState(newState);
            this.setState({showException: ''})
        }
    };


    handleChange7 = (ev, data) => {
        if (this.state.testDey === 'monday') {
            this.state.workTime.monday.splice(0, 2)
        }
        if (this.state.testDey === 'tuesday') {
            this.state.workTime.tuesday.splice(0, 2)
        }
        if (this.state.testDey === 'wednesday') {
            this.state.workTime.wednesday.splice(0, 2)
        }
        if (this.state.testDey === 'thursday') {
            this.state.workTime.thursday.splice(0, 2)
        }
        if (this.state.testDey === 'friday') {
            this.state.workTime.friday.splice(0, 2)
        }
        if (this.state.testDey === 'saturday') {
            this.state.workTime.saturday.splice(0, 2)
        }
        if (this.state.testDey === 'sunday') {
            this.state.workTime.sunday.splice(0, 2)
        }
        if (data === 'From') {
            this.setState({FROM: ev.target.value})
        } else {
            this.setState({TO: ev.target.value})
        }
    }

    hadnleWeekdays = (ev) => {
        this.setState({FROM: '', TO: '', invalidData: false})
        this.setState({testDey: ev.target.value})

    }

    AllDays = () => {
        this.setState({workTime: {"monday": ["ANYTIME"]}, showException: ''})
        this.setState((prevState) => ({DisableAutocomplete: !prevState.DisableAutocomplete}))
    }

    blockStation = (stationNumber, lock) => {
        const data = {stationNumber, lock}
        this.props.LockUnlockStation(data)
        this.props.clearStationInfo()
    }

    handleTime
    AddStationS = () => {
        const owner = this.state.owner ? this.state.owner : localStorage.getItem('Email')
        let stationNumber = this.state.stationNumber && this.state.stationNumber.length === 4 ? this.state.stationNumber : ''
        const {
            additionalAddress,
            src,
            tariffPlan,
            latitude,
            longitude,
            numberSlots,
            stationID,
            address,
            workTime,
            CechAnswer,
            CechStationIDAnswer
        } = this.state

        // console.log(this.state.workTime,'this.state.workTime')

        if (CechAnswer === false && CechStationIDAnswer === false) {
            if (address && latitude && longitude) {
                if (additionalAddress && tariffPlan && numberSlots && stationID && stationNumber && workTime) {
                    if (this.state.workTime.monday[0] !== "ANYTIME" ? this.state.workTime.monday.length && this.state.workTime.tuesday.length && this.state.workTime.wednesday.length && this.state.workTime.thursday.length && this.state.workTime.friday.length && this.state.workTime.saturday.length && this.state.workTime.sunday.length : "ANYTIME") {
                        this.props.AddStation(additionalAddress, tariffPlan, address, latitude, longitude, numberSlots, owner, stationID, stationNumber, workTime)

                        if (src.length) {
                            let index = 0
                            for (let a of this.state.file) {
                                index += 1
                                let type = a.name.split('.');
                                type = type[type.length - 1];
                                type = type === 'png' ? type : 'jpg'
                                let blob = a.slice(0, a.size, `image/${type}`)
                                let newFile = new File([blob], `${stationNumber}_${index}.${type}`, {type: `image/${type}`});
                                this.state.AllPhotos.push(newFile)
                            }
                        }
                        if (this.state.AllPhotos.length >= 3) {
                            setTimeout(() => this.handleSendPhotos(), 1000)
                        }

                        this.props.clearNewStation()
                        this.ClearState()
                        this.ClearCropImg()
                    } else {
                        this.setState({showException: 'Working hours are incorrect'})
                    }
                } else {
                    this.setState({showException: 'Input not field'})
                }
            } else {
                this.setState({showException: 'Select the location of the terminal on the map or enter it manually'})
            }
        }
    }


    handleInterval
    EditStationS = () => {

        const address = this.state.searchValue ? this.state.searchValue : this.state.address.length > 0 ? this.state.address : this.props.EditStation.address
        const additionalAddress = this.state.additionalAddress ? this.state.additionalAddress : this.props.EditStation.additionalAddress
        const tariffPlan = this.state.tariffPlan ? this.state.tariffPlan : this.props.EditStation.tariffPlan
        const latitude = this.state.coords ? this.state.coords[0] : this.state.latitude.length > 0 ? this.state.latitude : this.props.EditStation.latitude
        const longitude = this.state.coords ? this.state.coords[1] : this.state.longitude.length > 0 ? this.state.longitude : this.props.EditStation.longitude
        const numberSlots = this.state.numberSlots.length > 0 ? this.state.numberSlots : this.props.EditStation.numberSlots
        const owner = this.state.owner.length > 0 ? this.state.owner : this.props.EditStation.owner
        const stationID = this.state.stationID.length > 0 ? this.state.stationID : this.props.EditStation.stationID
        const stationNumber = this.state.stationNumber.length > 0 ? this.state.stationNumber : this.props.EditStation.stationNumber
        const WorkTime = Object.values(this.state.workTime)
        const workTime = WorkTime[0].length === 0 ? this.props.EditStation.workTime : this.state.workTime

        // console.log(this.state.workTime,'this.state.workTime')


        const {CechAnswer, CechStationIDAnswer, src} = this.state
        if (CechAnswer === false && CechStationIDAnswer === false) {
            if (this.state.workTime.monday.length ? this.state.workTime.monday[0] !== "ANYTIME" ? this.state.workTime.monday.length && this.state.workTime.tuesday.length && this.state.workTime.wednesday.length && this.state.workTime.thursday.length && this.state.workTime.friday.length && this.state.workTime.saturday.length && this.state.workTime.sunday.length : "ANYTIME" : true) {
                this.props.AddStation(additionalAddress, tariffPlan, address, latitude, longitude, numberSlots, owner, stationID, stationNumber, workTime,)

                if (src.length) {
                    let index = 0
                    for (let a of this.state.file) {
                        index += 1
                        let type = a.name.split('.');
                        type = type[type.length - 1];
                        type = type === 'png' ? type : 'jpg'
                        let blob = a.slice(0, a.size, `image/${type}`)
                        let newFile = new File([blob], `${stationNumber}_${index}.${type}`, {type: `image/${type}`});
                        this.state.AllPhotos.push(newFile)
                    }
                }
                if (this.state.AllPhotos.length >= 3) {
                    setTimeout(() => this.handleSendPhotos(stationNumber), 1000)
                }
                this.props.ClearEditInfo()
                this.ClearState()
            } else {
                this.setState({showException: 'Working hours are incorrect'})
            }
        }

    }

    ClearState = () => {
        this.setState({
            location: '',
            src: [],
            testDey: 'monday',
            coords: '',
            FROM: '',
            TO: '',
            DisableAutocomplete: true,
            TariffGroupList: [],
            days: [],
            address: '',
            latitude: '',
            longitude: '',
            numberSlots: '',
            stationID: '',
            stationNumber: '',
            owner: '',
            workTime: {
                "monday": [],
                "tuesday": [],
                "wednesday": [],
                "thursday": [],
                "friday": [],
                "saturday": [],
                "sunday": []
            },
        })
    }

    handleSendPhotos = (stationNumber) => {
        this.props.AddPhoto(this.state.AllPhotos, stationNumber)
        alert('ПОСЛЕ РЕГИСТРАЦИИ ТЕРМИНАЛА ПЕРЕЗАГРУЗИТЕ ЕГО.')
    }

    onChange(e) {
        this.setState({file: e.target.files[0]})
    }

    componentWillMount() {
        const Role = localStorage.getItem('role')
        this.props.GetAllGroupTariffs()
        if (Role === 'SUPER_ADMIN' || Role === 'ADMIN') {
            this.props.GetMerchantsAll()
            this.props.GetAdmins()
        }
    }

    componentWillUnmount() {
        const self = this;
        window.removeEventListener('keydown', function (event) {
            if (event.key === '+') {
                self.setState(({zoom: 10}))
            } else if (event.key === '-') {
                self.setState(({zoom: 10}))
            }
        });
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.searchValue !== this.state.searchValue) {
            if (this.state.searchValue) {
                if (this.state.searchValue.length > 0) {
                    this.setState({showSearch: true})
                    // this.props.showSearch(true)
                }
            }
        }

        if (this.state.TariffGroupList.length === 0) {
            this.props.AllGroup.map((row) => (
                this.state.TariffGroupList.push(row.name)
            ))
        }
        const From = this.state.FROM ? this.state.FROM : "00:30"
        const To = this.state.TO ? this.state.TO : "00:00"


        if (this.state.testDey === 'monday') {
            if (From === 'AllDay' || From === 'NotWork' || this.state.TO) {

                if (From !== 'AllDay' && From > this.state.workTime.monday[0]) {

                    this.state.workTime.monday.splice(0, 2)
                }
                if (this.state.workTime.monday.length === 0) {
                    if (From || To === 'AllDay') {
                        this.state.workTime.monday.push('AllDay')
                    } else if (From || To === 'NotWork') {
                        this.state.workTime.saturday.push('NotWork')
                    }
                    if (From && To !== 'AllDay' && 'NotWork') {
                        this.state.workTime.monday.splice(0, 2)
                        this.state.workTime.monday.push(From, To)

                    }
                }
            }
        }
        if (this.state.testDey === 'tuesday') {
            if (From === 'AllDay' || From === 'NotWork' || this.state.TO) {

                if (From !== 'AllDay' && From > this.state.workTime.tuesday[0]) {
                    this.state.workTime.tuesday.splice(0, 2)
                }
                if (this.state.workTime.tuesday.length === 0) {
                    if (From || To === 'AllDay') {
                        this.state.workTime.tuesday.push('AllDay')
                    } else if (From || To === 'NotWork') {
                        this.state.workTime.saturday.push('NotWork')
                    }
                    if (From && To !== 'AllDay' && 'NotWork') {
                        this.state.workTime.tuesday.splice(0, 2)
                        this.state.workTime.tuesday.push(From, To)
                    }
                }
            }
        }
        if (this.state.testDey === 'wednesday') {
            if (From === 'AllDay' || From === 'NotWork' || this.state.TO) {

                if (From !== 'AllDay' && From > this.state.workTime.wednesday[0]) {
                    this.state.workTime.wednesday.splice(0, 2)
                }
                if (this.state.workTime.wednesday.length === 0) {
                    if (From || To === 'AllDay') {
                        this.state.workTime.wednesday.push('AllDay')
                    } else if (From || To === 'NotWork') {
                        this.state.workTime.saturday.push('NotWork')
                    }
                    if (From && To !== 'AllDay' && 'NotWork') {
                        this.state.workTime.wednesday.splice(0, 2)
                        this.state.workTime.wednesday.push(From, To)
                    }
                }
            }
        }
        if (this.state.testDey === 'thursday') {

            if (From === 'AllDay' || From === 'NotWork' || this.state.TO) {

                if (From !== 'AllDay' && From > this.state.workTime.thursday[0]) {
                    this.state.workTime.thursday.splice(0, 2)
                }
                if (this.state.workTime.thursday.length === 0) {
                    if (From || To === 'AllDay') {
                        this.state.workTime.thursday.push('AllDay')
                    } else if (From || To === 'NotWork') {
                        this.state.workTime.saturday.push('NotWork')
                    }
                    if (From && To !== 'AllDay' && 'NotWork') {
                        this.state.workTime.thursday.splice(0, 2)
                        this.state.workTime.thursday.push(From, To)
                    }
                }
            }
        }
        if (this.state.testDey === 'friday') {
            if (From === 'AllDay' || From === 'NotWork' || this.state.TO) {

                if (From !== 'AllDay' && From > this.state.workTime.friday[0]) {
                    this.state.workTime.friday.splice(0, 2)
                }
                if (this.state.workTime.friday.length === 0) {
                    if (From || To === 'AllDay') {
                        this.state.workTime.friday.push('AllDay')
                    } else if (From || To === 'NotWork') {
                        this.state.workTime.saturday.push('NotWork')
                    }
                    if (From && To !== 'AllDay' && 'NotWork') {
                        this.state.workTime.friday.splice(0, 2)
                        this.state.workTime.friday.push(From, To)
                    }
                }
            }
        }
        if (this.state.testDey === 'saturday') {
            if (From === 'AllDay' || From === 'NotWork' || this.state.TO) {

                if (From !== 'AllDay' && From > this.state.workTime.saturday[0]) {
                    this.state.workTime.saturday.splice(0, 2)
                }
                if (this.state.workTime.saturday.length === 0) {
                    if (From || To === 'AllDay') {
                        this.state.workTime.saturday.push('AllDay')
                    } else if (From || To === 'NotWork') {
                        this.state.workTime.saturday.push('NotWork')
                    }
                    if (From && To !== 'AllDay' && 'NotWork') {
                        this.state.workTime.saturday.splice(0, 2)
                        this.state.workTime.saturday.push(From, To)
                    }
                }
            }
        }


        if (this.state.testDey === "sunday") {
            if (From === 'AllDay' || From === 'NotWork' || this.state.TO) {
                // console.log('wwwwwqqqq')
                if (From !== 'AllDay' && From > this.state.workTime.sunday ? this.state.workTime.sunday[0] : '') {
                    this.state.workTime.sunday.splice(0, 2)
                }
                if (this.state.workTime.sunday.length === 0) {
                    if (From || To === 'AllDay') {
                        this.state.workTime.sunday.push('AllDay')
                    } else if (From || To === 'NotWork') {
                        this.state.workTime.sunday.push('NotWork')
                    }
                    if (From && To !== 'AllDay' && 'NotWork') {
                        this.state.workTime.sunday.splice(0, 2)
                        this.state.workTime.sunday.push(From, To)
                    }
                }
            }
        }


        // if(close === 'close')

    }

    ChackboxHandlechange = (e) => {
        this.setState({showException: ''})
        this.props.AllGroup.map((row) => (
            e.target.value === row.name ?
                this.setState({tariffPlan: row})
                : ''
        ))
    }
    ChangeOpen = () => {
        this.props.EditStationInfo(this.props.StationAllINfo)
        this.props.clearStationInfo()
        // this.setState({TariffGroupList:[]})
    }

    bankNumber = (ev) => {
        this.setState({slotNumber: ev.target.value})
    }
    PutOutBank = () => {
        const slotNumber = this.state.slotNumber
        const stationId = this.props.StationAllINfo.stationID
        this.props.takePowerBanke(stationId, slotNumber)
    }

    onSelectFile = e => {
        this.setState({
            file: [...this.state.file, e.target.files[0]]
        })
        this.setState({})
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({
                    src: [...this.state.src, reader.result]
                })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };
    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({crop});
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({croppedImageUrl});

            fetch(croppedImageUrl, {mode: "cors"})
                .then(res => res.blob())
                .then(blob => {
                    const file = new File([blob], 'dot.jpg', {type: 'image/jpg'});
                    this.setState({file: file, showException: ''})
                });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }


    DeleteStation = () => {
        this.props.DeleteStations(this.props.StationAllINfo.stationNumber, this.props.page)
        this.setState({showModal: false})
        this.props.clearStationInfo()
    }
    handleChangePhoneNumber = (e) => {
        const val = e.target.value;
        if (e.target.validity.valid) {
            this.setState({numberSlots: e.target.value});

        } else if (val === '') {
            this.setState({numberSlots: val});
        }
    };


    handleChangesAddress = (e) => {
        this.setState({address: e.value})
    }
    handleChangesAddressForOnchange = (e) => {
        this.setState({QueryAddress: e})
    }

    closeEditSection = () => {
        this.setState({
            location: '',
            CechAnswer: false,
            CechStationIDAnswer: false,
            src: [],
            file: '',
            AllPhotos: [],
            additionalAddress: '',
            testDey: 'monday',
            coords: '',
            FROM: '',
            TO: '',
            DisableAutocomplete: true,
            TariffGroupList: [],
            days: [],
            address: '',
            latitude: '',
            longitude: '',
            numberSlots: '',
            stationID: '',
            stationNumber: '',
            owner: '',
            workTime: {
                "monday": [],
                "tuesday": [],
                "wednesday": [],
                "thursday": [],
                "friday": [],
                "saturday": [],
                "sunday": []
            },
        })
        this.props.ClearEditInfo()
        this.emptySearchResult()
    }


    ClearCropImg = (item) => {

        this.state.src.splice(item, 1);

    }

    handleReload = () => {
        this.setState({reloadIcon: true})
        this.props.ReloadStationAction(this.props.StationAllINfo.stationID)
        setTimeout(() => this.handleReloadFunctions(), 3000)
    }

    handleReloadFunctions = () => {
        this.setState({reloadIcon: false})
        this.props.GetStation(this.props.page)
        this.CloseSection()
    }


    handleCloseComment = () => {
        this.CloseSection()
        this.props.GetStation(this.props.page)
    }

    changeLegalAddressBy = (e) => {
        this.setState({address: e.value})
    }
    //
    // legalAddressDaDataRequest=(ev)=>{
    //   let token = "d395d2fe1619a04a7f414de8d730b54a1fccf9a2";
    //
    //   this.setState({address:ev.target.value})
    //   let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
    //   let query = ev.target.value;
    //   let options = {
    //     method: "POST",
    //     mode: "cors",
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Accept": "application/json",
    //       "Authorization": "Token " + token
    //     },
    //     body: JSON.stringify({query: query})
    //   }
    //   fetch(url, options)
    //       .then(response => response.json())
    //       .then(result => this.setState({legalAddressInfo:result.suggestions}))
    //       .catch(error => console.log("error", error))
    // }


    searchToggle = () => {
        // this.props.showSearch(false);
        this.setState({showSearch: false})
        // this.props.showSearch(false);
        this.setState({searchResult: [], errorMessage: "", searchValue: ""})
    };

    searchHandler = ({target: {value}}) => {
        this.setState({geolocationPlacemark: false, address: value, searchValue: value})
        this.ymapsSearchHandler(value)
    };
    ymapsSearchHandler = async (value) => {
        this.setState({errorMessage: ''})
        this.setState({showException: ''})
        if (this.ymaps) {
            const name = value;

            if (name) {

                const url = `https://geocode-maps.yandex.ru/1.x/?format=json&geocode=${name}&apikey=4e8f7bcc-1e8c-43ad-943e-f67017a61ab0`;
                const response = await axios(url);
                const addresses = await response.data.response.GeoObjectCollection.featureMember;

                this.setState({searchResult: addresses});
            } else this.setState({searchResult: []})
        }
    };

    getPixels = (ev) => {
        const xPixels = this.map[0];
        this.setState({xPixels})
    };

    GeolocationHandler = async () => {
        this.setState(prevState => ({myGeolocationZoom: !prevState.myGeolocationZoom}))
        if (this.ymaps) {
            const result = await this.ymaps.geolocation.get({autoReverseGeocode: true})
            const myAddress = await result.geoObjects.get(0).geometry.getCoordinates()
            const currentAddress = await result.geoObjects.get(0).properties.get('metaDataProperty').GeocoderMetaData.text
            await this.setState({
                currentAddress,
                myAddress,
                zoom: this.state.myGeolocationZoom ? 17 : 17.00000001,
                location: null
            })
        }
    };

    onMapClick = async (e) => {
        // TODO change this part add sco yandex api
        const coords = e.get("coords");
        this.setState({coords: coords, showException: '', location: coords, latitude: coords[0], longitude: coords[1],})
        let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address";
        let token = "d395d2fe1619a04a7f414de8d730b54a1fccf9a2";
        let query = {lat: coords[0], lon: coords[1]};
        let options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + token
            },
            body: JSON.stringify(query)

        }

        fetch(url, options)
            .then(response => response.json())
            .then(result => this.setState({
                searchValue: result.suggestions.length ? result.suggestions[0].value : '',
                address: result.suggestions.length ? result.suggestions[0].value : '',
                someElse: result.suggestions.length ? result.suggestions[0] : ''

            }))
            .catch(error => console.log("error", error));
    };

    ymapsSelect = async (name) => {
        if (!this.ymaps) this.createTemplateLayoutFactory()
        const url = `https://geocode-maps.yandex.ru/1.x/?format=json&geocode=${name}&apikey=4e8f7bcc-1e8c-43ad-943e-f67017a61ab0`;
        const result = await axios(url);

        if (name) {
            try {
                const address = await result.data.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos.split(" ").reverse()
                this.setState({
                    latitude: address[0],
                    longitude: address[1],
                    location: address,


                    coords: '',


                    currentAddress: result.data.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty.GeocoderMetaData.text,
                    errorMessage: "",
                    searchValue: result.data.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty.GeocoderMetaData.text,
                    address: result.data.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty.GeocoderMetaData.text,
                    searchResult: '',
                    myAddress: null,
                    zoom: 10,
                });
            } catch {
                this.setState({
                    searchResult: [],
                    errorMessage: `"Ничего не нашлось"`,
                    currentAddress: ""
                });
            }
        }

    }
    emptySearchResult = () => {
        this.setState({searchResult: [], searchValue: "", location: ''});
    };

    handleChangePBInfo = (info) => {
        this.setState({PBInfo: info})
    }

    handleEditionalAddress = async (ev) => {
//     const url = `https://search-maps.yandex.ru/v1/?text=${ev.target.value}&type=biz&lang=ru_RU&apikey${yandexMapKey}`
//     // https://geocode-maps.yandex.ru/1.x/?format=json&geocode=${name}&apikey=4e8f7bcc-1e8c-43ad-943e-f67017a61ab0`;
//     const response = await axios(url);
//     const addresses = await response.data
// console.log(addresses)

        this.setState({additionalAddress: ev.target.value, showException: ''})
    }

    render() {

        // console.log(this.props.StationAllINfo.workTime.monday[1],'this.props.StationAllINfo.workTime.monday[1]')
        const {errorMessage, searchResult, searchValue} = this.state;
        const {searchShown,} = this.props;

        const language = localStorage.getItem('Lan')
        const mapData = {
            center: this.state.myAddress ? this.state.myAddress : this.state.location ? this.state.location :
                this.state.defaultLocation ? this.state.defaultLocation
                    : localCoords ? [localCoords.latitude, localCoords.longitude] :
                        [55.751244, 37.618423],
            zoom: this.state.zoom,
        };
        const lan = language === 'ru' ? 'ru_RU' : 'en_US'


        const StationsArray = Object.values(this.props.StationAllINfo ? this.props.StationAllINfo : [])
        const EditStation = Object.values(this.props.EditStation)
        const timeSlots = Array.from(new Array(24 * 2)).map((_, index) => `${index < 20 ? '0' : ''}${Math.floor(index / 2)}:${index % 2 === 0 ? '00' : '30'}`,);
        const WorkList = this.state.workTime && this.state.workTime
        const TestDay = this.state.testDey
        const Start = WorkList &&
        TestDay === 'monday' ? WorkList.monday :
            TestDay === 'tuesday' ? WorkList.tuesday :
                TestDay === 'wednesday' ? WorkList.wednesday :
                    TestDay === 'thursday' ? WorkList.thursday :
                        TestDay === 'friday' ? WorkList.friday :
                            TestDay === 'saturday' ? WorkList.saturday :
                                TestDay === 'sunday' ? WorkList.sunday : ''


        const workDays = this.props.StationAllINfo ? this.props.StationAllINfo.workTime : ''
        if (workDays) {
            if (workDays.monday[0] === 'ANYTIME') {
                if (this.state.days[0] === "ANY DAY") {
                } else {
                    this.state.days.push("ANY DAY")
                }
            } else {
                for (let i in workDays) {
                    this.state.days.push(i)
                }
            }
        }

        const tariffPlan = this.state.tariffPlan ? this.state.tariffPlan : this.props.EditStation.tariffPlan
        const StationInfo = this.props.StationInfo
        const freePorts = StationInfo ? StationInfo.stationInfo : ''
        const freePortsLength = freePorts ? StationInfo.station.numberSlots - freePorts.length : ''
        const Role = localStorage.getItem('role')
        const {showModal} = this.state
        const token = 'd395d2fe1619a04a7f414de8d730b54a1fccf9a2';
        let simCardId = this.props.StationAllINfo ? this.props.StationAllINfo.realSimCardId : 'null'
        let simCardProvider = this.props.StationAllINfo ? this.props.StationAllINfo.simCardProvider : 'null'
        const NewArrayForSlot = []
        const numberSlots = this.props.StationAllINfo ? this.props.StationAllINfo.numberSlots : ''
        if (NewArrayForSlot.length === 0) {
            for (let i = 0; i < numberSlots; i++) {
                NewArrayForSlot.push(i + 1)
            }
        }
        const {crop, croppedImageUrl, src} = this.state;

        return (
            <Translation>
                {(t) =>
                    <>
                        {
                            this.props.failRequest === 'Request failed with status code 409' &&
                            <UniversalConfirmModal
                                header={`${t('modal.confirm.limitText')}`}
                                deniedFn={() => this.props.closePopupExtractBank()}
                                firstButtonText={`${t('modal.confirm.close')}`}
                            />
                        }
                        {EditStation.length > 0 ?
                            <div style={styles.headDiv}>
                                <div onClick={this.closeEditSection} className={'CloseSectionClass'}/>
                                <div onClick={() => this.setState({legalAddressInfo: '', searchResult: [],})}
                                     style={styles.hideDivEditStation}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: '10px',
                                        marginLeft: '-25px'
                                    }}>
                                        <Button onClick={this.closeEditSection} style={styles.ChangeDivCloseButton}>
                                            <img src={CloseVector} alt={CloseVector}/></Button>
                                        <span>{t("Edit Station")}</span>
                                    </div>


                                    <div className='EditMapStyle' style={{
                                        height: '290px',
                                        overFlow: 'hidden',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginTop: '10px'
                                    }}>
                                        <span style={{
                                            marginTop: '10px',
                                            marginBottom: '10px'
                                        }}>{t('Select terminal location on the map')}</span>
                                        <YandexMapForm
                                            searchResult={searchResult}
                                            searchShown={searchShown}
                                            errorMessage={errorMessage}
                                            searchValue={searchValue}
                                            ymapsSearchHandler={this.ymapsSearchHandler}
                                            searchToggle={this.searchToggle}
                                            inputMB={this.inputMB}
                                            ymapsSelect={this.ymapsSelect}
                                            emptySearchResult={this.emptySearchResult}
                                            searchHandler={this.searchHandler}
                                        />

                                        <YMaps query={{ns: "use-load-option", lang: lan, apikey: yandexMapKey,}}>
                                            <Map
                                                onClick={this.onMapClick}
                                                instanceRef={ref => {
                                                    if (ref) this.map = ref._globalPixelCenter
                                                }}
                                                onLoad={this.createTemplateLayoutFactory}
                                                state={mapData}
                                                modules={['templateLayoutFactory', 'geolocation', "geocode"]}
                                                style={{flex: 2, flexGrow: 1}}
                                                behavior={'scrollZoom'}
                                            >
                                                {this.state.coords ?
                                                    <Placemark geometry={this.state.coords}/>
                                                    :
                                                    this.state.location ?
                                                        <Placemark
                                                            geometry={this.state.location && [this.state.location[0], this.state.location[1]]}/>
                                                        :
                                                        null
                                                }
                                                <FullscreenControl
                                                    options={{maxWidth: '100px'}}
                                                />

                                                <ZoomControl
                                                    onClick={this.getPixels}
                                                    options={{position: {top: "25vh", right: 20}, size: 'small'}}
                                                />

                                                <GeolocationControl
                                                    onClick={this.GeolocationHandler}
                                                    options={{position: {top: "25vh", right: 8}, noPlacemark: true}}
                                                />
                                            </Map>
                                        </YMaps>

                                    </div>

                                    <div style={{display: 'flex', flexDirection: 'column', marginTop: '130px'}}>

                                        <div>
                                            <div>
                                                <p style={{color: 'red'}}>{t('Upload at least 3 photos for Yandex map')}</p>
                                                <input type="file" accept="image/*" onChange={this.onSelectFile}/>
                                            </div>
                                            {src.length ? src.map((i) => (
                                                <div style={{marginTop: '10px'}}>
                                                    <DeleteIconButton key={i} onClick={() => this.ClearCropImg(i)}/>
                                                    <ReactCrop
                                                        src={i}
                                                        ruleOfThirds
                                                        // crop={crop}
                                                        // onImageLoaded={this.onImageLoaded}
                                                        // onComplete={this.onCropComplete}
                                                        // onChange={this.onCropChange}
                                                    />
                                                </div>
                                            )) : null}

                                        </div>
                                        <div style={{marginTop: '30px'}}>

                                            <TextField
                                                id="outlined-select-currency-native"
                                                value={this.state.additionalAddress}
                                                type={'tel'}
                                                label={t('Additional information at')}
                                                name={'additionalAddress'}
                                                InputLabelProps={{
                                                    style: {
                                                        border: 'none',
                                                        fontSize: '14px',
                                                        lineHeight: '16px',
                                                        letterSpacing: '0.4px',
                                                        color: 'rgba(255, 255, 255, 0.6)',
                                                        padding: '0 10px 0 0 '
                                                    },
                                                }}
                                                inputProps={{
                                                    autoComplete: "new-password",
                                                    style: {
                                                        color: 'gray',
                                                        outline: 'none',
                                                        width: '227px',
                                                        fontFamily: 'Arial',
                                                        border: '1px solid white',
                                                        borderRadius: '5px',
                                                    }
                                                }}
                                                onChange={this.handleEditionalAddress}
                                                SelectProps={{native: true,}}
                                                variant="outlined"
                                            />


                                            <SelectInput
                                                InputWidth={'210px'}
                                                defaultData={this.state.numberSlots}
                                                onChange={this.handleChanges}
                                            />

                                            <CheckIdAndNumber
                                                showLoaderForCech={this.state.showLoaderForStationIDCech}
                                                onChange={this.handleCheckStationId}
                                                name={'stationID'} value={this.state.stationID}
                                                type='text' style={styles.inputStyle} label={t('  Station ID')}
                                                CechAnswer={this.state.CechStationIDAnswer}
                                                ErrText={t('Station ID exists')} Leng={16} wid={'228px'}
                                            />

                                            <CheckIdAndNumber
                                                showLoaderForCech={this.state.showLoaderForCech}
                                                onChange={this.handleChanges} name={'stationNumber'}
                                                value={this.state.stationNumber} type='number' style={styles.inputStyle}
                                                label={t('Station Number')} CechAnswer={this.state.CechAnswer}
                                                ErrText={t('Station exists')} wid={'228px'}
                                            />


                                            {Role === 'SUPER_ADMIN' || Role === 'ADMIN' ?
                                                <div style={{marginTop: '10px'}}>
                                                    <TextField
                                                        id="outlined-select-currency-native"
                                                        select
                                                        label={t("Owner")}
                                                        InputLabelProps={{
                                                            style: {
                                                                color: 'gray',
                                                                background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
                                                                padding: '0 10px 0 0 '
                                                            },
                                                        }}
                                                        inputProps={{
                                                            style: {
                                                                width: '210px',
                                                                color: 'gray',
                                                                fontFamily: 'Arial',
                                                                border: '1px solid white',
                                                                borderRadius: '5px',
                                                            }
                                                        }}
                                                        onChange={this.handleChanges}
                                                        SelectProps={{native: true,}}
                                                        variant="outlined"
                                                        name={'owner'}
                                                        value={this.state.owner}
                                                    >
                                                        {/*<option>{this.state.owner}</option>*/}
                                                        {
                                                            this.props.Admins.map((row, i) => (
                                                                <option key={i} value={row.email}>{row.email}</option>
                                                            ))
                                                        }
                                                    </TextField>
                                                </div>
                                                : null}

                                            <div style={{marginTop: '10px'}}>
                                                <TextField
                                                    id="outlined-select-currency-native"
                                                    select
                                                    label={t("Tariff")}
                                                    InputLabelProps={{
                                                        style: {
                                                            color: 'gray',
                                                            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
                                                            padding: '0 10px 0 0 '
                                                        },
                                                    }}
                                                    inputProps={{
                                                        style: {
                                                            width: '210px',
                                                            color: 'gray',
                                                            fontFamily: 'Arial',
                                                            border: '1px solid white',
                                                            borderRadius: '5px',
                                                        }
                                                    }}
                                                    onChange={this.ChackboxHandlechange}
                                                    SelectProps={{native: true,}}
                                                    variant="outlined"
                                                >
                                                    <option value={tariffPlan.name}>{tariffPlan.name}</option>
                                                    {/*<option>{groupTariffs.groupName}</option>*/}
                                                    {this.state.TariffGroupList.map((row, i) => (
                                                        <option key={i} value={row}>{row}</option>
                                                    ))}
                                                </TextField>

                                            </div>
                                            <hr style={{
                                                color: 'rgba(255, 255, 255, 0.2)',
                                                width: '255px',
                                                marginRight: '40px',
                                                marginTop: '20px'
                                            }}/>
                                            <span style={styles.TerminlHoursStyle}>{t('Terminal working hours')}</span>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <Checkbox onChange={this.AllDays} style={{color: 'white'}}/>
                                                <span>{t("Always open")}</span>
                                            </div>


                                            {this.state.DisableAutocomplete === true ?
                                                <>
                                                    <div>
                                                        <TextField
                                                            id="outlined-select-currency-native"
                                                            select
                                                            label={t("Day")}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: 'gray',
                                                                    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
                                                                    padding: '0 10px 0 0 '
                                                                },
                                                            }}
                                                            inputProps={{
                                                                style: {
                                                                    width: '210px',
                                                                    color: 'gray',
                                                                    fontFamily: 'Arial',
                                                                    border: '1px solid white',
                                                                    borderRadius: '5px',
                                                                }
                                                            }}
                                                            onChange={this.hadnleWeekdays}
                                                            SelectProps={{native: true,}}
                                                            variant="outlined"
                                                        >
                                                            {this.state.weekdays.map((row, i) => (
                                                                <option key={i} value={row}>{t(row)}</option>
                                                            ))}
                                                        </TextField>
                                                    </div>

                                                    <div style={{marginTop: '10px'}}>
                                                        <TextField
                                                            id="outlined-select-currency-native"
                                                            select
                                                            label={t("Work hours (From)")}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: 'gray',
                                                                    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
                                                                    padding: '0 10px 0 0 '
                                                                },
                                                            }}
                                                            inputProps={{
                                                                style: {
                                                                    width: '210px',
                                                                    color: 'gray',
                                                                    fontFamily: 'Arial',
                                                                    border: '1px solid white',
                                                                    borderRadius: '5px',
                                                                }
                                                            }}
                                                            onChange={(ev) => {
                                                                this.handleChange7(ev, 'From')
                                                            }}
                                                            SelectProps={{native: true,}}
                                                            value={this.state.FROM ? this.state.FROM : Start ? Start.length > 0 ?
                                                                Start[0] === 'AllDay' ? 'AllDay' : Start[0] === 'NotWork' ? 'NotWork' : Start[0] : '' : ''}
                                                            variant="outlined"
                                                        >
                                                            {this.state.avil === true ?
                                                                <>
                                                                    <option>{this.state.timeSlots[0]}</option>
                                                                    <option value={'AllDay'}>{t('AllDay')}</option>
                                                                    <option value={'NotWork'}>{t('Not Work')}</option>
                                                                    {this.state.timeSlots.map((row, i) => (
                                                                        <option key={i} value={row}>{row}</option>
                                                                    ))}
                                                                </>
                                                                :
                                                                <>
                                                                    <option>{}</option>
                                                                    <option value={'AllDay'}>{t('AllDay')}</option>
                                                                    <option value={'NotWork'}>{t('Not Work')}</option>
                                                                    {this.state.timeSlots.map((row, i) => (
                                                                        <option key={i} value={row}>{row}</option>
                                                                    ))}
                                                                </>
                                                            }
                                                        </TextField>
                                                    </div>
                                                    {this.state.FROM !== 'AllDay' ?
                                                        this.state.FROM !== 'NotWork' &&
                                                        Start !== undefined && Start !== 'ANYTIME' && Start[0] !== 'AllDay' && Start[0] !== 'NotWork' &&
                                                        <div style={{marginTop: '10px'}}>
                                                            <TextField
                                                                id="outlined-select-currency-native"
                                                                select
                                                                label={t("Work hours (To)")}
                                                                InputLabelProps={{
                                                                    style: {
                                                                        color: 'gray',
                                                                        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
                                                                        padding: '0 10px 0 0 '
                                                                    },
                                                                }}
                                                                inputProps={{
                                                                    style: {
                                                                        width: '210px',
                                                                        color: 'gray',
                                                                        fontFamily: 'Arial',
                                                                        border: '1px solid white',
                                                                        borderRadius: '5px',
                                                                    }
                                                                }}
                                                                onChange={(ev) => {
                                                                    this.handleChange7(ev, 'To')
                                                                }}
                                                                SelectProps={{native: true,}}
                                                                value={this.state.TO ? this.state.TO : Start ? Start.length > 0 ? Start[1] : '' : ''}
                                                                variant="outlined"
                                                            >
                                                                {this.state.avil === true ?
                                                                    <>
                                                                        <option>{this.state.timeSlots[0]}</option>
                                                                        {this.state.timeSlots.map((row, i) => (
                                                                            <option key={i} value={row}>{row}</option>
                                                                        ))}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <option>{}</option>
                                                                        {timeSlots.map((row, i) => (
                                                                            <option key={i} value={row}>{row}</option>
                                                                        ))}
                                                                    </>
                                                                }
                                                            </TextField>
                                                        </div>
                                                        : ''}
                                                </>
                                                : ''}
                                            {this.state.invalidData === true ?
                                                <span style={{
                                                    marginTop: '20px',
                                                    color: '#EB5B47',
                                                    fontSize: '16px',
                                                    lineHeight: '24px',
                                                    letterSpacing: '0.5px',
                                                }}>{t('Working hours are incorrect')}</span>
                                                : ''}


                                        </div>
                                        <div style={{marginTop: '20px', marginBottom: '20px'}}>
                                            <Button onClick={this.closeEditSection}
                                                    style={styles.ChancelButtonStyle}>{t("CANCEL")}</Button>
                                            <Button onClick={this.EditStationS}
                                                    style={styles.SaveButtonStyle}>{t("SAVE")}</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            StationsArray.length ?
                                this.state.ItionalImg === true ?

                                    <AdditionalImagesComponent
                                        NoImages={t('No Images')}
                                        imgList={this.props.StationAllImg}
                                        onClick={() => this.setState({ItionalImg: false})}
                                    />
                                    :


                                    <>
                                        {showModal &&
                                            <DeleteModal
                                                boolean={showModal}
                                                text={`${t("Are you sure you want delete Station")} - ${this.props.StationAllINfo.stationNumber}`}
                                                Cancel={'Cancel'} Delete={'Delete'}
                                                handleCancel={() => {
                                                    this.setState({showModal: false})
                                                }}
                                                handleDelete={() => {
                                                    this.DeleteStation()
                                                }}
                                            />}
                                        <div style={styles.headDiv}>
                                            <div onClick={() => this.CloseSection('InfoSection')}
                                                 className={'CloseSectionClass'}/>
                                            <div style={styles.hideDiv}>
                                                <>
                                                    <Button onClick={() => this.CloseSection('InfoSection')}
                                                            style={styles.hideDivCloseButton}>
                                                        <img src={CloseVector} alt={CloseVector}/>
                                                    </Button>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        marginLeft: '24px',
                                                        marginRight: '24px',
                                                        marginTop: '40px',
                                                        marginBottom: '20px'
                                                    }}>

                                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                            <span style={styles.showList}>{t('ID')}</span>
                                                            <button
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    background: 'none',
                                                                    border: 'none',
                                                                    outline: 'none',
                                                                    marginRight: '-6px'
                                                                }}
                                                                onClick={this.ChangeOpen}>
                                                                <img src={change} alt={change}/></button>
                                                        </div>
                                                        <span
                                                            style={styles.showListId}>{this.props.StationAllINfo.id.slice(0, 20)}</span>
                                                        <img src={StationInfo ? StationInfo.imageUrl : addphoto}
                                                             alt={'img'} style={{
                                                            maxHeight: '160px',
                                                            borderRadius: '8px',
                                                            width: '255px',
                                                            marginTop: '20px'
                                                        }}/>

                                                        <button className={'AdditionalPicturesButton'}
                                                                onClick={() => this.setState({ItionalImg: true})}>
                                                            {t('Additional pictures')}
                                                        </button>

                                                        <span style={styles.showList}>{t('Address')}</span>
                                                        <span
                                                            style={styles.showListInfo}>{this.props.StationAllINfo.address}</span>

                                                        <span
                                                            style={styles.showList}>{t("Additional information at")}</span>
                                                        <span
                                                            style={styles.showListInfo}>{t(this.props.StationAllINfo.additionalAddress ? this.props.StationAllINfo.additionalAddress : 'null')}</span>


                                                        <span style={styles.showList}>{t('Station Number')} </span>
                                                        <div style={{display: 'flex'}}>
                                                            <span
                                                                style={styles.showListInfo}>{this.props.StationAllINfo.stationNumber} </span>
                                                            <a href={`https://chart.apis.google.com/chart?cht=qr&chs=200x200&chl=https%3A//chargex.am/${StationsArray[1]}&chld=H|0`}
                                                               target={'_blank'} style={{
                                                                textDecoration: 'none',
                                                                color: '#5F94E2',
                                                                marginLeft: '10px'
                                                            }}>Show QR-code</a></div>
                                                        <span style={styles.showList}>{t("Charges")}</span>
                                                        <span
                                                            style={styles.showListInfo}>{this.props.StationAllINfo.numberSlots}</span>
                                                        <button
                                                            style={styles.PowerBanksButton}
                                                            onClick={() => this.handleChangePBInfo(StationInfo)}
                                                        >{t('Powerbanks')}</button>
                                                        {this.state.PBInfo &&
                                                            <StationPowerBanksInfo
                                                                info={this.state.PBInfo}
                                                                close={() => this.setState({PBInfo: ''})}
                                                            />

                                                        }
                                                        <span style={styles.showList}>{t("Free Ports")}</span>
                                                        <span style={styles.showListInfo}>{freePortsLength}</span>
                                                        <span style={styles.showList}>{t('Tariff Group')}</span>
                                                        <span
                                                            style={styles.showListInfo}>{this.props.StationAllINfo.tariffPlan ? this.props.StationAllINfo.tariffPlan.name : ''}</span>
                                                        <span style={styles.showList}>{t("Owner")}</span>
                                                        <span
                                                            style={styles.showListInfo}>{this.props.StationAllINfo.owner ? this.props.StationAllINfo.owner : 'null'}</span>


                                                        <span style={styles.showList}>{t('Status')}</span>
                                                        {this.props.StationAllINfo.availability === true ?
                                                            <span
                                                                style={{color: 'rgba(101, 227, 42, 0.87)'}}>{t('OPEN')}</span>
                                                            :
                                                            <span style={styles.showListInfo}>{t('CLOSED')}</span>
                                                        }
                                                        <span style={styles.showList}>{t('Connection')}</span>
                                                        {this.props.StationAllINfo.status === 'CONNECTED' ?
                                                            <div>
                                                                <span
                                                                    style={{color: 'rgba(101, 227, 42, 0.87)'}}>{t('Connected')}</span>
                                                            </div>
                                                            :
                                                            <span style={{color: '#EB5B47'}}>{t('Disconnected')}</span>
                                                        }
                                                        <span style={styles.showList}>{t('Signal Level')}</span>
                                                        {this.props.StationAllINfo.status === 'CONNECTED' ?
                                                            <div style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                marginTop: '5px'
                                                            }}>
                    <span>
                      {this.props.StationAllINfo.signalLevel ?
                          <SignalLevel signal={this.props.StationAllINfo.signalLevel}/>
                          : t('null')}
                      </span>
                                                                <span style={{
                                                                    color: 'rgb(68,183,255)',
                                                                    marginLeft: '15px',
                                                                    fontSize: '20px'
                                                                }}>
                          {this.props.StationAllINfo.connectionMode ?
                              this.props.StationAllINfo.connectionMode
                              : t('null')}
                        </span>
                                                            </div>
                                                            :
                                                            <img src={DIS} alt={DIS}
                                                                 style={{width: '40px', marginTop: '5px'}}/>
                                                        }

                                                        <span style={styles.showList}>{t("Sim Card Id")}</span>
                                                        <span style={styles.showListInfo}>{simCardId}</span>
                                                        <span style={styles.showList}>{t("Sim Card Provider")}</span>
                                                        <span style={styles.showListInfo}>{simCardProvider}</span>


                                                        <hr style={{width: '100%'}}/>

                                                        {Role === 'OPERATOR' ?
                                                            '' :
                                                            <div>
                                                                <FormControl style={{
                                                                    width: '40px',
                                                                    borderRadius: '5px',
                                                                    border: '1px solid white',
                                                                    height: '40px',
                                                                    background: 'white'
                                                                }}>
                                                                    <NativeSelect
                                                                        id="demo-customized-select-native"
                                                                        onChange={this.bankNumber}
                                                                    >
                                                                        <option value={''}>{}</option>
                                                                        {NewArrayForSlot.map((row, i) => (
                                                                            <option value={row}>{row}</option>
                                                                        ))}
                                                                    </NativeSelect>
                                                                </FormControl>
                                                                <Button
                                                                    style={{
                                                                        height: '40px',
                                                                        marginLeft: '10px',
                                                                        borderRadius: '5px',
                                                                        background: '#5F94E2',
                                                                        border: 'none',
                                                                        color: 'white'
                                                                    }}
                                                                    onClick={this.PutOutBank}
                                                                >
                                                                    {t("Put out PowerBank")}
                                                                </Button>
                                                            </div>
                                                        }
                                                        {this.state.days.length === 1 ?
                                                            <div style={{display: 'flex'}}>
                                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                                    <span
                                                                        style={styles.showList}>{t("Work Days")}</span>
                                                                    <span
                                                                        style={styles.showListInfoD}>{t("All Day")}</span>
                                                                </div>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    marginLeft: '60px'
                                                                }}>
                                                                    <span
                                                                        style={styles.showList}>{t("Work hours")}</span>
                                                                    <span
                                                                        style={styles.showListInfoD}>{t("All Day")}</span>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div style={{display: 'flex'}}>
                                                                {}

                                                                <div style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    marginTop: '10px'
                                                                }}>
                                                                    <span
                                                                        style={styles.showList}>{t("Work days and hours")}</span>

                                                                    <span
                                                                        style={styles.showListInfoD}>{`${t(this.state.days.slice(0, 1).join('.'))} - 
                                             ${this.props.StationAllINfo.workTime.monday[0] === 'AllDay' || this.props.StationAllINfo.workTime.monday[0] === 'NotWork' ?
                                                                        t(this.props.StationAllINfo.workTime.monday[0]) :
                                                                        this.props.StationAllINfo.workTime.monday[0] + ' - ' + this.props.StationAllINfo.workTime.monday[1]
                                                                    }`}</span>

                                                                    <span
                                                                        style={styles.showListInfoD}>{`${t(this.state.days.slice(1, 2).join('.'))} - 
                                             ${this.props.StationAllINfo.workTime.tuesday[0] === 'AllDay' || this.props.StationAllINfo.workTime.tuesday[0] === 'NotWork' ?
                                                                        t(this.props.StationAllINfo.workTime.tuesday[0]) :
                                                                        this.props.StationAllINfo.workTime.tuesday[0] + ' - ' + this.props.StationAllINfo.workTime.tuesday[1]
                                                                    }`}</span>

                                                                    <span
                                                                        style={styles.showListInfoD}>{`${t(this.state.days.slice(2, 3).join('.'))} - 
                                             ${this.props.StationAllINfo.workTime.wednesday[0] === 'AllDay' || this.props.StationAllINfo.workTime.wednesday[0] === 'NotWork' ?
                                                                        t(this.props.StationAllINfo.workTime.wednesday[0]) :
                                                                        this.props.StationAllINfo.workTime.wednesday[0] + ' - ' + this.props.StationAllINfo.workTime.wednesday[1]
                                                                    }`}</span>

                                                                    <span
                                                                        style={styles.showListInfoD}>{`${t(this.state.days.slice(3, 4).join('.'))} - 
                                             ${this.props.StationAllINfo.workTime.thursday[0] === 'AllDay' || this.props.StationAllINfo.workTime.thursday[0] === 'NotWork' ?
                                                                        t(this.props.StationAllINfo.workTime.thursday[0]) :
                                                                        this.props.StationAllINfo.workTime.thursday[0] + ' - ' + this.props.StationAllINfo.workTime.thursday[1]
                                                                    }`}</span>
                                                                    <span
                                                                        style={styles.showListInfoD}>{`${t(this.state.days.slice(4, 5).join('.'))} - 
                                             ${this.props.StationAllINfo.workTime.friday[0] === 'AllDay' || this.props.StationAllINfo.workTime.friday[0] === 'NotWork' ?
                                                                        t(this.props.StationAllINfo.workTime.friday[0]) :
                                                                        this.props.StationAllINfo.workTime.friday[0] + ' - ' + this.props.StationAllINfo.workTime.friday[1]
                                                                    }`}</span>
                                                                    <span
                                                                        style={styles.showListInfoD}>{`${t(this.state.days.slice(5, 6).join('.'))} - 
                                             ${this.props.StationAllINfo.workTime.saturday[0] === 'AllDay' || this.props.StationAllINfo.workTime.saturday[0] === 'NotWork' ?
                                                                        t(this.props.StationAllINfo.workTime.saturday[0]) :
                                                                        this.props.StationAllINfo.workTime.saturday[0] + ' - ' + this.props.StationAllINfo.workTime.saturday[1]
                                                                    }`}</span>
                                                                    <span
                                                                        style={styles.showListInfoD}>{`${t(this.state.days.slice(6, 7).join('.'))} -            
                                             ${this.props.StationAllINfo.workTime.sunday[0] === 'AllDay' || this.props.StationAllINfo.workTime.sunday[0] === 'NotWork' ?
                                                                        t(this.props.StationAllINfo.workTime.sunday[0])
                                                                        :
                                                                        this.props.StationAllINfo.workTime.sunday[0] + ' - ' + this.props.StationAllINfo.workTime.sunday[1]
                                                                    }`}</span>
                                                                </div>
                                                            </div>
                                                        }

                                                        {this.state.reloadIcon === false ?
                                                            <Button
                                                                disabled={this.props.StationAllINfo.status !== "CONNECTED"}
                                                                onClick={this.handleReload}
                                                                style={{
                                                                    background: this.props.StationAllINfo.status === "CONNECTED" ? '#5F94E2' : '#c3d5f1',
                                                                    width: '260px',
                                                                    color: 'white',
                                                                    marginTop: '30px',
                                                                    marginBottom: '5px'
                                                                }}>
                                                                {t("Reload Station")}</Button>

                                                            :
                                                            <div style={{marginLeft: '120px', marginTop: '30px',}}>
                                                                <CircularProgress size={30} disableShrink/>
                                                            </div>
                                                        }
                                                        <Button style={{width: '260px', marginTop: '10px',}}
                                                                className='DeletePopupStyle'
                                                                onClick={() => this.setState({showModal: true})}>{t("Delete Station")}</Button>

                                                        {Role === 'SUPER_ADMIN' && (this.props.StationAllINfo.locked === true ?
                                                            <button onClick={() => {
                                                                this.blockStation(this.props.StationAllINfo.stationNumber, true)
                                                            }}
                                                                    style={styles.ActiveUserButton}>{t("Active Station")}</button>
                                                            :
                                                            <button onClick={() => {
                                                                this.blockStation(this.props.StationAllINfo.stationNumber, false)
                                                            }}
                                                                    style={styles.blockUserButton}>{t("Block Station")}</button>
                                                        )}
                                                        {Role === 'SUPER_ADMIN' &&
                                                            <Comments
                                                                handleClose={() => this.handleCloseComment()}
                                                                text={this.props.StationAllINfo.comment}
                                                                id={this.props.StationAllINfo.id}
                                                                name={'FOR_STATION'}
                                                            />
                                                        }
                                                    </div>
                                                </>
                                            </div>
                                        </div>
                                    </>
                                : ''

                        }
                        {this.props.CreateStation === 'open' ?
                            <div style={styles.headDiv}>
                                <div onClick={() => this.CloseSection('NewStation')} className={'CloseSectionClass'}/>
                                <div onClick={() => this.setState({legalAddressInfo: '', searchResult: [],})}
                                     style={styles.hideBigDiv}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: '10px',
                                        marginLeft: '-25px'
                                    }}>
                                        <Button onClick={() => this.CloseSection('NewStation')}>
                                            <img src={CloseVector} alt={CloseVector}/></Button>
                                        <span>{t("New Station")}</span>


                                    </div>


                                    <div className={'MapStyle'} style={{
                                        height: '400px',
                                        overFlow: 'hidden',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginTop: '10px'
                                    }}>
                                        <span style={{
                                            marginTop: '10px',
                                            marginBottom: '10px'
                                        }}>{t('Select terminal location on the map')}</span>

                                        <YandexMapForm
                                            searchResult={searchResult}
                                            searchShown={searchShown}
                                            errorMessage={errorMessage}
                                            searchValue={searchValue}
                                            ymapsSearchHandler={this.ymapsSearchHandler}
                                            searchToggle={this.searchToggle}
                                            inputMB={this.inputMB} ymapsSelect={this.ymapsSelect}
                                            emptySearchResult={this.emptySearchResult}
                                            searchHandler={this.searchHandler}
                                        />

                                        {/*<div style={{marginTop:'10px',marginLeft:'20px',marginRight:'20px',height:'100%'}}>*/}
                                        <YMaps query={{ns: "use-load-option", lang: lan, apikey: yandexMapKey,}}>
                                            <Map
                                                onClick={this.onMapClick}
                                                instanceRef={ref => {
                                                    if (ref) this.map = ref._globalPixelCenter
                                                }}
                                                onLoad={this.createTemplateLayoutFactory}
                                                state={mapData}
                                                modules={['templateLayoutFactory', 'geolocation', "geocode"]}
                                                style={{flex: 2, flexGrow: 1}}
                                                behavior={'scrollZoom'}
                                            >
                                                {this.state.coords ?
                                                    <Placemark geometry={this.state.coords}/>
                                                    :
                                                    this.state.location ?
                                                        <Placemark
                                                            geometry={this.state.location && [this.state.location[0], this.state.location[1]]}/>
                                                        :
                                                        null
                                                }

                                                {/*{this.state.location &&*/}
                                                {/*<Placemark*/}
                                                {/*  geometry={this.state.location && [this.state.location[0], this.state.location[1]]}*/}
                                                {/*  options={{*/}
                                                {/*    iconLayout: 'default#image',*/}
                                                {/*    iconImageHref: ymapsPlaceMark,*/}
                                                {/*    iconOffset: [-20, -20],*/}
                                                {/*    iconImageSize: [34, 34],*/}
                                                {/*    iconImageOffset: [0, 0],*/}
                                                {/*  }}*/}
                                                {/*/>*/}
                                                {/*}*/}
                                                <FullscreenControl
                                                    options={{maxWidth: '100px'}}
                                                />
                                                <ZoomControl
                                                    onClick={this.getPixels}
                                                    options={{position: {top: "25vh", right: 20}, size: 'small'}}
                                                />

                                                <GeolocationControl
                                                    onClick={this.GeolocationHandler}
                                                    options={{position: {top: "25vh", right: 8}, noPlacemark: true}}
                                                />
                                            </Map>
                                        </YMaps>

                                        {/*</div>*/}

                                    </div>


                                    {/*<div style={{marginLeft: '20px',}}>*/}

                                    <div style={{
                                        display: 'flex',
                                        // flexDirection: 'column',

                                        marginTop: '110px', alignItems: 'center'
                                    }}>

                                        <div className="App">

                                            <div className={'AddPhoto'}>
                                                <p style={{color: 'red'}}>{t('Upload at least 3 photos for Yandex map')}</p>
                                                <input type="file" accept="image/*" onChange={this.onSelectFile}/>
                                            </div>


                                            {src.length ? src.map((i, item) => (
                                                <div>

                                                    <DeleteIconButton key={item}
                                                                      onClick={() => this.ClearCropImg(item)}/>

                                                    <ReactCrop
                                                        src={i}
                                                        // crop={crop}
                                                        ruleOfThirds
                                                        // onImageLoaded={this.onImageLoaded}
                                                        // onComplete={this.onCropComplete}
                                                        // onChange={this.onCropChange}
                                                    />

                                                </div>
                                            )) : null}


                                            {/*{croppedImageUrl && (*/}
                                            {/*  <div style={{height:'250px',maxWidth: '95%',display:'flex',marginTop:'10px' }} >*/}
                                            {/*    <Button  style={{height:'30px'}} onClick={this.ClearCropImg}>*/}
                                            {/*      <DeleteForeverIcon style={{color:'white'}} />*/}
                                            {/*      /!*<img src={CloseVector} alt={CloseVector}/>*!/*/}
                                            {/*    </Button>*/}
                                            {/*    <img alt="Crop" style={{padding:'5px', border:'2px dotted white',height:'215px',maxWidth: '440px',margin:'0 auto'}} src={croppedImageUrl} />*/}
                                            {/*  </div>*/}
                                            {/*)}*/}
                                        </div>
                                    </div>
                                    <div style={{marginTop: '20px',}}>


                                        <TextField
                                            id="outlined-select-currency-native"
                                            value={this.state.additionalAddress}
                                            type={'tel'}
                                            label={t('Additional information at')}
                                            name={'additionalAddress'}
                                            InputLabelProps={{
                                                style: {
                                                    border: 'none',
                                                    fontSize: '14px',
                                                    lineHeight: '16px',
                                                    letterSpacing: '0.4px',
                                                    color: 'rgba(255, 255, 255, 0.6)',
                                                    padding: '0 10px 0 0 '
                                                },
                                            }}
                                            inputProps={{
                                                autoComplete: "new-password",
                                                style: {
                                                    color: 'gray',
                                                    outline: 'none',
                                                    width: '410px',
                                                    fontFamily: 'Arial',
                                                    border: '1px solid white',
                                                    borderRadius: '5px',
                                                }
                                            }}
                                            onChange={this.handleEditionalAddress}
                                            SelectProps={{native: true,}}
                                            variant="outlined"
                                        />

                                        <SelectInput
                                            InputWidth={'392px'}
                                            onChange={this.handleChanges}
                                        />


                                        <CheckIdAndNumber
                                            showLoaderForCech={this.state.showLoaderForStationIDCech}
                                            onChange={this.handleCheckStationId}
                                            name={'stationID'}
                                            value={this.state.stationID}
                                            type='text' style={styles.inputStyle}
                                            label={t('  Station ID')}
                                            CechAnswer={this.state.CechStationIDAnswer}
                                            ErrText={t('Station ID exists')}
                                            Leng={16}
                                            wid={'410px'}
                                        />


                                        <CheckIdAndNumber
                                            wid={'410px'}
                                            showLoaderForCech={this.state.showLoaderForCech}
                                            onChange={this.handleChanges}
                                            name={'stationNumber'}
                                            value={this.state.stationNumber}
                                            type='number' style={styles.inputStyle}
                                            label={t('Station Number')}
                                            CechAnswer={this.state.CechAnswer}
                                            ErrText={t('Station exists')}
                                        />


                                        <div style={{marginTop: '10px'}}>
                                            <TextField
                                                id="outlined-select-currency-native"
                                                select label={t("Tariff")}
                                                InputLabelProps={{
                                                    style: {
                                                        border: 'none',
                                                        fontSize: '14px',
                                                        lineHeight: '16px',
                                                        letterSpacing: '0.4px',
                                                        color: 'rgba(255, 255, 255, 0.6)',
                                                        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
                                                        padding: '0 10px 0 0 '
                                                    },
                                                }}
                                                inputProps={{
                                                    style: {
                                                        color: 'gray',
                                                        outline: 'none',
                                                        width: '392px',
                                                        fontFamily: 'Arial',
                                                        border: '1px solid white',
                                                        borderRadius: '5px',
                                                    }
                                                }}
                                                onChange={this.ChackboxHandlechange}
                                                SelectProps={{native: true,}}
                                                variant="outlined"
                                            >
                                                <option>{''}</option>
                                                {this.state.TariffGroupList.map((row, i) => (
                                                    <option key={i} value={row}>{row}</option>
                                                ))
                                                }
                                            </TextField>
                                            {Role === 'SUPER_ADMIN' || Role === 'ADMIN' ?
                                                <div style={{marginTop: '10px'}}>
                                                    <TextField
                                                        id="outlined-select-currency-native"
                                                        select
                                                        label={t("Owner")}
                                                        InputLabelProps={{
                                                            style: {
                                                                color: 'gray',
                                                                background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
                                                                padding: '0 10px 0 0 '
                                                            },
                                                        }}
                                                        inputProps={{
                                                            style: {
                                                                width: '392px',
                                                                color: 'gray',
                                                                fontFamily: 'Arial',
                                                                border: '1px solid white',
                                                                borderRadius: '5px',
                                                            }
                                                        }}
                                                        onChange={this.handleChanges}
                                                        SelectProps={{native: true,}}
                                                        name={'owner'}
                                                        variant="outlined"
                                                    >
                                                        {/*<option>{this.state.owner ? this.state.owner : localStorage.getItem('Email')}</option>*/}
                                                        {this.props.Admins.map((row, i) => (
                                                            <option key={i} value={row.email}>{row.email}</option>
                                                        ))
                                                        }
                                                    </TextField>

                                                </div>


                                                : null}
                                        </div>

                                        {/*TIME FOR STATION START*/}


                                        <hr style={{
                                            color: 'rgba(255, 255, 255, 0.2)',
                                            width: '400px',
                                            marginRight: '40px',
                                            marginTop: '20px'
                                        }}/>
                                        <span style={styles.TerminlHoursStyle}>{t('Terminal working hours')}</span>
                                        <div style={{display: 'flex', alignItems: 'center'}}>

                                            <Checkbox onChange={this.AllDays} style={{color: 'white'}}/>
                                            <span>{t('Always open')}</span>
                                        </div>

                                        {/*Work Hours Start*/}
                                        {this.state.DisableAutocomplete === true ?
                                            <>
                                                <TextField
                                                    id="outlined-select-currency-native"
                                                    select
                                                    label={t("Day")}
                                                    InputLabelProps={{
                                                        style: {
                                                            color: 'gray',
                                                            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
                                                            padding: '0 10px 0 0 '
                                                        },
                                                    }}
                                                    inputProps={{
                                                        style: {
                                                            width: '392px',
                                                            color: 'gray',
                                                            fontFamily: 'Arial',
                                                            border: '1px solid white',
                                                            borderRadius: '5px',
                                                        }
                                                    }}
                                                    onChange={this.hadnleWeekdays}
                                                    SelectProps={{native: true,}}
                                                    variant="outlined"
                                                >
                                                    {this.state.weekdays.map((row, i) => (
                                                        <option key={i} value={row}>{t(row)}</option>
                                                    ))}
                                                </TextField>
                                                <div style={{marginTop: '10px'}}>
                                                    <TextField
                                                        id="outlined-select-currency-native"
                                                        select
                                                        label={t("Work hours (From)")}
                                                        InputLabelProps={{
                                                            style: {
                                                                color: 'gray',
                                                                background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
                                                                padding: '0 10px 0 0 '
                                                            },
                                                        }}
                                                        inputProps={{
                                                            style: {
                                                                width: '392px',
                                                                color: 'gray',
                                                                fontFamily: 'Arial',
                                                                border: '1px solid white',
                                                                borderRadius: '5px',
                                                            }
                                                        }}
                                                        onChange={(ev) => {
                                                            this.handleChange7(ev, 'From')
                                                        }}
                                                        SelectProps={{native: true,}}
                                                        value={this.state.FROM ? this.state.FROM : Start ? Start.length > 0 ? Start[0] === 'AllDay' ? 'AllDay' : Start[0] : '' : ''}
                                                        variant="outlined"
                                                    >
                                                        {this.state.avil === true ?
                                                            <>
                                                                <option>{this.state.timeSlots[0]}</option>
                                                                <option value={'AllDay'}>{t('AllDay')}</option>
                                                                <option value={'NotWork'}>{t('Not Work')}</option>
                                                                {this.state.timeSlots.map((row, i) => (
                                                                    <option key={i} value={row}>{row}</option>
                                                                ))}
                                                            </>
                                                            :
                                                            <>
                                                                <option>{}</option>
                                                                <option value={'AllDay'}>{t('AllDay')}</option>
                                                                <option value={'NotWork'}>{t('Not Work')}</option>
                                                                {this.state.timeSlots.map((row, i) => (
                                                                    <option key={i} value={row}>{row}</option>
                                                                ))}
                                                            </>
                                                        }
                                                    </TextField>
                                                </div>


                                                {this.state.FROM !== 'AllDay' ?
                                                    this.state.FROM !== 'NotWork' &&
                                                    <div style={{marginTop: '10px'}}>
                                                        {Start[0] !== 'AllDay' ?
                                                            <TextField
                                                                id="outlined-select-currency-native"
                                                                select
                                                                label={t("Work hours (To)")}
                                                                InputLabelProps={{
                                                                    style: {
                                                                        color: 'gray',
                                                                        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
                                                                        padding: '0 10px 0 0 '
                                                                    },
                                                                }}
                                                                inputProps={{
                                                                    style: {
                                                                        width: '392px',
                                                                        color: 'gray',
                                                                        fontFamily: 'Arial',
                                                                        border: '1px solid white',
                                                                        borderRadius: '5px',
                                                                    }
                                                                }}
                                                                onChange={(ev) => {
                                                                    this.handleChange7(ev, 'To')
                                                                }}
                                                                SelectProps={{native: true,}}
                                                                variant="outlined"
                                                                value={this.state.TO ? this.state.TO : Start ? Start.length > 0 ? Start[1] : '' : ''}
                                                            >
                                                                {this.state.avil === true ?
                                                                    <>
                                                                        <option>{this.state.timeSlots[0]}</option>
                                                                        <option value={'AllDay'}>{t('AllDay')}</option>
                                                                        <option
                                                                            value={'NotWork'}>{t('Not Work')}</option>

                                                                        {this.state.timeSlots.map((row, i) => (
                                                                            <option key={i} value={row}>{row}</option>
                                                                        ))}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <option>{}</option>
                                                                        <option value={'AllDay'}>{t('AllDay')}</option>
                                                                        <option
                                                                            value={'NotWork'}>{t('Not Work')}</option>

                                                                        {this.state.timeSlots.map((row, i) => (
                                                                            <option key={i} value={row}>{row}</option>
                                                                        ))}
                                                                    </>
                                                                }
                                                            </TextField>
                                                            : ''}
                                                    </div>
                                                    : ''
                                                }
                                            </>
                                            : ''}


                                    </div>
                                    {this.state.invalidData === true ?
                                        <span style={{
                                            marginTop: '20px',
                                            color: '#EB5B47',
                                            fontSize: '16px',
                                            lineHeight: '24px',
                                            letterSpacing: '0.5px',
                                        }}>{t('Working hours are incorrect')}</span>
                                        : ''}


                                    {/*TIME FOR STATION END*/}


                                    <ErrMessageText text={this.state.showException}/>

                                    {/*{this.state.showExeption === true ? <ErrMessage/>  :''}*/}
                                    <div style={{padding: '20px 0px 20px 0'}}>
                                        <Button onClick={this.CloseSection}
                                                style={styles.ChancelButton}>{t("CHANCEL")}</Button>
                                        <Button style={styles.CreateButton}
                                                onClick={this.AddStationS}>{t("CREATE STATION")}</Button>
                                    </div>
                                    {/*</div>*/}
                                </div>
                            </div>
                            : ''}
                    </>}
            </Translation>
        );
    }
}

const mapStateToProps = state => ({
    StationAllINfo: state.stationInfoReducer.StationAllINfo,
    page: state.stationInfoReducer.page,
    EditStation: state.stationInfoReducer.EditStation,
    CreateStation: state.stationInfoReducer.CreateStation,
    StationInfo: state.GetStationImgReducer.StationInfo,
    StationAllImg: state.GetStationImgReducer.StationAllImg,
    AllGroup: state.tariffsReducer.AllGroup,
    Merchants: state.MerchantesReducer.Merchants,
    Admins: state.adminReducer.Admins,
    CechStationAnswer: state.stationsReducer.CechStationAnswer,
    failRequest: state.TakeBankReducer.failRequest,
})

export default connect(mapStateToProps, {
    FindStationByNumber,
    GetStation,
    ReloadStationAction,
    GetMerchants,
    GetMerchantsAll,
    GetAdmins,
    ClearFindingStationData,
    GetStationImg,
    takePowerBanke,
    EditStationInfo,
    LockUnlockStation,
    sendStationNumber,
    AddPhoto,
    AddStation,
    clearStationInfo,
    clearNewStation,
    DeleteStations,
    LockStations,
    ClearEditInfo,
    GetAllGroupTariffs,
    closePopupExtractBank
})(StationInfoList);