export const GET_TARIFF={
  GET_TARIFFS_REQUEST:'GET_TARIFF.GET_TARIFFS_REQUEST',
  TARIFFS_LIST:'GET_TARIFF.TARIFFS_LIST',
  CLOSE_TARIFFS_LIST:'GET_TARIFF.CLOSE_TARIFFS_LIST',
  GET_TARIFFS_SUCCESS:'GET_TARIFF.GET_TARIFFS_SUCCESS',
  GET_TARIFFS_FAIL:'GET_TARIFF.GET_TARIFFS_FAIL',
  GET_ALL_GROUP_REQUEST:'GET_TARIFF.GET_ALL_GROUP_REQUEST',
  GET_ALL_GROUP_SUCCESS:'GET_TARIFF.GET_ALL_GROUP_SUCCESS',
  DELETE_TARIFF_REQUEST:'GET_TARIFF.DELETE_TARIFF_REQUEST',
  DELETE_TARIFF_SUCCESS:'GET_TARIFF.DELETE_TARIFF_SUCCESS'
}


const initialState={
  AllTariffs:[],
  AllGroup:[],
  tariffList:[]
}

export default (state=initialState,action)=>{
  switch (action.type) {
    case GET_TARIFF.GET_TARIFFS_REQUEST:{
      return{...state,AllTariffs:initialState.AllTariffs}
    }
    case GET_TARIFF.GET_TARIFFS_SUCCESS:{
      return{...state,AllTariffs:action.payload.data}
    }
    case GET_TARIFF.TARIFFS_LIST:{
      return{...state, tariffList :action.payload.data}
    }
    case GET_TARIFF.CLOSE_TARIFFS_LIST:{
      return{...state, tariffList :[]}
    }


    case GET_TARIFF.GET_TARIFFS_FAIL:{
      return{...state,AllTariffs:initialState.AllTariffs}
    }


    case GET_TARIFF.GET_ALL_GROUP_REQUEST:{
      return{...state,AllGroup:initialState.AllGroup}
    }
    case GET_TARIFF.GET_ALL_GROUP_SUCCESS:{
      return{...state,AllGroup:action.payload.data}
    }
    default:{
      return state
    }

  }
}
