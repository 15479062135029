import {MERCHANTS_ACTION} from "./MerchantesReducer";

export const GetMerchants =(all)=>{
  return{
     type:MERCHANTS_ACTION.GET_MERCHANTS_REQUEST,
    payload:{all:false}
  }
}

export const GetMerchantsAll =()=>{
  return{
    type:MERCHANTS_ACTION.GET_MERCHANTS_REQUEST_ALL,
    payload:{all:true}
  }
}

export const AddMerchantsPage =(data)=>{
  return{
    type:MERCHANTS_ACTION.OPEN_PAGE,
    payload:{data}
  }
}
export const GetRegMerchants =(data)=>{
  return{
    type:MERCHANTS_ACTION.GET_MERCHANTS_REG_REQUEST,
  }
}
export const GetRegMerchantsFullInfo =(email)=>{
  return{
    type:MERCHANTS_ACTION.GET_MERCHANTS_REG_FULLINFO_REQUEST,
    payload:{email}
  }
}
export const CloseMerchantFullInfo =()=>{
  return{
    type:MERCHANTS_ACTION.CLEAR_MERCHANTS_REG_FULLINFO_REQUEST,
  }
}
export const CloseAddMerchantPage =()=>{
  return{
    type:MERCHANTS_ACTION.CLOSE_PAGE
  }
}
export const showMerchantInfo =(data)=>{
  return{
    type:MERCHANTS_ACTION.OPEN_INFO,
    payload:{data}
  }
}
export const showIndividualMerchantInfo =(data)=>{
  return{
    type:MERCHANTS_ACTION.OPEN_INDIVIDUAL_INFO,
    payload:{data}
  }
}
export const CloseMerchantInfo =(data)=>{
  return{
    type:MERCHANTS_ACTION.CLOSE_INFO,
  }
}

export const CloseIndividualInfo =()=>{
  return{
    type:MERCHANTS_ACTION.CLOSE_INDIVIDUAL_INFO,

  }
}
export const BlockMerchants =(email,lock)=>{
  return{
    type:MERCHANTS_ACTION.BLOCK_MERCHANT_REQUEST,
    payload:{email,lock}
  }
}

export const BlockOneMerchant=(email,lock)=>{
  return{
    type:MERCHANTS_ACTION.BLOCK_ONE_MERCHANT,
    payload:{email,lock}
  }
}
export const OpenBlockMerchant=(data)=>{
  return{
    type:MERCHANTS_ACTION.OPEN_BLOCK_MERCHANT,
    payload:{data}
  }
}
export const CloseBlockMerchant=()=>{
  return{
    type:MERCHANTS_ACTION.CLOSE_BLOCK_MERCHANT,
  }
}

export const ChangeMerchantEmail =(oldEmail,newEmail)=>{
  return{
    type:MERCHANTS_ACTION.CHANGE_MERCHANT_EMAIL,
    payload:{oldEmail,newEmail}
  }
}

export const ChangeEmailErr  =(data)=>{
  return{
    type:MERCHANTS_ACTION.CHANGE_MERCHANT_EMAIL_ERR,
    payload:{data}
  }
}

export const SentReport=(data)=>{
  return{
    type :MERCHANTS_ACTION.SENT_REPORT_REQUEST,
    payload:{data}
  }
}
