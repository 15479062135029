import React, {Component,} from 'react';
import {connect} from "react-redux";
import {CloseSupportPage} from "../../../modules/documents/SupportText/addSuportText/addSuportTextAtion";
import CloseVector from "../../../assets/images/backImg.png";
import {
  addAgreementText,
  CloseAgreementPage
} from "../../../modules/documents/UserAgreement/addAgreement/addAgreementAction";
import CancelButton from "../../../components/common/Buttons/CancelButton";
import SaveButton from "../../../components/common/Buttons/SaveButton";
import RichTextEditor from 'react-rte';
import PropTypes from 'prop-types';
import TextField from "@material-ui/core/TextField"; //
import '../../../assets/styles/style.css';
import ErrMessage from "../../../components/common/ErrMessage";
import {Translation} from "react-i18next";
import {Card} from "../../../components/common/Card";
import {LocationTextField} from "../../../components/common/Inputs/LocationTextField";
import {SaveAndCancelButtons} from "../../../components/common/Buttons/SaveAndCancelButtons";

const styles = {
  hideDiv: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    minHeight: '100vh',
    zIndex: '20',
    overflowY: 'scroll',
    right: 0,
    boxSizing: 'border-box',
  },
  ChangeDivCloseButton: {
    marginLeft: '5px',
    background: 'none',
    border: 'none',
  },
  Container: {
    background: 'black',
    boxShadow: '-2px 0px 4px rgba(0, 0, 0, 0.2), -1px 0px 10px rgba(0, 0, 0, 0.12), -4px 0px 5px rgba(0, 0, 0, 0.14)',
    width: '100%',
    minHeight: '100vh',
    zIndex: '100',
    position: 'fixed',
    right: 0,
  },
  ContainerDiv: {
    display: 'flex',
    height: '40px',
    alignItems: 'center',
    width: '100%',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
  }
}

class AddUserAgreement extends Component {
  static propTypes = {
    onChange: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      text: '',
      locationForUserAgreement: '',
      value: RichTextEditor.createEmptyValue(),
      errMessage: false
    }
  }

  onChange = (value) => {
    this.setState({errMessage: false})
    this.setState({value: value});
    value.toString('html')
  };

  ChackboxHandlechange = (e) => {
    this.setState({errMessage: false})
    this.setState({locationForUserAgreement: e.target.value})
  }
  Create = () => {
    const {locationForUserAgreement,} = this.state
    const text = this.state.value._cache.html
    if (locationForUserAgreement && text) {
      this.props.addAgreementText(locationForUserAgreement, text)
      this.props.CloseAgreementPage()
    } else {
      this.setState({errMessage: true})
    }
  }

  render() {

    const {openAgreementPage} = this.props

    return (
        <Translation>{(t) => <>
        {openAgreementPage === true ?
          <div style={styles.Container}>
            <div style={styles.ContainerDiv} >
              <button onClick={() => this.props.CloseAgreementPage() &&  this.setState({errMessage: false})} style={styles.ChangeDivCloseButton}>
                <img style={{width: '16px'}} src={CloseVector} alt={CloseVector}/></button>
            </div>
            <div style={styles.hideDiv}>
              <div
                style={{display: 'flex', margin: '0 auto', width: '80%', marginTop: '20px', flexDirection: 'column'}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <LocationTextField  Back={'black'} onChange={this.ChackboxHandlechange}/>
                  <SaveAndCancelButtons HandleCancel={() => this.props.CloseAgreementPage() && this.setState({errMessage: false})} HandleSave={this.Create} Save={'CREATE'} Cancel={'CANCEL'}/>
                </div>
                {this.state.errMessage === true ? <ErrMessage/> :''}
              </div>
              <div style={{color: 'black', background: 'black'}}>
                <RichTextEditor
                  value={this.state.value}
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
          : ''}
            </>}</Translation>
    );
  }
}


const mapStateToProps = state => ({
  SupportText: state.supportTextReducer.SupportText,
  openAgreementPage: state.addAgreementReducer.openAgreementPage
})

export default connect(mapStateToProps, {CloseSupportPage, CloseAgreementPage, addAgreementText})(AddUserAgreement);
