export const AGREEMENT_DATA = {
  AGREEMENT_DATA_REQUEST : 'AGREEMENT_DATA:AGREEMENT_DATA_REQUEST',
  AGREEMENT_DATA_SUCCESS : 'AGREEMENT_DATA:AGREEMENT_DATA_SUCCESS',
  AGREEMENT_DATA_FAIL : 'AGREEMENT_DATA:AGREEMENT_DATA_FAIL',
};

const initialState={
  AgreementText :[]
};

export default  (state=initialState,action)  => {
  switch (action.type) {
    case AGREEMENT_DATA.AGREEMENT_DATA_REQUEST:{
      return {...state,AgreementText:initialState.AgreementText}
    }
    case AGREEMENT_DATA.AGREEMENT_DATA_SUCCESS:{
      return{...state,AgreementText:action.payload.data}
    }
    case AGREEMENT_DATA.AGREEMENT_DATA_FAIL:{
      return {...state,AgreementText:initialState.AgreementText}
    }
    default:{
      return state
    }
  }
}