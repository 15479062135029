import Russian from "../../assets/images/Rus (1).png";
import Engl from "../../assets/images/Rus (2).png";
import React from "react";
import TextTable from "./TextTable";

const Language = localStorage.getItem("Lan");
const changeLanguageRu = () => {
  localStorage.setItem("Lan", "RU");
  window.location.reload();
};
const changeLanguageEn = () => {
  localStorage.setItem("Lan", "EN");
  window.location.reload();
};
const LanguagesDropdown = () => {
  return (
    <div style={{ marginRight: "20px" }} className="dropdown">
      {Language === "RU" ? (
        <div className={"FlagsDiv"}>
          <img className="FlagsImg" alt={Russian} src={Russian} />
          <button className={"FlagsButton"}>Ru</button>
        </div>
      ) : (
        <div className="FlagsDiv">
          <img className="FlagsImg" alt={Engl} src={Engl} />
          <button className="FlagsButton">En</button>
        </div>
      )}
      <div className="dropdown-content">
        {Language === "RU" ? (
          <div className="FlagsDiv">
            <button className="FlagsButton" onClick={changeLanguageEn}>
              <img className="FlagsImg" alt={Engl} src={Engl} /> En
            </button>
          </div>
        ) : (
          <div className="FlagsDiv">
            <button onClick={changeLanguageRu} className="FlagsButton">
              <img className="FlagsImg" alt={Russian} src={Russian} />
              Ru
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default LanguagesDropdown;
