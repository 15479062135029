import {ADD_SUPPORT} from "./addSuportTextReducer";

export const addSupportText = (locationForSupport, supportEmail,supportPhoneNumber) => {
  return{
     type:ADD_SUPPORT.SUPPORT_TEXT_REQUEST,
    payload:{locationForSupport, supportEmail,supportPhoneNumber}
  }
}

export const AddSupportPage = (data)=>{
  return{
    type:ADD_SUPPORT.OPEN_SUPPORT_PAGE,
    payload:{data}
  }
}

export const CloseSupportPage = ()=>{
  return{
    type:ADD_SUPPORT.CLOSE_SUPPORT_PAGE,
  }
}