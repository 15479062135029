import React, {Component} from "react";
import help from '../../assets/images/help.png'
import {Translation} from "react-i18next";

export const Hinit = ({}) => {
    return (
        <Translation>
            {(t) =>
                <>
                    <div className={'HinitStyle'}>

                        <div style={{display: 'flex', marginRight: '20px', alignItems: 'center'}}>
                            <a className={'YukassLink'} href={'https://yookassa.ru/my/marketplace/payments'} target={'_blank'}>
                                {t('Register YooKassa')}
                            </a>
                            <div className={'YukassLink'}  data-title={t('Register YooKassa Desc')}
                            ><img src={help} alt={help}/></div>
                        </div>
                    </div>
                </>}
        </Translation>
    )
}