import React, {Component} from 'react';
import {connect} from "react-redux";
import CloseVector from "../../../assets/images/CloseVector.png";
import {
    BlockMerchants, ChangeEmailErr, ChangeMerchantEmail,
    CloseAddMerchantPage, CloseIndividualInfo, CloseMerchantFullInfo,
    CloseMerchantInfo,
    OpenBlockMerchant
} from "../../../modules/merchantes/merchantesAction";
import {acceptInvante, AddMerchant} from "../../../modules/merchantes/AddMerchants/AddMerchantsAction";
import ErrMessage from "../../../components/common/ErrMessage";
import {GetAdmins} from "../../../modules/admins/adminAction";
import TextField from "@material-ui/core/TextField";
import * as moment from "moment";
import {Translation} from "react-i18next";
import SendReport from "./SendReport";
import {MerchantsInfoList} from "./common/MerchantsInfoList";
import {InfoSwitch} from "../../../components/common/InfoModal";
import ChangeMerchantsInfo from "./ChangeMerchantsInfo";
import ChangeIndividualMerchant from "./ChangeIndividualMerchant";
import Button from "@material-ui/core/Button";
import edit from "../../../assets/images/change.png";

const styles = {
    Container: {
        background: 'rgba(0, 0, 0, 0.4)',
        width: '100%',
        minHeight: '100vh',
        zIndex: '100',
        position: 'fixed',
        right: 0,
    },
    // hideDiv: {
    //   background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
    //   boxShadow: '-2px 0px 4px rgba(0, 0, 0, 0.2), -1px 0px 10px rgba(0, 0, 0, 0.12), -4px 0px 5px rgba(0, 0, 0, 0.14)',
    //   width: '300px',
    //   height: '100%',
    //   position: 'absolute',
    //   minHeight: '100vh',
    //   zIndex: '20',
    //   right: 0,
    //   boxSizing: 'border-box',
    //   padding: '20px'
    // },
    ChangeDivCloseButton: {
        background: 'none',
        border: 'none',
    },
    hideDivCloseButton: {
        background: 'none',
        border: 'none',
        marginLeft: '220px',
        marginTop: '7px'
    },
    hideDivID: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        color: 'rgba(255, 255, 255, 0.6)'
    },
    hideDivIDcontent: {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: 'rgba(255, 255, 255, 0.87)'
    },
    hideDivIDcontentID: {
        marginLeft: '20px',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: 'rgba(255, 255, 255, 0.87)'
    },
    showList: {
        marginTop: '10px',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '10px',
        lineHeight: '16px',
        letterSpacing: '1.5px',
        textTransform: 'uppercase',
        color: 'rgba(255, 255, 255, 0.6)',
    },
    showListInfo: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        // lineHeight: '20px',
        letterSpacing: '0.25px',
        color: 'rgba(255, 255, 255, 0.87)',
    },
    hideDivIds: {
        display: 'flex',
        flexDirection: 'column',
    },
    blockUserButton: {
        background: 'none',
        border: '1px solid #EB5B47',
        color: '#EB5B47',
        borderRadius: '8px',
        height: '40px',
        width: '120px',
        marginTop: '25px',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '1.25px',
        marginLeft: '10px',
        textTransform: 'uppercase',
    },
    ActiveUserButton: {
        background: 'rgba(101, 227, 42, 0.87)',
        border: '1px solid green',
        color: 'white',
        borderRadius: '8px',
        height: '40px',
        width: '120px',
        marginTop: '25px',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '1.25px',
        textTransform: 'uppercase',
    },
    headDiv: {
        background: 'rgba(0, 0, 0, 0.4)',
        width: '100%',
        minHeight: '100vh',
        zIndex: '100',
        position: 'fixed',
        right: 0,
    },
    hideDiv: {
        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
        boxShadow: '-2px 0px 4px rgba(0, 0, 0, 0.2), -1px 0px 10px rgba(0, 0, 0, 0.12), -4px 0px 5px rgba(0, 0, 0, 0.14)',
        width: '320px',
        height: '100%',
        position: 'absolute',
        minHeight: '100vh',
        overflowY: 'scroll',
        overflowX: 'hidden',
        zIndex: '20',
        right: 0,
        boxSizing: 'border-box',
    },

}

const Role = localStorage.getItem('role')
const email = localStorage.getItem('Email')

class AddMerchants extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showInfo: true,
            HistoryColor: 'white',
            InfoColor: '#5F94E2',
            BlockPopup: false,
            blockButton: false,
            activeButton: false,
            lockUnlock: false,
            errMassage: false,
            adminEmail: email,
            NewEmail: '',
            mail: '',
            acceptErrMessage: false,


            ShowReport: false,
            showChangeInfoModal: false
        }
    }

    componentDidMount() {
        const Role = localStorage.getItem('role')
        if (Role === 'SUPER_ADMIN') {
            this.props.GetAdmins()
        }
    }

    handleChange = (ev) => {
        const {name, value} = ev.target;
        const newState = {};
        newState[name] = value;
        this.setState(newState);
        this.setState({errMassage: false})
    };

    addMerchant = () => {
        const {adminEmail, login, password} = this.state
        if (login && password) {
            this.props.AddMerchant(adminEmail, login, password)
            this.props.CloseAddMerchantPage()
        } else {
            this.setState({errMassage: true})
        }
    }


    CloseSection = () => {
        this.props.CloseMerchantInfo()
        this.setState({InfoColor: '#5F94E2', showInfo: true, HistoryColor: 'white'})

    }
    CloseIndividualPage = () => {
        this.props.CloseIndividualInfo()
    }
    SetColor = () => {
        this.setState({InfoColor: '#5F94E2', showInfo: true, HistoryColor: 'white'})
    }
    // openBlockingPage =(MerchantsInfo)=>{
    //   this.props.OpenBlockMerchant(MerchantsInfo)
    //   this.props.CloseAddMerchantPage()
    //   this.props.CloseMerchantInfo()
    // }
    // handleChangeNewEmail =(ev)=>{
    //   this.setState({NewEmail:ev.target.value})
    //   this.setState({errMassage:false})
    // }
    // ChangeMerchant=()=>{
    //   const oldEmail=this.props.OpenMerchantsAddPage[1]
    //   const newEmail=this.state.NewEmail
    //   if(this.state.NewEmail.length > 0 ) {
    //     this.props.ChangeMerchantEmail([oldEmail, newEmail])
    //     this.props.ChangeEmailErr('')
    //   }else{
    //     this.setState({errMassage:true})
    //   }
    // }
    // handleClose =()=>{
    //   this.props.ChangeEmailErr('')
    //   this.props.CloseAddMerchantPage()
    //   this.setState({errMassage:false})
    // }
    ChackboxHandlechange = (ev) => {
        this.setState({email: ev.target.value})
        this.setState({acceptErrMessage: false})
    }
    ChangeInfo = () => {
        this.setState({showChangeInfoModal: true})

    }

    render() {
        const Role = localStorage.getItem('role')
        const {showInfo} = this.state
        const individual = Object.values(this.props.MerchantsIndividualInfo)
        const dateS = this.props.MerchantsIndividualInfo.createdDate ? moment.tz(this.props.MerchantsIndividualInfo.createdDate, 'YYYY-MM-DD/HH:mm', 'Europe/Moscow').local().format(' DD.MM.YYYY/HH:mm') : '...'
        const MerchantsInfo = Object.values(this.props.MerchantsInfo)
        const regMerchantFullInfo = Object.values(this.props.regMerchantFullInfo)
        const admins = this.props.Admins
        const data = [this.props.MerchantsInfo.id, 'LEGAL_ENTITY', true, this.state.email]
        const CancelData = [this.props.MerchantsInfo.id, 'LEGAL_ENTITY', false, 'aaa']
        const date = this.props.MerchantsInfo.created ? moment.tz(this.props.MerchantsInfo.created, 'YYYY-MM-DD/HH:mm', 'Europe/Moscow').local().format(' DD.MM.YYYY/HH:mm') : '...'
        // const regMerchantFullInfoDate = this.props.regMerchantFullInfo.created ?  moment.tz(this.props.regMerchantFullInfo.created,'YYYY-MM-DD/HH:mm','Europe/Moscow').local().format(' DD.MM.YYYY/HH:mm') : '...'
        const Individualdata = [this.props.MerchantsIndividualInfo.id, 'INDIVIDUAL', true, this.props.MerchantsIndividualInfo.email]
        const IndividualCancelData = [this.props.MerchantsIndividualInfo.id, 'LEGAL_ENTITY', false, 'aaa']

        return (
            <>

                <Translation>
                    {(t) =>
                        <>
                            {individual.length ?
                                <div style={styles.headDiv}>
                                    <div onClick={this.CloseIndividualPage} className={'CloseSectionClass'}/>
                                    <div style={styles.hideDiv}>
                                        <div style={{marginLeft: '20px'}}>
                                            <Button onClick={this.CloseIndividualPage}
                                                    style={styles.hideDivCloseButton}><img
                                                src={CloseVector} alt={CloseVector}/></Button>

                                            <div style={styles.hideDivIds}>
                                                <span style={styles.hideDivID}>{t("ID")}</span>
                                                <span
                                                    style={styles.hideDivIDcontent}>{this.props.MerchantsIndividualInfo.id}</span>
                                            </div>
                                            <div style={{display: 'flex', marginTop: '20px'}}>
                                                <button onClick={this.SetColor}
                                                        style={{
                                                            width: '100%',
                                                            border: 'none',
                                                            borderBottom: `1px solid ${this.state.InfoColor}`,
                                                            background: 'none',
                                                            padding: '15px',
                                                            outline: 'none'
                                                        }}>
                                                    <span style={{color: this.state.InfoColor}}>{t("INFO")}</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div key={MerchantsInfo.id}
                                             style={{display: 'flex', flexDirection: 'column', marginLeft: '20px'}}>
                                            <span style={styles.showList}>{t("NAME")}</span>
                                            <span
                                                style={styles.showListInfo}>{this.props.MerchantsIndividualInfo.name}</span>
                                            <span style={styles.showList}>{t("Surname")}</span>
                                            <span
                                                style={styles.showListInfo}>{this.props.MerchantsIndividualInfo.surname}</span>
                                            <span style={styles.showList}>{t("Middle Name")}</span>
                                            <span
                                                style={styles.showListInfo}>{this.props.MerchantsIndividualInfo.middleName}</span>
                                            <span style={styles.showList}>{t("Phone Number")}</span>
                                            <span style={styles.showListInfo}>{this.props.MerchantsIndividualInfo.phoneNumber}</span>
                                            <span style={styles.showList}>{t("E-mail")}</span>
                                            <span style={styles.showListInfo}>{this.props.MerchantsIndividualInfo.email}</span>
                                            <span style={styles.showList}>{t("Account Number")}</span>
                                            <span
                                                style={styles.showListInfo}>{this.props.MerchantsIndividualInfo.accountNumber}</span>
                                            <span style={styles.showList}>{t("Bank Name")}</span>
                                            <span
                                                style={styles.showListInfo}>{this.props.MerchantsIndividualInfo.bankName}</span>
                                            <span style={styles.showList}>{t("Bic")}</span>
                                            <span
                                                style={styles.showListInfo}>{this.props.MerchantsIndividualInfo.bic}</span>
                                            <span style={styles.showList}>{t("INN")}</span>
                                            <span
                                                style={styles.showListInfo}>{this.props.MerchantsIndividualInfo.inn}</span>
                                            <span style={styles.showList}>{t("Type Individual Merchant")}</span>
                                            <span
                                                style={styles.showListInfo}>{this.props.MerchantsIndividualInfo.typeIndividualMerchant}</span>
                                            <span style={styles.showList}>{t("Online Kass")}</span>
                                            <span
                                                style={styles.showListInfo}>{this.props.MerchantsInfo.onlineCheckout ? this.props.MerchantsInfo.onlineCheckout === true ? t('There is') : t('There is none') : '...'}</span>
                                            <span style={styles.showList}>{t("Create Date")}</span>
                                            <span style={styles.showListInfo}>{dateS}</span>
                                            <p>{t("Documnet")}</p>
                                            <span style={styles.showList}>{t("Actual Address")}</span>
                                            <span
                                                style={styles.showListInfo}>{this.props.MerchantsIndividualInfo.document.actualAddress}</span>
                                            <span style={styles.showList}>{t("Address Of Registration")}</span>
                                            <span
                                                style={styles.showListInfo}>{this.props.MerchantsIndividualInfo.document.addressOfRegistration}</span>
                                            <span style={styles.showList}>{t("Date Of Issue")}</span>
                                            <span
                                                style={styles.showListInfo}>{this.props.MerchantsIndividualInfo.document.dateOfIssue}</span>
                                            <span style={styles.showList}>{t("Document Number")}</span>
                                            <span
                                                style={styles.showListInfo}>{this.props.MerchantsIndividualInfo.document.documentNumber}</span>
                                            <span style={styles.showList}>{t("Issue By")}</span>
                                            <span
                                                style={styles.showListInfo}>{this.props.MerchantsIndividualInfo.document.issueBy}</span>
                                            <span style={styles.showList}>{t("Issue Code")}</span>
                                            <span
                                                style={styles.showListInfo}>{this.props.MerchantsIndividualInfo.document.issueCode}</span>
                                            <span style={styles.showList}>{t("Series")}</span>
                                            <span
                                                style={styles.showListInfo}>{this.props.MerchantsIndividualInfo.document.series}</span>
                                            <span style={styles.showList}>{t("Shop id")}</span>
                                            <span
                                                style={styles.showListInfo}>{this.props.MerchantsIndividualInfo.shopId ? this.props.MerchantsIndividualInfo.shopId : '...'}</span>
                                            <div style={{display: 'flex', marginBottom: '20px'}}>
                                                <button onClick={() => {
                                                    if (this.props.MerchantsIndividualInfo.email) {
                                                        this.props.acceptInvante(Individualdata) && this.CloseIndividualPage();
                                                    } else {
                                                        console.error("email is empty")
                                                        this.setState({acceptErrMessage: true});
                                                    }
                                                }} style={styles.ActiveUserButton}>{t("Accept")} </button>
                                                <button onClick={() => {
                                                    this.props.acceptInvante(IndividualCancelData) && this.CloseIndividualPage()
                                                }} style={styles.blockUserButton}>{t("Ignore")}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''}


                            {regMerchantFullInfo.length ?
                                this.props.regMerchantFullInfo.typeIndividualMerchant === "SELF_EMPLOYED" ?
                                    <div style={styles.headDiv}>
                                        <div onClick={() => this.props.CloseMerchantFullInfo()}
                                             className={'CloseSectionClass'}/>

                                        <div style={styles.hideDiv}>
                                            <div style={{marginLeft: '20px'}}>
                                                <Button onClick={() => this.props.CloseMerchantFullInfo()}
                                                        style={styles.hideDivCloseButton}>
                                                    <img src={CloseVector} alt={CloseVector}/>
                                                </Button>
                                                {Role === 'SUPER_ADMIN' ?
                                                    <Button onClick={() => this.ChangeInfo()}
                                                            style={styles.hideDivCloseButton}>
                                                        <img src={edit} alt={edit}/>
                                                    </Button>
                                                    : ''}

                                                <div style={styles.hideDivIds}>
                                                    <span style={styles.hideDivID}>{t("ID")}</span>
                                                    <span
                                                        style={styles.hideDivIDcontent}>{this.props.regMerchantFullInfo.id}</span>
                                                </div>

                                                <InfoSwitch
                                                    ShowReport={this.state.ShowReport} FirstText={'INFO'}
                                                    SecondText={'Report'}
                                                    Show={() => this.setState({ShowReport: false})}
                                                    Close={() => this.setState({ShowReport: true})}
                                                />
                                            </div>
                                            {this.state.showChangeInfoModal === true ?
                                                <ChangeIndividualMerchant
                                                    close={() => {
                                                        this.props.CloseMerchantFullInfo()
                                                    }}
                                                    info={this.props.regMerchantFullInfo}
                                                    handleCLose={() => this.setState({showChangeInfoModal: false})}/>
                                                :
                                                this.state.ShowReport === true ?
                                                    <SendReport
                                                        changeScreen={() => {
                                                            this.setState({ShowReport: false})
                                                        }}
                                                        close={() => {
                                                            this.props.CloseMerchantFullInfo()
                                                        }}
                                                        email={this.props.regMerchantFullInfo.email}/>
                                                    :
                                                    <MerchantsInfoList
                                                        individual={true}
                                                        info={this.props.regMerchantFullInfo}/>
                                            }
                                        </div>
                                    </div>
                                    :

                                    <div style={styles.headDiv}>
                                        <div onClick={() => this.props.CloseMerchantFullInfo()}
                                             className={'CloseSectionClass'}/>
                                        <div style={styles.hideDiv}>
                                            <div style={{marginLeft: '20px'}}>
                                                <Button onClick={() => this.props.CloseMerchantFullInfo()}
                                                        style={styles.hideDivCloseButton}>
                                                    <img src={CloseVector} alt={CloseVector}/>
                                                </Button>
                                                {Role === 'SUPER_ADMIN' ?
                                                    <Button onClick={() => this.ChangeInfo()}
                                                            style={styles.hideDivCloseButton}>
                                                        <img src={edit} alt={edit}/>
                                                    </Button>
                                                    : ''}

                                                <div style={styles.hideDivIds}>
                                                    <span style={styles.hideDivID}>{t("ID")}</span>
                                                    <span
                                                        style={styles.hideDivIDcontent}>{this.props.regMerchantFullInfo.id ? this.props.regMerchantFullInfo.id : '...'}</span>
                                                    <span style={styles.showList}>{t("Admin")}</span>
                                                    <span
                                                        style={styles.showListInfo}>{this.props.regMerchantFullInfo.user ? this.props.regMerchantFullInfo.user.telegramChatId : '...'}</span>
                                                </div>

                                                {/*<InfoSwitch  info={'Active'} Show={() => this.setState({ShowReport: false})} Close={() => this.setState({ShowReport: true})}/>*/}
                                                <InfoSwitch
                                                    ShowReport={this.state.ShowReport} FirstText={'INFO'}
                                                    SecondText={'Report'}
                                                    Show={() => this.setState({ShowReport: false})}
                                                    Close={() => this.setState({ShowReport: true})}/>

                                            </div>
                                            {this.state.showChangeInfoModal === true ?
                                                <ChangeMerchantsInfo
                                                    close={() => {
                                                        this.props.CloseMerchantFullInfo()
                                                    }}
                                                    info={this.props.regMerchantFullInfo}
                                                    handleCLose={() => this.setState({showChangeInfoModal: false})}/>
                                                :
                                                this.state.ShowReport === true ?
                                                    <SendReport
                                                        changeScreen={() => {
                                                            this.setState({ShowReport: false})
                                                        }}
                                                        close={() => {
                                                            this.props.CloseMerchantFullInfo()
                                                        }}
                                                        email={this.props.regMerchantFullInfo.email}/>
                                                    :
                                                    <MerchantsInfoList individual={false}
                                                                       info={this.props.regMerchantFullInfo}/>
                                            }
                                        </div>
                                    </div>
                                : null
                            }
                            {MerchantsInfo.length ?
                                <div style={styles.headDiv}>
                                    <div onClick={this.CloseSection} className={'CloseSectionClass'}/>
                                    <div style={styles.hideDiv}>
                                        <div style={{marginLeft: '20px'}}>
                                            <Button onClick={this.CloseSection} style={styles.hideDivCloseButton}>
                                                <img src={CloseVector} alt={CloseVector}/>
                                            </Button>

                                            <div style={styles.hideDivIds}>
                                                <span style={styles.hideDivID}>{t("ID")}</span>
                                                <span
                                                    style={styles.hideDivIDcontent}>{this.props.MerchantsInfo.id ? this.props.MerchantsInfo.id : '...'}</span>
                                            </div>
                                            <div style={{display: 'flex', marginTop: '20px'}}>
                                                <button onClick={this.SetColor}
                                                        style={{
                                                            width: '100%',
                                                            border: 'none',
                                                            borderBottom: `1px solid ${this.state.InfoColor}`,
                                                            background: 'none',
                                                            padding: '15px',
                                                            outline: 'none'
                                                        }}>
                                                    <span style={{color: this.state.InfoColor}}>{t("INFO")}</span>
                                                </button>
                                            </div>
                                        </div>
                                        {showInfo ?
                                            <div key={MerchantsInfo.id}
                                                 style={{display: 'flex', flexDirection: 'column', marginLeft: '20px'}}>
                                                <span style={styles.showList}>{t("NAME")}</span>
                                                <span
                                                    style={styles.showListInfo}>{this.props.MerchantsInfo.name ? this.props.MerchantsInfo.name : '...'}</span>
                                                <span style={styles.showList}>{t("Surname")}</span>
                                                <span
                                                    style={styles.showListInfo}>{this.props.MerchantsInfo.surname ? this.props.MerchantsInfo.surname : '...'}</span>
                                                <span style={styles.showList}>{t("Middle Name")}</span>
                                                <span
                                                    style={styles.showListInfo}>{this.props.MerchantsInfo.middleName ? this.props.MerchantsInfo.middleName : '...'}</span>
                                                <span style={styles.showList}>{t("Phone Number")}</span>
                                                <span
                                                    style={styles.showListInfo}>{this.props.MerchantsInfo.phoneNumber ? this.props.MerchantsInfo.phoneNumber : '...'}</span>
                                                <span style={styles.showList}>{t("E-mail")}</span>
                                                <span
                                                    style={styles.showListInfo}>{this.props.MerchantsInfo.email ? this.props.MerchantsInfo.email : '...'}</span>
                                                <span style={styles.showList}>{t("Account Number")}</span>
                                                <span
                                                    style={styles.showListInfo}>{this.props.MerchantsInfo.accountNumber ? this.props.MerchantsInfo.accountNumber : '...'}</span>
                                                <span style={styles.showList}>{t("Position")}</span>
                                                <span
                                                    style={styles.showListInfo}>{this.props.MerchantsInfo.position ? this.props.MerchantsInfo.position : '...'}</span>
                                                <span style={styles.showList}>{t("Legal Entinty")}</span>
                                                <span
                                                    style={styles.showListInfo}>{this.props.MerchantsInfo.nameOfLegalEntity ? this.props.MerchantsInfo.nameOfLegalEntity : '...'}</span>
                                                <span style={styles.showList}>{t("INN")}</span>
                                                <span
                                                    style={styles.showListInfo}>{this.props.MerchantsInfo.inn ? this.props.MerchantsInfo.inn : '...'}</span>
                                                <span style={styles.showList}>{t("Legal Adress")}</span>
                                                <span
                                                    style={styles.showListInfo}>{this.props.MerchantsInfo.legalAddress ? this.props.MerchantsInfo.legalAddress : '...'}</span>
                                                <span style={styles.showList}>{t("Bank Name")}</span>
                                                <span
                                                    style={styles.showListInfo}>{this.props.MerchantsInfo.bankName ? this.props.MerchantsInfo.bankName : '...'}</span>
                                                <span style={styles.showList}>{t("Bic")}</span>
                                                <span
                                                    style={styles.showListInfo}>{this.props.MerchantsInfo.bic ? this.props.MerchantsInfo.bic : '...'}</span>

                                                <span style={styles.showList}>{t("Shop id")}</span>
                                                <span
                                                    style={styles.showListInfo}>{this.props.MerchantsInfo.shopId ? this.props.MerchantsInfo.shopId : '...'}</span>

                                                <span style={styles.showList}>{t("Online Kass")}</span>
                                                <span
                                                    style={styles.showListInfo}>{this.props.MerchantsInfo.onlineCheckout ? this.props.MerchantsInfo.onlineCheckout === true ? t('There is') : t('There is none') : '...'}</span>
                                                <span style={styles.showList}>{t("Create Date")}</span>
                                                <span style={styles.showListInfo}>{date}</span>

                                                {this.state.acceptErrMessage === true ?
                                                    <ErrMessage/> : ''}
                                                <div style={{display: 'flex', marginBottom: '20px'}}>
                                                    <button onClick={() => {
                                                        this.props.acceptInvante(data) && this.CloseSection()
                                                    }} style={styles.ActiveUserButton}>{t("Accept")}</button>
                                                    <button onClick={() => {
                                                        this.props.acceptInvante(CancelData) && this.CloseSection()
                                                    }} style={styles.blockUserButton}>{t("Ignore")}</button>
                                                </div>
                                            </div>
                                            :
                                            <div></div>
                                        }
                                    </div>
                                </div>
                                : ''}
                        </>}</Translation>
            </>
        );
    }
}

const mapStateToProps = state => ({
    OpenMerchantsAddPage: state.MerchantesReducer.OpenMerchantsAddPage,
    MerchantsInfo: state.MerchantesReducer.MerchantsInfo,
    MerchantsIndividualInfo: state.MerchantesReducer.MerchantsIndividualInfo,
    regMerchantFullInfo: state.MerchantesReducer.regMerchantFullInfo,
    MerchantsEmailErr: state.MerchantesReducer.MerchantsEmailErr,
    Admins: state.adminReducer.Admins
})

export default connect(mapStateToProps, {
    CloseIndividualInfo,
    CloseMerchantFullInfo,
    GetAdmins,
    acceptInvante,
    ChangeEmailErr,
    ChangeMerchantEmail,
    OpenBlockMerchant,
    BlockMerchants,
    CloseMerchantInfo,
    CloseAddMerchantPage,
    AddMerchant
})(AddMerchants);
