import React, {Component} from 'react';
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import another from "../../assets/images/another.png";
import {
  AddMerchantsPage,
  BlockMerchants,
  GetMerchants, GetRegMerchants, GetRegMerchantsFullInfo, OpenBlockMerchant, showIndividualMerchantInfo,
  showMerchantInfo
} from "../../modules/merchantes/merchantesAction";
import {GetMerchantByAdmin} from "../../modules/merchantes/GetMerchant/GetMerchantAction";
import Title from "../../components/common/TabbleText/AllTitle";
import {TableCEll} from "../../components/common/TableCEll";
import {Card} from "../../components/common/Card";
import {Translation} from "react-i18next";


const useStyles = {
  BodyTableCell: {
    color: 'white',
  },
  BodyTableRow: {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14)',
    borderBottom: '5px solid black',
    borderRadius: '10px',
    width: '100%',
    height: '56px',
    cursor:'pointer',
  },
  openInfoButton: {
    border: 'none',
    background: 'none',
    marginTop: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    letterSpacing: '0.1px',
    color: 'rgba(255, 255, 255, 0.87)'
  },
  ChangeInfoButton: {
    border: 'none',
    background: 'none',
    marginTop: '10px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    letterSpacing: '0.1px',
    color: 'rgba(255, 255, 255, 0.87)'
  },
  activeIndexStyle: {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #121212',
    boxShadow: '0px 11px 15px rgba(0, 0, 0, 0.2), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 24px 38px rgba(0, 0, 0, 0.14)',
    borderRadius: '5px',
    width: '100px',
    height: '120px',
    position: 'absolute',
    right: '80px',
    zIndex: '50',
  },
  activeIndexButtonsStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  BodyTableCellCheck: {
    color:'white',
    borderBottomLeftRadius: '5px',
    borderTopLeftRadius: '5px',
  },
  BodyTableCellRight: {
    borderBottomRightRadius: '5px',
    borderTopRightRadius: '5px',
    color: 'white',
  },
  BlockMerchantButtonStyle:{
    border: 'none',
    background: 'none',
    marginTop: '10px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    letterSpacing: '0.1px',
    color: '#EB5B47'
  },
  RestoreMerchantButtonStyle:{
    border: 'none',
    background: 'none',
    marginTop: '10px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    letterSpacing: '0.1px',
    color: 'rgba(101, 227, 42, 0.87)'
  },

};


class Merchants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: '',
      RegActiveIndex:'',
      openInfo: [],
      AllUsers: '#5F94E2',
      ActiveButton: 'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #121212',
      BlockedButton: 'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #121212',
    }
  }

  componentDidMount() {
    const Role  = localStorage.getItem('role')
    const Email  = localStorage.getItem('Email')
   if(Role === 'ADMIN'){
     this.props.GetMerchantByAdmin(Email)
    }
      else if(Role === 'SUPER_ADMIN' ) {
      this.props.GetMerchants()
      this.props.GetRegMerchants()

    }
  }

  handleClick = (i) => {
    this.setState({activeIndex: i})
  }
  handleRegClick = (i) => {
    this.setState({RegActiveIndex: i})
  }
  openInfo = (data) => {
    this.props.GetRegMerchantsFullInfo(data.email);
    if(data.typeIndividualMerchant) {
      this.props.showIndividualMerchantInfo(data)
    }else {
      this.props.showMerchantInfo(data)
    }
  }

  ClosePopup = () => {
    this.setState({activeIndex: ''})
  }
  RegClosePopup = () => {
    this.setState({RegActiveIndex: ''})
  }

  render() {
    const Merchants  = this.props.Merchants
    const regMerchant  = this.props.regMerchant
    const GetByEmail  = this.props.GetByEmail
    return (
    <Card>
      <Translation>
        {(t) =>
          <>
            <Title Text={'Merchants'}/>
              <Table size="small">
                <TableHead style={{borderBottom: '1px solid grey',}}>
                    <TableCEll text={['ID','E-mail','','','Status'] }/>
                </TableHead>
                <TableCell style={{border: 'none', padding: '1px'}}>.</TableCell>
                {regMerchant.length > 0 ?
                  regMerchant.map((row, i) => (
                <TableBody key={i}>{this.state.RegActiveIndex === i ?
                  <div onMouseLeave={() => {this.RegClosePopup('')}} style={useStyles.activeIndexStyle}>
                    <div style={useStyles.activeIndexButtonsStyle}>
                      <button onClick={() => {this.openInfo(row)}} style={useStyles.openInfoButton}>{t("Open Info")}</button>
                    </div>
                  </div>
                  : ''}
                  <TableRow style={useStyles.BodyTableRow} key={row.id}>
                    <TableCell style={useStyles.BodyTableCellCheck} onClick={()=>{this.openInfo(row)}}>{row.id.slice(0,4) + '...'}</TableCell>
                    <TableCell style={useStyles.BodyTableCell} onClick={()=>{this.openInfo(row)}} >{row.email ? row.email : '...'}</TableCell>
                    <TableCell style={useStyles.BodyTableCell} onClick={()=>{this.openInfo(row)}}>{}</TableCell>
                    <TableCell style={useStyles.BodyTableCell} onClick={()=>{this.openInfo(row)}}>{}</TableCell>
                    <TableCell style={useStyles.BodyTableCellRight} >
                      <div style={{display: 'flex', justifyContent: 'space-between',}}>
                        {row.merchantRequestStatus === 'PENDING' ?
                          <span onClick={() => {
                            this.openInfo(row, i)
                          }} style={{color: '#EB5B47'}}>{t("PENDING")}</span>
                          :
                          <span onClick={() => {
                            this.openInfo(row, i)
                          }} style={{color: 'rgba(101, 227, 42, 0.87)'}}>{t("Active")}</span>
                        }
                        <Button   onClick={() => {this.handleRegClick(i)}}><img src={another} alt={another}/></Button>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
                )) :''}
                {Merchants.length > 0 ?
                  Merchants.map((row, i) => (
                    <TableBody key={i}>{this.state.activeIndex === i ?
                      <div onMouseLeave={() => {this.ClosePopup('')}} style={useStyles.activeIndexStyle}>
                          <div style={useStyles.activeIndexButtonsStyle}>
                            <button onClick={() => {this.props.GetRegMerchantsFullInfo(row.email)}} style={useStyles.openInfoButton}>{t("Open Info")}</button>
                            {row.blocking === false  ?
                              <button onClick={() => {this.props.OpenBlockMerchant(Object.values(row))}} style={useStyles.BlockMerchantButtonStyle}>{t("Block Merchant")}</button>
                              :
                              <button onClick={() => {this.props.OpenBlockMerchant(Object.values(row))}} style={useStyles.RestoreMerchantButtonStyle}>{t("Restore Merchant")}</button>
                            }       </div>
                        </div>
                        : ''}
                      <TableRow style={useStyles.BodyTableRow} key={row.id}>
                        <TableCell style={useStyles.BodyTableCellCheck} onClick={()=>this.props.GetRegMerchantsFullInfo(row.email)}>{row.id.slice(0,4) + '...'}</TableCell>
                        <TableCell style={useStyles.BodyTableCell} onClick={()=>this.props.GetRegMerchantsFullInfo(row.email)}>{row.email ? row.email : '...'}</TableCell>
                        <TableCell style={useStyles.BodyTableCell} onClick={()=>this.props.GetRegMerchantsFullInfo(row.email)}>{}</TableCell>
                        <TableCell style={useStyles.BodyTableCell} onClick={()=>this.props.GetRegMerchantsFullInfo(row.email)}>{}</TableCell>
                        <TableCell style={useStyles.BodyTableCellRight} >
                          <div style={{display: 'flex', justifyContent: 'space-between',}}>
                            {row.blocking === true ?
                              <span onClick={()=>this.props.GetRegMerchantsFullInfo(row.email) }
                                style={{color: '#EB5B47'}}>{t("Blocked")}</span>
                              :
                              <span onClick={()=>this.props.GetRegMerchantsFullInfo(row.email) }
                                  style={{color: 'rgba(101, 227, 42, 0.87)'}}>{t("Active")}</span>
                            }
                              <Button   onClick={() => {this.handleClick(i)}}><img src={another} alt={another}/></Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))
                  :
                  GetByEmail.length > 0 ?
                      GetByEmail.map((row, i) => (
                        <TableBody key={i}>{this.state.activeIndex === i ? <div onMouseLeave={() => {this.ClosePopup('')}} style={useStyles.activeIndexStyle}>
                            <div style={useStyles.activeIndexButtonsStyle}>
                              <button onClick={()=>this.props.GetRegMerchantsFullInfo(row.email)} style={useStyles.openInfoButton}>{t("Open info")}</button>
                              {row.blocking === false  ?
                                <button onClick={() => {this.props.OpenBlockMerchant(Object.values(row))}} style={useStyles.BlockMerchantButtonStyle}>{t("Block Merchant")}</button>
                                :
                                <button onClick={() => {this.props.OpenBlockMerchant(Object.values(row))}} style={useStyles.RestoreMerchantButtonStyle}>{t("Restore Merchant")}</button>
                              }
                            </div>
                          </div>
                          : ''}
                          <TableRow style={useStyles.BodyTableRow} key={row.id}>
                            <TableCell style={useStyles.BodyTableCellCheck} onClick={()=>this.props.GetRegMerchantsFullInfo(row.email)}>{row.id.slice(-4) + '...'}</TableCell>
                            <TableCell style={useStyles.BodyTableCell} onClick={()=>this.props.GetRegMerchantsFullInfo(row.email)}>{row.telegramChatId}</TableCell>
                            <TableCell style={useStyles.BodyTableCell} onClick={()=>this.props.GetRegMerchantsFullInfo(row.email)}>{row.email ? row.email : '...'}</TableCell>
                            <TableCell style={useStyles.BodyTableCell} onClick={()=>this.props.GetRegMerchantsFullInfo(row.email)}>{}</TableCell>
                            <TableCell style={useStyles.BodyTableCell} onClick={()=>this.props.GetRegMerchantsFullInfo(row.email)}>{}</TableCell>
                            <TableCell style={useStyles.BodyTableCellRight} >
                              <div style={{display: 'flex', justifyContent: 'space-between',}}>
                                {row.blocking === false ?
                                  <span onClick={()=>this.props.GetRegMerchantsFullInfo(row.email)} style={{color: 'rgba(101, 227, 42, 0.87)'}}>{t("Active")}</span> :
                                  <span onClick={()=>this.props.GetRegMerchantsFullInfo(row.email)} style={{color: '#EB5B47'}}>{t("Blocked")}</span>}
                                <Button   onClick={() => {this.handleClick(i)}}><img src={another} alt={another}/></Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )) :''}
              </Table>
          </>}</Translation>
    </Card>
    );
  }
}

const mapStateToProps = state => ({
  signInData:state.signInReducer.signInData,
  Merchants: state.MerchantesReducer.Merchants,
  regMerchant: state.MerchantesReducer.regMerchant,
  GetByEmail:state.GetMerchantReducer.GetByEmail,
})
export default connect(mapStateToProps, {showIndividualMerchantInfo,GetRegMerchantsFullInfo,GetRegMerchants,OpenBlockMerchant,BlockMerchants,showMerchantInfo,GetMerchants,AddMerchantsPage,GetMerchantByAdmin})(Merchants);


