import React from "react";
import Input from "@material-ui/core/Input";
import {Translation} from "react-i18next";

const style={
  showListInfo: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: 'rgba(255, 255, 255, 0.87)',
  },
}

const ListText =({Text,style = {}})=>{
  return(
    <Translation>
      {(t) =>
        <>
    <span style={{...style.showListInfo, ...style}}>{t(Text)}</span>
        </>}</Translation>

)
}

export default ListText
