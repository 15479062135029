import TextField from "@material-ui/core/TextField";
import React from "react";
import {Translation} from "react-i18next";

const RegTextField = ({Shrink,onKeyDown,onBlur,onFocus,typeError,Length,pattern,disabled,value,type,onChange,name,label,onClick, style={}, children,inputProps, ...props}) => {
  return (
    <>
    <Translation>
      {(t) =>
        <>
          {typeError === 'Error' ?
            <div className={'EmailWrongShape'}  style={{marginTop: '10px'}}>
              <TextField
                error
                id="outlined-error-helper-text"
                label={t("Wrong shape")}
                value={value}
                onFocus={onFocus}
                onBlur={onBlur}
                disabled={disabled}
                maxLength={Length}
                type={type}
                name={name}
                InputLabelProps={{style: {border: 'none', fontSize: '14px', lineHeight: '16px', letterSpacing: '0.4px', color: 'red', background: '#121212', padding: '0 10px 0 0 '},}}
                inputProps={{autoComplete: "new-password", maxLength: Length, pattern: {pattern},
                  style: {color:  typeError === 'Error' ? 'red' : 'gray', outline: 'none', width: '280px', fontFamily: 'Arial', border: '2px solid red', borderRadius: '5px',}}}
                onChange={onChange}
                SelectProps={{native: true,}}
                variant="outlined"
              />
            </div>
            :
            <div style={{marginTop: '10px'}}>
              <TextField
                id="outlined-select-currency-native"
                value={value}
                disabled={disabled}
                maxLength={Length}
                onFocus={onFocus}
                onBlur={onBlur}
                type={type}
                label={label}
                onKeyDown={onKeyDown}
                name={name}
                InputLabelProps={{shrink: Shrink,style: {border: 'none', fontSize: '14px', lineHeight: '16px', letterSpacing: '0.4px', color: 'rgba(255, 255, 255, 0.6)', background: '#121212', padding: '0 10px 0 0 '},}}
                inputProps={{autoComplete: "new-password", maxLength: Length, pattern: pattern, style: {color: 'gray', outline: 'none', width: '280px', fontFamily: 'Arial', border: '1px solid white', borderRadius: '5px',}}}
                onChange={onChange}
                SelectProps={{native: true,}}
                variant="outlined"
              />
            </div>

          }
        </>
      }</Translation>
      </>
  )
};

export default RegTextField


