import {put, takeLatest,call} from "@redux-saga/core/effects";
import * as api from "../api";
import {ADMINS_ACTION} from "./adminReducer";
import axios from "axios";
import {BlockOneAdmin} from "./adminAction";




function* handleSaga(actions) {
  try {
    const {data} = yield call(api.getAdmins)
    yield put({
      type: ADMINS_ACTION.GET_ADMINS_SUCCESS,
      payload: {data}
    })
  } catch (e) {
    yield put({
      type:ADMINS_ACTION.GET_ADMINS_FAIL,
      message: e.message
    })
  }
}





function* attemptBlockAdmin ({payload}) {
  const email = payload.email
  const lock  = payload.lock
  try {
    const {data} = yield axios.get(`/api/private/admin/lockAdmin?${'email=' + email}`, {
      params: {
        'lock' :lock
      },
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('token'),
      }

    });
    yield put(BlockOneAdmin(payload.email,payload.lock))
  }catch(e) {
    console.log(e)
  }
}

function* adminsSaga() {
  yield takeLatest(ADMINS_ACTION.GET_ADMINS_REQUEST, handleSaga)
}

export function* attemptBlockEdAdmin() {
  yield takeLatest(ADMINS_ACTION.LOCK_ADMIN_BLOCKIN, attemptBlockAdmin);
}


export default adminsSaga
