export const GET_QUESTION = {
  CLEA_DEL_POPULAR_QA:'GET_QUESTION.CLEA_DEL_POPULAR_QA',
  DEL_POPULAR_QA:'GET_QUESTION.DEL_POPULAR_QA',
  GET_POPULAR_QUESTION_REQUEST:'GET_QUESTION.GET_POPULAR_QUESTION_REQUEST',
  GET_POPULAR_QUESTION_SUCCESS:'GET_QUESTION.GET_POPULAR_QUESTION_SUCCESS',
  GET_POPULAR_QUESTION_FAIL:'GET_QUESTION.GET_POPULAR_QUESTION_FAIL',
  EDIT_POPULAR_QUESTION_REQUEST:'GET_QUESTION.EDIT_POPULAR_QUESTION_REQUEST',
  CLOSE_EDIT_POPULAR_QUESTION_REQUEST: 'GET_QUESTION.CLOSE_EDIT_POPULAR_QUESTION_REQUEST'
}

const initialState={
  PopularQuestion:[],
  EditStation:[],
  DeleteQuestion:[]
}

export default (state=initialState,action)=>{
  switch (action.type) {
    case GET_QUESTION.DEL_POPULAR_QA:{
      return {...state,DeleteQuestion:action.payload.data}
    }
    case GET_QUESTION.CLEA_DEL_POPULAR_QA:{
      return {...state,DeleteQuestion:''}
    }
    case GET_QUESTION.GET_POPULAR_QUESTION_REQUEST:{
      return{...state,PopularQuestion:initialState.PopularQuestion}
    }
    case GET_QUESTION.GET_POPULAR_QUESTION_SUCCESS:{
      return {...state,PopularQuestion:action.payload.data}
    }
    case GET_QUESTION.GET_POPULAR_QUESTION_FAIL:{
      return{...state,PopularQuestion:initialState.PopularQuestion}
    }
    case GET_QUESTION.CLOSE_EDIT_POPULAR_QUESTION_REQUEST:{
      return{...state,EditStation:[]}
    }
    case GET_QUESTION.EDIT_POPULAR_QUESTION_REQUEST:{
      return {...state,EditStation:action.payload.data}
    }
      default:{
        return state
      }
  }

}