export const USERS_DEL={
  DELETE_USERS_REQUEST:'USERS_DEL.DELETE_USERS_REQUEST',
  DELETE_USERS_SUCCESS:'USERS_DEL.DELETE_USERS_SUCCESS',
  DELETE_USERS_FAIL:'USERS_DEL.DELETE_USERS_FAIL'
}

const initialState={
  DeleteUsers:[]
}

export default (state=initialState,action)=>{
  switch (action.type) {
    case USERS_DEL.DELETE_USERS_REQUEST:{
      return {...state,DeleteUsers:initialState.DeleteUsers}
    }
    case USERS_DEL.DELETE_USERS_SUCCESS:{
      return {...state,DeleteUsers:action.payload.data}
    }
    case USERS_DEL.DELETE_USERS_FAIL:{
      return {...state,DeleteUsers:initialState.DeleteUsers}
    }
    default:{
      return state
    }

  }
}
