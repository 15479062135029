import {put, takeLatest,call} from "@redux-saga/core/effects";
import * as api from "../../api";
import {ADD_ADMIN_ACTION} from "./AddAdminsReducer";
import {GetAdmins} from "../adminAction";




function* merchantsSaga() {
  yield takeLatest(ADD_ADMIN_ACTION.ADD_ADMIN_REQUEST, handleSaga)
}

function* handleSaga(action) {
  try {
    const {data} = yield call(api.addAdmins,action.payload.login,action.payload.password)
    yield put({
      type: ADD_ADMIN_ACTION.ADD_ADMIN_SUCCESS,
      payload: {data}
    })
    yield put(GetAdmins())
  } catch (e) {
    yield put({
      type: ADD_ADMIN_ACTION.ADD_ADMIN_FAIL,
      message: e.message
    })
  }
}

export default merchantsSaga
