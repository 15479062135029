import {GET_TARIFFS} from "./AddTarifTextReducer";

export const AddTariffText = (locationForTariff,text)=>{
  return{
    type:GET_TARIFFS.ADD_TARIFF_TEXT_REQUEST,
    payload:{locationForTariff,text}
  }
}

export const OpenTariffPopup = (data) =>{
  return{
   type:GET_TARIFFS.OPEN_TARIFFS_POPUP,
   payload:{data}
  }
}
export const ClosePopup = () =>{
  return{
   type:GET_TARIFFS.CLOSE_TARIFFS_POPUP,
  }
}
