import {ADMINS_ACTION} from "./adminReducer";

export const GetAdmins =()=>{
  return{
    type:ADMINS_ACTION.GET_ADMINS_REQUEST
  }
}

export const AddAdminsPage =(data)=>{
  return{
    type:ADMINS_ACTION.OPEN_PAGE,
    payload:{data}
  }
}

export const CloseAddAdminPage =()=>{
  return{
    type:ADMINS_ACTION.CLOSE_PAGE
  }
}

export const showAdminInfo = (data)=>{
  return{
    type: ADMINS_ACTION.OPEN_INFO,
    payload:{data}
  }
}
export const CloseAdminInfo = (data)=>{
  return{
    type: ADMINS_ACTION.CLOSE_INFO,
    payload:{data}
  }
}

export const OpenBlockAdmin =(data)=>{
  return{
    type:ADMINS_ACTION.OPEN_BLOCK_PAGE,
    payload:{data}
  }
}

export const CancelBlockAdmin =()=>{
  return{
    type:ADMINS_ACTION.CLOSE_BLOCK_PAGE,
  }
}

export const LockAdmin  =(email,lock)=>{
  return{
    type:ADMINS_ACTION.LOCK_ADMIN_BLOCKIN,
    payload:{email,lock}
  }
}

export const BlockOneAdmin=(email,lock)=>{
  return{
    type:ADMINS_ACTION.BLOCK_ONE_ADMIN,
    payload:{email,lock}
  }
}
