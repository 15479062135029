import React, {Component} from 'react';
import {connect} from "react-redux";
import {DeleteTariffAction, GetAllGroupTariffs, GetTariffs, TariffsListInfo} from "../../modules/tariff/tariffsAction";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {OpenTariffPopup} from "../../modules/tariff/AddTAriffText/AddTarifTextAction";
import TableBody from "@material-ui/core/TableBody";
import another from '../../assets/images/another.png';
import NewButton from "../../components/common/Buttons/ForNewButton";
import Title from "../../components/common/TabbleText/AllTitle";
import {TableCEll} from "../../components/common/TableCEll";
import {Card} from "../../components/common/Card";
import {DeleteModal} from "../../components/common/modalPopup/DeleteModal";
import {Translation} from "react-i18next";

const useStyles = {
  BodyTableCell: {
    color: 'white',
  },
  BodyTableCellRight: {
    borderBottomRightRadius: '5px',
    borderTopRightRadius: '5px',
    color: 'white',
  },
  BodyTableRow: {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14)',
    borderBottom: '5px solid black',
    borderRadius: '10px',
    width: '100%',
    height: '56px',
  },
  openInfoButton: {
    border: 'none',
    background: 'none',
    marginTop: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    letterSpacing: '0.1px',
    color: 'rgba(255, 255, 255, 0.87)'
  },
  activeIndexStyle: {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #121212',
    boxShadow: '0px 11px 15px rgba(0, 0, 0, 0.2), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 24px 38px rgba(0, 0, 0, 0.14)',
    borderRadius: '5px',
    width: '100px',
    height: '90px',
    position: 'absolute',
    right: '90px',
    zIndex: '50',
  },
  activeIndexButtonsStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  BodyTableCellCheck: {
    color: 'white',
    borderBottomLeftRadius: '5px',
    borderTopLeftRadius: '5px',
  },
};

class Tariffs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: '',
      openInfo: [],
      location: "ARM",
      showModal: false,
      tariffName: ''
    }
  }

  componentDidMount() {
    // this.props.GetTariffs(this.state.location)
    this.props.GetAllGroupTariffs()
  }

  handleClick = (i, row) => {
    this.setState({tariffName: row, activeIndex: i})
  }
  TariffText = (data) => {
    this.props.OpenTariffPopup(data)
  }
  ClosePopup = () => {
    this.setState({activeIndex: ''})
  }
  openInfo = (row) => {
    this.props.TariffsListInfo(row)
    this.state.openInfo.push(row)
  }
  DeleteTariff=(tariffName)=>{
    this.props.DeleteTariffAction(tariffName.id)
    this.setState({showModal: false})
  }
  render() {
    const AllGroup = Object.values(this.props.AllGroup)
    const {showModal, tariffName} = this.state


    return (
      <Card>
        <Translation>
          {(t) =>
            <>
        <Title Text={'Tariffs'}/>
        {showModal &&
        <DeleteModal
          boolean={showModal}
          text={`${t("Are you sure you want delete tariff")}: ${tariffName.name}`}
          Cancel={'Cancel'} Delete={'Delete'}
          handleCancel={() => {this.setState({showModal: false})}}
          handleDelete={() =>{this.DeleteTariff(tariffName)}}
        />}
        <Table size="small">
          <TableHead style={{borderBottom: '1px solid grey',}}>
            <TableCEll text={['ID', 'Name','Location', '','', '']}/>
          </TableHead>

          <TableCell style={{border: 'none', padding: '1px'}}>.</TableCell>
          {AllGroup.length > 0 ?
            AllGroup.map((row, i) => (
              <TableBody key={i}>{this.state.activeIndex === i ?
                <div onMouseLeave={() => {this.ClosePopup('')}} style={useStyles.activeIndexStyle}>
                  <div style={useStyles.activeIndexButtonsStyle}>
                    <button onClick={() => {this.openInfo(row)}} style={useStyles.openInfoButton}>{t("Open Info")}</button>
                    <button onClick={() => {this.setState({showModal: true})}} className={'blockUSersButton'}>{t("Delete Tariff")}</button>
                  </div>
                </div>
                : ''
              }
                <TableRow style={useStyles.BodyTableRow} key={row.id}>
                  <TableCell style={useStyles.BodyTableCellCheck} onClick={() => {this.openInfo(row)}}>{row.id ? row.id : '...'}</TableCell>
                  <TableCell style={useStyles.BodyTableCell} onClick={() => {this.openInfo(row)}}>{row.name ? row.name :'...' }</TableCell>
                  <TableCell style={useStyles.BodyTableCell} onClick={() => {this.openInfo(row)}}>{row.location ? row.location:'...'}</TableCell>
                  <TableCell style={useStyles.BodyTableCell} onClick={() => {this.openInfo(row)}}>{}</TableCell>
                  <TableCell style={useStyles.BodyTableCell} onClick={() => {this.openInfo(row)}}>{}</TableCell>
                  <TableCell style={useStyles.BodyTableCellRight}>
                      <Button onClick={() => {this.handleClick(i, row)}}><img src={another} alt={another}/></Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            )) : ''}
        </Table>
        <NewButton onClick={() => this.TariffText('open')} ButtonText={'+ NEW TARIFF'}/>
            </>}</Translation>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  AllGroup: state.tariffsReducer.AllGroup,
})

export default connect(mapStateToProps, {DeleteTariffAction,TariffsListInfo, GetAllGroupTariffs, OpenTariffPopup})(Tariffs);
