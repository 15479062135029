import Checkbox from "@material-ui/core/Checkbox";
import React from "react";
import {Translation} from "react-i18next";

export const WithoutRevShare =({handleClick,checked})=>{
    return(
        <Translation>
            {(t) =>
                <>
                    <div style={{display: 'flex', justifyContent: 'center',marginRight:'50px',marginTop:'5px'}}>
                        <p style={{fontSize:'15px',color:'#CCCCCC'}}>{t("Without RevShare")}</p>
                        <Checkbox
                            checked={checked}
                            onChange={() => handleClick()}
                            style={{color: 'white', marginLeft: '30px'}}
                            value="secondary"
                            inputProps={{'aria-label': 'secondary checkbox'}}
                        />
                    </div>
                </>
            }</Translation>

    )
}