import React from "react";
import { Translation } from "react-i18next";
import Title from "./TabbleText/AllTitle";
import { SearchInput } from "./Inputs/SearchInput";
import { FilterButtons } from "./Buttons/FilterButtons";

const styles = {
  CardStyle: {
    marginBottom: "90px",
  },
};
export const Card = ({
  filter,
  revert,
  download,
  downloadList,
  children,
  Count,
  TitleName,
  place,
  onClick,
  onChange,
  onKeyDown,
  search,
  value,
  clearSearch,
  FilterBoolean,
  allButtons,
  conditionsButtons,
}) => {
  return (
    <div className={"CardStyle"} style={styles.CardStyle}>
      <Translation>
        {(t) => (
          <>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Title
                      style={{ marginLeft: "17px" }}
                      Text={`${t(TitleName)}${
                        Count !== undefined ? ` - ${Count}` : ""
                      }`}
                    />
                    {TitleName && search ? (
                      <SearchInput
                        value={value}
                        placeholder={place}
                        onClick={onClick}
                        clearSearch={clearSearch}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                      />
                    ) : null}
              </div>
              {FilterBoolean === true && (
                <FilterButtons
                  filter={filter}
                  revert={revert}
                  downloadList={downloadList}
                  download={download}
                  allButtons={allButtons}
                  conditionsButtons={conditionsButtons}
                />
              )}
              <div style={{ margin: "0 20px" }}>{children}</div>
            </div>
          </>
        )}
      </Translation>
    </div>
  );
};
