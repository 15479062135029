import React from "react";
import Button from "@material-ui/core/Button";
import {Translation} from "react-i18next";
import {Card} from "../Card";

const styles = {
  Container: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems:'flex-end',
    position:'fixed',
    bottom: '24px',
    right: '24px',
    margin: 'auto',
  },
  ButtonStyle: {
    border: 'none',
    background: '#5F94E2',
    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14)',
    color: 'white',
    borderRadius: '28px',
    height: '40px'
  },
  SpanText: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '1.25px',
    textTransform: 'uppercase',
    color: '#FFFFFF',
  }
}

const NewButton = ({ButtonText, onClick, ...props}) => {
  return (
    <Translation>
      {(t) =>
        <>
    <div style={styles.Container}>
      <Button
        style={styles.ButtonStyle}
        onClick={onClick}
        {...props}
      >
     <span style={styles.SpanText}>
     {t(ButtonText)}
     </span>
      </Button>
    </div>
</>}</Translation>

  )
}

export default NewButton
