import {rentHistory} from "./rentHistoryReducer";

export const setRentHistoryItem = (phoneNumber) => {
    return{
        type:rentHistory.RENT_HISTORY_REQUEST ,
        payload: {phoneNumber},
    }
};

export const setRentHistoryIteM = item => ({
    type: rentHistory.SET_RENT_HISTORY_ITEM,
    payload: item
});
export const clearRentHistory =()=>{
    return{
    type:rentHistory.CLEAR_RENT_HISTORY
    }
}
