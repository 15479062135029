import React, {Component} from 'react';
import {connect} from "react-redux";
import {BlockMerchants, CloseBlockMerchant, CloseMerchantInfo} from "../../../modules/merchantes/merchantesAction";
import {CancelBlockAdmin, CloseAdminInfo, LockAdmin} from "../../../modules/admins/adminAction";
import {Translation} from "react-i18next";

const styles ={
  headDiv: {
    background: 'rgba(0, 0, 0, 0.4)',
    width: '100%',
    minHeight: '100vh',
    zIndex: '100',
    position: 'fixed',
    right: 0,
  },
  CanclePopup: {
    margin: 'auto',
    width: '330px',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #121212',
    boxShadow: '0px 11px 15px rgba(0, 0, 0, 0.2), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 24px 38px rgba(0, 0, 0, 0.14)',
    borderRadius: '8px',
    padding: '15px'
  },
  CanclePopupButtons: {
    color: '#5F94E2',
    border: 'none',
    background: 'none'
  },
}
class BlockMerchant extends Component {



  blockMerchant = () => {
    const email =this.props.openBlockPage[1]
    const lock =this.props.openBlockPage[7]
    if (lock === true) {
      this.props.BlockMerchants(email,false)
    } else {
      this.props.BlockMerchants(email,true)
    }
    this.props.CloseBlockMerchant()
    this.props.CloseMerchantInfo()
    this.setState({lockUnlock : false})
  }

  blockAdmin = () => {
    const email =this.props.openAdminBlockPage[1]
    const lock =this.props.openAdminBlockPage[7]
    if (lock === true) {
      this.props.LockAdmin(email,false)
    } else {
      this.props.LockAdmin(email,true)
    }
    this.props.CancelBlockAdmin()
    this.props.CloseAdminInfo()
    this.setState({lockUnlock : false})
  }
  CancelBlockMerchantPopup=()=>{
     this.props.CloseBlockMerchant()
  }


  render() {
    const openAdminBlockPage =this.props.openAdminBlockPage
    const AdminEmail =openAdminBlockPage[1]  ? openAdminBlockPage[1] : ''
    const openBlockPage =this.props.openBlockPage
    const Block = openAdminBlockPage[7] ===false ?   'Block' : 'Unlock'
    const MerchantEmail = openBlockPage[1]  ? openBlockPage[1] : ''
    const BlockMerchant =  openBlockPage[7] === false ?   'Block' : 'Unlock'

    return (
      <>
        <Translation>
          {(t) =>
            <>
      {openBlockPage.length?
        <div className='headDiv'>
          <div  className={'ModalPosition'}>
            <div className='ModalStyle'>
            <span>{`${t("Are you sure you want")} ${t(BlockMerchant)} ${t("Merchant's")}: ${MerchantEmail}`}</span>
              <div style={{marginLeft: '130px',marginTop:'10px'}}>
              <button style={styles.CanclePopupButtons} onClick={this.CancelBlockMerchantPopup}>{t("Cancel")}</button>
              <button style={styles.CanclePopupButtons} onClick={() => {this.blockMerchant(MerchantEmail,Block)}}>{t("Block")}</button>
            </div>
          </div>
        </div>
      </div>
          :

        openAdminBlockPage.length ?
          <div className='headDiv'>
            <div  className={'ModalPosition'}>
              <div className='ModalStyle'>
                <span>{`${t("Are you sure you want")} ${t(Block)} ${t("Admin's")}: ${AdminEmail}`}</span>
                <div style={{marginLeft: '130px',marginTop:'10px'}}>
                  <button style={styles.CanclePopupButtons} onClick={this.props.CancelBlockAdmin}>{t("Cancel")}</button>
                  <button style={styles.CanclePopupButtons} onClick={() => {this.blockAdmin(AdminEmail,Block)}}>{t("Block")}</button>
                </div>
              </div>
            </div>
          </div>

      :''
      }
            </>}</Translation>
          </>
    );
  }
}


const mapStateToProps = state => ({
  openBlockPage:state.MerchantesReducer.openBlockPage,
  openAdminBlockPage:state.adminReducer.openAdminBlockPage
})

export default connect(mapStateToProps, {
  CancelBlockAdmin,
  CloseAdminInfo,
  CloseBlockMerchant,
  CloseMerchantInfo,
  BlockMerchants,
  LockAdmin
})(BlockMerchant);
