import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {rentHistory} from "./rentHistoryReducer";
import * as api from "../../api";


function* historySagas() {
  yield takeLatest(rentHistory.RENT_HISTORY_REQUEST, hendleRent);
}

function* hendleRent(action) {
  try {
    const { data } = yield call(api.getUserRent,action.payload.phoneNumber);
    yield put({
      type: rentHistory.RENT_HISTORY_SUCCESS,
      payload: {data: data.reverse() }
    });
  } catch (e) {
    yield put({
      type: rentHistory.RENT_HISTORY_FAIL,
      message: e.message,
    });
  }
}

export default historySagas;
