import React from 'react';
import {InfoModalCard} from "../../../components/common/InfoModal/InfoModal";
import  {CardsComponent} from "./CardsComponent/CardsComponent";

export const UserCards = ({onClick,info }) => {
    return (
        <InfoModalCard
            info={'Card'}
            ClickClose={onClick}
            ModalSize={'Small'}
            position={'right'}
            titleText={'Cards'}
        >
            {info.length > 0 &&
                info.map((i) => (
                    <CardsComponent
                        props={i}
                    />
                ))
            }

        </InfoModalCard>
    )
}