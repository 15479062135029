import React, { Component } from "react";
import { connect } from "react-redux";
import {
  blockPowerBank,
  ClearBankInfo,
  DeletePowerBank,
} from "../../../modules/powerbanks/powerbanksAction";
import * as moment from "moment";
import { Translation } from "react-i18next";
import { InfoModalCard } from "../../../components/common/InfoModal/InfoModal";
import { DeleteModal } from "../../../components/common/modalPopup/DeleteModal";
import Comments from "../../../components/common/Comments";
import { PhotoControl } from "./PhotoControl/PhotoControl";
import { OutlinedButton } from "../../../components/common/Buttons/OutlinedButton";
import { takePowerBanke } from "../../../modules/stations/TakeBank/TakeBankAction";
import { UniversalConfirmModal } from "../../../components/common/Modals/UniversalConfirmModal";

class PowerBanksInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      showPhotoControl: false,
      blockModalActive: false
    };
  }

  shouldComponentUpdate(prevProps, prevState) {
    if (
      prevProps.BankFillInfo.blocked !== this.props.BankFillInfo.blocked ||
      prevProps.BankFillInfo.checkingStatus !== this.props.BankFillInfo.checkingStatus ||
      this.state.showPopup !== prevState.showPopup ||
      this.state.showPhotoControl !== prevState.showPhotoControl ||
      this.state.blockModalActive !== prevState.blockModalActive
    ) {
      return true;
    }
    return false;
  }

  handleDel = () => {
    this.props.DeletePowerBank(
      this.props.BankFillInfo.powerBankId,
      this.props.page
    );
    this.setState({ showPopup: false });
  };
  handleCloseComment = () => {
    this.props.ClearBankInfo();
    this.props.getPowerBanksList(this.props.page);
  };
  removePowerbank = (stationId, slotNumber) => {
    this.props.takePowerBanke(stationId, slotNumber);
  };

  onClickBlock = () => {
    this.setState({ blockModalActive: true })
  }
  onClickUnblock = () => {
    this.props.blockPowerBank(this.props.BankFillInfo.id, !this.props.BankFillInfo.blocked);
  }

  blockPowerBank = (value) => {
    this.props.blockPowerBank(this.props.BankFillInfo.id, !this.props.BankFillInfo.blocked, value);
    this.setState({ blockModalActive: false });
  };

  render() {
    const { showPopup } = this.state;
    const BankFillInfo = Object.values(this.props.BankFillInfo);
    const Role = localStorage.getItem("role");

    return (
      <Translation>
        {(t) => (
          <>
            {BankFillInfo.length > 0 ? (
              <InfoModalCard>
                <div className="sideHeader">
                  <span>{t("ID")}</span>
                  <strong>
                    {this.props.BankFillInfo.powerBankId
                      ? this.props.BankFillInfo.powerBankId
                      : "..."}
                  </strong>
                </div>
                {showPopup && (
                  <DeleteModal
                    boolean={showPopup}
                    text={`${t("Are you sure you want delete PowerBank")} : ${
                      this.props.BankFillInfo.powerBankId
                    }`}
                    Cancel={"Cancel"}
                    Delete={t("Delete")}
                    handleCancel={() => {
                      this.setState({ showPopup: false });
                    }}
                    handleDelete={() => {
                      this.handleDel();
                    }}
                  />
                )}
                {this.state.blockModalActive && (
                  <UniversalConfirmModal
                    firstButtonText={`${t("modal.confirm.no")}`}
                    header={`${t("modal.default.blockPowerbank")} ${
                      this.props.BankFillInfo.id
                    }?`}
                    isCommentRequired
                    deniedFn={() => this.setState({ blockModalActive: false })}
                    confirmFn={this.blockPowerBank}
                    secondButtonVisible={true}
                  />
                )}
                {!this.state.showPhotoControl ? (
                  <div className="UserModalInfo">
                    <div
                      style={{
                        display: "flex",
                        marginTop: "20px",
                        marginLeft: "-20px",
                      }}
                    >
                      <button className={"InfoButton"}>
                        <h3 style={{ color: "white", margin: 0 }}>
                          {t("INFO")}
                        </h3>
                      </button>
                    </div>
                    <span>{t("Registration date")}</span>
                    <p>
                      {this.props.BankFillInfo.created
                        ? `${moment
                            .tz(
                              this.props.BankFillInfo.created,
                              "YYYY-MM-DD/HH:mm",
                              "Europe/Moscow"
                            )
                            .local()
                            .format("DD.MM.YYYY/HH:mm")}`
                        : ""}
                    </p>
                    <span>{t("Battery Level")}</span>
                    <p>
                      {this.props.BankFillInfo.batteryLevelPercent && 
                        this.props.BankFillInfo.batteryLevelPercent >= 0 &&
                        this.props.BankFillInfo.batteryLevelPercent <= 100
                          ? `${this.props.BankFillInfo.batteryLevelPercent} %`
                          : t("Unknown")}
                    </p>
                    <hr className={"HrStyle"} />
                    <span>{t("Station ID")}</span>
                    <p>
                      {this.props.BankFillInfo.station.stationId
                        ? this.props.BankFillInfo.station.stationId
                        : t("Unknown")}
                    </p>
                    <span>{t("Station №")}</span>
                    <p>
                      {this.props.BankFillInfo.station.stationNumber
                        ? this.props.BankFillInfo.station.stationNumber
                        : t("Unknown")}
                    </p>
                    <span>{t("Station Address")}</span>
                    <p>
                      {this.props.BankFillInfo.station.address
                        ? this.props.BankFillInfo.station.address
                        : t("Unknown")}
                    </p>
                    <span>{t("Status")}</span>
                    <p>
                      {this.props.BankFillInfo.status
                        ? this.props.BankFillInfo.status
                        : t("Unknown")}
                    </p>

                    <OutlinedButton
                      onClick={() =>
                        this.removePowerbank(
                          this.props.BankFillInfo.station.stationId,
                          this.props.BankFillInfo.slot
                        )
                      }
                      color="green"
                    >
                      {t("PUT OUT POWERBANK")}
                    </OutlinedButton>

                    {/* <OutlinedButton
                      onClick={() => this.setState({ showPopup: true })}
                      color="red"
                    >
                      {t("DELETE POWERBANK")}
                    </OutlinedButton> */}
                    {Role !== "MERCHANT" &&
                      <OutlinedButton
                        onClick={!this.props.BankFillInfo.blocked ? this.onClickBlock : this.onClickUnblock}
                        color={this.props.BankFillInfo.blocked && Role === "OPERATOR" ? "disabled" : "red"}
                        disabled={Role === "OPERATOR" && this.props.BankFillInfo.blocked}
                      >
                        {this.props.BankFillInfo.blocked ? t("powerbanks.unblock") : t("powerbanks.block")}
                      </OutlinedButton>
                    }
                    <OutlinedButton
                      onClick={() => this.setState({ showPhotoControl: true })}
                      color="green"
                    >
                      {t("powerbanks.photoControl")}
                    </OutlinedButton>

                    {Role === "SUPER_ADMIN" && (
                      <Comments
                        handleClose={() => this.handleCloseComment()}
                        text={this.props.BankFillInfo.comment}
                        id={this.props.BankFillInfo.id}
                        name={"FOR_POWERBANK"}
                      />
                    )}
                  </div>
                ) : (
                  <InfoModalCard
                    ClickClose={() =>
                      this.setState({ showPhotoControl: false })
                    }
                  >
                    <PhotoControl id={this.props.BankFillInfo.id} />
                  </InfoModalCard>
                )}
              </InfoModalCard>
            ) : null}
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state) => ({
  BankFillInfo: state.powerbanksReducer.BankFillInfo,
  page: state.powerbanksReducer.page,
});

export default connect(mapStateToProps, {
  ClearBankInfo,
  DeletePowerBank,
  takePowerBanke,
  blockPowerBank,
})(PowerBanksInfo);
