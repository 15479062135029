import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { useDispatch } from "react-redux";

export const StatusLineChart = ({month, dataLostPowerbanks}) => {
    const dispatch = useDispatch();

    const [dates, setDates] = useState([]);
    const [values, setValues] = useState([]);
    const [data, setData] = useState({
        series: [{
            name: "",
            data: []
        }],
        options: {
          tooltip: {
            enabled: true,
            style: {
                fontSize: '13px'
            },
            theme: 'dark',
            x: {
                show: false
            }
          },
          chart: {
            toolbar: {
                show: false
            },
            height: 350,
            type: 'line',
            zoom: {
              enabled: false
            }
          },
          colors: ['#ecc53c'],
          grid: {
            show: true,
            borderColor: '#525252',
            yaxis: {
                lines: {
                    show: true
                }
            },
            xaxis: {
                lines: {
                    show: true
                }
            },
          },
          markers: {
            size: 4,
            strokeWidth: 0,
            colors: ['#ecc53c']
          },
          stroke: {
            curve: 'straight'
          },
          xaxis: {
            categories: [],
            axisBorder: {
                show: true,
                color: 'white',
                height: 2
            },
            axisTicks: {
                show: false
            },
            labels: {
                show: true,
                style: {
                    colors: "white",
                    fontSize: '12px'
                },
            },
          },
          yaxis: {
            axisBorder: {
                show: true,
                color: 'white',
                height: 2
            },
            labels: {
                show: true,
                style: {
                    colors: "white",
                    fontSize: '12px'
                },
                formatter: val => Math.floor(val),
            },
          },
        }
    });

    // // get saga data
    // useEffect(() => {
    //     const date = new Date();
    //     const localMonth = `${date.getMonth() < 9 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1}`;
    //     dispatch(changeMonthOnLostPowerbanksChart(localMonth));
    //     if(localMonth === '01' || localMonth === '03' || localMonth === '05' || localMonth === '07' || localMonth === '08' || localMonth === '10' || localMonth === '12') {
    //         dispatch(getLostPowerbanksInfo(`2023-${localMonth}-01`, `2023-${localMonth}-31`))
    //     } else if (localMonth === '02') {
    //         dispatch(getLostPowerbanksInfo(`2023-${localMonth}-01`, `2023-${localMonth}-28`))
    //     } else {
    //         dispatch(getLostPowerbanksInfo(`2023-${localMonth}-01`, `2023-${localMonth}-30`))
    //     }
    // }, [dispatch]);

    // set dates and values for chart
    useEffect(() => {
        setDates([]);
        setValues([]);
        let newValues = [];
        if(month === '01' || month === '03' || month === '05' || month === '07' || month === '08' || month === '10' || month === '12') {
            for(let day = 1; day <= 31; day++) {
                setDates(pv => [...pv, day.toString()]);
                newValues.push(0);
            }
            for (var key0 in dataLostPowerbanks) {
                newValues[new Date(key0).getDate() - 1] = dataLostPowerbanks[key0];
            }
            setValues(newValues);
        } else if (month === '02') {
            for(let day = 1; day <= 28; day++) {
                setDates(pv => [...pv, day.toString()])
                newValues.push(0);
            }
            for (var key1 in dataLostPowerbanks) {
                newValues[new Date(key1).getDate() - 1] = dataLostPowerbanks[key1];
            }
            setValues(newValues);
        } else if (month === '04' || month === '06' || month === '09' || month === '11'){
            for(let day = 1; day <= 30; day++) {
                setDates(pv => [...pv, day.toString()]);
                newValues.push(0);
            }
            for (var key2 in dataLostPowerbanks) {
                newValues[new Date(key2).getDate() - 1] = dataLostPowerbanks[key2];
            }
            setValues(newValues);
        }
    }, [month, dataLostPowerbanks]);

    // put dates and values inside chart data
    useEffect(() => {
        setData(pv => ({
            ...pv,
            series: [{
                ...pv.series,
                data: values
            }],
            options: {
                ...pv.options,
                xaxis: {
                    ...pv.options.xaxis,
                    categories: dates
                }
            }
        }))
    }, [dates, values]);

    return (
        <div className="lostPowerbanksChartMainScope">
            <ReactApexChart options={data.options} series={data.series} type="line" height={350} />
        </div>
    )
}