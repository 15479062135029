import React from "react";

import { ReactComponent as SearchIcon } from "./icons/search.svg";
import { ReactComponent as ClearIcon } from "./icons/clear.svg";

import "./styles/index.css";

export const SearchInput = ({ value, onChange, onClear }) => {
  return (
    <div className="search-container" tabIndex="1">
      <div className="search-wrapper" tabIndex="1">
        <SearchIcon className="search-icon" />
        <input value={value} onChange={onChange} className="search-input" />
        <ClearIcon onClick={onClear} className="search-clear-icon" />
      </div>
    </div>
  );
};
