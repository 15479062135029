import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {connect} from "react-redux";
import {TableSortLabel} from '@material-ui/core';
import {
  blockUserInfo,
  CechPromoStatus,
  FilterUserByActive,
  FilterUserByBlocked,
  FindUsersByPhoneNumber,
  getUserList,
} from "../../modules/users/usersActon";
import {sendUserInfo} from "../../modules/users/usersInfo/usersInfoAction";
import * as moment from "moment";
import {TableCEll} from "../../components/common/TableCEll";
import {Card} from "../../components/common/Card";
import {Translation} from 'react-i18next';
import { UsersStatus} from "../../components/common/Status/Statuss";
import {Page} from "../../components/common/Pages";
import DataList from "./Components/DataList";
import {Loader} from "../../components/common/Asyncs/Loader";
import {NotFound} from "../../components/common/Asyncs/NotFound";

// import UniversalTable from "../../components/common/UniversalTable";

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: '',
      activeIndex: '',
      closePage:true,
      page:0,
      openInfo:false,
      UsersList:'',
      allFilterButtons:[]
    }
  }

  componentDidMount() {
    this.props.getUserList(0);
    this.setState({
      allFilterButtons: [
        {name: 'All', fn: this.handleAll},
        {name: 'Active', fn: this.handleActive},
        {name: 'Blocked', fn: this.handleBlocked}
      ]
    });
  }
  FindeHandleChange = (ev) => {
    this.setState({phoneNumber: ev.target.value})
    if (ev.keyCode === 8) {
      if (this.state.phoneNumber.length === 0 || this.state.phoneNumber.length === 12 || this.state.phoneNumber.length === 24) {
        this.props.getUserList(this.state.page)
        this.setState({closePage:true})
      }
    }
  }
  handleClick = (i) => {
    this.setState({activeIndex: i})
  }
  ClosePopup = () => {
    this.setState({activeIndex: ''})
  }
  openInfo = (row) => {
    this.props.CechPromoStatus(row.id)
    this.props.sendUserInfo(row,this.state.page)
    this.setState({activeIndex: '',openInfo:true})
  }
  openTableInfo = (row) => {
    this.setState({activeIndex: ''})
    this.props.sendUserInfo(row)
  }
  sendFindData = () => {
    const {phoneNumber} = this.state
    if (phoneNumber.length > 10) {
      sessionStorage.setItem('filterButtons','all')
      this.props.FindUsersByPhoneNumber(phoneNumber)
      this.setState({closePage:false,})
    }
  }
  blockUser = (row) => {
    this.props.blockUserInfo(row,this.state.page)
    this.setState({activeIndex: ''})
  }

  handleChange =(event,value)=>{
    this.props.getUserList(value - 1)
    this.setState({UsersList:'',page:value - 1,closePage:true})
  }
  handleAll = () => {
    this.props.getUserList(this.state.page)
    this.setState({UsersList:'',closePage:true,phoneNumber:''})
  }
  handleActive=()=>{
    this.props.FilterUserByActive()
    this.setState({UsersList:'',closePage:false,phoneNumber:''})
  }
  handleBlocked=()=>{
    this.props.FilterUserByBlocked()
    this.setState({UsersList:'',closePage:false,phoneNumber:''})
  }
  handleRevert =()=>{
    this.setState({UsersList: this.props.usersList.reverse()})
  }

  render() {
    const {pageCount,userCount} = this.props.PageInfo
    const usersListArray =this.state.UsersList ?  this.state.UsersList : this.props.usersList
    return (
      <Translation>
        {(t) => <>
        <Card 
          TitleName={'Users'}
          Count={userCount} 
          search={true} 
          value={this.state.phoneNumber} 
          place={'Search users'}
          clearSearch={this.state.phoneNumber.length && this.handleAll }
          onClick={this.sendFindData} 
          onChange={this.FindeHandleChange} 
          onKeyDown={this.FindeHandleChange}
          FilterBoolean={true} 
          revert={this.handleRevert}
          allButtons={this.state.allFilterButtons}
        >
        {this.state.openInfo &&
        <DataList
          page={this.state.page}
          GetList={this.props.getUserList}
        />
        }
          <TableSortLabel/>
           <Table size="small">
           <TableHead style={{borderBottom: '1px solid grey',}}>
           <TableCEll text={['ID', 'Registration', 'Phone', '', 'E-mail', 'Status']}/>
          </TableHead>
          <TableCell style={{border: 'none', padding: '1px'}}>.</TableCell>
          {userCount === 0 ?   <NotFound/> : this.props.usersList === 'not found' ?  <NotFound/>  :
            usersListArray.length > 0 ?
            usersListArray.map((row, i) => (
              row !== undefined ? row.roles.length > 0 ? row.roles[0].role === 'USER' ?
                <TableBody key={i}>
                  {this.state.activeIndex === i ?
                    <div onMouseLeave={() => {this.setState({activeIndex: ''})}} className={'activeIndexStyle'}>
                      <div className={'activeIndexButtonsStyle'}>
                        <button onClick={() => {this.openInfo(row)}} className={'openInfoButton'}>{t("Open Info")}</button>
                        {row.blocking === false ?
                          <button onClick={() => {this.blockUser(row)}} className={'blockUSersButton'}>{t("Block user")}</button>
                          :
                          <button onClick={() => {this.blockUser(row)}} className={'ActiveUSersButton'}>{t("Active user")}</button>
                        }
                      </div>
                    </div> : ''}
                  <TableRow className={'BodyTableRow'} key={row.id ? row.id : i}>
                    <TableCell onClick={() => {this.openInfo(row, i)}} className={'BodyTableCellCheck'}>{row.id ? row.id.slice(-4) : '...'}</TableCell>
                    <TableCell onClick={() => {this.openInfo(row, i)}} className='BodyTableCell'>{row.createdDate ? `${moment.tz(row.createdDate, 'YYYY-MM-DD/HH:mm', 'Europe/Moscow').local().format('DD.MM.YYYY/HH:mm')}` : ''}</TableCell>
                    <TableCell onClick={() => {this.openInfo(row, i)}} className={'BodyTableCell'}>{row.phoneNumber ? row.phoneNumber.slice(0, 12) : '...'}</TableCell>
                    <TableCell onClick={() => {this.openInfo(row, i)}} className={'BodyTableCell'}>{}</TableCell>
                    <TableCell onClick={() => {this.openInfo(row, i)}} className={'BodyTableCell'}>{row.email ? row.email.slice(0, 20) : t('null')}</TableCell>
                       <UsersStatus
                         statusBoolean={row.blocking !== false }
                         Status={row.blocking === false ? 'Active' : 'Blocked' }
                         openTableInfo={()=>this.openTableInfo(row)}
                         onClick={()=>this.handleClick(i)} row={row}
                       />
                  </TableRow>
                </TableBody>
                : null : null : null)) : <Loader/>
          }
          </Table>
        {this.state.closePage &&
        <Page page={this.state.page + 1}
              handleChange={this.handleChange}
              PageCount={pageCount}/>}
      </Card>
        </>
        }
      </Translation>
    );
  }
}

const mapStateToProps = state => ({
  usersList: state.usersReducer.usersList,
  PageInfo: state.usersReducer.PageInfo,
})
export default connect(mapStateToProps, {CechPromoStatus,FilterUserByBlocked, FilterUserByActive, getUserList, FindUsersByPhoneNumber, sendUserInfo, blockUserInfo
})(Order);

// import React, { useEffect, useState } from 'react';
// import { getUserList } from "../../modules/users/usersActon";
// import { useDispatch, useSelector } from "react-redux";

// const Order = () => {
//   const dispatch = useDispatch();
//   const users = useSelector(state => state.usersReducer.usersList);
//   const [data, setData] = useState([]);
//   const columns = [{ title: 'ID' }, { title: 'ID', onCellClick: () => console.log('hello') }, { title: 'ID', onCellClick: () => console.log('hello') },{ title: 'ID', onCellClick: () => console.log('hello') }];

//   useEffect(() => {
//     dispatch(getUserList(0));
//   }, [dispatch])

//   useEffect(() => {
//     if (users.length) setData(users.map(({ id, createdDate, phoneNumber, email }) => ({ cells: [id, createdDate, phoneNumber, email] })))
//   }, [users])

//   return <UniversalTable columns={columns} data={data} />
// }

// export default Order;