import React, { useEffect, useState } from 'react';
import {Translation} from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { GetAllPromoTariffs, SetChosenPromoTariff } from '../../../modules/users/usersActon';

export const DeleteModal =({
  style,
  text,
  handleCancel,
  handleDelete,
  Cancel,
  Delete, 
  isActivePromo, 
  promoModal
}) => {
    const dispatch = useDispatch();
    const {allPromoTariffs, chosenPromoTariff} = useSelector(state => state.usersReducer);

    const [isShowTariffs, setIsShowTariffs] = useState(false);

    useEffect(() => {
      if(!isActivePromo && promoModal) {
        dispatch(GetAllPromoTariffs());
      }
    }, [promoModal, isActivePromo, dispatch]);

    const selectPromoTariff = (name, id) => {
      dispatch(SetChosenPromoTariff(name, id));
      setIsShowTariffs(false);
    }
    
    return (
      <Translation>
        {(t) =>
          <>
        <div className='headDiv'>
          <div  className='ModalPosition'>
            <div style={{...style}} className='ModalStyle'>
              {
                !isActivePromo && promoModal ?
                  <div className='promoTariffsBlock'>
                    <button 
                      onClick={() => setIsShowTariffs(pv => !pv)}
                      className='promoTariffsChoseButton'
                    >
                      {!chosenPromoTariff.name ? `${t('Choose a promo plan')}:` : chosenPromoTariff.name}
                    </button>
                    {
                      isShowTariffs &&
                      <div className='promoTariffsList'>
                        {
                          allPromoTariffs.map((item, key) => {
                            return(
                              <button 
                                key={key} 
                                onClick={() => selectPromoTariff(item.groupName, item.id)}
                              >
                                {item.groupName}
                              </button>
                            )
                          })
                        }
                      </div>
                    }
                  </div>
                : null
              }
              <span>{text}</span>
              <div className='ModalButtons'>
                <button onClick={() => handleCancel()}>{t(Cancel)}</button>
                <button onClick={() => handleDelete()}>{t(Delete)}</button>
              </div>
            </div>
          </div>
        </div>
          </>}</Translation>
    );
  }

