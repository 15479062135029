import {takeLatest, call, put} from "@redux-saga/core/effects";
import {GetQuestions} from "../GetPopularQuestionAction";
import {DELETE_USER} from "./DeleteQuestionReducer";
import * as api from "../../api";


function* handleSaga() {
  yield takeLatest(DELETE_USER.REQUEST,AddHandleSaga)
}
const Location = sessionStorage.getItem("QuestionLocal")
function* AddHandleSaga(action) {
  try {
    const {data} = yield call(api.DeleteQuestion,action.payload.id,)
    yield put({
      type:DELETE_USER.SUCCESS,
      payload:{data}
    })
 yield put(GetQuestions(Location))
  }catch (e) {
    yield put({
      type:DELETE_USER.FAIL,
      message:e.message
    })
  }
}
export default handleSaga
