import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {SUPPORT_REQUESTS} from './supportTextReducer'
import * as api from "../../api";

function* tariffSagas() {
  yield takeLatest(SUPPORT_REQUESTS.SUPPORT_REQUEST_REQUEST, hendleTarif);
}


function* hendleTarif(action) {
  try {
    const { data } = yield call(api.getSupport);
    yield put({
      type: SUPPORT_REQUESTS.SUPPORT_REQUEST_SUCCEED,
      payload: {data }
    });
  } catch (e) {
    yield put({
      type: SUPPORT_REQUESTS.SUPPORT_REQUEST_FAIL,
      message: e.message,
    });
  }
}

export default tariffSagas;