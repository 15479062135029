import React, {Component} from 'react';
import {connect} from "react-redux";
import {deleteUserInfo} from "../../../modules/users/usersInfo/usersInfoAction";
import {clearRentHistory, setRentHistoryItem} from "../../../modules/rents/rentHistory/rentHistoryActions";
import {deleteUserRentInfo} from "../../../modules/rents/rentUsersInfo/rentUsersInfoAction";
import {DeleteUsers} from "../../../modules/users/DeleteUsers/DeleteUsersAction";
import RentHistory from "../../Components/RentHistory/RentHistory";
import {blockUserInfo, ClearFindingData, GetUserCards, GivePromoToUser,} from "../../../modules/users/usersActon";
import moment from "moment";
import {DeleteModal} from "../../../components/common/modalPopup/DeleteModal";
import {Translation} from "react-i18next";
import {CLearFindingRentInfo} from "../../../modules/rents/rentsAction";
import {InfoSwitch} from "../../../components/common/InfoModal";
import {InfoModalCard} from "../../../components/common/InfoModal/InfoModal";
import usersReducer from "../../../modules/users/usersReducer";
import Comments from "../../../components/common/Comments";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import {UserCards} from "./UserCards";

class DataList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfo: false,
      blockButton:false,
      showModal:false,
      Promo:false,
      cardInfo:false
    }
  }


  CloseSection = () => {
    this.setState({showInfo: false})
    this.props.clearRentHistory()
    this.props.deleteUserInfo()
    this.props.deleteUserRentInfo()
    const InfoForClose =sessionStorage.getItem('InfoForClose')
    if(InfoForClose === 'CloseStation') {
      this.props.CLearFindingRentInfo()
      sessionStorage.removeItem('InfoForClose')
    }
    if( InfoForClose === 'CloseUser'){
      this.props.ClearFindingData()
      sessionStorage.removeItem('InfoForClose')
    }
  }
  SetSecondColor = (phoneNumber) => {
    this.props.setRentHistoryItem(phoneNumber)
    this.setState({showInfo: true})
  }
  blockUser = () => {
    this.props.blockUserInfo(this.props.UsersInfo)
    this.setState((prevState) => ({blockButton: !prevState.blockButton,}))
    this.props.deleteUserInfo()
  }
  DeleteUserPopup = () => {
    const phoneNumber = this.props.UsersInfo.phoneNumber
    this.props.DeleteUsers(phoneNumber,this.props.page)
    this.setState({showModal:false})
    this.CloseSection()
  }
  GiveUserPromo=()=>{
    const id = this.props.UsersInfo.id
    const bool = this.props.AboutPromo !== true
    const tariff = this.props.chosenPromoTariff.id
    let data = this.props.AboutPromo === false ? [id,bool,tariff] : [id,bool]
    this.props.GivePromoToUser(data)
    this.setState({showModal:false,Promo:false})
    this.CloseSection()
  }
  CloseModal=()=>{
    this.setState({showModal: false,Promo:false})
    this.props.clearRentHistory()
  }
  CloseCommentModal=()=>{
    this.CloseSection()
    this.props.GetList(this.props.page)
  }
  handleCheckCards =()=> {

    this.props.GetUserCards(this.props.UsersInfo.id)
this.setState({cardInfo:true})
  }

  render() {
    const {showInfo,cardInfo} = this.state
    const usersListArray = Object.values(this.props.UsersInfo)
    const RegistrationDate =this.props.UsersInfo.createdDate ? this.props.UsersInfo.createdDate : ''
    const RegistrationTime = `${moment.tz(RegistrationDate,'YYYY-MM-DD/HH:mm','Europe/Moscow').local().format('DD.MM.YYYY/HH:mm')}`
    const {showModal,Promo}=this.state
    let ShowModal = showModal === true ? showModal : Promo === true ?Promo :''
    let textForModal = showModal === true ? "Are you sure you want delete User" : Promo === true ?
      this.props.AboutPromo === false ? "Are you sure you want Give a Promo User" : 'Are you sure you want Stop a Promo User' :''
    let infoAboutUser = showModal === true ? this.props.UsersInfo.id : Promo === true ?  this.props.UsersInfo.phoneNumber  :''
    const Role = localStorage.getItem('role')
    
    return (
        <Translation>{(t) => <>
        {usersListArray.length > 0 ?
          <InfoModalCard ClickClose={this.CloseSection} ModalSize={'Small'} position={'right'} >
          <div style={{marginBottom:'20px'}}>
            {ShowModal &&
              <DeleteModal 
                boolean={showModal}
                text={`${t(textForModal)} : ${infoAboutUser}`} 
                Cancel={'Cancel'}
                Delete={t(showModal === true ? 'Delete' : Promo === true ?
                  this.props.AboutPromo === false ? !!this.props.chosenPromoTariff.id ? 'Give' : '' : 'Stop' :'')}
                handleCancel={this.CloseModal} handleDelete={Promo === true ? this.GiveUserPromo : this.DeleteUserPopup}
                promoModal={this.state.Promo}
                isActivePromo={this.props.AboutPromo}
              />
            }
            <div className={'UserModalInfo'}>
              <span>{t("ID")}</span>
              <p>{this.props.UsersInfo.id}</p>
            </div>
            <div style={{marginLeft:'-20px',}}>
              <InfoSwitch
                ShowReport={this.state.showInfo}
                FirstText={'INFO'} SecondText={'HISTORY'}
                Show={() =>this.setState({showInfo: false})}
                Close={() => {this.SetSecondColor(this.props.UsersInfo.phoneNumber)}}
              />
            </div>


            {cardInfo === true ?
           <UserCards
               info={this.props.UserCard}
               onClick={()=>this.setState({cardInfo:false})}
           />

            :


            showInfo === false ?

                  //  Todo change this part
                  <div key={this.props.UsersInfo.id} className={'UserModalInfo'} >
                    <span>{t("REGISTRATION DATE")}</span>
                    <p>{`${RegistrationTime}`}</p>
                    <span>{t('E-Mail')}</span>
                    <p>{this.props.UsersInfo.email ? this.props.UsersInfo.email : t('null')}</p>
                    <span>{t('PHONE')}</span>
                    <p>{this.props.UsersInfo.phoneNumber} </p>
                    <span>{t('CARDS')}</span>
                    <button
                        title={'Cards'}
                        className={'CreditCardsButton'}
                        onClick={() => this.handleCheckCards()}>
                      <CreditCardIcon color={'primary'}
                      />
                    </button>
                    <span>{t('STATUS')}</span>
                    {this.props.UsersInfo.blocking === false ?
                      <p style={{color: 'rgba(101, 227, 42, 0.87)'}}>{t('Active')}</p> :
                      <p style={{color: '#EB5B47'}}>{t('Blocked')}</p>}
                    {Role === 'SUPER_ADMIN' &&
                    <button className={ this.props.AboutPromo === false ? 'GivePromo' : 'GivePromoDisable'}
                            onClick={() => this.setState({Promo: true})}
                    >{this.props.AboutPromo === false ? t('Give Promo') : t('Stop Promo')}</button>
                    }
                      {this.props.UsersInfo.blocking  === false ?
                      <button onClick={() => {this.blockUser(this.props.UsersInfo)}} className={'DeleteUserButton'}>{t("BLOCK USER")}</button>
                      :
                      <button onClick={() => {this.blockUser(this.props.UsersInfo.phoneNumber, this.props.UsersInfo.blocking)}} className={'ActiveUserButton'}>{t("RESTORE USER")}</button>
                    }
                    <button onClick={() => {this.setState({showModal:true})}} className={'DeleteUserButton'}>{t("DELETE USER")}</button>
                  </div>
                  :
                  <RentHistory/>
                }
            {Role === 'SUPER_ADMIN' &&

            <Comments
              handleClose={() =>this.CloseCommentModal()}
              text={this.props.UsersInfo.comment}
              id={this.props.UsersInfo.id}
              name={'FOR_USER'}
            />
            }
          </div>
          </InfoModalCard>
          : '' }
          </>}</Translation>
    );
  }
}
const mapStateToProps = state => ({
  UsersInfo: state.usersInfoReducer.UsersInfo,
  page: state.usersInfoReducer.page,
  AboutPromo: state.usersReducer.AboutPromo,
  UserCard: state.usersReducer.UserCard,
  chosenPromoTariff: state.usersReducer.chosenPromoTariff
})

export default connect(mapStateToProps, {GetUserCards,GivePromoToUser,clearRentHistory, CLearFindingRentInfo, ClearFindingData, deleteUserInfo, blockUserInfo, setRentHistoryItem, deleteUserRentInfo, DeleteUsers,
})(DataList);