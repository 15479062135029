import { put, call, takeLatest } from "@redux-saga/core/effects";
import * as api from "../api";
import { DASHBOARD_ACTIONS } from "./dashboardReducer";

export function* getPowerbanksDashboardInfoWatcher() {
    yield takeLatest(DASHBOARD_ACTIONS.GET_POWERBANKS_DASHBOARD_INFO_REQUEST, getPowerbanksDashboardInfoWorker)
}
  
function* getPowerbanksDashboardInfoWorker() {
    try {
        const {data} = yield call(api.GetPowerbanksDashboardInfo)
        yield put({
        type: DASHBOARD_ACTIONS.SET_POWERBANKS_DASHBOARD_INFO,
        payload: data
        })
    }
    catch (e) {
        console.log(e)
    }
}

export function* getMerchantsDashboardInfoWatcher() {
  yield takeLatest(DASHBOARD_ACTIONS.GET_MERCHANTS_DASHBOARD_INFO_REQUEST, getMerchantsDashboardInfoWorker);
}
  
function* getMerchantsDashboardInfoWorker() {
  try {
    const {data} = yield call(api.GetMerchantsDashboardInfo)
    yield put({
      type: DASHBOARD_ACTIONS.SET_MERCHANTS_DASHBOARD_INFO,
      payload: data
    })
  }
  catch (e) {
    console.log(e)
  }
}

export function* getLostPowerbanksInfoWatcher() {
  yield takeLatest(DASHBOARD_ACTIONS.GET_LOST_POWERBANKS_INFO_REQUEST, getLostPowerbanksInfoWorker);
}
  
function* getLostPowerbanksInfoWorker(action) {
  try {
    const {data} = yield call(api.GetLostPowerbanksInfo, action.payload.startDate, action.payload.endDate,
        action.payload.merchant, action.payload.station)
    yield put({
      type: DASHBOARD_ACTIONS.SET_LOST_POWERBANKS_INFO,
      payload: data
    })
  }
  catch (e) {
    console.log(e)
  }
}
  
export function* sortPowerbanksStatusesDashboardInfoByMerchantWatcher() {
  yield takeLatest(DASHBOARD_ACTIONS.SORT_POWERBANKS_STATUSES_DASHBOARD_INFO_BY_MERCHANT_REQUEST, sortPowerbanksStatusesDashboardInfoByMerchantWorker);
}

function* sortPowerbanksStatusesDashboardInfoByMerchantWorker(action) {
  try {
    const {data} = yield call(api.GetPowerbanksDashboardInfoSortByMerchant, action.payload.merchantEmail)
    yield put({
      type: DASHBOARD_ACTIONS.SORT_POWERBANKS_STATUSES_DASHBOARD_INFO_BY_MERCHANT,
      payload: data
    })
  }
  catch (e) {
    console.log(e)
  }
}

export function* sortPowerbanksConditionsDashboardInfoByMerchantWatcher() {
    yield takeLatest(DASHBOARD_ACTIONS.SORT_POWERBANKS_CONDITIONS_DASHBOARD_INFO_BY_MERCHANT_REQUEST, sortPowerbanksConditionsDashboardInfoByMerchantWorker);
}

function* sortPowerbanksConditionsDashboardInfoByMerchantWorker(action) {
  try {
      const {data} = yield call(api.GetPowerbanksDashboardInfoSortByMerchant, action.payload.merchantEmail)
      yield put({
        type: DASHBOARD_ACTIONS.SORT_POWERBANKS_CONDITIONS_DASHBOARD_INFO_BY_MERCHANT,
          payload: data
        })
      }
      catch (e) {
    console.log(e)
  }
}

export function* getAllStationsOnMapWatcher() {
    yield takeLatest(DASHBOARD_ACTIONS.GET_ALL_STATIONS_ON_MAP, getAllStationsOnMapWokrer);
}

function* getAllStationsOnMapWokrer() {
  try {
      const {data} = yield call(api.GetStationsOnMap)
      yield put({
        type: DASHBOARD_ACTIONS.SET_ALL_STATIONS_ON_MAP,
          payload: data
        })
      }
      catch (e) {
    console.log(e)
  }
}

export function* getStationInfoOnMapWatcher() {
    yield takeLatest(DASHBOARD_ACTIONS.GET_STATION_INFO_ON_MAP, getStationInfoOnMapWokrer);
}

function* getStationInfoOnMapWokrer(action) {
  try {
      const {data} = yield call(api.GetStationInfoOnMap, action.payload)
      yield put({
        type: DASHBOARD_ACTIONS.SET_STATION_INFO_ON_MAP,
          payload: data
        })
      }
      catch (e) {
    console.log(e)
  }
}