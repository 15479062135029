import {ADD_TRANSACTIONS} from "./AddTransactionsReducer";


export const AddTransactions=(phoneNumber,description,price,rentId,transactionStatus)=>{
  return{
    type:ADD_TRANSACTIONS.TRANSACTIONS_REQUEST,
    payload:{phoneNumber,description,price,rentId,transactionStatus}
  }
}
export const EditTaransaction=(transactionId,description,price,rentId,transactionStatus)=>{
  return{
    type:ADD_TRANSACTIONS.EDIT_TRANSACTIONS_REQUEST,
    payload:{transactionId,description,price,rentId,transactionStatus}
  }
}


export const OpenTransactions=(data)=>{
  return{
    type:ADD_TRANSACTIONS.OPEN_TRANSACTION,
    payload:{data}
  }
}

export const CloseTransactions=( )=>{
  return{
    type:ADD_TRANSACTIONS.CLOSE_TRANSACTION,
  }
}

export const openTar =(data)=>{
  return{
    type:ADD_TRANSACTIONS.OPEN_TRANS ,
    payload:{data}
  }
}

export const CloseInfoPopup =( )=>{
  return{
    type:ADD_TRANSACTIONS.ClOSE_POPUP ,
  }
}
