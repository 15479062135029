import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { OutlinedButton } from "../Buttons/OutlinedButton";
import { UniversalTextField } from "../Inputs/UniversalTextField";
import "./styles/index.css";

export const UniversalConfirmModal = ({
  header,
  confirmFn,
  deniedFn,
  isCommentRequired,
  secondButtonVisible,
  firstButtonText
}) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState("");
  const validateFn = (fn) => {
    if (!fn) return;
    if (isCommentRequired) return fn(comment);
    return fn();
  };
  const handleChange = (e) => setComment(e.target.value);
  return (
    <div className="backgroundFrame">
      <div className="modalAlign">
        <div className="modalContainer">
          <span>
            {header ? header : t("modal.default.blockPowerbankDefault")}
          </span>
          {isCommentRequired ? (
            <UniversalTextField
              value={comment}
              label={t("modal.confirm.commentLabel")}
              onChange={handleChange}
              placeholder={t("modal.confirm.commentPlaceholder")}
            />
          ) : (
            false
          )}
          <div className="modalButtonWrapper">
            <OutlinedButton onClick={validateFn.bind({}, deniedFn)} color="red">
              {firstButtonText}
            </OutlinedButton>

            { 
              secondButtonVisible &&
              <OutlinedButton
                onClick={validateFn.bind({}, confirmFn)}
                color="green"
                disabled={!comment.trim()}
              >
                {t("modal.confirm.yes")}
              </OutlinedButton>
            }
          </div>
        </div>
      </div>
    </div>
  );
};
