import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {AGREEMENT_DATA} from "./agreementReducer";
import * as api from "../../api";


function* agreementSaga() {
  yield takeLatest(AGREEMENT_DATA.AGREEMENT_DATA_REQUEST, hendleTarif);
}


function* hendleTarif(action) {
  try {
    const { data } = yield call(api.getAgreement,);
    yield put({
      type: AGREEMENT_DATA.AGREEMENT_DATA_SUCCESS,
      payload: {data }
    });
  } catch (e) {
    yield put({
      type: AGREEMENT_DATA.AGREEMENT_DATA_FAIL,
      message: e.message,
    });
  }
}

export default agreementSaga;