import React from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../../utiles/formatDate";

const PhotoCard = ({ createdAt, status, image, onCardClick }) => {
  const { t } = useTranslation();

  return (
    <div className="powerbankCard" onClick={onCardClick}>
      <div className="powerbankImage">
        <img src={image} alt="powerbank img" />
      </div>
      <div className="cardFooter">
        <div className="infoBlock">
          <p className="infoBlockHeader">{t("default.date")}</p>
          <p className="infoBlockBody">{formatDate(createdAt)}</p>
        </div>
        <div className="infoBlock">
          <p className="infoBlockHeader">{t("default.status")}</p>
          <p className="infoBlockBody">{status}</p>
        </div>
      </div>
    </div>
  );
};

export default PhotoCard;
