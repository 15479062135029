import {put,call, takeLatest} from "@redux-saga/core/effects";
import * as api from "../../api";
import {STATES_DEL} from "./DeleteStationReducer";
import {GetStation} from "../stationsAction";


function* handleDelSaga() {
  yield takeLatest(STATES_DEL.DELETE_STATION_REQUEST,StationDelSaga)
}

function* StationDelSaga(action) {
  try {
    const {data} = yield call(api.DeleteStation,action.payload.stationNumber)
    yield put({
      type:STATES_DEL.DELETE_STATION_SUCCESS,
      payload:{data}
    })
    yield put(GetStation(action.payload.page))
  }
  catch (e) {
    yield put({
      type:STATES_DEL.DELETE_STATION_FAIL,
      message:e.message
    })
  }

}

export default handleDelSaga
