import React, {Component} from 'react';
import Title from "../../components/common/TabbleText/AllTitle";
import CloseVector from "../../assets/images/backImg.png";
import {Link, withRouter} from "react-router-dom";
import {Translation} from "react-i18next";


const styles = {
  head: {
    overflow: 'auto',
    width: '100%',
    height: '100vh',
    left: 0,
    top: 0,
    background: '#121212',
    position: 'fixed',
    zIndex: 9999
  },
  BodyTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
    color: 'rgba(255, 255, 255, 0.87)',
  },
  Body: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
    color: 'rgba(255, 255, 255, 0.87)',
  }
}

class licens extends Component {

  render() {
    const token = localStorage.getItem('token')

    return (
      <Translation>
        {(t) =>
          <>
      <div style={styles.head}>
         <div style={{width: '95%',margin:'0 auto'}}>
           <div style={{marginTop:'20px'}}>
           <Link to={token ? '/' : '/merchantReg'} ><img style={{width: '16px'}} src={CloseVector} alt={CloseVector}/></Link>
           </div>
           <Title style={{textAlign: 'center'}} Text={t('LICENSE AGREEMENT')}/>
          <p style={styles.BodyTitle}>ЛИЦЕНЗИОННОЕ СОГЛАШЕНИЕ для партнеров сервиса Chargex</p>
          <p style={styles.BodyTitle}>1. ОСНОВНЫЕ ПОНЯТИЯ </p>
          <p style={styles.Body}>
            1.1. Для целей настоящего Лицензионного соглашения (далее - Соглашение) перечисленные ниже термины имеют следующие значения:<br/>
             Сервис – сервис по поиску (выбору), бронированию и аренде Портативных зарядных устройств. Услуги сервиса предоставляются зарегистрированным и авторизованным пользователям с помощью сайта и мобильного приложения в режиме двадцать четыре часа, семь дней в неделю, без выходных и праздничных дней.<br/>
             Сайт – принадлежащий Администратору Сервиса составной (сложный) объект интеллектуальной собственности в составе программ для ЭВМ и других программных средств, баз данных, графического контента и других произведений, объединенных для обеспечения функционирования Сайта и использования его возможностей пользователями; доступ к Сайту осуществляется посредством сети Интернет; Сайт расположен на доменном имени http://chargex.am/.<br/>
             Приложение – принадлежащая Администратору программа ЭВМ для использования Сервиса на мобильных устройствах, распространяемая на платформах Apple Store и Google Play. Программа предоставляет такие функции как: регистрация Пользователя, доступ в личный кабинет; поиска на карте терминалов и расположенных в терминалах портативных зарядных устройств; получение и возврат портативных зарядных устройств; пополнение счета; просмотр статистики и получения необходимой информации для пользования зарядным устройством.<br/>
             Бот – программа ЭВМ для использования Сервиса на мобильных устройствах в приложении Telegram.  <br/>
             Программы ЭВМ Сервиса – принадлежащие Администратору Сайт, Приложение и Бот, предоставляющие Пользователям возможность использовать Сервис.<br/>
             Администратор Сервиса, Администратор – ООО “Займикс”, ИНН: 03546136, электронный адрес: d.oganyan@zaimix.am, юридический адрес: 2412, Республика Армения, Котайкская обл., п. Нор-Ачин, ул. Исаакяна, д. 8, кв. 16. Владелец сайта http://chargex.am/, мобильных приложений (Chargex) на платформах Apple Store и Google Play.<br/>
             Оператор - Общество с ограниченной ответственностью Небанковская Кредитная организация "Юмани" (ОГРН 1127711000031, ИНН 7750005725, лицензия на осуществление банковских операций № 3510-К), предоставляющее возможность в рамках взаимодействия с Сервисом «ЮKassa» осуществлять расчеты Партнера и Пользователей, Партнера и Агента при использовании Сервиса chargex.am. <br/>
             Агент - Общество с ограниченной ответственностью «ЧАРДЖЭКС» (ОГРН 1103123010440, ИНН 3123217915), являющееся представителем Администратора на территории Российской Федерации, именуемое в отношениях с Оператором Администратором Платформы, Агентом. Агент вправе реализовывать все права Администратора на территории Российской Федерации.<br/>
             Партнер – юридическое лицо, индивидуальный предприниматель, физическое лицо (в том числе, применяющее специальный налоговый режим “Налог на профессиональный доход”), приобретающее неисключительную лицензию на использование Программ ЭВМ Сервиса на основании настоящего Соглашения.<br/>
             В целях настоящего Лицензионного соглашения под определением «Партнер» также понимаются лица, аффилированные с Партнером, контролирующие или контролируемые лица, иным образом связанные с Партнером лица, его правопреемники, а также любые иные лица, выступающие от имени и в интересах Партнера.<br/>
             Личный кабинет Пользователя – страница Пользователя на Сайте и в Приложении, содержащая необходимую информацию о прокате портативного зарядного устройства для конкретного Пользователя.<br/>
             Личный кабинет Партнера – страница Партнера на Сайте и в Приложении, предоставляющая возможность стать Партнером Сервиса; зарегистрировать Терминал в Сервисе, просматривать / добавлять / редактировать список Терминалов и информацию о них; получать список транзакций по аренде ПЗУ в Терминалах Партнера; получать статистику использования ПЗУ и Терминалов Партнера, свободную информацию по всем разделам личного кабинета; знакомиться с актуальным текстом настоящего Соглашения и другими документами Сервиса, а также иную информацию и другие возможности. <br/>
             Регистрация – заполнение Пользователем или Партнером регистрационной формы на Сайте, в Приложении или в Боте. Регистрация предоставляет Пользователю и Партнеру использовать Сервис.<br/>
             Пользователь – физическое лицо, пользующееся услугами Сервиса.<br/>
             Портативное зарядное устройство, ПЗУ – портативный аккумулятор «Powerbank Chargex» с тремя проводами для накопления, хранения и передачи энергии на другие электронные устройства. ПЗУ являются собственностью Партнеров Администратора.<br/>
             Терминал - принадлежащее Партнеру устройство, обеспечивающее прием и выдачу Пользователю Портативных зарядных устройств, их зарядку. Терминалы являются собственностью Партнеров Администратора.<br/>
             Ликвидность ПЗУ в Терминале – необходимое количество ПЗУ в Терминале - не менее 40% и не более 80% от полной вместительности Терминала.<br/>
             Торговые марки Администратора – названия, знаки, коммерческие обозначения, зарегистрированные торговые марки, фирменные наименования, аббревиатуры, торговое оформление, доменные имена, графические и иные символы, логотипы, элементы звукового и/или видео ряда, а также иные элементы брэнд-стиля, которые встречаются в реквизитах, продуктах и маркетинговых материалах Администратора или в материалах аффилированных с ним лиц, включая все создаваемые в Период действия Соглашения производные и модификации от указанных объектов.<br/>
             Период действия Соглашения – временной интервал от момента вступления в силу настоящего Соглашения до момента его расторжения.<br/>
             Территория действия Соглашения – настоящее Соглашение действует на всей территории Российской Федерации и Республика Армения.<br/>
          </p>
          <p style={styles.BodyTitle}>2. ОБЩИЕ ПОЛОЖЕНИЯ </p>
          <p style={styles.Body}>
            2.1. Настоящее Соглашение регулирует отношения между Администратором и Партнером Сервиса «Chargex» по предоставлению Администратором Партнеру права пользования Программами ЭВМ Сервиса и подключения к Сервису, Торговыми марками Администратора в целях предоставления Пользователям права временного пользования Портативными зарядными устройствами из сети Сервиса «Chargex»  и Терминалами Партнера.<br/>
            2.2. Сервис «Chargex», включая Сайт, Приложение и Бот, является интеллектуальной собственностью Администратора.<br/>
            2.3. Настоящее Соглашение является публичной офертой. Для присоединения к условиям настоящего Соглашения Партнер акцептует оферту путем принятия условий Соглашения, в соответствии с положениями статей 437, 438 Гражданского кодекса Российской Федерации.<br/>
            2.4. При этом офертой Администратора Сервиса следует считать текст настоящего Соглашения, а акцептом Партнера – оформление через Сайт или Приложение заявки на присоединение к сети партнеров Сервиса.<br/>
            Администратор вправе отказать Партнеру в заключении Соглашения без объяснения причин, направив уведомление на адрес электронной почты Партнера, указанный в заявке на присоединение.<br/>
            2.5 Датой заключения настоящего Соглашения между Администратором и Партнером является дата предоставления Партнеру доступа к личному кабинету Партнера.<br/>
            Все документы/уведомления/распоряжения, размещенные/совершенные Партнером в Личном кабинете, признаются подлинными, целостными, равнозначными документам/уведомлениями на бумажном носителе, которые удостоверены собственноручной подписью уполномоченного лица Партнера и направлены Администратору.<br/>
            Любые действия, совершенные с использованием Личного кабинета, признаются совершенными Партнером.<br/>
            Размещение Администратором информации в Личном кабинете признается надлежащим уведомлением Партнера о юридически значимых действиях и событиях.<br/>
            Скриншоты экрана и выгрузки данных из Программам ЭВМ Сервиса являются надлежащими доказательствами содержания Личного кабинета Партнера и информации об использовании Программ ЭВМ и Сервиса Партнером.<br/>
            Партнер обязан хранить свои авторизационные данные в тайне.<br/>
            Администратор не несет ответственности за убытки и иные неблагоприятные последствия для Партнера, возникшие в результате доступа неуполномоченных Партнером лиц к Личному кабинету в результате разглашения или утраты Партнером его авторизационных данных.<br/>
            2.6. Пользование Партнером услугами Сайта и Приложения в любой форме (любых его ресурсов и функций) также является подтверждением того, что:<br/>
            § Партнер ознакомился с действующими условиями настоящего Соглашения в полном объеме до начала использования Сервиса;<br/>
            § Партнер принимает все условия настоящего Соглашения в полном объеме без каких-либо изъятий и ограничений и обязуется соблюдать их либо незамедлительно прекратить использование Сервиса в любой форме.<br/>
            2.7 Соглашение (в том числе любая из его частей) может быть изменен Администратором в одностороннем порядке. Новая редакция Соглашения вступает в силу с момента ее размещения на Сайте и в Приложении, либо доведения до сведения Партнера в иной удобной форме, если иное не предусмотрено новой редакцией Соглашения.<br/>
            2.8 Отслеживание фактов внесения изменений в условия Соглашения является обязанностью Партнера.<br/>
          </p>
          <p style={styles.BodyTitle}>
            3. ПРЕДМЕТ СОГЛАШЕНИЯ<br/>
            В рамках настоящего Соглашения:</p>

          <p style={styles.Body}>
            3.1. Администратор обязуется:<br/>
            § предоставить Партнеру на условиях простой неисключительной лицензии право пользования Программами ЭВМ Сервиса в установленных настоящим Соглашением пределах;<br/>
            § подключить Партнера к Сервису в целях предоставления Пользователям за плату право временного пользования принадлежащих ему Портативных зарядных устройств и Терминалов;<br/>
            § от своего имени заключать с Пользователями соглашения на право временного пользования Портативными зарядными устройствами и Терминалами Партнера за плату на основании Пользовательского соглашения Сервиса.<br/>
            3.2. Партнер обязуется:<br/>
            § предоставлять Пользователям во временное пользование свои Портативные зарядные устройства и Терминалы в пользование Пользователям Сервиса;<br/>
            § оплачивать Администратору вознаграждение за пользование Программами ЭВМ Сервиса и подключение к Сервису, пользование Торговыми марками Администратора, указанное в п. 6.2 настоящего Соглашения.<br/>
            3.3. При присоединении к настоящему Соглашению Партнер предоставляет свои ПЗУ в общую сеть ПЗУ Сервиса. Находясь в общей сети Сервиса, ПЗУ Партнера попадают в Терминалы других Партнеров Сервиса. При этом, ПЗУ Партнера могут оставаться во владении других партнеров Сервиса и не возвращаться во владение Партнера - собственника ПЗУ. При расторжении (прекращении действия) настоящего Лицензионного соглашения ПЗУ, находящиеся в терминалах Партнера на дату расторжения (прекращения действия) Лицензионного соглашения, переходят в собственность Партнера (остаются в его собственности), а ПЗУ, находящиеся во владении других партнеров Сервиса, переходят (остаются) в их собственности. Партнер не вправе уменьшать количество ПЗУ в сети Сервиса, в противном случае обязан выплатить Администратору (Агенту) стоимость извлеченных из сети Сервиса ПЗУ.<br/>
            3.4. Передача Партнером третьим лицам предоставленных Партнеру по настоящему Лицензионному соглашению прав не допускается, в том числе на основании сублицензионных договоров.<br/>
          </p>
          <p style={styles.BodyTitle}>4. ПРАВА И ОБЯЗАННОСТИ СТОРОН</p>

          <p style={styles.Body}>
            4.1. Администратор обязан:<br/>
            4.1.1. Оказывать Партнерам и Пользователям техническую поддержку, касающуюся использования Программой ЭВМ Сервиса, в течение срока действия настоящего Соглашения и Пользовательского Соглашения по вопросам, связанным с функциями Сервиса, особенностями его эксплуатации.<br/>
            4.1.2. Своевременно устранять возможные программные сбои в работе Программ ЭВМ Сервиса по заявкам Партнера. Партнер соглашается с тем, что никакое программное обеспечение Программ ЭВМ Сервиса не свободно от ошибок, Программы ЭВМ Сервиса предоставляются в пользование «как есть» («АS IS»). Администратор не дает гарантий, что Программы ЭВМ Сервиса будут работать на любом оборудовании, на любой рабочей станции, совместно с любым другим приложением и/или программным обеспечением третьих лиц без возникновения ошибок.<br/>
            4.1.3. Немедленно информировать Партнера о возникновении претензий третьих лиц, которые могут быть обращены на Партнера или (в случае удовлетворения) могут привести к недействительности любого из условий настоящего Соглашения и любым образом повлиять на возможность использования Сервиса Пользователями.<br/>
            Администратор не гарантирует, что произведенное третьими лицами программное обеспечение или любые другие материалы и средства, применяемые для использования Сервиса абсолютно защищены от компьютерных вирусов, других вредоносных компонентов и не подвержены взлому.<br/>
            4.2. Администратор имеет право:<br/>
            4.2.1. Приостановить или прекратить Партнеру доступ к Программам ЭВМ Сервиса и Сервису в случае нарушения Партнером условий настоящего Соглашения.<br/>
            4.2.2. Требовать от Партнера установки Терминалов и Портативных зарядных устройств только определенных Администратором марок, поддерживать удовлетворительное состояние Терминалов и ПЗУ, ремонтировать или заменять неисправные или утратившие товарный вид Терминалы и ПЗУ.<br/>
            4.2.3. По своему усмотрению наносить на ПЗУ и Терминалы Партнера свои Торговые марки, а также торговые марки третьих лиц.<br/>
            4.2.4. Приостанавливать Партнеру доступ к Программам ЭВМ Сервиса и Сервису на время, необходимое для проведения профилактических и иных технически необходимых работ, предварительно уведомив Партнера по адресу электронной почты о таком приостановлении не позднее, чем за 1 (Один) день до даты приостановления.<br/>
            4.2.5. Добавлять или изымать Портативные зарядные устройства в (из) Терминал(а), в случае если Ликвидность ПЗУ в Терминале не соответствует установленным требованиям - не менее 40% и не более 80% от полной вместительности Терминала.<br/>
            4.2.6. Предоставить имущество, оборудование, материалы и документы для выполнения обязательств по настоящему Лицензионному соглашению Партнером.<br/>
            4.2.7. Осуществлять контроль за качеством оказываемых услуг Партнером с использованием Сервиса «Chargex» и за исполнением условий настоящего Лицензионного соглашения.<br/>
            4.3. Партнер обязан:<br/>
            4.3.1. Для подключения к Сервису приобретать ПЗУ и Терминалы только определенных Администратором марок.<br/>
            4.3.2. Самостоятельно получать разрешения на установку Терминалов.<br/>
            4.3.3. Обеспечить бесперебойную работу Терминалов (в том числе, подключать Терминалы к электросети и сети Интернет).<br/>
            4.3.4. Соблюдать авторские и исключительные права Администратора на все результаты интеллектуальной деятельности, принадлежащие ему.<br/>
            4.3.5. Не производить действий, которые могут нанести вред деятельности или репутации Администратора, его сотрудников, партнеров и правопреемников.<br/>
            4.3.6. Поддерживать рабочее состояние и товарный вид Терминалов, Портативных зарядных устройств, сохранять изображения Торговых марок Администратора, нанесенных на ПЗУ и Терминалы, проводить профилактические и иные технические работы по обеспечению технической исправности Терминалов и Портативных зарядных устройств.<br/>
            Профилактические работы по обеспечению технической исправности и товарного вида Терминалов и Портативных зарядных устройств Партнер обязан проводить не реже 1 (одного) раза в месяц.<br/>
            Работы по устранению выявленных неисправностей или ненадлежащего состояния Терминала и Портативных зарядных устройств Партнер обязан проводить не позднее следующего календарного дня, когда Партнеру стало известно о неисправности, в том числе при получении претензий от Пользователей или заявок от Администратора.<br/>
            Партнер обязан ежемесячно отправлять Администратору отчет обо всех выполненных им профилактических и иных работах в отношении Терминалов не позднее 5 (пятого) числа месяца, следующего за отчетным.<br/>
            Партнер не вправе размещать на Терминалах и Портативных зарядных устройствах рекламные и/или информационные материалы, не согласованные или не предоставленные Администратором.<br/>
            4.3.7. Партнер обязан поддерживать Ликвидность ПЗУ в Терминале - не менее 40% и не более 80% от полной вместительности Терминала.<br/>
            При выявлении факта отклонения количества Портативных зарядных устройств в Терминале Партнера от установленной настоящим Лицензионным соглашением Ликвидности ПЗУ в Терминале Партнер обязан не позднее следующего календарного дня обеспечить необходимое количество Портативных зарядных устройств в Терминале.<br/>
            4.3.8. Обеспечить сохранность имущества, оборудования и документов, переданных Администратором для исполнения Партнером обязательств по настоящему Лицензионному соглашению и вернуть их Администратору по первому требованию, но в любом случае не позднее 5 (пяти) дней с даты расторжения (прекращения действия) настоящего Лицензионного соглашения (в том числе вернуть предоставленные сим карты), в противном случае возместить убытки Администратора (Агента) (в том числе в размере сумм, начисленных оператором связи за период с даты расторжения настоящего Лицензионного соглашения до момента возврата сим-карты Администратору).<br/>
            4.3.9. Получать у Администратора разрешение на любое использование его Торговых марок, за исключением случаев, описанных в разделе 5 настоящего Соглашения.<br/>
            4.3.10. Оформить и поддерживать действие договора аренды (субаренды) или прав собственности согласованного Администратором Помещения для установки Терминалов и Портативных зарядных устройств по согласованному адресу Помещения; предоставлять Администратору документы, подтверждающие права на согласованное Помещение (договор аренды/субаренды, согласие собственника на субаренду, выписку из ЕГРП на объект недвижимости).<br/>
            4.3.11. Предоставлять Администратору актуальную и корректную информацию в соответствии с требованиями Закона «О защите прав потребителей», в том числе целях размещения на сайте Администратора и в Сервисе информации о Партнере, как организации, оказывающей услуги Пользователям.<br/>
            4.3.12. Участвовать в утвержденных Администратором акциях и других маркетинговых и рекламных мероприятиях и активностях в целях развития Сервиса «Chargex».<br/>
            4.3.13. В течение 3-х дней уведомить Администратора об изменении существенных условий аренды помещения, а также других обстоятельствах, препятствующих или существенно изменяющих  условия деятельности предприятия Партнера в рамках Лицензионного соглашения, также немедленно предупредить Администратора об обстоятельствах, независящих от Партнера, которые создают препятствия или невозможность исполнения обязательств по настоящему Лицензионному соглашению.<br/>
            4.3.14. В течение 3-х дней уведомить Администратора об изменении учредителей (участников) Партнера, генерального директора/директора, иного руководителя единоличного/коллективного исполнительного органа, главного бухгалтера, наименования, банковских реквизитов, юридического и/или почтового адреса, электронных адресов, других контактов Партнера, по которым осуществляется взаимодействие Сторон.<br/>
            4.3.15. Обеспечить доступ представителей Администратора в Помещения, где размещены Терминалы Партнера, для осуществления контроля соблюдения требований Лицензионного соглашения и реализации иных прав по настоящему Соглашению.<br/>
            4.3.16. В установленные Администратором сроки устранять выявленные несоответствия, нарушения условий Лицензионного соглашения.<br/>
            4.3.17. Обеспечивать прием и рассмотрение обращений и претензий Пользователей в соответствии с требованиями закона «О защите прав потребителей» и действующего законодательства.<br/>
            4.3.18. Своевременно знакомиться с информацией, направляемой Администратором.<br/>
            4.3.19. Незамедлительно информировать Администратора о запросах и контрольно-надзорных мероприятиях уполномоченных государственных органов, должностных лиц в отношении Партнера в связи с деятельностью, связанной с Сервисом.<br/>
            4.3.20. Не допускать разглашения конфиденциальной информации, подписать с персоналом обязательство о неразглашении конфиденциальной информации.<br/>
            4.3.21. Уведомлять Администратора о любом известном случае нарушения его исключительных прав, незаконного использования Товарного знака, о любых действиях, направленных на дискредитацию Сервиса, Торговых марок и/или деловой репутации Администратора.<br/>
            4.3.22. В случае приостановки по требованию Администратора доступа к использованию Программ ЭВМ Сервиса и Торговых марок Администратора либо в случае прекращения действия настоящего Лицензионного соглашения незамедлительно прекратить идентификацию услуг Торговыми марками Администратора, в том числе на вывеске, в рекламно-информационных материалах снаружи и внутри Помещения, на любых носителях и ресурсах и по требованию Администратора возвратить все имеющиеся носители, маркированные Торговыми марками Администратора.<br/>
            4.3.23. Незамедлительно уведомить Администратора о намерении продать бизнес, ликвидировать юридическое лицо (Партнера), подать заявление о признании юридического лица (Партнера) банкротом.<br/>
            4.3.24. В случае решения о продаже предприятия Партнера (бизнеса/части бизнеса, непосредственно связанного с  Сервисом) предложить приобрести продаваемое предприятие, Терминалы, Портативные зарядные устройства в первую очередь Администратору или другому лицу по указанию Администратора, которые Администратор вправе выкупить по рыночной цене полностью или частично.<br/>
            4.3.25. В случае прекращения действия настоящего Лицензионного соглашения, по заявлению Администратора обеспечить перевод прав аренды/субаренды согласованного Помещения размещения Терминалов на Администратора или иное лицо по указанию Администратора, где Администратор вправе установить свои терминалы либо терминалы третьих лиц взамен Терминалов Партнера.<br/>
            4.3.26. Осуществлять иные установленные Лицензионным соглашением и указаниями Администратора действия, являющиеся зоной ответственности Партнера.<br/>
            4.3.27. Партнер по адресу размещения Терминалов и Портативных зарядных устройств сети Сервиса «Chargex» не вправе предоставлять услуги зарядки устройств самостоятельно либо заключать договоры с третьими лицами, оказывающими услуги зарядки устройств, в том числе путем размещения Терминалов и портативных зарядных устройств конкурирующих сервисов, а также совершать действия, направленные на заключение таких договоров (иных видов сделок).<br/>
            Партнер не вправе заключать договоры на получение прав, предоставляемых иными правообладателями на условиях лицензионных договоров или коммерческой концессии для видов деятельности, осуществляемых в рамках настоящего Соглашения по адресу размещения Терминалов и Портативных зарядных устройств сети Сервиса «Chargex».<br/>
            Партнер не вправе использовать полученную в рамках исполнения настоящего Соглашения информацию, документацию, консультации и ресурсы Администратора или Агента в целях, не связанных с предоставлением услуг Сервиса «Chargex».<br/>
            Партнер не вправе оказывать услуги, идентифицируемые Торговыми марками Администратора в Помещении, не согласованном с Администратором.<br/>
            В случае прекращения действия настоящего Лицензионного соглашения по любой причине Партнер  и его аффилированные лица в течение 3 (трех) лет не вправе вести по адресу  размещения Терминалов и Портативных зарядных устройств сети Сервиса «Chargex» аналогичную деятельность (предоставление услуг для зарядки устройств пользователей, в том числе путем размещения Терминалов и портативных зарядных устройств конкурирующих сервисов).<br/>
            Стороны согласны с тем, что Администратор вкладывает ресурсы в развитие сети Сервиса «Chargex», включая рекламу мест размещения Терминалов и Портативных зарядных устройств Партнера, нарушение Партнером п. 4.3.27 настоящего Лицензионного соглашения влечет причинение убытков Администратору, которые могут быть взысканы с Партнера вместе с предусмотренной настоящим Лицензионным соглашением неустойкой.<br/>
            В случае размещения Партнером терминалов и портативных зарядных устройств конкурирующих сервисов, в том числе путем предоставления в аренду третьим лицам и заключения сделок с третьими лицами, или предоставления иных услуг зарядки устройств в помещениях и местах, где ранее размещались и/или  размещены терминалы и портативные зарядные устройства, подключенные к сервису «Chargex», то упущенная выгода Администратора, подлежащая взысканию с Партнера, определяется по данным сервиса «ЮKassa» как среднедневное вознаграждение Агента, полученное Агентом с переводов от пользователей  сервиса «Chargex» Партнеру, (среднедневное вознаграждение Агента, полученное с переводов Партнеру, рассчитывается по выбору Агента как сумма вознаграждения Агента, полученная от Оператора за переводы через сервис «ЮKassa»  Партнеру, за любые три месяца, разделенная на 90), умноженное количество дней нарушения Партнером условий настоящего пункта 4.3.27 Лицензионного соглашения.<br/>
            Условия настоящего пункта 4.3.27 являются самостоятельным соглашением Сторон и сохраняют силу в течение 3 (трех) лет после расторжения (прекращения действия) Лицензионного соглашения.<br/>
            4.4. Партнер имеет право:<br/>
            4.4.1. Получать от Администратора консультации по вопросам работы Сервиса.<br/>
            4.4.2. Требовать от Администратора своевременного устранения возможных программных сбоев в работе Программ ЭВМ Сервиса.<br/>
            4.4.3. Использовать Торговые марки Администратора в коммерческих целях в рамках тех случаев, которые установлены в настоящем Соглашение.<br/>
            4.4.4. Получать своевременное возмещение убытков напрямую от Пользователя, в случае повреждения последним Портативного зарядного устройства или Терминала.<br/>
            4.5. В период действия Лицензионного соглашения и в течение 3 (трех) лет после прекращения его действия (расторжения) каждая Сторона, исполняя настоящее Лицензионное соглашение, выражает согласие на обработку другой Стороной предоставленных персональных данных, в том числе следующих персональных данных (своих и/или представителей, работников): Ф.И.О., паспортные данные, адрес места регистрации, дата рождения, почтовый адрес, номер телефона, адрес электронной почты и иных.  Каждая Сторона также  гарантирует другой Стороне получение в соответствии с требованиями федерального закона № 152-ФЗ «О персональных данных» согласия физических лиц, чьи персональные данные передаются, в целях исполнения настоящего Соглашения, на их обработку, включая передачу и разглашение в установленных законом случаях, а также в целях исполнения настоящего Соглашения. Сторона вправе отозвать согласие на обработку персональных данных, направив другой Стороне соответствующее уведомление в случаях, предусмотренных законодательством Российской Федерации.<br/>
          </p>
            <p style={styles.BodyTitle}>5. ПРАВА НА ТОРГОВЫЕ МАРКИ</p>

            <p style={styles.Body}>
            5.1. На Период действия Соглашения Администратор передает Партнеру на условиях простой (неисключительной) лицензии исключительные права на использование, демонстрацию и отображение Торговых марок Администратора для нанесения на Портативные зарядные устройства, Терминалы, отображения на сайтах Партнера.<br/>
            5.2. Использование Торговых марок Администратора в форме, отличной от указанной в п.5.1. Соглашения, допускается только после согласования и получения разрешения Администратора. В том числе, согласования требует нанесение Торговых марок на любые рекламные материалы и другие информационные материалы.<br/>
            </p>
          <p style={styles.BodyTitle}>6. ПОРЯДОК ПЕРЕДАЧИ ПРАВ И ПРОВЕДЕНИЯ РАСЧЕТОВ</p>

          <p style={styles.Body}>
            6.1. Все права, передаваемые по настоящему Соглашению, считаются переданными Партнеру от Администратора с момента заключения настоящего Соглашения.<br/>
            6.2. Если Сторонами не согласовано иное, за передаваемое по настоящему Соглашению право пользования Программами ЭВМ Сервиса и подключение к Сервису, пользование Торговыми марками Администратора Партнер уплачивает вознаграждение посредством расчетов через сервис «ЮKassa» в следующем порядке:<br/>
            6.2.1. Партнер принимает от Пользователей плату за пользование ПЗУ и Терминалами Партнера в системе Сервиса «Chargex» через сервис «ЮKassa», в этих целях Партнер заключает с обществом ограниченной ответственностью небанковской кредитной организацией "ЮМани" (Оператор) договор об информационно-технологическом взаимодействии при осуществлении переводов на условиях Правил работы сервиса «ЮKassa», размещенных на Сайте Оператора (https://yoomoney.ru/doc.xml?id=527067).<br/>
            6.2.2. Оператор удерживает из сумм переводов Партнеру от Пользователей Сервиса «Chargex», полученных с использованием сервиса «ЮKassa», в качестве вознаграждения Оператора 25% (двадцать пять процентов) от суммы каждого Перевода.<br/>
            6.2.3. Из суммы, указанной в п. 6.2.2 Соглашения, Агент получает причитающиеся ему денежные средства от Оператора на основании Агентского договора, заключенного Агентом с Оператором. Расчеты между Агентом и Администратором осуществляются на основании отдельного договора (соглашения), заключенного между Агентом и Администратором.<br/>
            6.3. Условия, касающиеся расчетов через сервис «ЮKassa», указанные в п. 6.2 (включая п.п. 6.2.1 – 6.2.3) настоящего Соглашения, распространяют действия на отношения Сторон, начиная с фактического начала использования сервиса «ЮKassa» для Сервиса «Chargex», в том числе в соответствии с п. 2 ст. 425 ГК РФ распространяют действие на отношения Сторон, возникшие до даты внесения соответствующих изменений в настоящее Соглашение.<br/>
            6.4. Все расчеты Партнера по Соглашению производятся в безналичном порядке, в рублях РФ.<br/>
            6.5. В случае, если у Сторон есть разногласия по причитающимся им по Соглашению суммам, то Стороны обязаны принять меры по немедленному урегулированию таких разногласий, при этом суммы, по которым нет спора, должны быть перечислены своевременно.<br/>
            6.6. Стороны обязуются по запросу любой из Сторон проводить сверку взаимных расчетов и подписывать двухсторонний акт сверки расчетов на бумажном носителе или электронном носителе. Сторона, инициирующая сверку расчетов, направляет другой Стороне подписанный со своей стороны акт сверки расчетов в двух экземплярах. Сторона, получившая акт сверки расчетов, обязана подписать акт сверки расчетов и направить обратно один подписанный экземпляр такого акта или направить мотивированный отказ от его подписи в течение 10 (Десяти) рабочих дней со дня его получения. В случае просрочки получившей Стороной срока возврата подписанного со своей стороны акта сверки расчетов, такой акт считается подписанным обеими Сторонами в редакции Стороны, инициирующей и направившей акт сверки расчетов.<br/>
          </p>
          <p style={styles.BodyTitle}>7. КОНФИДЕНЦИАЛЬНОСТЬ</p>

          <p style={styles.Body}>
            7.1. К конфиденциальной информации в рамках настоящего  Лицензионного соглашения относятся: сведения о внутренних правилах, технологиях управления и оказания услуг с использованием Сервиса «Chargex» и/или под Торговыми марками Администратора, ноу-хау, стандартах обслуживания, финансовой и другой деятельности Администратора, Агента, партнёров Администратора и Агента (коммерческую тайну Администратора и Агента), а также иная, информация, обрабатываемая в рамках оказания услуг с использованием Сервиса «Chargex».<br/>
            7.2. Партнер обязан обеспечить защиту сведений, относящихся к конфиденциальной информации, и обработку конфиденциальной информации способами, исключающими их разглашение или использование для целей, противоречащих действующему законодательству и настоящему Лицензионному соглашению. <br/>
            7.3. Партнер не вправе каким-либо способом разглашать конфиденциальную информацию, без согласования Администратора передавать третьим лицам копии документации, относящейся к коммерческой тайне Администратора или Агента, кроме случаев, установленных законодательством.<br/>
            7.4. Если в результате нарушения Партнером требований к защите конфиденциальной информации, Администратору или Агента будет нанесен материальный ущерб или ущерб деловой репутации, иные убытки, Партнер обязан компенсировать Администратору (Агенту) в полном размере убытки и  репутационный вред и уплатить предусмотренную настоящим Лицензионным соглашением неустойку.<br/>
          </p>
          <p style={styles.BodyTitle}>8. ОТВЕТСТВЕННОСТЬ СТОРОН</p>

          <p style={styles.Body}>
            8.1. В случае неисполнения или ненадлежащего исполнения обязательств по настоящему Соглашению Стороны несут ответственность в соответствии с действующим законодательством Российской Федерации.<br/>
            8.2. Партнер несет ответственность за техническую исправность Терминалов и Портативных зарядных устройств. Партнер несет ответственность в случае причинение вреда здоровью, жизни, имуществу Пользователей и третьих лиц, в связи с ненадлежащим состоянием Портативных зарядных устройств, Терминалов.<br/>
            8.3. Партнер обязан выплатить  Администратору компенсацию за нарушение  исключительных прав в соответствии со статьями 1252, 1301 Гражданского кодекса Российской Федерации в размере 5 (пяти) млн.рублей и  компенсировать убытки Администратора в связи с неправомерным использованием Программ ЭВМ Сервиса, Торговых марок Администратора и подключением к Сервису, включая следующие нарушения:<br/>
            8.3.1. использование Программ ЭВМ Сервиса или Торговых марок Администратора при оказании не согласованных с Администратором услуг,<br/>
            8.3.2. привлечение несогласованных Администратором третьих лиц к использованию Программ ЭВМ Сервиса или Торговых марок Администратора, <br/>
            8.3.3. использование Программами ЭВМ Сервиса или Торговых марок Администратора способом или с нарушением требований, предусмотренных настоящим Лицензионным соглашением,<br/>
            8.3.4. разглашение Партнером информации о возможностях используемого ПО, передача скриншотов ПО, Инструкций, а также передача посторонним лицам предоставленного Партнеру логина/пароля доступа к ПО, иное разглашение коммерческой тайны Администратора (Агента), конфиденциальной информации,<br/>
            8.3.5. нарушение Партнером обязательств, установленных пунктами 3.4, 4.3.1, 4.3.4, 4.3.8, 4.3.9, 4.3.20- 4.3.25 (включительно), 4.3.27,  5.2, 7.3 настоящего Соглашения,<br/>
            8.3.6. иного, несогласованного Партнером пользования Программами ЭВМ Сервиса или Торговыми марками Администратора.<br/>
            8.4. При выявлении нарушений, указанных в п.п. 8.3.1-8.3.5 настоящего Лицензионного соглашения, Партнер обязан выплатить по требованию Администратора  штраф  в размере 1 (одного) млн. рублей, а также незамедлительно устранить нарушение и привести деятельность  в соответствие с установленными Администратором требованиями согласно настоящему Лицензионному соглашению.  В случае повторного выявления нарушения Администратор вправе потребовать выплаты Партнером штрафа за каждое нарушение, до момента устранения нарушения.<br/>
            8.5. За нарушение Партнером обязательств, установленных пунктами 4.3.6, 4.3.7, 4.3.10-4.3.19 (включительно), 7.2 настоящего Лицензионного соглашения, Партнер обязан выплатить по требованию Администратора штраф в размере 200 (двухсот) тыс. рублей, а также незамедлительно устранить нарушение и привести качество оказания услуг с использованием Программам ЭВМ Сервиса и Торговых марок Администратора в соответствии с установленными Администратором и Лицензионным соглашением требованиями.  В случае повторного выявления нарушения Администратор вправе потребовать выплаты Партнером штрафа за каждое нарушение, до момента устранения нарушения.<br/>
            8.6. При выявлении нарушений настоящего Лицензионного соглашения доступ к использованию Программ ЭВМ Сервиса, Торговых марок Администратора и подключение к Сервису «Chargex» Партнера  может быть временно приостановлен Администратором до момента устранения нарушений Партнером.<br/>
            В случае не устранения Партнером выявленных нарушений, которые послужили основанием для приостановки, Администратор вправе в одностороннем внесудебном порядке отказаться от исполнения настоящего Соглашения, уведомив Партнера.<br/>
            8.7. В случае нарушения сроков оплаты вознаграждения по настоящему Лицензионному соглашению Партнер выплачивает по требованию Администратора неустойку в размере 0,3% от суммы задолженности за каждый день просрочки.<br/>
            8.8. В случае неоднократной просрочки (два и более раз в течение 12 месяцев) оплаты вознаграждения по настоящему Лицензионному соглашению Администратор вправе временно приостановить использование Программ ЭВМ Сервиса, Торговых марок Администратора и подключение к Сервису «Chargex» Партнера  и/или в одностороннем внесудебном порядке отказаться от исполнения настоящего Лицензионного соглашения.<br/>
            8.9. Условия раздела 8 настоящего Лицензионного соглашения сохраняют силу и после расторжения (прекращения действия) Лицензионного соглашения в течение 3 (трех) лет, применяются, в том числе к нарушением, совершенным после расторжения (прекращения действия) Лицензионного соглашения в течение 3 (трех) лет.<br/>
          </p>
          <p style={styles.BodyTitle}>9. ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ</p>

          <p style={styles.Body}>
            9.1. В случае возникновения любых разногласий или споров между Сторонами настоящего Соглашения обязательным условием до обращения в суд является предъявление претензии (письменного предложения о добровольном урегулировании спора). Претензии к Администратору должны  быть направлены:<br/>
            § официальным письмом по юридическому адресу Администратора;<br/>
            § по электронной почте по адресу support@chargex.am.<br/>
            При направлении претензии по электронной почте к электронному письму должна быть приложена сканированная версия претензии в письменном виде.<br/>
            9.2. Адресат претензии в течение 30 (тридцати) календарных дней со дня ее направления, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.<br/>
            9.3. При невозможности разрешить спор в добровольном порядке любая из Сторон вправе обратиться за его разрешением в суд по выбору истца:<br/>
            § в Российской Федерации – в Арбитражный суд города Москвы, или в Суд по интеллектуальным правам, или в Симоновский районный суд г. Москвы соответственно подсудности спора;<br/>
            § в Республики Армения – Суд общей юрисдикции общин Кентрон и Норк-Мараш.<br/>
          </p>
          <p style={styles.BodyTitle}>10. СРОК ДЕЙСТВИЯ И ПОРЯДОК РАСТОРЖЕНИЯ СОГЛАШЕНИЯ</p>

          <p style={styles.Body}>
            10.1. Настоящее Соглашение вступает в силу с даты присоединения Партнера и действует в течение 1 (одного) года, начиная с даты вступления настоящего Соглашения в силу. Если за 10 (десять) дней до окончания срока действия настоящего Соглашения ни одна из Сторон не заявит в письменной форме о намерении его расторгнуть, срок действия Соглашения автоматически продлевается на 1 (один) год. Количество пролонгаций не ограниченно.<br/>
            10.2. Переход исключительного права на передаваемые по настоящему Соглашению Программы ЭВМ Сервиса к иному правообладателю не является основанием для изменения или расторжения настоящего Соглашения.<br/>
            10.3. Любая из Сторон вправе расторгнуть настоящий Соглашение досрочно, уведомив об этом другую Сторону письменно не позднее, чем за 60 (шестьдесят) календарных дней до даты такого расторжения, при условии проведения всех взаиморасчетов по Соглашению.<br/>
            10.4. В случае расторжения настоящего Соглашения его положения о персональных данных, конфиденциальной информации, а также об ответственности за ненадлежащее исполнение условий Соглашения сохраняют своё действие в течение 3 (Трех) лет с даты такого расторжения.<br/>
            10.5. При нарушениях Партнером обязанностей по настоящему Соглашению (в том числе по устранению недостатков ПЗУ и Терминалов) Администратор может отказаться в одностороннем порядке от настоящего Соглашения и потребовать возмещения убытков, причиненных его расторжением. Для расторжения Соглашения в данном случае Администратор уведомляет Партнера по электронной почте и/или сообщением в Сервисе и прекращает доступ Администратора к Сервису.<br/>
            10.6. При прекращении действия Соглашения Администратор и Партнер в течение 15 (пятнадцати) дней проводят завершающие расчеты.<br/>
          </p>

           <p style={styles.BodyTitle}>11. ОБСТОЯТЕЛЬСТВА НЕПРЕОДОЛИМОЙ СИЛЫ</p>

           <p style={styles.Body}>
             11.1. Стороны по настоящему Соглашению освобождаются от ответственности за полное или частичное неисполнение своих обязательств в случае, если такое неисполнение явилось следствием обстоятельств непреодолимой силы, то есть событий, которые нельзя было предвидеть или предотвратить. К вышеуказанным обстоятельствам в контексте настоящего Соглашения относятся: стихийное бедствие, пожар, война или военные действия, забастовка в отрасли или регионе, принятие органом государственной власти/управления и/или органом местного самоуправления, правовых и/или правоприменительных актов, повлекших невозможность исполнения настоящего Соглашения и иные действия, находящиеся вне разумного предвидения и контроля сторон.<br/>
             11.2. При наступлении обстоятельств непреодолимой силы каждая сторона должна не позднее 5 (пяти) рабочих дней с момента наступления таких обстоятельств известить о них в письменном виде другую сторону. Извещение должно содержать данные о характере обстоятельств, оценку их влияния на возможность исполнения стороной своих обязательств по настоящему Соглашению, а также предполагаемые сроки их действия.<br/>
             11.3. В случае если любое из вышеуказанных обстоятельств повлияло на исполнение настоящего Соглашения в срок исполнения обязательств, то срок исполнения обязательств по Соглашению отодвигается на период действия обстоятельства непреодолимой силы.<br/>
             11.4. Если действие обстоятельств непреодолимой силы продолжается свыше одного месяца, Стороны проводят дополнительные переговоры для выявления приемлемых альтернативных способов исполнения настоящего Соглашения либо Соглашение подлежит расторжению в установленном порядке.<br/>
           </p>

           <p style={styles.BodyTitle}>12. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</p>

           <p style={styles.Body}>
             12.1. Отношения между Партнером и Администратором, возникающие в связи с заключением, исполнением и прекращением настоящего Соглашения регулируются правом Российской Федерации.<br/>
             12.2. Стороны по настоящему Соглашению признают, что документы, переданные посредством e-mail и содержащие необходимые реквизиты, имеют ту же юридическую силу (т.е. являются подлинными), что и документы на бумажном носителе, подписанные указанными в документе должностными лицами и имеющими печать Стороны, подписавшей документы, за исключением случаев, когда это противоречит действующему законодательству и правилам делового документооборота.<br/>
             12.3. Администратор вправе направлять Партнеру информацию и любые  документы, касающиеся исполнения настоящего Соглашения, по любым предоставленным Партнером контактам: электронной почте, телефону, а также посредством Личного кабинета и мессенджеров.<br/>
             12.4. Во всем, что не предусмотрено настоящим Соглашением, Стороны руководствуются действующим законодательством Российской Федерации.<br/>
           </p>
        </div>
      </div>
          </>}</Translation>
    );
  }
}

export default  withRouter(licens);
