export const ADD_STATION = {
  ADD_REQUEST:'ADD_STATION.ADD_REQUEST',
  ADD_SUCCESS:'ADD_STATION.ADD_SUCCESS',
  ADD_FAIL:'ADD_STATION.ADD_FAIL',
}


const initialState={
  addStations:[]

}

export default (state=initialState,action)=>{
  switch (action.type) {
    case ADD_STATION.ADD_REQUEST:{
      return{...state,addStations:initialState.addStations}
    }
    case ADD_STATION.ADD_SUCCESS:{
      return {...state,addStations:action.payload.data}
    }
    case ADD_STATION.ADD_FAIL:{
      return{...state,addStations:initialState.addStations}
    }
    default:{
      return state
    }
  }
}