import React, {useEffect, useState } from "react";
import Chart from "react-apexcharts";

export const CircleLostCount = ({dataStatuses, dataLostPowerbanks}) => {
    const [datas, setDatas] = useState({
        options: {
            labels: ['Остальные', 'За месяц'],
            colors: ['#4a4a4a', '#ECC53C'],
            plotOptions: {
                pie: {
                donut: {
                    labels: {
                        show: true,
                        value: {
                            show: true,
                            color: '#fff',
                            fontSize: '20px'
                        },
                        total: {
                            show: true,
                            color: '#fff',
                            fontSize: '15px',
                            label: 'Всего'
                        }
                    }
                }
                }
            },
            legend: {
                position: 'bottom',
                fontSize: '16px',
                labels: {
                    colors: '#fff'
                },
                markers: {
                    offsetX: '-5'
                },
            },
        },
        statuses: {
            otherLostStations: 0,
            lostInPeriod: 0
        }
    });

    useEffect(() => {
        setDatas(pv => ({
            ...pv,
            statuses: {
                otherLostStations: dataStatuses.lost - Object.values(dataLostPowerbanks).reduce((a,b) => a+b, 0),
                lostInPeriod: Object.values(dataLostPowerbanks).reduce((a,b) => a+b, 0)
            }
        }))
    }, [dataStatuses, dataLostPowerbanks]);

    return (
        <div className="circleLostChart">
            <Chart
                options={datas.options}
                series={
                    [
                        datas.statuses.otherLostStations,
                        datas.statuses.lostInPeriod
                    ]
                }
                type="donut"
            />
        </div>
    )
}