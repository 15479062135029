export const ADD_AGREEMENT ={
  AGREEMENT_TEXT_REQUEST:'ADD_AGREEMENT.AGREEMENT_TEXT_REQUEST',
  AGREEMENT_TEXT_SUCCESS:'ADD_AGREEMENT.AGREEMENT_TEXT_SUCCESS',
  AGREEMENT_TEXT_FAIL:'ADD_AGREEMENT.AGREEMENT_TEXT_FAIL',
  OPEN_AGREEMENT_PAGE:'ADD_AGREEMENT.OPEN_AGREEMENT_PAGE',
  CLOSE_AGREEMENT_PAGE:'ADD_AGREEMENT.CLOSE_AGREEMENT_PAGE'
}

const initialState = {
  CrateAgreement:[],
  openAgreementPage:[]
}

export default (state=initialState,action)=>{
  switch (action.type) {
    case ADD_AGREEMENT.AGREEMENT_TEXT_REQUEST :{
      return {...state,CrateAgreement:initialState.CrateAgreement}
    }
    case ADD_AGREEMENT.AGREEMENT_TEXT_SUCCESS:{
      return {...state,CrateAgreement:action.payload.data}
    }
    case ADD_AGREEMENT.AGREEMENT_TEXT_FAIL:{
      return {...state,CrateAgreement:initialState.CrateAgreement}
    }
    case ADD_AGREEMENT.OPEN_AGREEMENT_PAGE:{
      return{...state,openAgreementPage:action.payload.data}
    }
    case ADD_AGREEMENT.CLOSE_AGREEMENT_PAGE:{
      return {...state,openAgreementPage:[]}
    }
    default:{
      return state
    }
  }

}