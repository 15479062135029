
import {MERCHANTS_ACTION} from "../MerchantesReducer";

export const GET_MERCHANT_BY_EMAIL ={
  GET_MERCHANT_REQUEST:'GET_MERCHANT_BY_EMAIL.GET_MERCHANT_REQUEST',
  GET_MERCHANT_SUCCESS:'GET_MERCHANT_BY_EMAIL.GET_MERCHANT_SUCCESS',
  GET_MERCHANT_FAIL:'GET_MERCHANT_BY_EMAIL.GET_MERCHANT_FAIL',
  BLOCK_ONE_MERCHANT_BY_ADMIN:'GET_MERCHANT_BY_EMAIL.BLOCK_ONE_MERCHANT_BY_ADMIN'
}


const initialState={
  GetByEmail:[]
}

export  default (state=initialState,action)=>{
  switch (action.type) {
    case GET_MERCHANT_BY_EMAIL.GET_MERCHANT_REQUEST:{
      return{...state,GetByEmail:initialState.GetByEmail}
    }
    case GET_MERCHANT_BY_EMAIL.GET_MERCHANT_SUCCESS: {
      return {...state, GetByEmail: action.payload.data}
    }
    case GET_MERCHANT_BY_EMAIL.GET_MERCHANT_FAIL:{
      return{...state,GetByEmail:initialState.GetByEmail}
    }
    case GET_MERCHANT_BY_EMAIL.BLOCK_ONE_MERCHANT_BY_ADMIN:
      const newActiveUserList=state.GetByEmail.map(item=> {
        if (item.email === action.payload.email) {
          return {...item, blocking: action.payload.lock}
        }
        return item
      })
      return {...state,GetByEmail:newActiveUserList}

    default:{
      return state
    }
  }
}