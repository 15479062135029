import {put, takeLatest,call} from "@redux-saga/core/effects";
import * as api from "../../api";
import {FINDING} from "./FindingRentsReducer";



function* rentSaga() {
  yield takeLatest(FINDING.RENTS_REQUEST, handleSaga)
}

function* handleSaga(action) {
  try {
    const {data} = yield call(api.getUserRent,action.payload.phoneNumber)
    yield put({
      type: FINDING.RENTS_SUCCESS,
      payload: {data}
    })
  } catch (e) {
    yield put({
      type: FINDING.RENTS_FAIL,
      message: e.message
    })
  }
}

export default rentSaga
