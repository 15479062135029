import React from 'react'
import card from '../../../../assets/images/CreditCard.png';
import card1 from '../../../../assets/images/CreditCard.png';
import ApplePay from '../../../../assets/images/ApplePay.png';
import GPay from '../../../../assets/images/GPay.png';
import card2 from '../../../../assets/images/card1.png';
import {Translation} from "react-i18next";

export const CardsComponent =({props})=>{
    const firstSixLastTwo = props.first6.slice(4,6);
    const firstSixFirstFour = props.first6.slice(0,4);
    const lastTwoOfLastFour = props.last4.slice(0,4);
    const expireYear = props.expiry_year.slice(2,4);



    let src = props.source  ? props.source ==='apple_pay' ? ApplePay :  GPay :  card;
    if(expireYear % 2 === 0) {
        src = props.source  ? props.source ==='apple_pay' ? ApplePay :  GPay : card1;
    }
    if(expireYear % 3 === 0) {
        src =props.source  ? props.source ==='apple_pay' ? ApplePay :  GPay  :card2;
    }

    return(

        <div style={{paddingRight:'20px',marginTop:'35px'}}>

            <div style={{width: '100%'}}>
                <p style={{position: 'absolute', right: '79px', color: 'white'}}>
                    { props.card_type && props.card_type  }
                </p>

                <div style={{marginTop: '70px', left: '40px', color: 'white', position: 'absolute', display: 'flex'}}>


                    <p style={{marginTop: '48px'}}>{`${firstSixFirstFour} ${firstSixLastTwo}** **** ${lastTwoOfLastFour}`}</p>
                    <div style={{marginLeft: '30px', marginTop: '15px'}}>
                        <Translation>
                            {(t) =>
                                <p style={{lineHeight: '14px'}}>{t('Expires end')}</p>
                            }
                        </Translation>
                        <p style={{
                            lineHeight: '0px',
                            marginTop: '5px'
                        }}>{`${props.expiry_month}/${props.expiry_year.slice(2, 4)}`}</p>
                    </div>
                </div>
                {/*}*/}

                <img style={{height:'auto',width:'100%',
                    boxShadow: '0 0 10px 5px blue',borderRadius:'12px'

                }} src={src} />
            </div>


        </div>

    )
}