import React, {Component} from 'react';
import {connect} from 'react-redux';
import {HistoryItem} from './components/HistoryItem';
import moment from 'moment';
import {rentHistoryItemSelector} from "../../../../modules/rents/rentHistory/rentHistorySelectors";
import {Translation} from "react-i18next";

const styles = {
  containerStyle: {
    width: '100%',
    maxWidth: '440px',
    margin: '0 auto',
    padding: '0 0 0 0'
  },
  rentDebt: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '150%',
    color: '#EE1F35'
  },
  infoStyle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.0025em',
    color: 'white'
  },
  forH4: {
    letterSpacing: '0.0025em',
    color: 'white',
    margin: '20px 0 0 0',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '32px',
  },
  ForH: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '32px',
    letterSpacing: '0.0025em',
    color: 'white',
    margin: '20px 0 26px 0',
  }
};


class RentHistoryItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Time: [],
      Station: this.props.rentHistoryItem
    }
  }

  render() {
    const {rentHistoryItem: {totalAmount, id, startStation, stationSlot, startDate, finishedDate, price, allRentTime, rentCoast, completionStation, completionStationSlot, notificationStatus}} = this.props;
    const StartTime = moment.tz(startDate,'YYYY-MM-DD/HH:mm','Europe/Moscow').local().format('DD.MM.YYYY in HH:mm')
    const FinishTime = finishedDate ? moment.tz(finishedDate,'YYYY-MM-DD/HH:mm','Europe/Moscow').local().format('DD.MM.YYYY in HH:mm') :''
    const location =this.props.rentHistoryItem.groupTariffs ? this.props.rentHistoryItem.groupTariffs.location : ''

    let d1 = new Date(startDate ? startDate[0] : '',
      startDate ? startDate[1] : '',
      startDate ? startDate[2] : '',
      startDate ? startDate[3] : '',
      startDate ? startDate[4] : '',
      startDate ?startDate[5] :'');
    let d2 = new Date(finishedDate ? finishedDate[0] : '',
      finishedDate ? finishedDate[1] : '',
      finishedDate ? finishedDate[2] : '',
      finishedDate ? finishedDate[3] : '',
      finishedDate ? finishedDate[4] : '',
      finishedDate ? finishedDate[5] :'');
    let date = new Date(d2-d1);
    let hours = date.getUTCHours();
    let min = date.getUTCMinutes();
    let sec = date.getUTCSeconds();
    let days = date.getUTCDate() - 1;


   return (
      <div style={styles.containerStyle}>
        <Translation>
          {(t) =>
            <>
        <h4 style={styles.ForH}> {'№' + id.slice(-4)}</h4>
        {this.props.rentHistoryItem.debt ?
          <div>
                <div>
                  <h3 style={{color:'#EE1F35',margin:'0'}}>
                    {t("YOU HAVE RENT DEBT")}
                  </h3>
                  <p style={styles.rentDebt}>{` - ${this.props.rentHistoryItem.debt} `}
                    <span style={{fontFamily: 'PT Sans'}}>₽</span></p>
                </div>
          </div>:''}
            <div>
              <div>
                <HistoryItem
                  heading={t("Start of rent")}
                  value={StartTime ? `${StartTime}` : ''}
                />
                <HistoryItem
                  heading={t("End of rent")}
                  value={FinishTime ?  `${FinishTime}` : 'In Progress'}
                />
                <HistoryItem
                  heading={t("Tariff")}
                  value={this.props.rentHistoryItem.currentTariff.tariffName}
                />
                  <HistoryItem
                    heading={t("Total rent time")}
                    value={finishedDate ? `${days > 0 ? `${days}${t("d")} ` : ''} ${hours > 0 ? `${hours}${t("h")} ` : ''}   ${min > 0 ? `${min}${t("m")}` : ''} ${sec > 0 ? `${sec}${t("s")}` : ''}` : t('In Progress')}
                  />
                <HistoryItem
                  heading={t("Total rent cost")}
                  value={<>{totalAmount}
                    {location === 'RU' ?
                      <span style={{fontFamily: 'PT Sans'}}>₽</span>
                      :
                      <span style={{fontFamily: 'PT Sans'}}>֏</span>
                    }
                  </>

                  }
                />
                <HistoryItem
                  heading={t("Receiving station address")}
                  value={startStation}
                />
                <HistoryItem
                    heading={t("Receiving station slot")}
                    value={stationSlot ? stationSlot : t('null')}
                />
                <HistoryItem
                  heading={t("Return station address")}
                  value={completionStation ? completionStation : t('null')}
                />
                <HistoryItem
                    heading={t("Return station slot")}
                    value={completionStationSlot ? completionStationSlot : t('null')}
                />
              </div>
            </div>
            </>}</Translation>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  rentHistoryItem: rentHistoryItemSelector(state),
})

export default connect(mapStateToProps, {})(RentHistoryItem);
