import {ADD_AGREEMENT} from "./addAgreementReducer";

export const addAgreementText = (locationForUserAgreement,text) => {
  return{
    type:ADD_AGREEMENT.AGREEMENT_TEXT_REQUEST,
    payload:{locationForUserAgreement,text}
  }
}

export const AddAgreementPage = (data)=>{
  return{
    type:ADD_AGREEMENT.OPEN_AGREEMENT_PAGE,
    payload:{data}
  }
}

export const CloseAgreementPage = ()=>{
  return{
    type:ADD_AGREEMENT.CLOSE_AGREEMENT_PAGE,
  }
}