import React, {Component} from 'react';
import {connect} from "react-redux";
import {getAgreementText} from "../../modules/documents/UserAgreement/agreementAction";
import {AddAgreementPage} from "../../modules/documents/UserAgreement/addAgreement/addAgreementAction";
import TextTable from "../../components/common/TextTable";
import NewButton from "../../components/common/Buttons/ForNewButton";
import Title from "../../components/common/TabbleText/AllTitle";
class Documents extends Component {

  componentDidMount() {
    this.props.getAgreementText()
  }
  render() {
    const {AgreementText} =this.props
    return (
      <div>
         <Title  style={{marginLeft:'20px'}} Text={'Documents'}/>
              <div style={{display:'flex'}}>
          {AgreementText.length > 0 ? AgreementText.map((row, i) => (
      <TextTable
        key={i}
        onClick={() => this.props.AddAgreementPage(row.userAgreementText ? [row.userAgreementText,row.locationUserAgreement]  : '')}
        Location={row.locationUserAgreement}
        Text={'Documents'}
        AllText={ row.userAgreementText ?
            `${row.userAgreementText.slice(0,20)}
             ${row.userAgreementText.slice(20,40)}
             ${row.userAgreementText.slice(40,60)}
             ${row.userAgreementText.slice(60,80)}
             ${row.userAgreementText.slice(80,100)}... ` : ''}/>
          )) : null}
          </div>
        <NewButton onClick={() => this.props.AddAgreementPage(true)} ButtonText={'+ NEW Agreement'}/>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  AgreementText: state.agreementReducer.AgreementText,
})
export default connect(mapStateToProps, {getAgreementText, AddAgreementPage})(Documents);