import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import {Translation} from "react-i18next";
import {Close} from "@material-ui/icons";


const styles={
  HeadTitleDiv: {
    display: 'flex',
    alignItems: 'center',
    width: '300px',
    height: '40px',
    margin: '30px 20px 0 0',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #121212',
    borderRadius: '5px'
  },
  ButtonStyle:{
    background: 'none',
    border: 'none',
    outline: 'none'
  }
}

export const SearchInput = ({onChange,placeholder,onClick,clearSearch,onKeyDown,value}) => {
  return(
    <Translation>
      {(t) =>
        <>
    <div className={'SearchInputDiv'} style={styles.HeadTitleDiv}>
      <Button onClick={onClick} style={styles.ButtonStyle}>
        <SearchIcon style={{color: 'white'}}/></Button>
      <input
        value={value}
        className='SearchInput'
        placeholder={t(placeholder)}
        onChange={onChange}
        onKeyDown={onKeyDown}/>
        <Close onClick={clearSearch} style={{color: 'white',cursor:'pointer'}}/>
    </div>
        </>}</Translation>

  )
}
