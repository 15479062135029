import React, {Component} from 'react';
import {connect} from "react-redux";
import {GetSupportText} from "../../modules/documents/SupportText/supportTextAction";
import {AddSupportPage} from "../../modules/documents/SupportText/addSuportText/addSuportTextAtion";
import Title from "../../components/common/TabbleText/AllTitle";
import TextTable from "../../components/common/TextTable";
import NewButton from "../../components/common/Buttons/ForNewButton";

class Support extends Component {
  componentDidMount() {
    this.props.GetSupportText()


  }

  render() {
    const SupportTextList = Object.values(this.props.SupportText)
    return (
      <div>
       <Title style={{marginLeft: '20px'}} Text={'Support'}/>
        <div style={{display: 'flex'}}>
          {SupportTextList.length &&
            SupportTextList.map((row, i) => (
              <TextTable
                key={i}
                onClick={() => this.props.AddSupportPage(row)}
                Location={row.locationForSupport}
                Text={'Support Text'}
                AllText={`${row.supportEmail} ${row.supportPhoneNumber} `}
              />
            ))
          }
        </div>
        <NewButton onClick={() => this.props.AddSupportPage(true)} ButtonText={'+ NEW Support Text'}/>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  SupportText: state.supportTextReducer.SupportText,
})
export default connect(mapStateToProps, {
  GetSupportText, AddSupportPage,
})(Support);