import React from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../../utiles/formatDate";

const ElementWithDetails = ({
  status,
  createdAt,
  description,
  links = [],
  onGoBack,
}) => {
  const { t } = useTranslation();
  const handleCleanUp = () => onGoBack && onGoBack();
  return (
    <>
      <div className="goBackOverflow" onClick={handleCleanUp} />
      {links.map((link, index) => {
        if (link) {
          return (
            <div className="powerbankImage" key={index}>
              <img src={link} alt="powerbank img" />
            </div>
          );
        }
      })}
      <div className="infoBlock vertical">
        <p className="infoBlockHeader">{t("default.date")}</p>
        <p className="infoBlockBody">{formatDate(createdAt)}</p>
      </div>
      <div className="infoBlock vertical">
        <p className="infoBlockHeader">{t("default.status")}</p>
        <p className="infoBlockBody">{status}</p>
      </div>
      <div className="infoBlock vertical">
        <p className="infoBlockHeader">{t("default.description")}</p>
        <p className="infoBlockBody">{description}</p>
      </div>
    </>
  );
};

export default ElementWithDetails;
