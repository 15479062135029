import React, {useEffect, useState} from 'react';
import {Translation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {
    getPowerbanksDashboardInfo,
    getMerchantsDashboardInfo,
    getLostPowerbanksInfo
} from '../../../modules/dashboard/dashboardAction';
import {MapOnDashboard} from './MapOnDashboard';
import {PowerbanksConditionsChart} from './PowerbanksConditionsChart';
import {PowerbanksStatusesChart} from './PowerbanksStatusesChart';
import {StatusLineChart} from './StatusLineChart';
import {SelectMonth, SelectYear} from './StatusLineChart/ChangeMonth';
import {CircleLostCount} from './StatusLineChart/CirlceLostCount';
import loading from '../../../assets/images/loading.gif';
import {MerchantsList} from "./MerchantsList";
import {BarChart} from "./BarChart";
import {StationList} from "./StationList";

const now = new Date();
export default function Charts() {
    const {
        dataStatuses,
        dataMerchants,
        dataLostPowerbanks,
        allBanksOnMap,
    } = useSelector(state => state.dashboardReducer);
    const [isStationsUploadComplete, setIsStationsUploadComplete] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const dispatch = useDispatch();
    const [selectedMonth, setSelectedMonth] = useState(now.getMonth())
    const [selectedYear, setSelectedYear] = useState(now.getFullYear())
    const [merchant, setMerchant] = useState(null)
    const [station, setStation] = useState(null)
    const Role = localStorage.getItem('role')

    useEffect(() => {
        setIsDataLoaded(false);
        const offset = new Date().getTimezoneOffset();
        dispatch(getLostPowerbanksInfo(
                new Date(selectedYear, selectedMonth, 1, 0, -offset).toISOString().split('T')[0],
                new Date(selectedYear, selectedMonth + 1, 1, 0, offset).toISOString().split('T')[0],
                merchant,
                station
            )
        );
    }, [selectedMonth, merchant, station]);

    useEffect(() => {
        dispatch(getPowerbanksDashboardInfo())
        dispatch(getMerchantsDashboardInfo())
    }, []);

    useEffect(() => {
        setTimeout(() => {
            !!allBanksOnMap.length ? setIsStationsUploadComplete(true) : setIsStationsUploadComplete(false);
        }, 1000);
    }, [allBanksOnMap]);

    useEffect(() => {
        setIsDataLoaded(true);
    }, [dataLostPowerbanks]);

    return (
        <Translation>
            {(t) => (
                <>
                    <div style={{background: 'black', minHeight: '100vh'}}>
                        <h1 style={{color: 'white', marginLeft: '20px'}}>{t('Dashboard')}</h1>

                        <section className='charts'>
                            <div className='simpleChartBlock'>
                                <h2>{t('Powerbanks statuses chart')}</h2>
                                {
                                    dataStatuses.allPowerBanks
                                        ?
                                        <h3 className="dataLoadSuccess circleCrartsStatus">Данные успешно загружены</h3>
                                        :
                                        <div className="dataLoadLoading circleCrartsStatus">
                                            <img src={loading} alt="..."/>
                                            <h3 className="banksOnMapLoading">Из-за большого количества пауэрбанков их
                                                загрузка занимает некоторое время. Пожалуйста подождите</h3>
                                        </div>
                                }
                                <div className='chart'>
                                    <PowerbanksStatusesChart
                                        dataPowerbanks={dataStatuses}
                                        merchantsList={dataMerchants}
                                    />
                                </div>
                            </div>
                            <div className='simpleChartBlock'>
                                <h2>{t('Powerbanks conditions chart')}</h2>
                                {
                                    dataStatuses.allPowerBanks
                                        ?
                                        <h3 className="dataLoadSuccess circleCrartsStatus">Данные успешно загружены</h3>
                                        :
                                        <div className="dataLoadLoading circleCrartsStatus">
                                            <img src={loading} alt="..."/>
                                            <h3 className="banksOnMapLoading">Из-за большого количества пауэрбанков их
                                                загрузка занимает некоторое время. Пожалуйста подождите</h3>
                                        </div>
                                }
                                <div className='chart'>
                                    <PowerbanksConditionsChart
                                        dataPowerbanks={dataStatuses}
                                        merchantsList={dataMerchants}
                                    />
                                </div>
                            </div>
                        </section>
                        <section className='charts'>
                            <div className='simpleChartBlock lostBanksBlock'>
                                <div className="lostBanksTitle">
                                    <h2>График утерянных пауэрбанков</h2>
                                    <div className="lostBanksSelectors">
                                        <SelectMonth month={selectedMonth} updateRange={setSelectedMonth} />
                                        <SelectYear year={selectedYear} updateRange={setSelectedYear} />
                                    </div>
                                </div>
                                <div className='chart lostBanksMainScope'>
                                    {Role === 'SUPER_ADMIN' ?
                                        <>
                                            <MerchantsList
                                                chooseAllMerchants={() => setMerchant(null)}
                                                sortFn={(merchant) => setMerchant(merchant)}
                                                merchantsList={dataMerchants}
                                            />
                                            <StationList
                                                chooseAll={() => setStation(null)}
                                                chooseOne={(station) => setStation(station)}
                                                list={dataLostPowerbanks.stations}
                                            />
                                        </>
                                        : ''}
                                    {
                                        isDataLoaded
                                            ?
                                            <h3 className="dataLoadSuccess">Данные успешно загружены</h3>
                                            :
                                            <div className='dataLoadLoading'>
                                                <img src={loading} alt="..."/>
                                                <h3 className="banksOnMapLoading">Из-за большого количества пауэрбанков
                                                    их загрузка занимает некоторое время. Пожалуйста подождите</h3>
                                            </div>
                                    }
                                    <CircleLostCount
                                        dataStatuses={dataStatuses}
                                        dataLostPowerbanks={dataLostPowerbanks.lostPowerBanks}
                                    />
                                    <StatusLineChart
                                        month={selectedMonth}
                                        dataLostPowerbanks={dataLostPowerbanks.lostPowerBanks}
                                    />
                                    {['SUPER_ADMIN', 'ADMIN', 'MERCHANT'].includes(Role) ?
                                        <>
                                            <BarChart
                                                dataValues={dataLostPowerbanks.utilization}
                                                isHorizontal={true}
                                                stacked={true}
                                                height={100}
                                            />
                                            <BarChart
                                                dataValues={dataLostPowerbanks.utilizationStations}
                                                isHorizontal={true}
                                                stacked={true}
                                                height={dataLostPowerbanks.utilizationStations.categories.length * 20 + 80}
                                            />
                                            <BarChart
                                                dataValues={dataLostPowerbanks.creditDebit}
                                                isHorizontal={false}
                                                height={350}
                                            />
                                            <BarChart
                                                dataValues={dataLostPowerbanks.rentScan}
                                                isHorizontal={false}
                                                height={350}
                                            />
                                            <BarChart
                                                dataValues={dataLostPowerbanks.rents}
                                                isHorizontal={false}
                                                height={350}
                                            />
                                            <BarChart
                                                dataValues={dataLostPowerbanks.scans}
                                                isHorizontal={false}
                                                height={350}
                                            />
                                            <BarChart
                                                dataValues={dataLostPowerbanks.creditDebitStations}
                                                isHorizontal={true}
                                                height={dataLostPowerbanks.creditDebitStations.categories.length * 20 + 80}
                                            />
                                            <BarChart
                                                dataValues={dataLostPowerbanks.creditDebitMerchants}
                                                isHorizontal={true}
                                                height={dataLostPowerbanks.creditDebitMerchants.categories.length * 20 + 80}
                                            />
                                        </>
                                        : ''}
                                </div>
                            </div>
                        </section>
                        <section className='mapOnDashboard'>
                            <h2>Поиск по адресу</h2>
                            {
                                isStationsUploadComplete
                                    ?
                                    <h3 className="dataLoadSuccess">Станции успешно загружены на карту</h3>
                                    :
                                    <div className='dataLoadLoading'>
                                        <img src={loading} alt="..."/>
                                        <h3 className="banksOnMapLoading">Из-за большого количества терминалов их
                                            загрузка на карту занимает некоторое время. Пожалуйста подождите</h3>
                                    </div>
                            }

                            <MapOnDashboard allBanksOnMap={allBanksOnMap}/>
                        </section>
                    </div>
                </>
            )}
        </Translation>
    )
}
