import React from "react";

import SIGNALLEVEL1 from '../assets/images/SIGNALLOW.png'
import SIGNALLEVEL2 from '../assets/images/SIGNAL LEVEL (3).png'
import SIGNALLEVEL3 from '../assets/images/SIGNAL LEVEL.png'
import SIGNALLEVEL4 from '../assets/images/SIGNAL LEVEL (1).png'
import SIGNALLEVEL5 from '../assets/images/SIGNALFULL.png'

const SignalLevel =({signal})=>{
  return(
    <>
      {signal === 'ONE' ?
        <img src={SIGNALLEVEL1} alt={SIGNALLEVEL1} />:
         signal === 'TWO' ?
           <img src={SIGNALLEVEL2} alt={SIGNALLEVEL2} />:
           signal === 'THREE' ?
            <img src={SIGNALLEVEL3} alt={SIGNALLEVEL3} />:
                    signal === 'FOUR' ?
              <img src={SIGNALLEVEL4} alt={SIGNALLEVEL4} />:
                signal === 'FIVE' ?
                  <img src={SIGNALLEVEL5} alt={SIGNALLEVEL5} />
                  : "Null"}
      </>
  )

}
export default SignalLevel
