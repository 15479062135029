import { BANKS_ACTIONS } from "./powerbanksReducer";

export const getPowerBanksList = (page) => {
  return {
    type: BANKS_ACTIONS.GET_LIST_REQUEST,
    payload: { page },
  };
};

export const FilterBanksByStatus = ({ page, status, checkingStatus }) => {
  return {
    type: BANKS_ACTIONS.GET_LIST_BY_STATUS_REQUEST,
    payload: { page, status, checkingStatus },
  };
};

export const OpenBankInfo = (data, page) => {
  return {
    type: BANKS_ACTIONS.OPEN_BANKS_INFO,
    payload: { data, page },
  };
};

export const ClearBankInfo = () => {
  return {
    type: BANKS_ACTIONS.CLEAR_BANKS_INFO,
  };
};

export const DeletePowerBank = (data, page) => {
  return {
    type: BANKS_ACTIONS.DEL_POWERBANK_REQUEST,
    payload: { data, page },
  };
};

export const FindPowerBankById = (powerBankId) => {
  return {
    type: BANKS_ACTIONS.FINDING_REQUEST,
    payload: { powerBankId },
  };
};

export const downloadPowerbanksList = (payload) => ({
  type: BANKS_ACTIONS.DOWNLOAD_POWERBANKS_LIST_ASYNC,
  payload,
});

export const photoControlById = (id) => ({
  type: BANKS_ACTIONS.PHOTO_CONTROL_ASYNC,
  payload: id,
});

export const blockPowerBank = (id, block, comment) => ({
  type: BANKS_ACTIONS.BLOCK_POWERBANK_ASYNC,
  payload: { id, block, comment },
});

export const searchPowerbank = (value) => ({
  type: BANKS_ACTIONS.SEARCH_POWERBANK_ASYNC,
  payload: { value },
});

export const searchPowerbankByStation = (value) => ({
  type: BANKS_ACTIONS.SEARCH_POWERBANK_BY_STATION_ASYNC,
  payload: { value },
});

export const searchPowerbankCollection = (value) => ({
  type: BANKS_ACTIONS.SEARCH_POWERBANK_COLLECTION_ASYNC,
  payload: value,
});

export const setCrossLinkPowerbankId = (value) => ({
  type: BANKS_ACTIONS.SET_CROSS_LINK_POWERBANK_ID,
  payload: value
})
