import React from 'react';
import {InfoModalCard} from "../../../components/common/InfoModal/InfoModal";
import {Translation} from "react-i18next";
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCrossLinkPowerbankId } from '../../../modules/powerbanks/powerbanksAction';

const styles = {
    showListInfo: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        color: 'rgba(255, 255, 255, 0.87)',
    },
    BatteryFull: {
        color: 'rgba(101, 227, 42, 0.87)',
    },
    BatteryLowFull: {
        color: 'rgba(170,239,137,0.87)',
    },
    BatteryMiddle: {
        color: 'orange',
    },
    BatteryLowMiddle: {
        color: 'red',
    },
    BatteryLow: {
        color: 'rgb(238,120,123)',
    },
    showList: {
        marginTop: '10px',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '10px',
        lineHeight: '16px',
        letterSpacing: '1.5px',
        textTransform: 'uppercase',
        color: 'rgba(255, 255, 255, 0.6)',
    },
    powerbankCrossLink: {
        borderRadius: '5px',
        padding: '10px',
        background: 'rgb(95, 148, 226)',
        color: 'white',
        fontSize: '16px',
        border: 'none',
    }
}

export const StationPowerBanksInfo = ({info, close}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const showInPowerbanksList = (i) => {
        dispatch(setCrossLinkPowerbankId(i.powerBankId))
        history.push('powerBank');
    }

    return (
        <Translation>
            {(t) =>
                <InfoModalCard ClickClose={close} ModalSize={'Small'} position={'right'}>
                    <div>
                        <span style={styles.showList}>{t("Station Number")}</span>
                        <p style={styles.showListInfo}>{info.station.stationNumber ? info.station.stationNumber : t('...')}</p>
                        <span style={styles.showList}>{t("Station Address")}</span>
                        <p style={styles.showListInfo}>{info.station.address ? info.station.address : t('...')}</p>
                        <hr/>
                    </div>

                    {info.stationInfo.length && info.stationInfo.map((i) => (
                        <div style={{marginTop: '10px'}}>
                            <span style={styles.showList}>{t("Battery Level")}</span>
                            <p className={'PercentLevelStyle'} style={
                                i.batteryLevel === 'PERCENT_81_100' ?
                                    styles.BatteryFull :
                                    i.batteryLevel === ' PERCENT_61_80' ?
                                        styles.BatteryLowFull :
                                        i.batteryLevel === ' PERCENT_41_60' ?
                                            styles.BatteryMiddle :
                                            i.batteryLevel === ' PERCENT_21_40' ?
                                                styles.BatteryLowMiddle :
                                                i.batteryLevel === ' PERCENT_1_20' ?
                                                    styles.BatteryLow
                                                    : styles.showListInfo
                            }>{i.batteryLevel ? i.batteryLevel : '...'}</p>
                            <span style={styles.showList}>{t("Slot")}</span>
                            <p style={styles.showListInfo}>{i.slot ? i.slot : '...'}</p>
                            <span style={styles.showList}>{t("PowerBank Id")}</span>
                            <p style={styles.showListInfo}> {i.powerBankId ? i.powerBankId : '...'}</p>
                            <button style={styles.powerbankCrossLink} onClick={() => showInPowerbanksList(i)}>{t("crossPowerbankLink")}</button>
                            <hr/>
                        </div>
                    ))
                    }
                </InfoModalCard>
            }</Translation>
    )
}