import TextField from "@material-ui/core/TextField";
import React from "react";

const PhoneNUmber = ({Length,pattern,disabled,value,type,onChange,name,label,onClick, style={}, children,inputProps, ...props}) => {
  return (

    <div style={{ marginTop: '10px'}}>
      <TextField
        id="outlined-select-currency-native"
        value={value}
        type={'tel'}
        label={label}
        placeholder={'+7(000) 000-00-00'}
        name={'phoneNumber'}
        InputLabelProps={{style:{border:'none',fontSize: '14px', lineHeight: '16px', letterSpacing: '0.4px',color:'rgba(255, 255, 255, 0.6)',background:'#121212',padding:'0 10px 0 0 '},}}
        inputProps={{maxLength :Length, pattern:"^[+0-9]\\d*", style: {color:'gray',outline:'none',width:'280px',fontFamily: 'Arial',border:'1px solid white',borderRadius: '5px',}}}
        onChange={onChange}
        SelectProps={{native: true,}}
        variant="outlined"
      />
    </div>
  )
};

export default PhoneNUmber
