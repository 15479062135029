import {takeLatest, call, put} from "@redux-saga/core/effects";
import * as api from "../../../api";
import {ADD_STATION_IMAGE} from "./AddStationImageReducer";

function* handleSaga() {
  yield takeLatest(ADD_STATION_IMAGE.ADD_IMAGE_REQUEST,handleAddSaga)

}
function* handleAddSaga(action) {
  try {
    const {data}=yield call(api.AddImage,action.payload.image,action.payload.stationNumber)
    yield put({
      type:ADD_STATION_IMAGE.ADD_IMAGE_SUCCESS,
      payload:{data}
    })
  }catch (e) {
    yield put({
      type:ADD_STATION_IMAGE.ADD_IMAGE_FAIL,
      message:e.message
    })
  }
}

export default handleSaga