import {STATES_DEL} from "./DeleteStationReducer";

export const DeleteStations = (stationNumber,page) => {
  return {
    type: STATES_DEL.DELETE_STATION_REQUEST,
    payload: {stationNumber,page}
  }
}

export const sendStationNumber = (data) => {
  return {
    type: STATES_DEL.SEND_STATION_NUMBER,
    payload: {data}
  }
}

export const LockUnlockStation = (data) => {
  return {
    type: STATES_DEL.LOCK_UNLOCK_STATION,
    payload: {data}
  }
}

export const ClearLockUnlockStationInfo = () => {
  return {
    type: STATES_DEL.CLEAR_LOCK_UNLOCK_STATION_INFO,

  }
}

export const ClearSendedStationNumber = () => {
  return {
    type: STATES_DEL.CLEAR_SENDED_STATION_NUMBER,
  }
}
