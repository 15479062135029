import {TAKE_BANK} from "./TakeBankReducer";

export const takePowerBanke =(stationId,slotNumber)=>{
  return{
    type:TAKE_BANK.TAKE_BANK_REQUEST,
    payload:{stationId,slotNumber}
  }
}

export const closePopupExtractBank = () => {
  return {
    type: TAKE_BANK.CLOSE_POPUP_EXTRACT_BANK
  }
}