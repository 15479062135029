import React from "react";
import TextField from "@material-ui/core/TextField";

export const UniversalTextField = ({ value, label, onChange, placeholder }) => {
  return (
    <TextField
      variant="outlined"
      value={value}
      // hiddenLabel
      // label={label}
      onChange={onChange}
      placeholder={placeholder}
      InputLabelProps={{
        style: {
          fontSize: "14px",
          lineHeight: "16px",
          letterSpacing: "0.4px",
          color: "rgba(255, 255, 255, 0.6)",
          // background:
          //   "linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212",
          // padding: "0 10px 0 0 ",
          outline: "none",
          border: "none",
        },
      }}
      SelectProps={{ native: true }}
      inputProps={{
        style: {
          color: "gray",
          background: "white",
          outline: "none",
          border: "none",
          width: "100%",
          fontFamily: "Arial",
          borderRadius: "5px",
        },
      }}
      // multiline
    />
  );
};
