import React, {useEffect, useState} from "react"
import ReactApexChart from "react-apexcharts"

export const BarChart = ({dataValues, isHorizontal, stacked = false, height }) => {
    const [dates, setDates] = useState([]);
    const [values, setValues] = useState([]);
    const [data, setData] = useState({
        series: [{
            name: "",
            data: []
        }],
        options: {
            dataLabels: {
                enabled: false,
            },
            tooltip: {
                enabled: true,
                style: {
                    fontSize: '13px'
                },
                theme: 'dark',
                x: {
                    show: false
                }
            },
            chart: {
                toolbar: {
                    show: false
                },
                height: height,
                type: 'bar',
                zoom: {
                    enabled: false
                },
                stacked: stacked,
                stackType: '100%'
            },
            plotOptions: {
                bar: {
                    horizontal: isHorizontal
                }
            },
            colors: ['#ecc53c', '#3c90ec', '#3ceca6', '#c03cec', '#ec3c5c', '#ec853c', '#3c90ec'],
            grid: {
                show: true,
                borderColor: '#525252',
                yaxis: {
                    lines: {
                        show: true
                    }
                },
            },
            xaxis: {
                categories: [],
                axisBorder: {
                    show: true,
                    color: 'white',
                    height: 2
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: true,
                    style: {
                        colors: "white",
                        fontSize: '12px'
                    },
                },
            },
            yaxis: {
                axisBorder: {
                    show: true,
                    color: 'white',
                    height: 2
                },
                labels: {
                    show: true,
                    style: {
                        colors: "white",
                        fontSize: '12px'
                    },
                },
            },
        }
    });

    // set dates and values for chart
    useEffect(() => {
        setDates(dataValues.categories);
        setValues(dataValues.series);
    }, [dataValues]);

    // put dates and values inside chart data
    useEffect(() => {
        setData(pv => ({
            ...pv,
            series: values || [{
                name: "",
                data: []
            }],
            options: {
                ...pv.options,
                xaxis: {
                    ...pv.options.xaxis,
                    categories: dates || []
                }
            }
        }))
    }, [dates, values]);

    return (
        <div className="lostPowerbanksChartMainScope">
            <ReactApexChart options={data.options} series={data.series} type='bar' height={height}/>
        </div>
    )
}