export const RENTS_INFO = {
  SEND_USERS_RENT_INFO:'RENTS_INFO.SEND_USERS_RENT_INFO',
  CLEAR_USERS_RENT_INFO:'RENTS_INFO.CLEAR_USERS_RENT_INFO'
}

const initialState = {
  UsersRentInfo: [],
  RentPage:[]
}

export default (state = initialState, action) => {

  switch (action.type) {
    case RENTS_INFO.SEND_USERS_RENT_INFO: {
      return {...state, UsersRentInfo: action.payload.data, RentPage: action.payload.page}
    }
    case RENTS_INFO.CLEAR_USERS_RENT_INFO: {
      return {...state, UsersRentInfo:''}
    }
    default: {
      return state
    }
  }
}