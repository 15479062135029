import {STATES} from "../stationsReducer";

export const GET_IMG ={
  STATION_IMG_REQUEST:'GET_IMG.STATION_IMG_REQUEST',
  STATION_IMG_SUCCESS:'GET_IMG.STATION_IMG_SUCCESS',
  STATION_IMG_FAIL:'GET_IMG.STATION_IMG_FAIL',

  STATION_ALL_IMG_REQUEST:'GET_IMG.STATION_ALL_IMG_REQUEST',
  STATION_ALL_IMG_SUCCESS:'GET_IMG.STATION_ALL_IMG_SUCCESS',

}


const initialState={
  StationInfo:[],
  StationAllImg:[]
}

export default (state=initialState,action)=>{
  switch (action.type) {
    case GET_IMG.STATION_IMG_REQUEST:{
      return{...state,StationInfo:initialState.StationInfo}
    }
    case GET_IMG.STATION_IMG_SUCCESS:{
      return{...state,StationInfo:action.payload.data}
    }
    case GET_IMG.STATION_IMG_FAIL:{
      return{...state,StationInfo:initialState.StationInfo}
    }
    case GET_IMG.STATION_ALL_IMG_SUCCESS:{
      return{...state,StationAllImg:action.payload.data}
    }
    default:{
      return state
    }

  }
}