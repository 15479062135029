import React from 'react';
import rightArrowPng from '../../../../../assets/images/up.png';
import moment from 'moment';
import {Translation} from "react-i18next";

const styles = {
  cardStyle: {
    color:'white',
    display: 'flex',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), #121212',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14)',
    borderRadius: '5px',
    marginBottom: '8px',
    cursor: 'pointer',
  },
  numberStyle: {
    margin: 0,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    letterSpacing: '0.005em',
    color: 'white',
  },
  dateStyle: {
    fontStyle: 'normal',
    fontWeight: 500,
    // lineHeight: '20px',
    fontSize: '12px',
    letterSpacing: '0.005em',
    color: 'white',
  },
  infoStyle: {
    margin: 0,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    color: '#000000',
  }
};


export const RentHistoryCard = ({item, openRent}) => {
  const RantDay =   <Translation>{(t) =>
    moment.tz(item.startDate, 'YYYY-MM-DD/HH:mm', 'Europe/Moscow').local().format(`DD.MM.YYYY ${t("in")} HH:mm`)
  }</Translation>
  const RentNumber = item.id.slice(-4);

  return (
    <Translation>
      {(t) =>
        <>
    <div
      style={{...styles.cardStyle, border: item.debt ? '2px solid #EE1F35' : 'none'}}
      onClick={openRent}
    >
      <div style={{marginLeft: '13px', marginTop: '8px'}}>
        <p style={styles.numberStyle}>{'№ ' + RentNumber || '? 344-43 '}</p>
        <div style={{display: 'flex', flexDirection: 'column', marginTop: '12px'}}>
          <span style={{fontSize: '12px'}}>{t("DATE")}</span>
            <span style={styles.dateStyle}>{RantDay}</span>
        </div>
      </div>

      <div style={{display: 'flex', flexDirection: 'column', marginLeft: "20px", marginTop: '8px'}}>
        <div style={{display: 'flex', alignItems: 'center',}}>
          {item.rentStatus === "PROCESSING" ?
            <span style={{color: 'green'}}>{t("In Progress")}</span>
            :
            <span style={{color: 'red'}}>{t("Finished")}</span>
          }
          <img style={{marginLeft: '10px', width: '7px', height: '12px'}} src={rightArrowPng} alt={rightArrowPng}/>
        </div>
        <div style={{marginRight: '20px', display: 'flex', flexDirection: 'column', marginTop: '14px'}}>
          <span style={{fontSize: '12px', marginTOp: '60px'}}>{t("PRICE")}</span>
          <span>{`${item.totalAmount} ${item.groupTariffs ? item.groupTariffs.location === 'RU' ? '₽' : '֏' : ''}`}</span>
        </div>
      </div>
    </div>
        </>
      }
    </Translation>
  )
}




