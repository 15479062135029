import {put,call, takeLatest} from "@redux-saga/core/effects";
import {GET_TARIFF} from "./tariffsReducer";
import * as api from "../api";
import {GetAllGroupTariffs} from "./tariffsAction";

function* handleSaga() {
  yield takeLatest(GET_TARIFF.GET_TARIFFS_REQUEST,tariffSaga)
}

function* tariffSaga(action) {
  try {
    const {data} = yield call(api.GetTariff)
    yield put({
      type:GET_TARIFF.GET_TARIFFS_SUCCESS,
      payload:{data}
    })
  }
  catch (e) {
    yield put({
      type:GET_TARIFF.GET_TARIFFS_FAIL,
      message:e.message
    })
  }

}


export function* GetAllGroup() {
  yield takeLatest(GET_TARIFF.GET_ALL_GROUP_REQUEST, makeDefaultCard);
}


function* makeDefaultCard() {
  try {
    const {data} = yield call(api.GetAllGroup)
    yield put({
      type:GET_TARIFF.GET_ALL_GROUP_SUCCESS,
      payload:{data}
    })
  }
  catch (e) {
    console.log(e)
  }
}


export function* DelTariff() {
  yield takeLatest(GET_TARIFF.DELETE_TARIFF_REQUEST, DelTariffSaga);
}


function* DelTariffSaga(action) {
  try {
    const {data} = yield call(api.DeleteTariffApi,action.payload.data)
    yield put({
      type:GET_TARIFF.DELETE_TARIFF_SUCCESS,
      payload:{data}
    })
    yield put(GetAllGroupTariffs())
  }
  catch (e) {
    console.log(e)
  }
}



export default handleSaga
