import {put, takeLatest,call} from "@redux-saga/core/effects";
import {LOCK_USERS} from "./lockUsersReducer";
import * as api from "../../api";
import {
  BlockActiveUserByPhone,
  BlockBlocedUserByPhone,
  BlockOneUserByPhone,
} from "./lockUsersAction";
import {getUserList} from "../usersActon";
function* LockSaga() {
yield takeLatest(LOCK_USERS.LOCK_USERS_REQUEST,handleSaga)
}

function* handleSaga(action) {
  try {
    const {data}=yield call(api.lockUser,action.payload.phoneNumber, action.payload.lock)
    yield put({
      type:LOCK_USERS.LOCK_USERS_SUCCESS,
      payload:{data}
    })
    yield put(getUserList(action.payload.page))
  }catch (e) {
    yield put({
      type:LOCK_USERS.LOCK_USERS_FAIL,
      message:e.message
    })
  }
}

export default LockSaga
