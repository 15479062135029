import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {connect} from "react-redux";
import {downloadStationList, FilterStationByActive, FilterStationByBlocked,FindStationByNumber, getActiveStations, getBlockedStations, GetStation
} from "../../modules/stations/stationsAction";
import {DeleteStations, sendStationNumber} from "../../modules/stations/DeleteSelectedStation/DeleteStationAction";
import {GetStationImg, GetStationManyImg} from "../../modules/stations/GetStationImg/GetStationImgAction";
import {createStation, EditStationInfo, sendStationInfo} from "../../modules/stations/stationInfo/stationInfoAction";
import NewButton from "../../components/common/Buttons/ForNewButton";
import {TableCEll} from "../../components/common/TableCEll";
import {Card} from "../../components/common/Card";
import {DeleteModal} from "../../components/common/modalPopup/DeleteModal";
import {Translation} from "react-i18next";
import StationInfoList from "./components/StationInfoList";
import {Page} from "../../components/common/Pages";
import {TableSortLabel} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import another from '../../assets/images/another.png'
import {NotFound} from "../../components/common/Asyncs/NotFound";
import {Loader} from "../../components/common/Asyncs/Loader";
const useStyles = {
  BodyTableCellRight: {
    borderBottomRightRadius: '5px',
    borderTopRightRadius: '5px',
    color: 'white',
  },
}
class Stations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stationNumber: '',
      activeIndex: '',
      showModal:false,
      StationInfos:'',
      page:0,
      closePage:true,
      DownloadInfo:false,
      AllStationsList:'',
      allFilterButtons:[]
    }
  }

  componentDidMount() {
    this.props.GetStation(0);
    this.setState({
      allFilterButtons: [
        {name: 'All', fn: this.handleAll},
        {name: 'Opened', fn: this.handleOpened},
        {name: 'Closed', fn: this.handleClosed}
      ]
    });
  }
  FindeHandleChange = (ev) => {
    this.setState({stationNumber: ev.target.value})
    if (ev.keyCode === 8) {
        if (this.state.stationNumber.length === 0 || this.state.stationNumber.length === 4 || this.state.stationNumber.length === 16) {
        this.props.GetStation(this.state.page)
        this.setState({closePage:true})
      }
    }
  }
  sendFindData = () => {
    const {stationNumber} = this.state
    if (stationNumber.length > 3) {
      sessionStorage.setItem('filterButtons','all')
      this.props.FindStationByNumber(stationNumber)
      this.setState({closePage:false})
    }
  }
  handleClick = (i,row) => {
    this.setState({activeIndex: i,StationInfos:row})
  }
  ClosePopup = () => {
    this.setState({activeIndex: ''})
  }
  openInfo = (row) => {
    this.props.GetStationImg(row.stationNumber)
    this.props.GetStationManyImg(row.stationNumber)
    this.props.sendStationInfo(row,this.state.page)
    this.setState({ activeIndex: ''})
  }
  editInfo = (row) => {
    this.setState({show: true, activeIndex: ''})
    this.props.EditStationInfo(row,this.state.page)
  }
  newStation = (data) => {
    this.props.createStation(data)
  }
  DeleteStation=()=>{
    this.props.DeleteStations(this.state.StationInfos.stationNumber,this.state.page)
    this.setState({showModal: false})
  }
  handleChange =(event,value)=>{
    this.props.GetStation(value - 1)
    this.setState({AllStationsList:'',page:value - 1,closePage:true})
  }

  handleAll = () => {
    this.props.GetStation(this.state.page)
    this.setState({AllStationsList :'',closePage:true,stationNumber:''})
  }
  handleOpened = () => {
    this.props.FilterStationByActive()
    this.setState({AllStationsList :'',closePage:false,stationNumber:''})
  }
  handleClosed = () => {
    this.props.FilterStationByBlocked()
    this.setState({AllStationsList :'', closePage:false,stationNumber:''})
  }

  GetFile=()=>{
    this.props.downloadStationList()
    this.setState({DownloadInfo:true})
  }

  handleRevert =()=>{
    this.setState({AllStationsList: this.props.AllStations.reverse()})
  }
  render() {

    const StationsListArray =  this.state.AllStationsList ? this.state.AllStationsList : this.props.AllStations
    const {showModal,StationInfos,DownloadInfo}=this.state
     const {pageCount,stationCount} = this.props.PageInfo
    return (
      <Translation>{(t) => <>
      <Card 
        downloadList={this.GetFile} 
        download={true} 
        TitleName={'Charge Station'} 
        value={this.state.stationNumber} 
        Count={stationCount} 
        search={true}
        clearSearch={this.state.stationNumber.length && this.handleAll }
        place={'Search stations'} 
        onClick={this.sendFindData} 
        onChange={this.FindeHandleChange} 
        onKeyDown={this.FindeHandleChange}
        FilterBoolean={true} 
        revert={this.handleRevert}
        allButtons={this.state.allFilterButtons}
      >
        {DownloadInfo &&
        <div className='headDiv'>
          <div  className={'ModalPosition'}>
            <div  className='ModalStyle'>
              <span>{t('Are you sure you want download Station list')}</span>
              <div className='ModalButtons'>
                <button onClick={() => this.setState({DownloadInfo:false})}>{t('Cancel')}</button>
                <a onClick={() => this.setState({DownloadInfo:false})} href={this.props.DownloadList} download>{t('Download')}</a>
              </div>
            </div>
          </div>
        </div>
        }
        {showModal &&
        <DeleteModal
          boolean={showModal}
          text={t( "Are you sure you want delete Station" )+ StationInfos.stationNumber}
          Cancel={'Cancel'} Delete={'Delete'}
          handleCancel={() => {this.setState({showModal: false})}}
          handleDelete={() =>{this.DeleteStation()}}
        />}

              <TableSortLabel/>
            <Table size="small">
              <TableHead onClick={() => this.handleClick('')} style={{borderBottom: '1px solid grey',}}>
                       <TableCEll text={['ID','Address','Station Number','Work hours','Charges','Status'] }/>
              </TableHead>
              <TableCell style={{border: 'none', padding: '1px'}}>.</TableCell>
              {stationCount === 0 ?   <NotFound/> : this.props.AllStations === 'not found' ?  <NotFound/>  :
                StationsListArray.length > 0 ?
                    StationsListArray.map((row, i) => (
                      row !== undefined ?
                      <TableBody key={i}>
                        {this.state.activeIndex === i ?
                          <div onMouseLeave={() => {this.ClosePopup('')}} className={'activeIndexStyleForBigModal'}>
                             <div className={'activeIndexButtonsStyle'}><button onClick={() => {this.openInfo(row.station ? row.station : row)}} className={'openInfoButton'}>{t('Open Info')}</button>
                               <button onClick={() => {this.editInfo(row.station ? row.station : row)}} className={'EditInfoButton'}>{t('Edit station')}</button>
                               <button onClick={() => {this.setState({showModal:true})}} className={'blockUSersButton'}>{t('Delete station')}</button>
                             </div>
                            <StationInfoList
                              GetList={this.props.GetStation}
                              page={this.state.page}
                              row={row}
                            />
                          </div>
                          : ''}
                        <TableRow className={'BodyTableRow'}  key={row.id}>
                          <TableCell onClick={() => {this.openInfo(row.station ? row.station : row)}} className={'BodyTableCellCheck'}>{row.stationID ? row.stationID:row.station.stationID ? row.station.stationID: ''}</TableCell>
                          <TableCell onClick={() => {this.openInfo(row.station ? row.station : row)}} className={'BodyTableCell'}>{row.address ? row.address.slice(0, 40) + '...' : row.station.address ? row.station.address.slice(0, 40) + '...' : '...' }</TableCell>
                          <TableCell onClick={() => {this.openInfo(row.station ? row.station : row)}} className={'BodyTableCell'}>{row.stationNumber ? row.stationNumber : row.station.stationNumber ? row.station.stationNumber :'...'}</TableCell>
                          <TableCell onClick={() => {this.openInfo(row.station ? row.station : row)}} className={'BodyTableCell'}>{t(
                            row.workTime ?
                              Object.values(row.workTime)[0][0] === "ANYTIME" ?
                                "All Day"
                                :
                                Object.keys(row.workTime).join('.').slice(0, 20) + '...'
                              :
                              row.station.workTime ?
                                Object.values(row.station.workTime)[0][0] === "ANYTIME" ?
                                  "All Day" : Object.keys(row.station.workTime).join('.').slice(0, 20) + '...' :null
                          )}
                          </TableCell>
                            <TableCell onClick={() => {this.openInfo(row.station ? row.station : row)}}  className='BodyTableCell'>{row.numberSlots ? row.numberSlots : row.station.numberSlots ? row.station.numberSlots :'...'}</TableCell>
                          {!row.station ?
                            <TableCell style={useStyles.BodyTableCellRight}>
                              {row.availability === false  ?
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                  <span onClick={() => {this.openInfo(row.station ? row.station : row)}} style={{marginTop: '4px', color: 'rgba(255, 255, 255, 0.6)'}}>{t('CLOSED')}</span>
                                  <Button onClick={() => this.handleClick(i,row)}><img src={another} alt={another}/></Button>
                                </div>
                                :
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                  <span onClick={() => {this.openInfo(row.station ? row.station : row)}} style={{color: 'rgba(101, 227, 42, 0.87)', marginTop: '4px',}}>{t('OPEN')}</span>
                                  <Button onClick={() => this.handleClick(i,row)}><img src={another} alt={another}/></Button>
                                </div>
                              }
                            </TableCell>
                            :
                            <TableCell style={useStyles.BodyTableCellRight}>
                              {row.station.availability === false  ?
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                  <span onClick={() => {this.openInfo(row.station ? row.station : row)}} style={{marginTop: '4px', color: 'rgba(255, 255, 255, 0.6)'}}>{t('CLOSED')}</span>
                                  <Button onClick={() => this.handleClick(i)}><img src={another} alt={another}/></Button>
                                </div>
                                :
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                  <span onClick={() => {this.openInfo(row.station ? row.station : row)}} style={{color: 'rgba(101, 227, 42, 0.87)', marginTop: '4px',}}>{t('OPEN')}</span>
                                  <Button onClick={() => this.handleClick(i)}><img src={another} alt={another}/></Button>
                                </div>
                              }
                            </TableCell>
                          }
                        </TableRow>

                          {/*<UsersStatus*/}
                          {/*  name={'Station'}*/}
                          {/*  statusBoolean={row.blocking !== false }*/}
                          {/*  Status={row.availability === true ? 'OPEN' : 'CLOSED' }*/}
                          {/*  openTableInfo={()=>this.openInfo(row.station ? row.station : row)}*/}
                          {/*  onClick={()=>this.handleClick(i)} row={row}*/}
                          {/*/>*/}
                        {/*</TableRow>*/}
                      </TableBody>  :'')) :  <Loader/>}
              {this.state.closePage &&
              <Page page={this.state.page + 1}
                    handleChange={this.handleChange}
                    PageCount={pageCount}/>}
            </Table>
            <NewButton onClick={() => this.props.createStation('open')} ButtonText={'+ New Station'}/>
           </Card>
          </>}</Translation>
    );
  }
}

const mapStateToProps = state => ({
  AllStations: state.stationsReducer.AllStations,
  PageInfo: state.stationsReducer.PageInfo,
  DownloadList: state.stationsReducer.DownloadList,
})
export default connect(mapStateToProps, {GetStationManyImg,downloadStationList,FindStationByNumber,FilterStationByBlocked,FilterStationByActive,sendStationNumber, GetStationImg, EditStationInfo, DeleteStations, GetStation, sendStationInfo, createStation, getActiveStations, getBlockedStations
})(Stations);