import React, {Component} from 'react';
import {connect} from "react-redux";
import {CloseTariffsPopup, DeleteTariffAction} from "../../../modules/tariff/tariffsAction";
import Button from "@material-ui/core/Button";
import CloseVector from "../../../assets/images/CloseVector.png";
import {DeleteModal} from "../../../components/common/modalPopup/DeleteModal";
import {Card} from "../../../components/common/Card";
import {Translation} from "react-i18next";


const styles = {
  hideDiv: {
    overflowY: 'scroll',
    overflowX:'hidden',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
    boxShadow: '-2px 0px 4px rgba(0, 0, 0, 0.2), -1px 0px 10px rgba(0, 0, 0, 0.12), -4px 0px 5px rgba(0, 0, 0, 0.14)',
    width: '300px',
    height: '100%',
    position: 'absolute',
    minHeight: '100vh',
    zIndex: '20',
    right: 0,
    boxSizing: 'border-box',
  },
  hideDivCloseButton: {
    background: 'none',
    border: 'none',
    marginLeft: '220px',
    marginTop: '5px'
  },
  showList: {
    marginTop: '10px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    color: 'rgba(255, 255, 255, 0.6)',
  },
  showListInfo: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: 'rgba(255, 255, 255, 0.87)',
  },
}
class TariffsList extends Component {
  constructor(props) {
    super(props);
    this.state={
      showModal:false
    }
  }
  DeleteTariff=()=>{
    const tariffId =this.props.tariffList ? this.props.tariffList.id : ''
    this.props.DeleteTariffAction(tariffId)
    this.setState({showModal: false})
    this.props.CloseTariffsPopup()
  }
  render() {
   const {showModal}=this.state
    const tariffList = Object.values(this.props.tariffList)
    return (
        <>
          <Translation>
            {(t) =>
              <>
          {tariffList.length > 0 ?
            <div className='ListStyle'>
              {showModal &&
              <DeleteModal
                boolean={showModal}
                text={`${t("Are you sure you want delete tariff")} - ${this.props.tariffList ?this.props.tariffList.name : '...'}`}
                Cancel={'Cancel'} Delete={'Delete'}
                handleCancel={() => {this.setState({showModal: false})}}
                handleDelete={() =>{this.DeleteTariff()}}
              />}
              <div onClick={()=>{this.props.CloseTariffsPopup()}} className={'CloseSectionClass'}  />

              <div style={styles.hideDiv}>
                <>
                  <Button onClick={()=>{this.props.CloseTariffsPopup()}} style={styles.hideDivCloseButton}>
                    <img src={CloseVector} alt={CloseVector}/></Button>
                  <div style={{display: 'flex', flexDirection: 'column', marginLeft: '24px',}}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <span style={styles.showList}>{t('ID')}</span>
                       </div>
                    <span style={styles.showListInfo}>{this.props.tariffList.id}</span>
                    <span style={styles.showList}>{t('Name')}</span>
                    <span style={styles.showListInfo}>{this.props.tariffList.name}</span>
                    <span style={styles.showList}>{t('Location')}</span>
                    <span style={styles.showListInfo}>{this.props.tariffList.location}</span>
                    <span style={styles.showList}>{t('Tariff Type')}</span>
                    <span style={styles.showListInfo}>{this.props.tariffList.tariffType ? this.props.tariffList.tariffType : '...' }</span>
                    <span style={styles.showList}>{t('Holding amount')}</span>
                    <span style={styles.showListInfo}>{`${this.props.tariffList.holdAmount} ${this.props.tariffList.location === 'RU' ? '₽' : '֏' }`}</span>
                    <span style={styles.showList}>{t('Penalty amount')}</span>
                    <span style={styles.showListInfo}>{`${this.props.tariffList.penaltyAmount} ${this.props.tariffList.location === 'RU' ? '₽' : '֏' }`}</span>

                    {this.props.tariffList.parts.length ?
                      this.props.tariffList.parts.map((row, i) => (
                     <div  key={i} style={{marginTop:'30px'}}>
                       <hr style={{width: '100%'}}/>
                       <span>{`${t('Period')} № ${row.order}`}</span>
                       <span style={{marginLeft:'20px'}}>{`${row.name}`}</span>
                       <div style={{display: 'flex',marginTop:'20px'}}>
                       <div style={{display:'flex',flexDirection:'column'}}>
                         <span style={{color:'rgba(255, 255, 255, 0.6)'}}>{t('Time')}</span>
                          <span>{`${row.timePeriod} ${row.timeUnit}`}</span>
                       </div>

                       <div style={{marginLeft:'60px',display:'flex',flexDirection:'column'}}>
                         <span style={{color:'rgba(255, 255, 255, 0.6)'}}>{t("Price")}</span>
                        <span>{row.price === 0 ? 'Free' :`${row.price}  ${this.props.tariffList.location === 'RU' ? '₽' : '֏' }`}</span>
                       </div>


                       </div>
                       <div style={{display:'flex',flexDirection:'column'}}>
                         <span style={{color:'rgba(255, 255, 255, 0.6)'}}>{t('Period count')}</span>
                         <span>{`${row.periodCount}`}</span>
                       </div>
                      </div>

                      )) : ''}
                    <Button className='DeletePopupStyle' onClick={()=>this.setState({showModal:true})}>{t("DELETE TARIFF")}</Button>
                  </div>
                </>
              </div>
            </div>
            :""
          }
              </>}</Translation>
      </>
    );
  }
}

const mapStateToProps = state => ({
  tariffList:state.tariffsReducer.tariffList,
})

export default connect(mapStateToProps, {DeleteTariffAction,CloseTariffsPopup
})(TariffsList);
