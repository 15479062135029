import { STATES } from "./stationsReducer";

export const GetStation = (page) => {
  return {
    type: STATES.STATE_INFO_REQUEST,
    payload: { page },
  };
};
export const getActiveStations = (data) => {
  return {
    type: STATES.ACTIVE_STATIONS,
    payload: { data },
  };
};

export const getBlockedStations = (data) => {
  return {
    type: STATES.BLOCKED_STATIONS,
    payload: { data },
  };
};
export const ClearFindingStationData = () => {
  return {
    type: STATES.CLEAR_FINDING_STATE_INFO,
  };
};

export const FilterStationByActive = () => {
  return {
    type: STATES.FILTER_ACTIVE_STATIONS,
  };
};

export const FilterStationByBlocked = () => {
  return {
    type: STATES.FILTER_BLOCKED_STATIONS,
  };
};

export const FindStationByNumber = (stationNumber) => {
  return {
    type: STATES.FINDING_STATION_REQUEST,
    payload: { stationNumber },
  };
};

//
// export const cechStation =(stationNumber)=>{
//   return {
//     type:STATES.CECH_STATION_REQUEST,
//     payload: {stationNumber}
//   }
// }

export const downloadStationList = () => {
  return {
    type: STATES.DOWNLOAD_STATION_LIST_REQUEST,
  };
};

export const ReloadStationAction = (stationID) => {
  return {
    type: STATES.RELOAD_STATION_REQUEST,
    payload: { stationID },
  };
};
