import React, { Component } from 'react';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from "../../assets/images/Avatar.png";
import ListItemText from "@material-ui/core/ListItemText";
import settings from "../../assets/images/settings.png";
import { Link, withRouter } from "react-router-dom";
import UserIcon from "../../assets/images/Vectorss.png";
import ActiveDashboard from "../../assets/images/ActivDashboard (1).png";
import activeDoc from "../../assets/images/activeDoc.png";
import ActiveStation from "../../assets/images/ActivStation.png";
import ActiveRents from "../../assets/images/ActiveRents.png";
import ActivePowerbanks from "../../assets/images/ActivePowerbanks.png";
import Powerbanks from "../../assets/images/PowerBank.png";
import ActiveAdmins from "../../assets/images/ActiveAdmins.png";
import ActiveTariffs from "../../assets/images/ActiveTariffs.png";
import QAactiove from "../../assets/images/QAactiove.png";
import QA from "../../assets/images/Q&A.png";
import doc from "../../assets/images/doc.png";
import Dashboard from "../../assets/images/Dashboard.png";
import StationIcon from "../../assets/images/station.png";
import Rents from "../../assets/images/Rents.png";
import bank from "../../assets/images/bank.png";
import AdminUsers from "../../assets/images/AdminUsers.png";
import SignOut from "../../assets/images/SignOut.png";
import User from "../../assets/images/User.png";
import tariffs from "../../assets/images/tariffs.png";
import Finance from "../../assets/images/Finance.png";
import activeFinance from "../../assets/images/Activefinance.png";
import merchants from "../../assets/images/merchants.png";
import ActicMerchants from "../../assets/images/ActicMerchants.png";
import Admins from "../../assets/images/Admins.png";
import { connect } from "react-redux";
import { ClearAuthorization, getSignInParams } from "../../modules/signIn/signInAction";
import { GetRegMerchants } from "../../modules/merchantes/merchantesAction";
import { Translation } from "react-i18next";
import { Card } from "../../components/common/Card";


const styles = {
  LeftMenuConteiner: {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212',
    boxShadow: '2px 0px 4px rgba(0, 0, 0, 0.2), 1px 0px 10px rgba(0, 0, 0, 0.12), 4px 0px 5px rgba(0, 0, 0, 0.14)',
    minHeight: '100vh',
    height: 'auto',
    overflowX: 'hidden'
  },
  iconsStyle: {
    height: '18px',
  },
  iconsMerchantStyle: {
    height: '17px',
    width: '25px',
    marginLeft: '-2px'
  },
  linksStyle: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    width: '100%',
    height: '35px'
  },
  activeLinksStyle: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
    color: '#ECC53C',
  },
  ListItemTextStyle: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
    color: 'rgba(255, 255, 255, 0.87)',
  },
  Merchantlengt: {
    marginTop: '-1.3px',
    marginLeft: '4.4px',
    color: 'black',
    fontWeight: 'bold'

  },
  MerchantlengtDiv: {
    marginTop: '-15px',
    marginRight: '35px',
    background: 'red',
    width: '17px',
    height: '17px',
    borderRadius: '20px'
  }
}

function getMenuByRole(role, regMerchant) {
  if (role === 'ADMIN') {
    return [
      { link: '/', primary: 'Dashboard', src: Dashboard, activeSrc: ActiveDashboard },
      { link: '/station', primary: 'Charge Station', src: StationIcon, activeSrc: ActiveStation },
      { link: '/rents', primary: 'Rents', src: Rents, activeSrc: ActiveRents },
      { link: '/powerBank', primary: 'Powerbanks', src: Powerbanks, activeSrc: ActivePowerbanks },
      { link: '/merchants', primary: 'Admin’s users', src: merchants, activeSrc: ActicMerchants },
      { link: '/finance', primary: 'Finance', src: Finance, activeSrc: activeFinance },
      { link: '/license_agreement', primary: 'License Agreement', src: doc, activeSrc: activeDoc }
    ]
  }

  if (role === 'MERCHANT') {
    return [
      { link: '/', primary: 'Dashboard', src: Dashboard, activeSrc: ActiveDashboard },
      { link: '/station', primary: 'Charge Station', src: StationIcon, activeSrc: ActiveStation },
      { link: '/rents', primary: 'Rents', src: Rents, activeSrc: ActiveRents },
      { link: '/powerBank', primary: 'Powerbanks', src: Powerbanks, activeSrc: ActivePowerbanks },
      { link: '/finance', primary: 'Finance', src: Finance, activeSrc: activeFinance },
      { link: '/license_agreement', primary: 'License Agreement', src: doc, activeSrc: activeDoc }
    ]
  }

  return [
    { link: '/', primary: 'Dashboard', src: Dashboard, activeSrc: ActiveDashboard },
    { link: '/users', primary: 'Users', src: User, activeSrc: UserIcon },
    { link: '/station', primary: 'Charge Station', src: StationIcon, activeSrc: ActiveStation },
    { link: '/rents', primary: 'Rents', src: Rents, activeSrc: ActiveRents },
    { link: '/powerBank', primary: 'Powerbanks', src: Powerbanks, activeSrc: ActivePowerbanks },
    { link: '/tariff', primary: 'Tariffs', src: tariffs, activeSrc: ActiveTariffs },
    { link: '/partners', primary: 'Merchants', src: merchants, activeSrc: ActicMerchants, invite: regMerchant.length },
    { link: '/Q&A', primary: 'Q&A', src: QA, activeSrc: QAactiove },
    { link: '/documents', primary: 'Documents', src: doc, activeSrc: activeDoc },
    { link: '/support', primary: 'Support', src: doc, activeSrc: activeDoc },
    { link: '/finance', primary: 'Finance', src: Finance, activeSrc: activeFinance },
    { link: '/merchants', primary: 'Admin’s users', src: Admins, activeSrc: ActiveAdmins },
    { link: '/license_agreement', primary: 'License Agreement', src: doc, activeSrc: activeDoc }
  ]
}

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Role: ''
    }
  }

  LogOut = () => {
    localStorage.setItem('role', '')
    localStorage.setItem('Email', '')
    localStorage.setItem('token', '')
    window.location.reload()
  }
  componentDidMount() {
    const Role = localStorage.getItem('role')
    if (Role === 'SUPER_ADMIN') {
      this.props.GetRegMerchants()
    }
  }
  componentDidUpdate(prevProps) {
    return prevProps.CrossLinkPowerbankId !== this.props.CrossLinkPowerbankId
  }

  render() {
    // const email = localStorage.getItem('Email')
    const menu = getMenuByRole(localStorage.getItem('role'), this.props.regMerchant)

    const href = this.props.location.pathname
    const selectedMenuIndex = menu.findIndex(item => href === item.link)

    return (
      <div style={styles.LeftMenuConteiner}>
        <Translation>
          {(t) =>
            <>
              {
                menu.map((listItem, index) => (
                  <div key={index} style={{ marginTop: '0px', marginLeft: '7px' }}>
                    <ListItem button>
                      <Link to={listItem.link} style={styles.linksStyle}>
                        <ListItemIcon>
                          <img style={listItem.primary === 'Merchants' ? styles.iconsMerchantStyle : styles.iconsStyle}
                            src={selectedMenuIndex === index ? listItem.activeSrc : listItem.src}
                            alt={selectedMenuIndex === index ? listItem.activeSrc : listItem.src}
                          />
                        </ListItemIcon>
                        <ListItemText
                          style={selectedMenuIndex === index ? styles.activeLinksStyle : styles.ListItemTextStyle}
                          primary={t(listItem.primary)}
                        />
                        {listItem.invite ?
                          <div style={styles.MerchantlengtDiv}><p style={styles.Merchantlengt}>{listItem.invite}</p> </div> : ''}
                      </Link>
                    </ListItem>
                  </div>
                ))
              }

              <div>
                <ListItem style={{ marginTop: '50px', marginLeft: '8px' }} button>
                  <Link to={''} onClick={() => this.LogOut()} style={styles.linksStyle}>
                    <ListItemIcon>
                      <img style={styles.iconsStyle} src={SignOut} alt={SignOut} />
                    </ListItemIcon>
                    <span style={{ color: 'white' }}>{t('Log Out')}</span>
                    <ListItemText />
                  </Link>
                </ListItem>
              </div>
            </>}
        </Translation>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  signInData: state.signInReducer.signInData,
  regMerchant: state.MerchantesReducer.regMerchant,
  CrossLinkPowerbankId: state.powerbanksReducer.crossLinkPowerbankId,
})

export default connect(mapStateToProps, { GetRegMerchants })(withRouter(List));
