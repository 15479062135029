import { powerbankService } from "../services/powerbankService";
import { regexConstants } from "./constant/regex";

export const powerBankSearchController = (inputValue, options) => {
  const value = inputValue ? inputValue.trim() : null;

  if (regexConstants.email.test(value) || (!value && options)) {
    return powerbankService({ type: "collection", value, options });
  }

  if (regexConstants.stationNumber.test(value)) {
    return powerbankService({ type: "stationNumber", value });
  }

  if (regexConstants.stationId.test(value)) {
    return powerbankService({ type: "stationId", value });
  }

  if (regexConstants.powerbankNumber.test(value)) {
    return powerbankService({ type: "powerbankNumber", value });
  }

  if (regexConstants.powerbankId.test(value)) {
    return powerbankService({ type: "powerbankId", value });
  }

  return false;
};

export const powerBankDownloadController = (options) => {
  const value = options.search ? options.search.trim() : null;
  delete options.search;

  if (regexConstants.email.test(value) || !value) {
    return powerbankService({
      type: "download",
      options: { ...options, merchantEmail: value },
    });
  }

  return false;
};
