export const SIGN_ACTIONS = {
  SIGN_IN_REQUEST: 'SIGN_ACTIONS:SIGN_IN_REQUEST',
  SIGN_IN_SUCCESS: 'SIGN_ACTIONS:SIGN_IN_SUCCESS',
  SIGN_IN_FAIL: 'SIGN_ACTIONS:SIGN_IN_FAIL',
  SIGN_IN_TOKEN:'SIGN_ACTIONS.SIGN_IN_TOKEN',
  CLEAR_SIGN_IN_TOKEN:'SIGN_ACTIONS.CLEAR_SIGN_IN_TOKEN',
  CHANGE_PASSWORD_REQUEST:'SIGN_ACTIONS.CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS:'SIGN_ACTIONS.CHANGE_PASSWORD_SUCCESS'
}

const initialState = {
  signInData: [],
  AuthorizationData:'default',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SIGN_ACTIONS.SIGN_IN_REQUEST : {
      return {...state, signInData: initialState.signInData}
    }
    case SIGN_ACTIONS.SIGN_IN_SUCCESS : {
      return {...state, signInData: action.payload.data}
    }
    case SIGN_ACTIONS.SIGN_IN_FAIL: {
      return {...state, signInData: initialState.signInData}
    }
    case SIGN_ACTIONS.SIGN_IN_TOKEN : {
      return {...state, AuthorizationData: action.payload.data}
    }
    case SIGN_ACTIONS.CLEAR_SIGN_IN_TOKEN : {
      return {...state, AuthorizationData:'default'}
    }

    default: {
      return state
    }
  }
}
