import Pagination from "@material-ui/lab/Pagination";
import React from "react";

const style={
  pagesContainer:{
    marginTop:'10px',
    bottom:30,
    position:'fixed',
    margin: '0 auto',
    width: '80%'
  }
}

export const Page =({PageCount,handleChange,page,onCLick})=>{
  return(
    <div style={style.pagesContainer}>
     <Pagination  onCLick={onCLick} page={page} onChange={handleChange} count={PageCount} color="primary" />
    </div>
  )
}
