import {USER_ACTIONS} from "./usersReducer";

export const getUserList = (page)=>{
  return{
    type:USER_ACTIONS.USER_LIST_REQUEST,
    payload:{page}
  }
}

export const blockUserInfo =(data,page)=>{
  return{
    type:USER_ACTIONS.FOR_BLOCKING_USERS,
    payload:{data,page}
  }
}

export const clearForBlockUser = ()=>{
  return{
    type:USER_ACTIONS.CLEAR_FOR_BLOCKING_USERS
  }
}


export const deleteUserPopup =(data) =>{
  return{
    type:USER_ACTIONS.DELETE_POPUP_INFO,
    payload:{data}
  }
}


export const ClearDeleteUserPopup =() =>{
  return{
    type:USER_ACTIONS.CLEAR_DELETE_POPUP_INFO,
  }
}







export const FilterUserByActive =()=>{
  return{
    type:USER_ACTIONS.FILTER_ACTIVE_USERS
  }
}

export const FilterUserByBlocked =()=>{
  return{
    type:USER_ACTIONS.FILTER_BLOCKED_USERS
  }
}

export const FindUsersByPhoneNumber =(phoneNumber)=>{
  return {
    type: USER_ACTIONS.FINDING_REQUEST,
    payload: {phoneNumber}
  }
}

export const ClearFindingData =()=>{
  return {
    type: USER_ACTIONS.CLEAR_FINDING_DATA,
  }
}
//
// export const GivePromo =()=>{
//   return{
//     type:USER_ACTIONS.GIVE_PROMO,
//     payerEmail:
//   }
// }
export const CechPromoStatus =(phoneNumber)=>{
  return{
    type:USER_ACTIONS.CECH_PROMO_STATUS_REQUEST,
    payload:{phoneNumber}
  }
}

export const GivePromoToUser =(data)=>{
  return{
    type:USER_ACTIONS.GIVE_PROMO_REQUEST,
    payload:{data}
  }
}

export const GetAllPromoTariffs = () => {
  return {
    type: USER_ACTIONS.GET_ALL_PROMO_TARIFFS,
  }
}

export const SetChosenPromoTariff = (name, id) => {
  return {
    type: USER_ACTIONS.SET_CHOSEN_PROMO_TARIFF,
    payload: {name, id}
  }
}

export const GetUserCards =(id)=>{
  return{
    type:USER_ACTIONS.GET_USER_CARD_REQUEST,
    payload:{id}
  }
}
export const ClearUserCards =( )=>{
  return{
    type:USER_ACTIONS.CLEAR_USER_CARD_REQUEST,
  }
}
